import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { MarkerType } from 'core/three/object/type';
import { MarkerField } from 'constants/markerFields';

import { ReactComponent as ArrowIcon } from 'assets/images/icon/arrow-down.svg';

import Collapse from '@material-ui/core/Collapse';
import CtaNameBox from './PropertyBox/CtaNameBox';
import LinkPropertyBox from './PropertyBox/LinkPropertyBox';
import SpacePropertyBox from './PropertyBox/SpacePropertyBox';
import UpLoadFilePropertyBox from './PropertyBox/UpLoadFilePropertyBox';
import VideoUrlPropertyBox from './PropertyBox/VideoUrlPropertyBox';
import MoreVideoPropertyBox from './PropertyBox/MoreVideoPropertyBox';

const LinkSetting = ({ type, fields }: { type: MarkerType; fields: MarkerField }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const onCloseEvent = () => {
        setOpen((prev) => !prev);
    };

    return (
        <>
            <div className="property-slide-bar" onClick={onCloseEvent}>
                {t('propertyMenu.linkSetting')}
                <button>
                    <ArrowIcon className={open ? 'fa-icon-open' : 'fa-icon-close'} />
                </button>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div>
                    <p className="property-description">{t('propertyMenu.ctaButtonSetting')}</p>
                    {fields.ctaName && <CtaNameBox />}
                    {fields.linkUrl && <LinkPropertyBox />}
                    {fields.moreVideoUrl && <MoreVideoPropertyBox />}
                    {fields.spaceId && <SpacePropertyBox />}
                    {fields.videoUrl && <VideoUrlPropertyBox />}
                    {fields.fileUrl && <UpLoadFilePropertyBox type={type} />}
                </div>
            </Collapse>
        </>
    );
};

export default LinkSetting;
