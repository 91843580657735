import { MarkerType } from 'core/three/object/type';
import Media from 'core/three/object/media/Media';
import Youtube from './videoPlayer/Youtube';
import Vimeo from './videoPlayer/Vimeo';
import GreenVideo from './GreenVideo/GreenVideo';
import BasicVideo from './videoPlayer/BasicVideo';
import StreamVideo from './videoPlayer/StreamVideo';
import { IVideo } from 'core/types/media';
import * as THREE from 'three';
import VideoThumbnail from './VideoThumbnail';
import { VideoType } from './type';

export default class Video extends Media {
    public openPlayer: boolean;
    public videoPlayer: Youtube | Vimeo | GreenVideo | BasicVideo | StreamVideo;
    private videoId?: string;
    private _videoType: VideoType;

    private autoplay: boolean;
    private volume: number;
    private mesh: THREE.Mesh;
    private greenVideo: boolean;

    private thumbnail: VideoThumbnail;
    static enableAutoplay = false;

    private oldThumbnailUrl: string = '';

    constructor() {
        super(MarkerType.VIDEO);
        this.updateRaycastMesh();
    }

    public get json() {
        return {
            ...super['json'],
            autoplay: this.autoplay,
            volume: this.volume,
            greenVideo: this.greenVideo,
        };
    }

    public get videoType() {
        return this._videoType;
    }

    public showLevel() {
        if (!this.lodEnabled) {
            return;
        }
        const level = this.curLevel;

        if (this.prevLevel !== level) {
            this.prevLevel = level;

            if (this._videoType === VideoType.Youtube) {
                if (this.videoPlayer !== undefined) {
                    this.videoPlayer.destroy();
                    this.videoPlayer = undefined;
                    this.object.remove(this.mesh);
                }
            }

            switch (level) {
                case 0:
                    if (this._videoType === VideoType.Youtube || this._videoType === VideoType.Vimeo) {
                        this.thumbnail.visible = true;
                    } else {
                        this.mesh.visible = true;
                    }
                    this.showBoundaryBox(false);
                    break;
                case 1:
                    if (this._videoType === VideoType.Youtube || this._videoType === VideoType.Vimeo) {
                        this.thumbnail.visible = false;
                    } else {
                        this.mesh.visible = false;
                    }
                    this.showBoundaryBox(true);
                    break;
                case 2:
                    if (this._videoType === VideoType.Youtube || this._videoType === VideoType.Vimeo) {
                        this.thumbnail.visible = false;
                    } else {
                        this.mesh.visible = false;
                    }
                    this.showBoundaryBox(false);
                    break;
            }
        }
    }
    public async init(data: IVideo): Promise<void> {
        try {
            super.init(data);
            this.autoplay = data.autoplay ?? false;
            this.openPlayer = data.openPlayer ?? false;
            this.greenVideo = data.greenVideo ?? false;
            this.volume = data.volume ?? 0;
            if (this.fileUrl.includes('youtu')) {
                this._videoType = VideoType.Youtube;
                const url = new URL(this.fileUrl);
                this.videoId = url.searchParams.get('v') || url.pathname.split('/')[1];
                this.css3d = true;
            } else if (this.fileUrl.includes('vimeo')) {
                this._videoType = VideoType.Vimeo;
                const url = new URL(this.fileUrl);
                this.videoId = url.pathname.split('/')[1];
                this.css3d = true;
            } else if (this.greenVideo) {
                this._videoType = VideoType.GreenVideo;
            } else if (/m3u8/.test(this.fileUrl)) {
                this._videoType = VideoType.StreamVideo;
            } else {
                this._videoType = VideoType.BasicVideo;
            }
            await this.loadThumbnail();
            if (this.autoplay && Video.enableAutoplay) this.toggle();
        } catch (error) {
            console.log('Video init() error');
            console.error(error);
        }
    }
    async loadThumbnail() {
        if (this.oldThumbnailUrl === this.thumbnailUrl) return;
        const oldThumbnail = this.thumbnail;
        this.thumbnail = new VideoThumbnail(this.thumbnailUrl, this._videoType);
        await this.thumbnail.init();
        this.thumbnail.position.copy(this.object.position);
        this.thumbnail.rotation.copy(this.object.rotation);
        this.thumbnail.scale.copy(this.object.scale);
        this.object.attach(this.thumbnail);
        this.oldThumbnailUrl = this.thumbnailUrl;

        if (oldThumbnail) {
            oldThumbnail.dispose();
            this.object.remove(oldThumbnail);
        }
    }

    public async setJson(data: IVideo) {
        super.setJson(data);
        this.autoplay = data.autoplay;
        this.volume = data.volume;
        this.greenVideo = data.greenVideo;
        super.markStatusUpdate();
        await this.loadThumbnail();
    }

    public toggle() {
        if (this.videoPlayer === undefined) {
            if (this.lod.getCurrentLevel() === 0) {
                this.loadVideo().then(() => {
                    this.thumbnail.visible = false;
                    this.videoPlayer.playVideo();
                });
            }
        } else {
            this.videoPlayer.toggle();
        }
    }

    private async loadVideo(): Promise<void> {
        let ratio;

        if (this._videoType === VideoType.Youtube) {
            this.videoPlayer = new Youtube(this.videoId);
            this.mesh = this.videoPlayer.createObject();
            ratio = this.videoPlayer.ratio;
        } else if (this._videoType === VideoType.Vimeo) {
            this.videoPlayer = new Vimeo(this.videoId);
            this.mesh = this.videoPlayer.createObject();
            ratio = this.videoPlayer.ratio;
        } else {
            const textureRatio = this.thumbnail.ratio;
            const thumbnailSize = {
                width: 1.0 * this.thumbnail.defaultWidth,
                height: textureRatio * this.thumbnail.defaultWidth,
            };
            if (this._videoType === VideoType.GreenVideo) {
                this.videoPlayer = new GreenVideo(thumbnailSize);
            } else if (this._videoType === VideoType.StreamVideo) {
                this.videoPlayer = new StreamVideo(thumbnailSize);
            } else if (this.videoType === VideoType.BasicVideo) {
                this.videoPlayer = new BasicVideo(thumbnailSize);
            }
            this.mesh = await (this.videoPlayer as BasicVideo).load(this.fileUrl);
        }
        this.object.add(this.mesh);
        this.videoPlayer.setVolume(this.volume);
        this.updateRaycastMesh(this.mesh.geometry);
        this.updateBoundaryBox(2, 2 * ratio, 0.001);
    }

    public destroy() {
        super.destroy();

        //dispose geometry
        if (this.mesh) {
            if (this.mesh.geometry) {
                this.mesh.geometry.dispose();
                this.mesh.geometry = null;
            }
            if (this.mesh.material) {
                //dispose texture
                if ((this.mesh.material as THREE.MeshBasicMaterial).map) {
                    (this.mesh.material as THREE.MeshBasicMaterial).map.dispose();
                    (this.mesh.material as THREE.MeshBasicMaterial).map = null;
                }

                //dispose material
                (this.mesh.material as THREE.Material).dispose();
                this.mesh.material = null;
            }
        }

        this.videoPlayer?.destroy();
        this.thumbnail?.destroy();
    }
    public updateAnimation(deltaSec: number): void {
        if (this.thumbnail) this.thumbnail.updateFrame()
        if (this.videoPlayer instanceof BasicVideo || this.videoPlayer instanceof StreamVideo)
            this.videoPlayer.update();
    }
}
