import _ from 'lodash';
import EventDispatcher from './EventDispatcher';

export default abstract class BaseMgr<T extends { id: string }, E = {}> extends EventDispatcher<E> {
    protected _state: T[] = [];
    protected diff(a: T[], b: T[]) {
        const aMap = a.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {} as T);
        const bMap = b.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {} as T);
        const toDelete = a.filter((item) => !bMap[item.id]);
        const toCreate = b.filter((item) => !aMap[item.id]);
        const toUpdate = b.filter((item) => aMap[item.id]).filter((item) => !_.isEqual(aMap[item.id], bMap[item.id]));
        return {
            toDelete,
            toCreate,
            toUpdate,
        };
    }
    abstract get state(): T[];
    abstract set state(state: T[]);
    abstract create(state: T[]): void;
    abstract update(state: T[]): void;
    abstract delete(state: T[]): void;
}
