import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { withStyles } from '@material-ui/core/styles';

enum UIComponetKey {
    value = 'value',
}

enum PropertyKey {
    name = 'price',
}

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
})(TextField);

const PricePropertyBox: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);
    const maxNameLength: number = 15;

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event, newValue?) {
            markerUpdater.call({ ...propertyData, [propertykey]: newValue || event.target[key] });
        };
    };

    return (
        <div className="TitlePropertyBox">
            <div className="BoxTitle">{t('propertyText.price') + ' :'}</div>
            <CssTextField
                id="price"
                placeholder={t('US $ 3.00')}
                onChange={handleChange(PropertyKey.name, UIComponetKey.value)}
                inputProps={{ maxLength: maxNameLength }}
                variant="outlined"
                size="small"
                value={propertyData.price ?? ''}
            />
        </div>
    );
};

export default PricePropertyBox;
