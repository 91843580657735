import * as THREE from 'three';
import ISceneSlot from 'core/types/sceneSlot';
import CustomObject3D from '../CustomObject3D';
import { EObjectType } from '../type';
import defaultImage from './default.png';
import hoverImage from './hover.png';

export default class SceneSlot extends CustomObject3D {
    public width: number;
    public height: number;
    public depth: number;
    public translateEnabled = true;
    public scaleEnabled = true;
    public rotateEnabled = true;
    private mesh: THREE.Mesh<THREE.BoxGeometry, THREE.MeshBasicMaterial>;
    private defaultMats = new THREE.MeshBasicMaterial({
        transparent: false,
        map: new THREE.TextureLoader().load(defaultImage as any),
    });
    private hoverMats = new THREE.MeshBasicMaterial({
        transparent: false,
        map: new THREE.TextureLoader().load(hoverImage as any),
    });
    constructor() {
        super(EObjectType.SCENESLOT);
    }

    public init(data: ISceneSlot) {
        this.json = data;
        this.createSlot();
    }

    private createSlot() {
        const raycastGeometry = new THREE.BoxGeometry(this.width, this.height, this.depth);
        const geometry = new THREE.BoxGeometry(this.width, this.height, this.depth);
        this.mesh = new THREE.Mesh(geometry, this.defaultMats);
        this.object.add(this.mesh);
        this.updateRaycastMesh(geometry);
    }

    set json(data: ISceneSlot) {
        super.setJson(data);
        this.id = data.id;
        this.width = data.width ?? 1;
        this.height = data.height ?? 1;
        this.depth = data.depth ?? 1;
        this.translateEnabled = data.translateEnabled;
        this.rotateEnabled = data.rotateEnabled;
        this.scaleEnabled = data.scaleEnabled;
        this.object.position.set(data.position.x, data.position.y, data.position.z);
        this.object.quaternion.set(data.rotation.x, data.rotation.y, data.rotation.z, data.rotation.w);
    }

    get json(): ISceneSlot {
        return {
            id: this.id,
            width: this.width,
            height: this.height,
            depth: this.depth,
            translateEnabled: this.translateEnabled,
            rotateEnabled: this.rotateEnabled,
            scaleEnabled: this.scaleEnabled,
            ...(super['json'] as any),
        };
    }

    onClick(): void {}
    onMouseDown(): void {}
    onMouseEnter(): void {
        this.mesh.material = this.hoverMats;
        this.mesh.material.needsUpdate = true;
        document.body.style.cursor = 'pointer';
    }
    onMouseLeave(): void {
        this.mesh.material = this.defaultMats;
        this.mesh.material.needsUpdate = true;
        document.body.style.cursor = 'auto';
    }
    onMouseMove(): void {}
    onMouseUp(): void {}
    show() {
        this.mesh.visible = true;
    }
    hide() {
        this.mesh.visible = false;
    }
}
