import Vimeo from '@vimeo/player';
import PlayerFactory from 'youtube-player';

export default class VideoPlayerBase {
    public videoID: string;
    public videoElement: HTMLDivElement;
    public timer: any;
    public isPlaying: Boolean;
    public player: ReturnType<typeof PlayerFactory> | Vimeo;
    public width: number = 640;
    public height: number = 360;
    public firstLoad: boolean = true;

    constructor(videoId: string) {
        this.videoID = videoId;
    }

    toggle() {
        if (this.isPlaying) this.pauseVideo();
        else this.playVideo();
    }

    playVideo() {}

    pauseVideo() {}

    getVolume() {}

    getCurrentTime() {}

    setCurrentTime(time: number) {}

    destroy() {
        if (this.videoElement) {
            this.videoElement.remove();
        }
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    public get ratio() {
        return this.height / this.width;
    }
}
