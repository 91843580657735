const PlatfromKeys = {
    development: {
        productId: '402886ee7b2de269017b2de3a22e0000',
        productKey: 'test',
    },
    test: {
        productId: '402886ee7b2de269017b2de3a22e0000',
        productKey: 'test',
    },
    production: {
        productId: '402886f27c112667017c1127e3910000',
        productKey: '9d209d0abfeae970ab484839a943c600',
    },
};

export default PlatfromKeys[process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV];
