import Model from 'core/three/object/media/model/Model';
import Tag from 'core/three/object/media/image/Tag';
// import Gif from 'core/three/object/media/image/Gif';
import Gif from 'core/three/object/media/image/GifOnCanvas';
import NpcModel from 'core/three/object/media/model/NpcModel';
import Text from 'core/three/object/media/text/Text';
import Video from 'core/three/object/media/video/Video';
import WebWall from 'core/three/object/media/web/WebWall';
import Hotspot from 'core/three/object/hotspot/Hotspot';
import Portal from 'core/three/object/media/image/Portal';
import WebSite from 'core/three/object/media/web/WebSite';
import MUSIC from 'core/three/object/media/image/Music';
import ARTag from 'core/three/object/media/arTag/ARTag';
import VisitorHeadshot from 'core/three/object/media/image/VisitorHeadshot';
import AvatarModel from 'core/three/object/media/model/AvatarModel';

const CustomObjects = {
    Model,
    Tag,
    Gif,
    Text,
    Video,
    WebWall,
    Hotspot,
    Portal,
    WebSite,
    NpcModel,
    MUSIC,
    ARTag,
    VisitorHeadshot,
    AvatarModel,
};

export default CustomObjects;
