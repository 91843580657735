import EventDispatcher from './EventDispatcher';
import CustomObject from 'core/three/object';
import { IMarker } from 'core/types/media';
import { MarkerType, MediaStatus } from '../object/type';
import Media from '../object/media/Media';
import { RenderFrame } from 'core/types';

interface MarkerMgrEvent {
    inited: Media;
    created: Media;
    deleted: Media;
    updated: Media;
}
export default class MarkerMgr extends EventDispatcher<MarkerMgrEvent> {
    public inited = 'inited' as const;
    public created = 'created' as const;
    public deleted = 'deleted' as const;
    public updated = 'updated' as const;
    public mediaList: Media[] = [];

    public get mediaStatus() {
        const created = this.mediaList.filter((marker) => marker.status === MediaStatus.CREATED).length;
        const updated = this.mediaList.filter((marker) => marker.status === MediaStatus.UPDATED).length;
        const deleted = this.mediaList.filter((marker) => marker.status === MediaStatus.DELETED).length;
        const read = this.mediaList.filter((marker) => marker.status === MediaStatus.READ).length;
        return {
            created,
            updated,
            deleted,
            read,
        };
    }

    public create(markers: IMarker | IMarker[]) {
        markers = markers instanceof Array ? markers : [markers];
        return Promise.all(markers.map((marker) => this.createMedia(marker)));
    }

    public resetMedia() {
        while (this.mediaList.length > 0) {
            this.deleteMedia(this.mediaList[0]);
        }
    }

    public deleteMedia(media: Media) {
        media.destroy();
        const index = this.mediaList.indexOf(media);
        if (index !== -1) this.mediaList.splice(index, 1);
        this.dispatchEvent({ type: this.deleted, data: media });
    }

    public async createMedia(marker: IMarker) {
        let media: Media;
        switch (marker.type) {
            case MarkerType.MODEL:
                if (marker.fileUrl.includes('npc')) media = new CustomObject.NpcModel();
                else media = new CustomObject.Model();
                break;
            case MarkerType.TAG:
                media = new CustomObject.Tag();
                break;
            case MarkerType.GIF:
                media = new CustomObject.Gif();
                break;
            case MarkerType.WEBWALL:
                media = new CustomObject.WebWall();
                break;
            case MarkerType.VIDEO:
                media = new CustomObject.Video();
                break;
            case MarkerType.TEXT:
                media = new CustomObject.Text();
                break;
            case MarkerType.PORTAL:
                media = new CustomObject.Portal();
                break;
            case MarkerType.WEBSITE:
                media = new CustomObject.WebSite();
                break;
            case MarkerType.MUSIC:
                media = new CustomObject.MUSIC();
                break;
            case MarkerType.ARTAG:
                media = new CustomObject.ARTag();
        }
        if (media == null) return;
        this.dispatchEvent({ type: this.created, data: media });
        await media.init(marker);
        // todo: move the following into media init?
        media.manager = this;
        media.object.position.set(marker.position.x, marker.position.y, marker.position.z);
        media.object.quaternion.set(marker.rotation.x, marker.rotation.y, marker.rotation.z, marker.rotation.w);
        media.object.scale.set(marker.scale.x, marker.scale.y, marker.scale.z);
        // media.LODLevel = marker.LODLevel; // todo: remove?
        // media.showLevel();// todo: remove?

        if (media.undoRedoId) {
            const index = this.mediaList.findIndex((m) => media.undoRedoId === m.undoRedoId);
            if (index > -1) {
                const [oldMedia] = this.mediaList.splice(index, 1);
                if (oldMedia.id) {
                    media.status = MediaStatus.UPDATED;
                } else {
                    media.status = MediaStatus.CREATED;
                }
            }
        }

        this.dispatchEvent({ type: this.inited, data: media });
        this.mediaList.push(media);
        return media;
    }

    public onMediaUpdate(media: Media) {
        this.dispatchEvent({ type: this.updated, data: media });
    }

    public update(renderFrame: RenderFrame) {
        this.mediaList.forEach((media) => media.update(renderFrame));
    }
}
