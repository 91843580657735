import * as THREE from 'three';
import { EASING_ANIME } from './AnimationMgr';
import CameraMgr from './CameraMgr';
import ViewportControls from './ViewportControls';
import EventDispatcher from './EventDispatcher';
import anime from 'animejs';

const posEasing = (t: number) => {
    return t < 0.5 ? 8 * t ** 4 : 1 - 8 * (t - 1) ** 4;
};


const getDurationByDistance = (distance: number) => {
    let duration = distance * 150;
    if (duration < 1) { // todo: check if needed
        duration = 25; // If duration < 1, it would not active change panorama
    }
    return Math.min(duration, 1500);
};

const getDurationByAngle = (angle) => {
    return (angle * 180) / Math.PI * 10;
};

const getDurationByZoom = (zoom) => {
    return zoom * 100
};

interface ViewporAnimatorEvents {
    rotateStart: void;
    rotateEnd: void;
    moveStart: void;
    moveEnd: void;
    zoomStart: void;
    zoomEnd: void
}

export default class ViewportAnimator extends EventDispatcher<ViewporAnimatorEvents> {
    public viewporControls: ViewportControls;
    public floorplanPolar: number = 0;
    public dollHousePolar: number = Math.PI / 4;
    public prevGroundPolar: number = 0
    public prevGroundAzimuth: number = Math.PI
    public prevZoomInDistance: number = 0

    constructor(viewporControls: ViewportControls) {
        super()
        this.viewporControls = viewporControls;
    }

    public goAction(position: THREE.Vector3) {
        const action = this.goPositionAction(position);
        const distance = this.viewporControls.getObjectPosition().distanceTo(position);
        const duration = getDurationByDistance(distance);
        action.duration = duration
        return action
    }

    public zoomInAction() {
        return this.zoomAction(this.prevZoomInDistance)
    }

    public zommOutAction() {
        this.prevZoomInDistance = this.viewporControls.distance
        return this.zoomAction(15)
    }

    public zoomAction(dstDistance = this.viewporControls.distance) {
        const srcDistance = this.viewporControls.distance
        const duration = getDurationByZoom(Math.abs(dstDistance - srcDistance))
        return {
            duration,
            easing: EASING_ANIME.LINEAR,
            begin: () => this.dispatchEvent({ type: 'zoomStart', data: void 0 }),
            targets: this.viewporControls,
            distance: [srcDistance, dstDistance],
            complete: () => this.dispatchEvent({ type: 'zoomEnd', data: void 0 }),
        }
    }

    public rotateToAction(azimuthAngle = this.viewporControls.azimuthAngle, polarAngle = this.viewporControls.polarAngle) {
        const srcAzimuthAngle = this.viewporControls.azimuthAngle;
        const srcPolarAngle = this.viewporControls.polarAngle;
        const dstAzimuthAngle = azimuthAngle;
        const dstPolarAngle = polarAngle;
        const maxAngle = Math.max(Math.abs(srcAzimuthAngle - dstAzimuthAngle), Math.abs(srcPolarAngle - dstPolarAngle));
        const duration = getDurationByAngle(maxAngle);
        return {
            duration,
            begin: () => this.dispatchEvent({ type: 'rotateStart', data: void 0 }),
            targets: this.viewporControls,
            easing: EASING_ANIME.EASE_IN_OUT_SINE,
            polarAngle: [srcPolarAngle, dstPolarAngle],
            azimuthAngle: [srcAzimuthAngle, dstAzimuthAngle],
            complete: () => this.dispatchEvent({ type: 'rotateEnd', data: void 0 }),
        };
    }

    public rotateAction(azimuthAngle = 0, polarAngle = 0) {
        return this.rotateToAction(this.viewporControls.azimuthAngle + azimuthAngle, this.viewporControls.polarAngle + polarAngle)
    }

    public rotateToGroundAction(azimuthAngle = this.prevGroundAzimuth, polarAngle = this.prevGroundPolar) {
        return this.rotateToAction(azimuthAngle, polarAngle);
    }

    public rotateToDollhouseAction() {
        this.prevGroundPolar = this.viewporControls.polarAngle
        this.prevGroundAzimuth = this.viewporControls.azimuthAngle
        return this.rotateToAction(undefined, this.dollHousePolar);
    }

    public rotateToFloorplanAction() {
        this.prevGroundPolar = this.viewporControls.polarAngle
        this.prevGroundAzimuth = this.viewporControls.azimuthAngle
        return this.rotateToAction(undefined, this.floorplanPolar);
    }

    private goPositionAction(dstTargetPosition: THREE.Vector3): anime.AnimeParams {
        const srcTargetPosition = this.viewporControls.getTargetPosition();
        return {
            begin: () => this.dispatchEvent({ type: 'moveStart', data: void 0 }),
            easing: EASING_ANIME.EASE_IN_OUT_SINE,
            update: (anime) => {
                const targetPosition = new THREE.Vector3().lerpVectors(srcTargetPosition, dstTargetPosition, posEasing(anime.progress / 100));
                this.viewporControls.setTargetPosition(targetPosition);
            },
            complete: () => {
                this.viewporControls.setTargetPosition(dstTargetPosition);
                this.dispatchEvent({ type: 'moveEnd', data: void 0 });
            },
        };
    }

}