import * as THREE from 'three';
import TransformControlsGizmo from './TransformControlsGizmo';
import TransformControlsPlane from './TramsformConrolsPlane';
import { EObjectType, ObjectType, MarkerType } from '../object/type';
import Media from '../object/media/Media';
import { Vector3 } from 'three';

export interface LimitBboxDict {
    [id: string]: THREE.Box3;
}

export interface PreviousStatus {
    position: THREE.Vector3;
    rotation: THREE.Euler;
    scale: THREE.Vector3;
}

export default class TransformControls extends THREE.Object3D<any> {
    scope: this;
    domElement: HTMLElement;

    camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
    object: THREE.Object3D | undefined;
    previousStatus: PreviousStatus | null;
    boxHelper: THREE.BoxHelper;
    isVertical: boolean;
    mediatype: ObjectType;
    media: Media;
    boundaryBox: THREE.Object3D;
    proportionalScale: boolean;
    enabled: boolean;
    showHelper: boolean;
    axis: string | null;
    mode: string;
    translationSnap: number | null;
    rotationSnap: number | null;
    scaleSnap: number | null;
    space: string;
    size: number;
    dragging: boolean;
    showX: boolean;
    showY: boolean;
    showZ: boolean;
    showXYZ: boolean;
    visible: boolean;
    gizmo: TransformControlsGizmo;
    plane: TransformControlsPlane;
    moveWithPointer: boolean;

    pointStart: THREE.Vector3;
    pointEnd: THREE.Vector3;
    offset: THREE.Vector3;
    startNorm: THREE.Vector3;
    endNorm: THREE.Vector3;
    rotationAxis: THREE.Vector3;
    rotationAngle: number;

    cameraPosition: THREE.Vector3;
    cameraQuaternion: THREE.Quaternion;
    cameraScale: THREE.Vector3;

    parentPosition: THREE.Vector3;
    parentQuaternion: THREE.Quaternion;
    parentQuaternionInv: THREE.Quaternion;
    parentScale: THREE.Vector3;

    worldPositionStart: THREE.Vector3;
    worldQuaternionStart: THREE.Quaternion;
    worldScaleStart: THREE.Vector3;

    worldPosition: THREE.Vector3;
    worldQuaternion: THREE.Quaternion;
    worldQuaternionInv: THREE.Quaternion;
    worldScale: THREE.Vector3;

    eye: THREE.Vector3;

    positionStart: THREE.Vector3;
    quaternionStart: THREE.Quaternion;
    scaleStart: THREE.Vector3;

    changeEvent: { type: string };
    mouseDownEvent: { type: string; mode: string };
    mouseUpEvent: { type: string; mode: string; undoRedoId: string };
    objectChangeEvent: { type: string };
    raycaster: THREE.Raycaster;

    _tempVector: THREE.Vector3;
    _tempVector2: THREE.Vector3;
    _tempQuaternion: THREE.Quaternion;
    _unit: { X: THREE.Vector3; Y: THREE.Vector3; Z: THREE.Vector3 };

    public getCustumRaycastPoint;

    readonly isTransformControls: true;
    mouseButtons: {
        LEFT: THREE.MOUSE;
        MIDDLE: THREE.MOUSE;
        RIGHT: THREE.MOUSE;
    };
    modeChangeEvent: { type: string; mode: string };

    private _enableLimitInBboxMode: boolean = false;
    private limitBboxDictionary: LimitBboxDict;
    private limitBboxExpandVector: THREE.Vector3;

    constructor(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera, domElement) {
        super();
        if (domElement === undefined) {
            console.warn('THREE.TransformControls: The second parameter "domElement" is now mandatory.');
            domElement = document;
        }

        //THREE.Object3D.call( this );

        this.visible = false;
        this.domElement = domElement;

        let _gizmo = new TransformControlsGizmo(this);
        this.gizmo = _gizmo;

        let _plane = new TransformControlsPlane(this);
        this.plane = _plane;

        this.add(this.gizmo);
        this.add(this.plane);

        this.camera = camera;

        this.object = undefined;
        this.enabled = true;
        this.axis = null;
        this.mode = 'translate';
        this.translationSnap = null;
        this.rotationSnap = null;
        this.scaleSnap = null;
        this.space = 'world';
        this.size = 1;
        this.dragging = false;
        this.showX = true;
        this.showY = true;
        this.showZ = true;
        this.showXYZ = true;

        this.scope = this;

        this.changeEvent = { type: 'change' };
        this.mouseDownEvent = { type: 'mouseDown', mode: this.scope.mode };
        this.mouseUpEvent = { type: 'mouseUp', mode: this.scope.mode, undoRedoId: '' };
        this.objectChangeEvent = { type: 'objectChange' };

        this.modeChangeEvent = { type: 'modeChange', mode: this.scope.mode };

        this.raycaster = new THREE.Raycaster();

        this._tempVector = new THREE.Vector3();
        this._tempVector2 = new THREE.Vector3();
        this._tempQuaternion = new THREE.Quaternion();
        this._unit = {
            X: new THREE.Vector3(1, 0, 0),
            Y: new THREE.Vector3(0, 1, 0),
            Z: new THREE.Vector3(0, 0, 1),
        };

        this.pointStart = new THREE.Vector3();
        this.pointEnd = new THREE.Vector3();
        this.offset = new THREE.Vector3();
        this.rotationAxis = new THREE.Vector3();
        this.startNorm = new THREE.Vector3();
        this.endNorm = new THREE.Vector3();
        this.rotationAngle = 0;

        this.cameraPosition = new THREE.Vector3();
        this.cameraQuaternion = new THREE.Quaternion();
        this.cameraScale = new THREE.Vector3();

        this.parentPosition = new THREE.Vector3();
        this.parentQuaternion = new THREE.Quaternion();
        this.parentQuaternionInv = new THREE.Quaternion();
        this.parentScale = new THREE.Vector3();

        this.worldPositionStart = new THREE.Vector3();
        this.worldQuaternionStart = new THREE.Quaternion();
        this.worldScaleStart = new THREE.Vector3();

        this.worldPosition = new THREE.Vector3();
        this.worldQuaternion = new THREE.Quaternion();
        this.worldQuaternionInv = new THREE.Quaternion();
        this.worldScale = new THREE.Vector3();

        this.eye = new THREE.Vector3();

        this.positionStart = new THREE.Vector3();
        this.quaternionStart = new THREE.Quaternion();
        this.scaleStart = new THREE.Vector3();

        this.onPointerDown = this.onPointerDown.bind(this);
        this.onPointerMove = this.onPointerMove.bind(this);
        this.onPointerHover = this.onPointerHover.bind(this);
        this.onPointerUp = this.onPointerUp.bind(this);

        domElement.addEventListener('pointerdown', this.onPointerDown, false);
        domElement.addEventListener('pointermove', this.onPointerHover, false);
        this.scope.domElement.ownerDocument.addEventListener('pointerup', this.onPointerUp, false);

        this.limitBboxDictionary = {};
        this.limitBboxExpandVector = new THREE.Vector3(0, 0, 0);
    }

    get enableLimitInBboxMode() {
        return this._enableLimitInBboxMode;
    }

    intersectObjectWithRay(object, raycaster, includeInvisible) {
        var allIntersections = raycaster.intersectObject(object, true);

        for (var i = 0; i < allIntersections.length; i++) {
            if (allIntersections[i].object.visible || includeInvisible) {
                return allIntersections[i];
            }
        }

        return false;
    }

    dispose() {
        this.domElement.removeEventListener('pointerdown', this.onPointerDown);
        this.domElement.removeEventListener('pointermove', this.onPointerHover);

        this.scope.domElement.ownerDocument.removeEventListener('pointermove', this.onPointerMove);
        this.scope.domElement.ownerDocument.removeEventListener('pointerup', this.onPointerUp);
        this.traverse(function (child: any) {
            if (child.geometry) child.geometry.dispose();
            if (child.material) child.material.dispose();
        });
    }

    attachObject(obj: Media | THREE.Object3D) {
        if (obj instanceof Media) {
            this.media = obj;
            this.boundaryBox = this.media.boundaryBox;
            this.mediatype = obj.type;
            this.isVertical = obj.isVertical;
            this.attach(obj.object);
        } else if (obj instanceof THREE.Object3D) {
            this.mediatype = MarkerType.MODEL;
            this.isVertical = false;
            this.media = null;
            this.attach(obj);
        }
    }

    attach(object: THREE.Object3D): this {
        this.object = object;
        this.visible = true;

        this.boxHelper?.parent?.remove(this.boxHelper);
        const quaternion = this.object.quaternion.clone();
        this.object.quaternion.set(0, 0, 0, 1);

        // if (this.media instanceof Image) {
        //     let hotspotTag = this.media.gethotspotTag
        //     object.remove(hotspotTag);
        //     this.boxHelper = new THREE.BoxHelper(object, 0xffff00);
        //     object.add(hotspotTag);
        // }

        this.boxHelper = new THREE.BoxHelper(this.object, 0x0077ff);
        this.object.quaternion.copy(quaternion);
        this.boxHelper.applyMatrix4(this.object.matrixWorld.invert());
        this.object.add(this.boxHelper);

        return this;
    }

    detach(): this {
        this.object = undefined;
        this.visible = false;
        this.axis = null;
        this.boxHelper?.parent?.remove(this.boxHelper);
        this.media = null;
        this.boundaryBox = null;
        this.isVertical = false;
        this.mediatype = undefined;
        return this;
    }

    public checkIfAttachObjInBbox() {
        // if not enable limit in bbox mode always return true
        if (!this._enableLimitInBboxMode) return true;

        if (this.object && this.media && this.media instanceof Media) {
            const pos = this.object.position;
            if (this.checkPosInLimitBox(pos)) {
                return true;
            } else {
                return false;
            }
        }
    }

    private computeHeightOffset(): number {
        if (this.media == null) return 0.5;
        let offset;
        let worldPos = new THREE.Vector3();
        let scale = new THREE.Vector3();
        this.boxHelper.getWorldPosition(worldPos);
        this.boxHelper.getWorldScale(scale);

        if (this.isVertical) {
            if (this.mediatype === MarkerType.MODEL || this.mediatype === EObjectType.BOOTH) {
                offset = this.media.widthoffset * this.object.scale.z;
            } else {
                offset = this.media.heightoffset * this.object.scale.y;
            }
        } else {
            if (this.mediatype === MarkerType.MODEL) {
                offset = this.media.heightoffset * this.object.scale.y;
            } else {
                offset = this.media.widthoffset * this.object.scale.z + 0.001;
            }
        }
        offset = Math.abs(offset);
        return offset;
    }

    updateMatrixWorld() {
        if (this.object !== undefined) {
            this.object.updateMatrixWorld();

            if (this.object.parent === null) {
                console.error('TransformControls: The attached 3D object must be a part of the scene graph.');
            } else {
                this.object.parent.matrixWorld.decompose(this.parentPosition, this.parentQuaternion, this.parentScale);
            }

            this.object.matrixWorld.decompose(this.worldPosition, this.worldQuaternion, this.worldScale);

            this.parentQuaternionInv.copy(this.parentQuaternion).invert();
            this.worldQuaternionInv.copy(this.worldQuaternion).invert();
        }
        this.camera.updateMatrixWorld();
        this.camera.matrixWorld.decompose(this.cameraPosition, this.cameraQuaternion, this.cameraScale);

        this.eye.copy(this.cameraPosition).sub(this.worldPosition).normalize();

        THREE.Object3D.prototype.updateMatrixWorld.call(this);
    }

    pointerHover(pointer) {}

    pointerDown(pointer) {
        if (this.object === undefined || this.dragging === true || pointer.button !== 0) return;

        this.raycaster.setFromCamera(pointer, this.camera);
        let intersect = this.intersectObjectWithRay(this.gizmo.picker[this.mode], this.raycaster, false);

        if (intersect) {
            this.axis = intersect.object.name;
        }

        this.previousStatus = {
            position: this.object.position.clone(),
            rotation: this.object.rotation.clone(),
            scale: this.object.scale.clone(),
        };
        if (this.media !== null) {
            this.isVertical = this.media.isVertical;
        }
        if (this.axis !== null) {
            this.raycaster.setFromCamera(pointer, this.camera);

            var planeIntersect = this.intersectObjectWithRay(this.plane, this.raycaster, true);

            if (planeIntersect) {
                var space = this.space;

                if (this.mode === 'scale') {
                    space = 'local';
                } else if (this.axis === 'E' || this.axis === 'XYZE' || this.axis === 'XYZ') {
                    space = 'world';
                }

                if (space === 'local' && this.mode === 'rotate') {
                    var snap = this.rotationSnap;

                    if (this.axis === 'X' && snap)
                        this.object.rotation.x = Math.round(this.object.rotation.x / snap) * snap;
                    if (this.axis === 'Y' && snap)
                        this.object.rotation.y = Math.round(this.object.rotation.y / snap) * snap;
                    if (this.axis === 'Z' && snap)
                        this.object.rotation.z = Math.round(this.object.rotation.z / snap) * snap;
                }

                this.object.updateMatrixWorld();
                this.object.parent.updateMatrixWorld();

                this.positionStart.copy(this.object.position);
                this.quaternionStart.copy(this.object.quaternion);
                this.scaleStart.copy(this.object.scale);

                this.object.matrixWorld.decompose(
                    this.worldPositionStart,
                    this.worldQuaternionStart,
                    this.worldScaleStart,
                );

                this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart);
            }

            this.dragging = true;
            this.mouseDownEvent.mode = this.mode;
            this.dispatchEvent(this.mouseDownEvent);

            if (this.mode === 'translate' && this.axis === 'XYZ') {
                this.setShowHelper(false);
            }
        }
    }

    pointerMove(pointer) {
        let axis = this.axis;
        let mode = this.mode;
        let object = this.object;
        let space = this.space;

        if (mode === 'scale') {
            space = 'local';
        } else if (axis === 'E' || axis === 'XYZE' || axis === 'XYZ') {
            space = 'world';
        }

        if (object === undefined || axis === null || this.dragging === false) return;
        this.raycaster.setFromCamera(pointer, this.camera);

        let planeIntersect = this.intersectObjectWithRay(this.plane, this.raycaster, true);

        if (!planeIntersect) this.pointEnd = new THREE.Vector3(0, 0, 0);
        else this.pointEnd.copy(planeIntersect.point).sub(this.worldPositionStart);

        if (mode === 'translate') {
            // Apply translate
            this.offset.copy(this.pointEnd).sub(this.pointStart);

            if (space === 'local' && axis !== 'XYZ') {
                this.offset.applyQuaternion(this.worldQuaternionInv);
            }

            if (axis.indexOf('X') === -1) this.offset.x = 0;
            if (axis.indexOf('Y') === -1) this.offset.y = 0;
            if (axis.indexOf('Z') === -1) this.offset.z = 0;

            if (space === 'local' && axis !== 'XYZ') {
                this.offset.applyQuaternion(this.quaternionStart).divide(this.parentScale);
            } else {
                this.offset.applyQuaternion(this.parentQuaternionInv).divide(this.parentScale);
            }

            if (axis !== 'XYZ') {
                const targetPos = new Vector3().copy(this.offset).add(this.positionStart);

                // limit in box
                if (this._enableLimitInBboxMode) {
                    if (this.checkPosInLimitBox(targetPos)) {
                        object.position.copy(this.offset).add(this.positionStart);
                    }
                } else {
                    object.position.copy(this.offset).add(this.positionStart);
                }
            } else {
                if (!this.getCustumRaycastPoint || !this.showXYZ) return;

                let sceneModelntersect = this.getCustumRaycastPoint(pointer);
                if (!sceneModelntersect) return;

                let intersectPoint = new THREE.Vector3();
                if (sceneModelntersect.point.distanceTo(this.camera.position) > 20) {
                    intersectPoint = this.camera.position
                        .clone()
                        .add(sceneModelntersect.point.normalize().multiplyScalar(3));
                } else {
                    intersectPoint = sceneModelntersect.point;
                }
                this.offset.copy(intersectPoint);
                // limit in box
                if (this._enableLimitInBboxMode) {
                    const targetPos = new Vector3().copy(this.offset);
                    if (this.checkPosInLimitBox(targetPos)) {
                        object.position.copy(this.offset);
                    } else {
                        return;
                    }
                } else {
                    object.position.copy(this.offset);
                }

                const worldNormal = sceneModelntersect.face.normal.clone();
                sceneModelntersect.object.updateMatrixWorld(true);
                worldNormal.transformDirection(sceneModelntersect.object.matrixWorld);

                let objNormal;

                if (this.mediatype === MarkerType.MODEL) {
                    if (this.isVertical) objNormal = new Vector3(0, 0, 1);
                    else objNormal = new Vector3(0, 1, 0);
                } else {
                    if (this.isVertical) objNormal = new Vector3(0, 1, 0);
                    else objNormal = new Vector3(0, 0, 1);
                }

                let objWorldNormal = objNormal.clone();
                objWorldNormal.applyEuler(this.object.rotation);

                let mat = new THREE.Matrix4();

                if (objWorldNormal.manhattanDistanceTo(worldNormal) > 0.0001) {
                    const up = objNormal;
                    let axis;
                    if (up.equals(new THREE.Vector3(0, 1, 0))) {
                        if (worldNormal.y === 1 || worldNormal.y === -1) axis = new THREE.Vector3(1, 0, 0);
                        else axis = up.cross(worldNormal);
                        // determine the amount to rotate
                        const radians = Math.acos(worldNormal.dot(up));
                        // create a rotation matrix that implements that rotation
                        mat.makeRotationAxis(axis, radians);
                    } else {
                        mat = new THREE.Matrix4().lookAt(worldNormal, new THREE.Vector3(0, 0, 0), this.camera.up);
                    }

                    this.object.setRotationFromMatrix(mat);
                    if (this.mediatype === MarkerType.MODEL && this.isVertical) {
                        this.object.rotateX(Math.PI);
                    }
                }

                let offset = this.computeHeightOffset();

                let off = worldNormal.multiplyScalar(offset);
                object.position.add(off);
            }

            if (this.translationSnap) {
                if (space === 'local') {
                    object.position.applyQuaternion(this._tempQuaternion.copy(this.quaternionStart).invert());

                    if (axis.search('X') !== -1) {
                        object.position.x = Math.round(object.position.x / this.translationSnap) * this.translationSnap;
                    }

                    if (axis.search('Y') !== -1) {
                        object.position.y = Math.round(object.position.y / this.translationSnap) * this.translationSnap;
                    }

                    if (axis.search('Z') !== -1) {
                        object.position.z = Math.round(object.position.z / this.translationSnap) * this.translationSnap;
                    }

                    object.position.applyQuaternion(this.quaternionStart);
                }

                if (space === 'world') {
                    if (object.parent) {
                        object.position.add(this._tempVector.setFromMatrixPosition(object.parent.matrixWorld));
                    }

                    if (axis.search('X') !== -1) {
                        object.position.x = Math.round(object.position.x / this.translationSnap) * this.translationSnap;
                    }

                    if (axis.search('Y') !== -1) {
                        object.position.y = Math.round(object.position.y / this.translationSnap) * this.translationSnap;
                    }

                    if (axis.search('Z') !== -1) {
                        object.position.z = Math.round(object.position.z / this.translationSnap) * this.translationSnap;
                    }

                    if (object.parent) {
                        object.position.sub(this._tempVector.setFromMatrixPosition(object.parent.matrixWorld));
                    }
                }
            }
        } else if (mode === 'scale') {
            if (axis.search('XYZ') !== -1) {
                let d = this.pointEnd.length() / this.pointStart.length();

                if (this.pointEnd.dot(this.pointStart) < 0) d *= -1;

                this._tempVector2.set(d, d, d);
            } else {
                this._tempVector.copy(this.pointStart);
                this._tempVector2.copy(this.pointEnd);

                this._tempVector.applyQuaternion(this.worldQuaternionInv);
                this._tempVector2.applyQuaternion(this.worldQuaternionInv);

                this._tempVector2.divide(this._tempVector);

                if (axis.search('X') === -1) {
                    this._tempVector2.x = 1;
                }

                if (axis.search('Y') === -1) {
                    this._tempVector2.y = 1;
                }

                if (axis.search('Z') === -1) {
                    this._tempVector2.z = 1;
                }
            }

            // Apply scale

            object.scale.copy(this.scaleStart).multiply(this._tempVector2);

            if (this.scaleSnap) {
                if (axis.search('X') !== -1) {
                    object.scale.x = Math.round(object.scale.x / this.scaleSnap) * this.scaleSnap || this.scaleSnap;
                }

                if (axis.search('Y') !== -1) {
                    object.scale.y = Math.round(object.scale.y / this.scaleSnap) * this.scaleSnap || this.scaleSnap;
                }

                if (axis.search('Z') !== -1) {
                    object.scale.z = Math.round(object.scale.z / this.scaleSnap) * this.scaleSnap || this.scaleSnap;
                }
            }
        } else if (mode === 'rotate') {
            this.offset.copy(this.pointEnd).sub(this.pointStart);

            let ROTATION_SPEED =
                20 / this.worldPosition.distanceTo(this._tempVector.setFromMatrixPosition(this.camera.matrixWorld));

            if (axis === 'E') {
                this.rotationAxis.copy(this.eye);
                this.rotationAngle = this.pointEnd.angleTo(this.pointStart);

                this.startNorm.copy(this.pointStart).normalize();
                this.endNorm.copy(this.pointEnd).normalize();

                this.rotationAngle *= this.endNorm.cross(this.startNorm).dot(this.eye) < 0 ? 1 : -1;
            } else if (axis === 'XYZE') {
                this.rotationAxis.copy(this.offset).cross(this.eye).normalize();
                this.rotationAngle =
                    this.offset.dot(this._tempVector.copy(this.rotationAxis).cross(this.eye)) * ROTATION_SPEED;
            } else if (axis === 'X' || axis === 'Y' || axis === 'Z') {
                this.rotationAxis.copy(this._unit[axis]);

                this._tempVector.copy(this._unit[axis]);

                if (space === 'local') {
                    this._tempVector.applyQuaternion(this.worldQuaternion);
                }

                this.rotationAngle = this.offset.dot(this._tempVector.cross(this.eye).normalize()) * ROTATION_SPEED;
            }

            // Apply rotation snap

            if (this.rotationSnap)
                this.rotationAngle = Math.round(this.rotationAngle / this.rotationSnap) * this.rotationSnap;

            // Apply rotate
            if (space === 'local' && axis !== 'E' && axis !== 'XYZE') {
                object.quaternion.copy(this.quaternionStart);
                object.quaternion
                    .multiply(this._tempQuaternion.setFromAxisAngle(this.rotationAxis, this.rotationAngle))
                    .normalize();
            } else {
                this.rotationAxis.applyQuaternion(this.parentQuaternionInv);
                object.quaternion.copy(this._tempQuaternion.setFromAxisAngle(this.rotationAxis, this.rotationAngle));
                object.quaternion.multiply(this.quaternionStart).normalize();
            }
        }
        this.dispatchEvent(this.changeEvent);
        this.dispatchEvent(this.objectChangeEvent);
    }

    pointerUp(pointer) {
        if (pointer.button !== 0) return;
        if (this.dragging && this.media) {
            this.media.markStatusUpdate();
        }
        if (this.dragging && this.axis !== null) {
            this.mouseUpEvent.mode = this.mode;
            this.mouseUpEvent.undoRedoId = this.object.userData.undoRedoId || this.media?.undoRedoId;
            this.dispatchEvent(this.mouseUpEvent);
            this.setShowHelper(true);
            this.previousStatus = {
                position: this.object.position.clone(),
                rotation: this.object.rotation.clone(),
                scale: this.object.scale.clone(),
            };
        }

        this.dragging = false;
        this.axis = null;
    }

    // normalize mouse / touch pointer and remap {x,y} to view space.

    getPointer(event) {
        if (this.scope.domElement.ownerDocument.pointerLockElement) {
            return {
                x: 0,
                y: 0,
                button: event.button,
            };
        } else {
            let pointer = event.changedTouches ? event.changedTouches[0] : event;

            let rect = this.domElement.getBoundingClientRect();

            return {
                x: ((pointer.clientX - rect.left) / rect.width) * 2 - 1,
                y: (-(pointer.clientY - rect.top) / rect.height) * 2 + 1,
                button: event.button,
            };
        }
    }

    onPointerHover(event) {
        if (!this.scope.enabled) return;

        switch (event.pointerType) {
            case 'mouse':
            case 'pen':
                this.scope.pointerHover(this.getPointer(event));
                break;
        }
    }

    onPointerDown(event) {
        if (!this.scope.enabled) return;
        this.scope.domElement.style.touchAction = 'none'; // disable touch scroll
        this.scope.domElement.ownerDocument.addEventListener('pointermove', this.onPointerMove, false);

        this.scope.pointerHover(this.getPointer(event));
        this.scope.pointerDown(this.getPointer(event));
    }

    onPointerMove(event) {
        if (!this.scope.enabled) return;
        this.scope.pointerMove(this.getPointer(event));
    }

    onPointerUp(event) {
        if (!this.scope.enabled) return;
        this.scope.domElement.style.touchAction = '';
        this.scope.domElement.ownerDocument.removeEventListener('pointermove', this.onPointerMove, false);
        this.scope.pointerUp(this.getPointer(event));
        this.setShowHelper(true);
    }

    // TODO: deprecate
    public getMode(): string {
        return this.scope.mode;
    }

    public setMode(mode: string): void {
        this.scope.mode = mode;
        this.modeChangeEvent.mode = mode;
        this.dispatchEvent(this.modeChangeEvent);
    }

    public setTranslationSnap(translationSnap: number): void {
        this.scope.translationSnap = translationSnap;
    }

    public setRotationSnap(rotationSnap: number): void {
        this.scope.rotationSnap = rotationSnap;
    }

    public setScaleSnap(scaleSnap: number): void {
        this.scope.scaleSnap = scaleSnap;
    }

    public setSize(size): void {
        this.scope.size = size;
    }

    public setSpace(space): void {
        this.scope.space = space;
    }

    public setRaycastScene(getcustumRaycastPoint): void {
        this.getCustumRaycastPoint = getcustumRaycastPoint;
    }

    public setAxis(name: string): void {
        this.axis = name;
    }

    public setDragging(enable: boolean): void {
        this.dragging = enable;
    }

    public setEnableXYZ(enable: boolean): void {
        this.showXYZ = enable;
    }

    private setShowHelper(enable: boolean): void {
        this.showHelper = enable;
    }

    private setBoxHelperVisible(enable: boolean): void {
        if (this.boxHelper !== undefined) this.boxHelper.visible = enable;
    }

    public showTransformControllerHelper(enable: boolean): void {
        this.setShowHelper(enable);
        this.setBoxHelperVisible(enable);
    }

    public addPointerMoveEvent() {
        this.scope.domElement.ownerDocument.addEventListener('pointermove', this.onPointerMove, false);
    }

    public setProportionalScale(enable: boolean) {
        this.proportionalScale = enable;
    }

    public setEnableLimitInBboxMode(option: boolean) {
        this._enableLimitInBboxMode = option;
    }

    public removeLimitTargetObject(limitBoxId: string) {
        const limitBox = this.limitBboxDictionary[limitBoxId];
        if (limitBox) {
            delete this.limitBboxDictionary[limitBoxId];
        } else {
            console.warn(`Limit box(${limitBoxId}) has remove`);
        }
    }

    public clearAllLimitBox() {
        for (let id in this.limitBboxDictionary) {
            if (this.limitBboxDictionary.hasOwnProperty(id)) {
                delete this.limitBboxDictionary[id];
            }
        }
    }

    private checkPosInLimitBox(position: Vector3) {
        for (const [id, threeBox] of Object.entries(this.limitBboxDictionary)) {
            if (threeBox.containsPoint(position)) {
                return true;
            }
        }
        return false;
    }
}
