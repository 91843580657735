import * as React from 'react';
import './AdvancePropertyMenu.css';

import { useTranslation } from 'react-i18next';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';

import CloseIcon from '@material-ui/icons/Close';
import MultipleLanguagesSetting from './PropertyMenus/MultipleLanguagesSetting';
import PropertySetting from './PropertyMenus/PropertySetting';
import { getMarkerFields } from 'utils';

const AdvancePropertyMenu = ({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();
    const { currentMarker } = React.useContext(TagEditorContext);

    if (currentMarker == null || !currentMarker.editable) return null;

    return (
        <div className="AdvancePropertyMenu" style={{ visibility: open ? 'visible' : 'hidden' }}>
            <div className="titleBar">
                {t('propertyMenu.advanceSetting')}
                <button className="closeButton" onClick={() => setOpen(false)}>
                    <CloseIcon fontSize="small" />
                </button>
            </div>
            <div className="MenuContainer">
                <PropertySetting type={currentMarker.type} fields={getMarkerFields(currentMarker.type)} />
                <MultipleLanguagesSetting type={currentMarker.type} fields={getMarkerFields(currentMarker.type)} />
            </div>
        </div>
    );
};

export default AdvancePropertyMenu;
