import * as THREE from 'three';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import VideoPlayerBase from './VideoPlayerBase';
import Vimeo from '@vimeo/player';
import { EquippedPromise } from 'core/utils';

let self;

export default class VimeoPlayer extends VideoPlayerBase {
    public player: Vimeo;
    public hasFirstClick = false;
    public playerReady = new EquippedPromise();

    constructor(videoId: string) {
        super(videoId);
        self = this;
    }

    public createObject(): THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial> {
        const element = document.createElement('div');
        element.style.width = this.width + 'px';
        element.style.height = this.height + 'px';

        var options = {
            id: this.videoID as unknown as number,
            autoplay: false,
            autopause: false,
            loop: true,
            muted: true,
            width: this.width,
            height: this.height,
        };

        this.player = new Vimeo(element, options);

        this.isPlaying = false;

        this.player.ready().then(() => {
            this.playerReady.resolve();
            // the player is ready
            if (!this.hasFirstClick) {
                const detectFirstClick = () => {
                    this.hasFirstClick = true;
                    document.removeEventListener('click', detectFirstClick);
                };
                document.addEventListener('click', detectFirstClick);
            }
        });

        this.player.on('ended', () => {
            this.playVideo();
        });

        this.videoElement = element;

        const css3dObject = new CSS3DObject(element);

        let sizeX = 2;
        let sizeY = this.ratio * 2;
        css3dObject.scale.set((1 / this.width) * sizeX, (1 / this.height) * sizeY, 1);

        const material = new THREE.MeshBasicMaterial({
            opacity: 0,
            color: new THREE.Color(0x000000),
            blending: THREE.NoBlending,
            side: THREE.DoubleSide,
            transparent: true,
        });

        let geometry = new THREE.PlaneGeometry(sizeX, sizeY);
        let mesh = new THREE.Mesh(geometry, material);

        mesh.add(css3dObject);
        return mesh;
    }

    public async playVideo() {
        //TODO : Play vimeo video
        // sometimes video is unstarted ...
        clearInterval(this.timer);
        this.timer = setInterval(
            async function () {
                if (!this.hasFirstClick) return;
                const isPaused = await this.player.getPaused();
                if (isPaused === true) {
                    this.player.play();
                    this.isPlaying = true;
                    if (this.firstLoad) {
                        this.player.setMuted(false);
                        this.firstLoad = false;
                    }
                } else {
                    clearInterval(this.timer);
                }
            }.bind(this),
            500,
        );
    }

    public pauseVideo() {
        this.player.pause();
        clearInterval(this.timer);
        this.isPlaying = false;
    }

    public setVolume(volume: number) {
        this.player.setVolume(volume / 100);
    }

    public async getVolume() {
        const volume = await this.player.getVolume();
        return volume * 100;
    }

    public async getMuted() {
        return (this.player as Vimeo).getMuted();
    }

    public async getCurrentTime() {
        return this.player.getCurrentTime();
    }

    public setCurrentTime(time: number) {
        this.player.setCurrentTime(time);
    }
}
