import * as THREE from 'three';
import { EquippedPromise } from 'core/utils';

export interface IBasicVideo {
    load(url: string): Promise<THREE.Mesh>;
    toggle(b: boolean): void;
    playVideo(): void;
    pauseVideo(): void;
    getVolume(): Promise<number>;
    setVolume(n: number): void;
    setCurrentTime(t: number): void;
    getCurrentTime(): void;
}
export default class BasicVideo implements IBasicVideo {
    public mesh: THREE.Mesh;
    public isPlaying: boolean;
    public thumbnailSize = { width: 1, height: 1 };
    public player: THREE.VideoTexture;
    public playerReady = new EquippedPromise();

    constructor(thumbnailSize?) {
        this.player = null;
        this.isPlaying = false;
        if (thumbnailSize) this.thumbnailSize = thumbnailSize;
    }

    private createMesh(video: HTMLVideoElement, ratio: number) {
        const geometry = new THREE.PlaneGeometry(this.thumbnailSize.width, this.thumbnailSize.width * ratio);
        const texture = new THREE.VideoTexture(video);
        texture.generateMipmaps = true;
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.colorSpace = THREE.SRGBColorSpace;
        const material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
        });
        this.player = texture;

        const mesh = new THREE.Mesh(geometry, material);
        return mesh;
    }

    public async load(url: string) {
        const video = document.createElement('video')
        video.src = url;
        video.load();
        video.setAttribute('playsinline', '');
        video.crossOrigin = 'anonymous';
        video.loop = true;
        video.autoplay = true;
        video.muted = true;
        await new Promise((resolve) => {
            // wait util the videoWidth and videoHeight is set by browser
            video.addEventListener('resize', resolve, { once: true });
        });
        this.playerReady.resolve();
        this.mesh = this.createMesh(video, video.videoHeight / video.videoWidth);
        this.player.image = video;
        return this.mesh;
    }

    public toggle() {
        if (this.isPlaying) this.pauseVideo();
        else this.playVideo();
    }

    public playVideo() {
        this.player.image.play();
        this.isPlaying = true;
        const autoAudio = () => {
            this.player.image.muted = false;
            document.removeEventListener('click', autoAudio);
        };
        document.addEventListener('click', autoAudio);
    }

    public pauseVideo() {
        this.player.image.pause();
        this.isPlaying = false;
    }

    public getVolume() {
        return Promise.resolve(this.player.image.volume * 100);
    }

    public getMuted() {
        return Promise.resolve(this.player.image.muted);
    }

    public setVolume(volume: number) {
        this.player.image.volume = volume / 100;
    }

    public getCurrentTime() {
        return this.player.image.currentTime;
    }

    public setCurrentTime(time: number) {
        this.player.image.currentTime = time;
    }

    public getPaused() {
        return this.player.image.paused;
    }

    public destroy() {
        //dispose geometry
        if (this.mesh == null) return;
        if (this.mesh.geometry) {
            this.mesh.geometry.dispose();
            this.mesh.geometry = null;
        }
        if (this.mesh.material) {
            //dispose material
            (this.mesh.material as THREE.Material).dispose();
            this.mesh.material = null;
        }
    }
    
    public update() {}
}
