import * as THREE from 'three';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import VideoPlayerBase from './VideoPlayerBase';
import PlayerFactory from 'youtube-player';
import PlayerStates from 'youtube-player/dist/constants/PlayerStates';
import { EquippedPromise } from 'core/utils';

let self;

export default class Youtube extends VideoPlayerBase {
    public player: ReturnType<typeof PlayerFactory>
    public playerReady = new EquippedPromise();
    constructor(videoID: string) {
        super(videoID);
        self = this;
    }

    public createObject(): THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial> {
        const element = document.createElement('div');
        element.style.width = this.width + 'px';
        element.style.height = this.height + 'px';

        const iframe = document.createElement('div');
        element.appendChild(iframe);
        iframe.style.width = '100%';
        iframe.style.height = '100%';

        this.player = PlayerFactory(iframe);
        this.player.loadVideoById(this.videoID);
        this.isPlaying = false;

        this.player.on('ready', (event) => {
            this.playerReady.resolve();
            this.player.stopVideo();
        });

        this.player.on('stateChange', (event) => {
            if (event.data === PlayerStates.ENDED) {
                this.playVideo();
            }
        });

        this.videoElement = element;

        const css3dObject = new CSS3DObject(element);

        let sizeX = 2;
        let sizeY = this.ratio * 2;

        css3dObject.scale.set((1 / this.width) * sizeX, (1 / this.height) * sizeY, 1);

        const material = new THREE.MeshBasicMaterial({
            opacity: 0,
            color: new THREE.Color(0x000000),
            blending: THREE.NoBlending,
            side: THREE.DoubleSide,
            transparent: true,
        });

        let geometry = new THREE.PlaneGeometry(sizeX, sizeY);
        let mesh = new THREE.Mesh(geometry, material);

        mesh.add(css3dObject);
        return mesh;
    }

    public playVideo() {
        //TODO : Play youtube video
        // sometimes video is unstarted ...
        clearInterval(this.timer);
        this.timer = setInterval(
            function () {
                this.player.getPlayerState().then(async (state) => {
                    if (state !== PlayerStates.PLAYING) {
                        this.player.playVideo();
                        this.isPlaying = true;
                        if (this.firstLoad) {
                            this.player.unMute();
                            this.firstLoad = false;
                        }
                    }
                    if (state === PlayerStates.PLAYING) {
                        clearInterval(this.timer);
                    }
                });
            }.bind(this),
            500,
        );
    }

    public pauseVideo() {
        this.player.pauseVideo();
        clearInterval(this.timer);
        this.isPlaying = false;
    }

    public setVolume(volume: number) {
        this.player.setVolume(volume);
        if (volume === 0) {
            // Due to the YT plugin would set volume to 5 in beginning if volume is 0.
            const timeId = setInterval(async () => {
                const currentVolume = await this.player.getVolume();
                if (currentVolume !== 0) this.player.setVolume(0);
                else clearInterval(timeId);
            }, 500);
        }
    }

    public getVolume() {
        return this.player.getVolume();
    }

    public async getMuted() {
        return this.player.isMuted();
    }

    public async getCurrentTime() {
        return this.player.getCurrentTime();
    }

    public setCurrentTime(time: number) {
        this.player.seekTo(time, true);
    }
}
