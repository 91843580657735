import { isMac } from 'core/utils';

type UndoRedoActionItem = {
    action: ((...arg: any[]) => Promise<UndoRedoActionItem>) | ((...arg: any[]) => UndoRedoActionItem);
};

export default class UndoRedoController {
    isMacOs = isMac();
    private undoStack: Array<UndoRedoActionItem> = [];
    private redoStack: Array<UndoRedoActionItem> = [];

    init(el: HTMLCanvasElement) {
        el.addEventListener('keydown', (e) => {
            if (this.isMacOs) {
                if (!e.metaKey) return;
                if (e.shiftKey && e.code === 'KeyZ') {
                    this.redo();
                    return;
                }
                if (e.code === 'KeyZ') {
                    this.undo();
                    return;
                }
            } else {
                if (!e.ctrlKey) return;
                if (e.shiftKey && e.code === 'KeyZ') {
                    this.redo();
                    return;
                }
                if (e.code === 'KeyY') {
                    this.redo();
                    return;
                }
                if (e.code === 'KeyZ') {
                    this.undo();
                    return;
                }
            }
        });
    }

    public pushNewAction(action: UndoRedoActionItem) {
        this.undoStack.push(action);
        this.redoStack = [];
    }

    public async undo() {
        const action = this.undoStack.pop();
        if (!action) return;
        const reverseAction = await action.action(true);
        if (reverseAction) {
            this.redoStack.push(reverseAction);
        }
    }

    public async redo() {
        const action = this.redoStack.pop();
        if (!action) return;
        const reverseAction = await action.action(true);
        if (reverseAction) {
            this.undoStack.push(reverseAction);
        }
    }

    public clear() {
        this.redoStack = [];
        this.undoStack = [];
    }
}
