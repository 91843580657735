import React from 'react';
// import 'core/i18n/i18n';
import './TitlebarGridList.css';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { MarkerType } from 'core/three/object/type';
import { useTranslation } from 'react-i18next';
import ThreeContext from 'app/pages/three-context';
import SERVER from 'constants/server';
import { IMedia } from 'core/types/media';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';

const loadingColors = ['#D9D9D9', '#000000', '#FFFFFF', '#3D3E4E', '#444444', '#666666', '#E7E7E9'];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
        },
        ...loadingColors.reduce((colorObject, color, index) => {
            colorObject[`root-color-${index}`] = {
                backgroundColor: color,
            };
            return colorObject;
        }, {}),
        mediaHint: {
            backgroundColor: '#888',
            height: 30,
            width: '100%',
            lineHeight: '30px',
            color: '#fff',
            '&  b': {
                cursor: 'pointer',
                color: '#003d6ece',
                textDecoration: 'underline',
            },
        },
    }),
);

export interface ITitlebarGridListProps {
    className: string;
    onRefresh(): void;
    onClose(): void;
    list: IMedia[];
}

const NoNeedToShowHint = [MarkerType.TEXT, MarkerType.PORTAL, MarkerType.NPC, MarkerType.ARTAG];

const TitlebarGridList: React.FunctionComponent<ITitlebarGridListProps> = (props: ITitlebarGridListProps) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { actions } = React.useContext(ThreeContext);
    const { currentSlotId, sideMenu } = React.useContext(TagEditorContext);
    const tobeCreaetedRef = React.useRef(null);
    const type = props.list[0]?.type;
    const enforceCreateBySlot = new URLSearchParams(window.location.search).get('enforceSlot') === 'true';

    const pointerDown = (tile, event) => {
        if (!tobeCreaetedRef.current) {
            tobeCreaetedRef.current = tile;
        }
    };

    const pointerMove = () => {
        if (tobeCreaetedRef.current) {
            if (enforceCreateBySlot && !currentSlotId.state) return;
            actions.createObject({ ...tobeCreaetedRef.current, slotId: currentSlotId.state });
            currentSlotId.setState('');
            sideMenu.setState(false);
            tobeCreaetedRef.current = null;
        }
    };

    const UploadMediaHint = () => {
        const styles = useStyles();
        const direct = () => {
            window.open(SERVER.SPACE_EDITOR_URL + '/material-manage');
        };
        const TwHint = () => (
            <span>
                可點此直接前往<b onClick={direct}>素材庫</b>上傳素材，再<b onClick={props.onRefresh}>重新刷新</b>此頁面
            </span>
        );
        const EnHint = () => (
            <span>
                Go to <b onClick={direct}>Media Libray</b> to upload media, and <b onClick={props.onRefresh}>refresh</b>{' '}
                this page.
            </span>
        );
        return <div className={styles.mediaHint}>{i18n.language === 'zh-TW' ? <TwHint /> : <EnHint />}</div>;
    };

    return (
        <div className={classes.root + ` TitlebarGridList ${props.className}`}>
            <div className="title" key="Subheader">
                <ListSubheader component="div">{t(`media.${type}`)}</ListSubheader>
                <button
                    onClick={() => {
                        props.onClose();
                    }}
                >
                    <KeyboardArrowLeft />
                </button>
            </div>
            <ImageList rowHeight={190} className="gridList">
                {!NoNeedToShowHint.includes(type) && <UploadMediaHint />}
                {props.list.length > 0
                    ? props.list.map((tile, index) => (
                          <ImageListItem
                              onPointerDown={pointerDown.bind(this, tile)}
                              onPointerMove={pointerMove}
                              key={`Item${index}`}
                              draggable="false"
                          >
                              <div className="gridListMask"></div>
                              <img
                                  className="gridListImg"
                                  src={(tile.thumbnailUrl || tile.fileUrl) ?? getDefaultIcon(tile.type)}
                                  alt={tile.name}
                                  draggable="false"
                              />

                              <ImageListItemBar
                                  style={{ backgroundColor: 'rgba(99, 114, 128, 0.5)', textAlign: 'left' }}
                                  title={tile.name}
                              />
                          </ImageListItem>
                      ))
                    : loadingColors.map((color, index) => (
                          <ImageListItem key={color} classes={{ root: classes[`root-color-${index}`] }} />
                      ))}
            </ImageList>
        </div>
    );
};

function getDefaultIcon(type: string): string {
    return `/images/icon/${type.toLowerCase()}.svg`;
}

export default TitlebarGridList;
