// react, redux
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// material ui , css
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

// constants
import * as MARKERS from 'constants/markers';

// utils, resources
import { verifyFile, getBase64 } from 'utils';
import { uploadFile } from 'api/s3';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import styles from './UploadThumbnailPropertyBox.module.css';
import { AppContext } from 'App';
import { useLatest } from 'hooks';

const PropertyKey = 'thumbnailUrl';

const thumbnailRegex = /png$|jpg$|jpeg$|gif$/i;

const thumbnailMaxSize = MARKERS.THUMBNAIL_FILE_MAX_SIZE;

const acceptTypes = ['jpeg', 'png', 'gif'];

const i18nAcceptTypes = acceptTypes.join(', ');

const inputAccept = acceptTypes.map((type) => `image/${type}`).join(',');

const UploadThumbnailPropertyBox: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);
    const latestPropertyDataRef = useLatest(propertyData);

    const [image64Object, setImage64Object] = useState<{ [markerId: string]: string }>({});
    const [isLoadingObject, setIsLoadingObject] = useState<{ [markerId: string]: boolean }>({});

    const maxSize = thumbnailMaxSize / 1024 / 1024;
    const fileUrl = propertyData.thumbnailUrl;

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentMarkerId = latestPropertyDataRef.current.id;
        setIsLoadingObject((loading) => ({ ...loading, [currentMarkerId]: true }));

        const file = e.target.files[0];

        if (!file) return;
        const path = `user/${auth.id}/media`;
        const config = {
            maxSize: thumbnailMaxSize,
            regExp: thumbnailRegex,
        };

        const errMsg = verifyFile(file, config);

        if (errMsg) {
            setIsLoadingObject((loading) => ({ ...loading, [currentMarkerId]: false }));
            alert(errMsg);
            return;
        }

        try {
            let tempMarkerData = latestPropertyDataRef.current;

            const base64File = await getBase64(file);
            setImage64Object((image64s) => ({ ...image64s, [currentMarkerId]: base64File }));

            const url = await uploadFile(file, path);
            setIsLoadingObject((loading) => ({ ...loading, [currentMarkerId]: false }));
            setImage64Object((image64s) => ({ ...image64s, [currentMarkerId]: '' }));

            if (tempMarkerData.id === latestPropertyDataRef.current.id) tempMarkerData = latestPropertyDataRef.current; // prevent markId in latestPropertyDataRef isn't same as before uploading
            markerUpdater.call({ ...tempMarkerData, [PropertyKey]: url });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className={styles.UploadThumbnailPropertyBox}>
            <div className={styles.BoxTitle}>{t(`propertyText.Thumbnail`) + '* :'}</div>
            <div className={styles.imageActionWrapper}>
                <Tooltip
                    title={t(`propertyText.FileUploadToolTip`, {
                        maxSize,
                        acceptTypes: i18nAcceptTypes,
                    })}
                >
                    <label
                        htmlFor="ThumbnaulInput"
                        className={`${styles.previewImageBox} ${
                            isLoadingObject[propertyData.id] ? '' : styles.hoverable
                        }`}
                    >
                        {image64Object[propertyData.id] || fileUrl ? (
                            <img
                                className={styles.previewImage}
                                src={image64Object[propertyData.id] || fileUrl}
                                alt="thumbnail"
                            />
                        ) : (
                            <div className={styles.emptyImageBlock}></div>
                        )}
                        {isLoadingObject[propertyData.id] ? (
                            <CircularProgress classes={{ root: styles.loadingProgress }} size={30} />
                        ) : (
                            <>
                                <input
                                    id="ThumbnaulInput"
                                    type="file"
                                    accept={inputAccept}
                                    hidden
                                    onChange={handleChange}
                                />
                            </>
                        )}
                    </label>
                </Tooltip>
            </div>
        </div>
    );
};

export default UploadThumbnailPropertyBox;
