import * as THREE from 'three';

export const throttle = (fn: Function, wait = 0) => {
    let lastTime;
    return (...args) => {
        if (!lastTime || Date.now() - lastTime > wait) {
            lastTime = Date.now();
            fn.apply(this, args);
        }
    };
};

export const debounce = (fn: Function, delay = 0) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(this, args);
        }, delay);
    };
};

export class EquippedPromise<T> extends Promise<T> {
    public resolve: (value?: T | PromiseLike<T>) => void;
    public reject: (reason?: any) => void;
    constructor(executor?: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void) {
        let toResolve;
        let toReject;
        super((resolve, reject) => {
            toResolve = resolve;
            toReject = reject;
        });
        this.resolve = toResolve;
        this.reject = toReject;
        executor?.call(this, this.resolve, this.reject);
    }
}
/**
 * Helper to dispose geometry and materials in THREE.Mesh
 * @param mesh
 */
export const disposeMesh = (mesh: THREE.Mesh) => {
    mesh.geometry.dispose();

    const mat = mesh.material;
    if (Array.isArray(mat)) {
        mat.forEach((element) => {
            // @ts-ignore
            if (element.map) element.map.dispose();

            element.dispose();
        });
    } else {
        // @ts-ignore
        if (mat.map) mat.map.dispose();
        mat.dispose();
    }
};

/**
 * Determine whether it is macOS
 */
export const isMac = () => {
    return navigator.userAgent.indexOf('Mac') > -1;
};

/**
 * A Helper for disposing geometry, materials and texture recursively.
 * @param obj
 */
export const recursiveDispose = (obj: THREE.Object3D) => {
    if (obj == null) return;
    obj.traverse((node: THREE.Mesh) => {
        node.geometry?.dispose();
        if (node.material instanceof Array) node.material.forEach((m) => m.dispose());
        else node.material?.dispose();
    });
};

export const loadScript = (url: string, onLoad?: () => void, onError?: (e: any) => void) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = () => onLoad();
    script.onerror = (e) => onError && onError(e);
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
};
