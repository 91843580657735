export const THUMBNAIL_FILE_MAX_SIZE = 5 * 1024 * 1024;
export const IMAGE_FILE_MAX_SIZE = 10 * 1024 * 1024;
export const TAG_FILE_MAX_SIZE = 5 * 1024 * 1024;
export const VIDEO_FILE_MAX_SIZE = 200 * 1024 * 1024;
export const MUSIC_FILE_MAX_SIZE = 5 * 1024 * 1024;
export const MODEL_FILE_MAX_SIZE = 10 * 1024 * 1024;
export const BOOTH_FILE_MAX_SIZE = 10 * 1024 * 1024;

export const BOOTH_THUMBNAIL_MAX_SIZE = 2 * 1024 * 1024;
export const MODEL_THUMBNAIL_MAX_SIZE = 2 * 1024 * 1024;
export const VIDEO_THUMBNAIL_MAX_SIZE = 5 * 1024 * 1024;

export const IMAGE_URLS_MAX_COUNT = 5;
