import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './TextPropertyBox.css';
import ThreeContext from 'app/pages/three-context';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { withStyles } from '@material-ui/core/styles';

enum UIComponetKey {
    value = 'value',
}

enum PropertyKey {
    label = 'label',
}

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
})(TextField);

const TextPropertyBox: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const maxLength: number = 1000;
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event, newValue?) {
            markerUpdater.call({ ...propertyData, [propertykey]: newValue || event.target[key] });
        };
    };

    return (
        <div className="TextPropertyBox">
            <div className="BoxTitle">{t(`propertyText.Content`) + ':'}</div>
            <CssTextField
                id="textContent"
                value={propertyData.label || ''}
                onChange={handleChange(PropertyKey.label, UIComponetKey.value)}
                multiline
                minRows={5}
                inputProps={{ maxLength: maxLength }}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment className="endAdornment" position="end">
                            {(propertyData.label.length ?? '0') + '/' + maxLength}
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

export default TextPropertyBox;
