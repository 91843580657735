import * as THREE from 'three';
import { MarkerType } from 'core/three/object/type';
import Media from 'core/three/object/media/Media';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { IModel } from 'core/types/media';
import { recursiveDispose } from 'core/utils';
import { loadModel } from 'core/utils/loader';

export default class Model extends Media {
    public size: THREE.Vector3;
    public center: THREE.Vector3;
    public autoRotate: boolean;
    public autoPlayAnimation: boolean;
    public glb: THREE.Group | THREE.Object3D;
    public animations: THREE.AnimationClip[] = [];

    private imageUrls: string[];
    private ctaName: string;
    private price: string | null;
    private videoUrl: string;
    private modelAnimationMixer: THREE.AnimationMixer;
    private modelAction: THREE.AnimationAction;

    static cubeRenderTarget: THREE.WebGLRenderTarget;

    constructor() {
        super(MarkerType.MODEL);

        this.size = new THREE.Vector3();
        this.center = new THREE.Vector3();
    }

    public get json() {
        return {
            ...super['json'],
            autoRotate: this.autoRotate,
            rotateControl: this.rotateControl,
            imageUrls: this.imageUrls,
            ctaName: this.ctaName,
            price: this.price,
            videoUrl: this.videoUrl,
            autoPlayAnimation: this.autoPlayAnimation,
        };
    }
    public async setJson(data) {
        super.setJson(data);

        this.autoRotate = data.autoRotate ?? false;
        this.rotateControl = data.rotateControl ?? false;
        this.imageUrls = data.imageUrls;
        this.ctaName = data.ctaName;
        this.videoUrl = data.videoUrl;
        this.price = data.price;
        this.autoPlayAnimation = data.autoPlayAnimation;
        //await this.updateModel()
        super.markStatusUpdate();
    }

    public async init(data: IModel): Promise<void> {
        super.init(data);
        await this.updateModel();
        this.autoRotate = data.autoRotate;
        this.rotateControl = data.rotateControl;
        this.imageUrls = data.imageUrls || [];
        this.ctaName = data.ctaName || '';
        this.videoUrl = data.videoUrl || '';
        this.price = data.price || '';
        this.autoPlayAnimation = data.autoPlayAnimation;
        if (this.autoPlayAnimation) this.startAnimation();
    }

    private async updateModel() {
        const promise = await this.load(this.fileUrl).catch((e) => {
            //Even if loading fails, you can still update
            console.log('error', e);
        });
        this.updateBoundaryBox();
        this.setupEnvMap();

        return promise;
    }

    protected setMesh(obj) {
        if (this.glb) this.object.remove(this.glb);

        this.glb = obj;
        this.object.add(this.glb);
        this.updateRaycastMesh(this.glb);
        this.object.updateMatrixWorld()
        const bbox = new THREE.Box3().setFromObject(this.object);
        bbox.getSize(this.size);
        bbox.getCenter(this.center);
    }

    public setGLTF(obj: GLTF, callback?: (n: THREE.Group) => void) {
        this.setMesh(obj.scene);

        if (callback) callback(obj.scene);

        return;
    }

    public async load(url: string, callback?: (n: THREE.Group) => void): Promise<void> {
        const obj: any = await loadModel(url);
        this.animations = obj.animations;
        this.setMesh(obj.scene);

        if (callback) callback(obj.scene);

        return;
    }

    private async setupEnvMap() {
        if (!Model.cubeRenderTarget) return;
        this.glb.traverse((object) => {
            if (object instanceof THREE.Mesh && object.material && object.material.metalness > 0) {
                object.material.envMap = Model.cubeRenderTarget.texture;
                object.material.needsUpdate = true;
            }
        });
    }

    protected updateBoundaryBox() {
        let size = new THREE.Vector3();
        this.object.remove(this.glb);
        let bbox = new THREE.Box3().setFromObject(this.glb);
        bbox.getSize(size);
        this.object.add(this.glb);

        let maxSize = Math.max(size.x, size.y, size.z);
        const oldGeometry = (this.boundaryBox as THREE.Mesh).geometry;
        const newGeometry = new THREE.BoxGeometry(size.x / maxSize, size.y / maxSize, size.z / maxSize);
        (this.boundaryBox as THREE.Mesh).geometry = newGeometry;

        let center = (bbox.max.y + bbox.min.y) / 2;
        this.boundaryBox.position.y = center;

        this.heightoffset = Math.abs(bbox.min.y);
        this.widthoffset = Math.abs(bbox.max.z);

        if (oldGeometry) oldGeometry.dispose();
    }

    public startAnimation() {
        if (this.animations[0]) {
            this.playModelAnimation(this.animations[0].name);
        }
    }

    public updateAnimation(deltaSec: number) {
        super.updateAnimation(deltaSec);
        if (this.modelAnimationMixer) {
            this.modelAnimationMixer.update(deltaSec);
        }
    }

    public playModelAnimation(animationName: string) {
        if (!this.glb) return;
        if (!this.modelAnimationMixer) this.modelAnimationMixer = new THREE.AnimationMixer(this.glb);
        if (!this.animations.length) return;
        const clips = this.animations;
        const clip = THREE.AnimationClip.findByName(clips, animationName);
        if (clip === null) return;
        if (this.modelAction) {
            this.modelAction.stop();
        }
        this.modelAction = this.modelAnimationMixer.clipAction(clip);
        return this.modelAction.play();
    }

    public stopAnimation(animationName: string) {
        if (this.modelAction) {
            if (this.modelAction.getClip().name === animationName) this.modelAction.stop();
        }
    }

    public destroy() {
        if (this.glb) recursiveDispose(this.glb);
        super.destroy();
    }
}
