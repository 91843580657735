const SERVER = {
    development: {
        BUCKET_NAME: '3d-maker-dev',
        SERVER_URL: 'https://3dmaker-dev.istaging.com',
        EDITOR_URL: 'https://3deditor-dev.metamaker.istaging.com',
        SPACE_EDITOR_URL: 'https://space-editor-dev.metamaker.istaging.com',
        VIEWER_URL: 'https://viewer-dev.metamaker.istaging.com',
        CDN_URL: 'https://3d-maker-dev.s3.ap-northeast-1.amazonaws.com',
        PLATFORM_URL: 'https://sso-dev.istaging.com',
    },
    test: {
        BUCKET_NAME: 'vrmaker-pro-test',
        SERVER_URL: 'https://3dmaker-test.istaging.com',
        EDITOR_URL: 'https://standard-test.d30t24qxas0jk7.amplifyapp.com',
        SPACE_EDITOR_URL: 'https://standard-test.dta7cpfriuig9.amplifyapp.com',
        VIEWER_URL: 'https://standard-test.d2x960h2lwu6mi.amplifyapp.com',
        CDN_URL: 'https://cdn-images-test.istaging.com',
        PLATFORM_URL: 'https://sso-test.istaging.com',
    },
    production: {
        BUCKET_NAME: 'vrmaker-pro-prod',
        SERVER_URL: 'https://3dmaker.istaging.com',
        EDITOR_URL: 'https://3deditor.metamaker.istaging.com',
        SPACE_EDITOR_URL: 'https://space-editor.metamaker.istaging.com',
        VIEWER_URL: 'https://viewer.metamaker.istaging.com',
        CDN_URL: 'https://cdn-images-prod.istaging.com',
        PLATFORM_URL: 'https://sso.istaging.com',
    },
};
export default SERVER[process.env.BUILD_ENV || process.env.REACT_APP_BUILD_ENV];
