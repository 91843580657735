import React from 'react';
import { useTranslation } from 'react-i18next';
import ThreeContext from 'app/pages/three-context';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import * as THREE from 'three';

const properties = ['x', 'y', 'z'] as const;

type PropertyKeys = (typeof properties)[number];

const PositionPropertyBox = () => {
    const { t } = useTranslation();
    const { actions } = React.useContext(ThreeContext);
    const { currentMarker, markerUpdater } = React.useContext(TagEditorContext);
    const [position, setPosition] = React.useState<{ [key in PropertyKeys]: string }>({ x: '0', y: '0', z: '0' });
    const initialMouseXRef = React.useRef(0);
    const realInputRefs = React.useRef<{ [key in PropertyKeys]: HTMLInputElement | null }>({
        x: null,
        y: null,
        z: null,
    });
    const [showInputs, setShowInputs] = React.useState<{ [key in PropertyKeys]: boolean }>({
        x: false,
        y: false,
        z: false,
    });
    const preStatusRef = React.useRef(null);
    const newStatusRef = React.useRef(null);
    const object = actions.getObjectByUndoRedoId(currentMarker.undoRedoId);

    React.useEffect(() => {
        if (!currentMarker) return;
        setPosition({
            x: String(currentMarker.position.x),
            y: String(currentMarker.position.y),
            z: String(currentMarker.position.z),
        });
    }, [currentMarker]);

    const handleChange =
        (key: PropertyKeys, continuous = false) =>
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | number) => {
            // Get correct number in string format
            let numeralString: string = '';
            if (typeof e === 'number') {
                numeralString = String(e);
            } else {
                const nonNumReg = /[^0-9.-]/g;
                numeralString = e.target.value.replace(nonNumReg, '');
            }
            if (numeralString === '' || Number.isNaN(Number(numeralString))) return;
            if (object) {
                const newPosition = { ...position, [key]: numeralString };
                const newPositionVector3 = new THREE.Vector3(
                    Number(newPosition.x),
                    Number(newPosition.y),
                    Number(newPosition.z),
                );
                const newStatus = {
                    position: newPositionVector3,
                    rotation: object.object.rotation.clone(),
                    scale: object.object.scale.clone(),
                };
                if (!continuous) {
                    // Add action to undoRedoController
                    const preStatus = {
                        position: object.object.position.clone(),
                        rotation: object.object.rotation.clone(),
                        scale: object.object.scale.clone(),
                    };
                    actions.onObjectTransformUpdate(preStatus, newStatus, currentMarker.undoRedoId);
                } else {
                    newStatusRef.current = newStatus;
                }
                // Setup object data
                object.object.position.copy(newPositionVector3);
                // Notify marker data has been changed
                object.markStatusUpdate();
            }
        };
    const mouseDownHandler = (key: PropertyKeys) => (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        initialMouseXRef.current = e.pageX;
        document.body.style.cursor = 'ew-resize';
        preStatusRef.current = {
            position: object.object.position.clone(),
            rotation: object.object.rotation.clone(),
            scale: object.object.scale.clone(),
        };
        const onMouseMove = (e: MouseEvent) => {
            const deltaX = e.pageX - initialMouseXRef.current;
            handleChange(key, true)(Number(Number(position[key]) + deltaX * 0.01));
        };
        const onMouseUp = () => {
            if (!preStatusRef.current || !newStatusRef.current) {
                console.warn('preStatusRef.current or newStatusRef.current is null.');
            }
            actions.onObjectTransformUpdate(preStatusRef.current, newStatusRef.current, currentMarker.undoRedoId);
            document.body.style.cursor = 'initial';
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            preStatusRef.current = null;
            newStatusRef.current = null;
        };
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };

    const activeInput = (key: PropertyKeys) => () => {
        setShowInputs({ x: false, y: false, z: false, [key]: true });
        setTimeout(() => {
            realInputRefs.current[key].focus();
        }, 100);
        realInputRefs.current[key].value = position[key];
    };

    const hideInput = (key: PropertyKeys) => () => {
        setShowInputs({ ...showInputs, [key]: false });
    };

    return (
        <div className="TitlePropertyBox">
            <div className="BoxTitle">{t('propertyText.position')}</div>
            {properties.map((key) => (
                <div key={key + 'PositionPropertyBox'} className="value-box">
                    <span className="BoxTitle property-grabable" onMouseDown={mouseDownHandler(key)}>
                        {key.toUpperCase()}:
                    </span>
                    <div className="fake-input" onClick={activeInput(key)}>
                        <span
                            className="fake-input-value"
                            style={{ display: showInputs[key] ? 'none' : 'inline-block' }}
                        >
                            {position[key]}
                        </span>
                        <input
                            ref={(ref) => {
                                realInputRefs.current[key] = ref;
                            }}
                            className={`real-input ${showInputs[key] ? 'active' : ''}`}
                            onChange={handleChange(key)}
                            type="number"
                            step="0.1"
                            onBlur={hideInput(key)}
                            disabled={!currentMarker.translateEnabled}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PositionPropertyBox;
