import api from 'api';

export interface ACL {
    imageTag: boolean;
    npcTag: boolean;
    portalTag: boolean;
    webwallTag: boolean;
    websiteTag: boolean;
    modelTag: boolean;
    videoTag: boolean;
    textTag: boolean;
    audioTag: boolean;
    arTag: boolean;
}

export const getAcl = async (sceneId: string, roleId: string = '') => {
    const urlEnd = `/api/v1/sceneRoleAcl/?sceneId=${sceneId}`;
    const response = await api.get<ACL[]>(urlEnd);
    const result = response.data;
    return result;
};
