import React, { useLayoutEffect } from 'react';
export const useContextState = <T>(defaultValue: T) => {
    const [state, setState] = React.useState<T>(defaultValue);
    return {
        state,
        setState,
    };
};

export interface ContextState<T extends any> {
    state: T;
    setState: React.Dispatch<React.SetStateAction<T>>;
}

export const useContextAction = <T extends (arg: any) => any>(defaultAction: T) => {
    const [action, setAction] = React.useState<T>(() => defaultAction);
    const updateAction = React.useCallback((func: T) => setAction(() => func), []);

    return {
        call: action,
        setAction: updateAction,
    };
};
export interface ContextAction<T extends (arg: any) => any> {
    call: T;
    setAction: (func: T) => void;
}

export const useLatest = <T extends any>(value: T) => {
    const ref = React.useRef<T>(value);
    useLayoutEffect(() => {
        ref.current = value;
    });
    return ref;
};
