import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MultipleImagePropertyBox.module.css';

import * as MARKERS from 'constants/markers';
import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// utils, resources
import { verifyFile, getBase64 } from 'utils';
import { uploadFile } from 'api/s3';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { AppContext } from 'App';
import { useLatest } from 'hooks';

const useStyles = makeStyles((theme) => ({
    buttonRoot: {
        color: '#fff',
    },
}));

const PropertyKey = 'imageUrls';

const imageFileRegex = /png$|jpg$|jpeg$|gif$/i;

const imageFileMaxFileSize = MARKERS.IMAGE_FILE_MAX_SIZE;

const acceptTypes = ['jpeg', 'png', 'gif'];

const i18nAcceptTypes = acceptTypes.join(' / ');

const inputAccept = acceptTypes.map((type) => `image/${type}`).join(',');

const imageUrlsMaxCount = MARKERS.IMAGE_URLS_MAX_COUNT;

const MultipleImagePropertyBox = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { auth } = React.useContext(AppContext);
    const { markerUpdater, currentMarker: propertyData, uploading } = React.useContext(TagEditorContext);
    const [loadings, setLoadings] = React.useState<string[][]>(Array.from({ length: 5 }, (_) => [])); // To seperate different marker loading status
    const latestPropertyDataRef = useLatest(propertyData);

    const { imageUrls = [] } = latestPropertyDataRef.current;

    const deleteImageUrl = (index: number) => {
        const copyImageUrls = imageUrls.slice();
        copyImageUrls.splice(index, 1);
        markerUpdater.call({ ...propertyData, [PropertyKey]: copyImageUrls });
    };

    const handlePartialChange = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const currentMarkerId = latestPropertyDataRef.current.id;
        const file = e.target.files[0];
        if (!file) return;

        const path = `user/${auth.id}/media`;
        const config = {
            maxSize: imageFileMaxFileSize,
            regExp: imageFileRegex,
        };

        const errMsg = verifyFile(file, config);

        if (errMsg) {
            alert(errMsg);
            return;
        }

        setLoadings((loadings) => {
            const newLoadings = loadings.slice();
            newLoadings[index].push(currentMarkerId);
            return newLoadings;
        });

        uploading.setState(true);

        try {
            let tempMarkerData = latestPropertyDataRef.current;
            const imageBase64 = await getBase64(file);

            const copyImageUrls = imageUrls.slice();
            copyImageUrls.splice(index, 1, imageBase64);

            if (tempMarkerData.id === latestPropertyDataRef.current.id) tempMarkerData = latestPropertyDataRef.current; // prevent markId in latestPropertyDataRef isn't same as before uploading
            markerUpdater.call({ ...tempMarkerData, [PropertyKey]: copyImageUrls });

            const url = await uploadFile(file, path);

            const copyImageUrls2 = copyImageUrls.slice();
            copyImageUrls2.splice(index, 1, url);
            if (tempMarkerData.id === latestPropertyDataRef.current.id) tempMarkerData = latestPropertyDataRef.current; // prevent markId in latestPropertyDataRef isn't same as before uploading
            markerUpdater.call({ ...tempMarkerData, [PropertyKey]: copyImageUrls2 });
        } catch (err) {
            console.error(err);
        } finally {
            setLoadings((loadings) => {
                const newLoadings = loadings.slice();
                const idIndex = newLoadings[index].findIndex((id) => id === currentMarkerId);
                if (idIndex > -1) {
                    newLoadings[index].splice(idIndex, 1);
                }
                return newLoadings;
            });
            uploading.setState(false);
        }
    };

    const handleMultipleUploadChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentMarkerId = latestPropertyDataRef.current.id;
        const files = Array.from(e.target.files);

        if (!files.some((file) => file)) return;

        const path = `user/${auth.id}/media`;
        const config = {
            maxSize: imageFileMaxFileSize,
            regExp: imageFileRegex,
        };

        const errMsgs = files.map((file) => verifyFile(file, config));

        if (errMsgs.some((err) => err)) {
            const errMapping = errMsgs.map((errMsg, index) => ({ file: files[index], errMsg }));
            const errMsgContent = errMapping.filter((file) => file.errMsg);
            alert(
                errMsgContent.reduce((outputErrMsg, currentContent) => {
                    return outputErrMsg + `${currentContent.file.name}: ${currentContent.errMsg} `;
                }, ''),
            );
            return;
        }

        if (files.length + imageUrls.length > imageUrlsMaxCount) {
            alert(t('errorMsg.imageUrlsLimit'));
            return;
        }

        const images: Array<string | File> = [].concat([...imageUrls, ...files]);

        const newLoadings = loadings.slice();
        images.forEach((image, index) => {
            if (typeof image === 'object') {
                newLoadings[index].push(currentMarkerId);
            }
        });
        setLoadings(newLoadings);
        uploading.setState(true);

        try {
            let tempMarkerData = latestPropertyDataRef.current;
            const imageBase64s = await Promise.all(files.map((file) => getBase64(file)));

            const copyImageUrls = [].concat(imageUrls).concat(imageBase64s);
            if (tempMarkerData.id === latestPropertyDataRef.current.id) tempMarkerData = latestPropertyDataRef.current; // prevent markId in latestPropertyDataRef isn't same as before uploading
            markerUpdater.call({ ...tempMarkerData, [PropertyKey]: copyImageUrls });

            const urls = await Promise.all(files.map((file) => uploadFile(file, path)));

            const copyImageUrls2 = [].concat(imageUrls).concat(urls);
            propertyData[PropertyKey] = copyImageUrls2;
            if (tempMarkerData.id === latestPropertyDataRef.current.id) tempMarkerData = latestPropertyDataRef.current; // prevent markId in latestPropertyDataRef isn't same as before uploading
            markerUpdater.call({ ...tempMarkerData, [PropertyKey]: copyImageUrls2 });
        } catch (err) {
            console.log(err);
        } finally {
            setLoadings((loadings) => {
                const newLoadings = loadings.slice();
                images.forEach((image, index) => {
                    if (typeof image === 'object') {
                        const idIndex = newLoadings[index].findIndex((id) => id === currentMarkerId);
                        if (idIndex) {
                            newLoadings[index].splice(idIndex, 1);
                        }
                    }
                });
                return newLoadings;
            });
            uploading.setState(false);
        }
    };

    return (
        <div className={styles.MultipleImagePropertyBox}>
            <div className={styles.BoxTitle}>{t('propertyText.imageUrls') + ' :'}</div>
            <div className={styles.imageActionWrapper}>
                {imageUrls.map((url, index) => (
                    <div key={url.slice(-30)} className={styles.imageItem}>
                        <span>{index + 1}</span>
                        <label
                            htmlFor={`imageUrlInput${index}`}
                            className={`${styles.previewImageBox} ${loadings[index] ? '' : styles.hoverable}`}
                        >
                            {url ? (
                                <img className={styles.previewImage} src={url} alt="thumbnail" />
                            ) : (
                                <div className={styles.emptyImageBlock}></div>
                            )}
                        </label>
                        {loadings[index].find((id) => id === propertyData.id) ? (
                            <div className={styles.loadingMask}>
                                <CircularProgress classes={{ root: styles.loadingProgress }} size={30} />
                            </div>
                        ) : (
                            <>
                                <input
                                    id={`imageUrlInput${index}`}
                                    type="file"
                                    accept={inputAccept}
                                    hidden
                                    onChange={(e) => handlePartialChange(e, index)}
                                />
                            </>
                        )}
                        <IconButton
                            onClick={() => deleteImageUrl(index)}
                            classes={{ root: classes.buttonRoot }}
                            aria-label="delete"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                ))}
                <label className={styles.uploadButton} htmlFor="imageUrlsInput">
                    <AddIcon fontSize="small" />
                </label>
                <input
                    id="imageUrlsInput"
                    type="file"
                    multiple
                    accept={inputAccept}
                    hidden
                    onChange={handleMultipleUploadChange}
                />
                <div>
                    {t(`propertyText.imageUrlsHint`, {
                        acceptTypes: i18nAcceptTypes,
                    })}
                </div>
            </div>
        </div>
    );
};

export default MultipleImagePropertyBox;
