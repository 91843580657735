import * as THREE from 'three';
import CustomObject from 'core/three/object';
import EventDispatcher from './EventDispatcher';
import Hotspot from '../object/hotspot/Hotspot';
import { SceneHotspot } from 'core/types/sceneHotspots';

interface HotspotMgrEvent {
    created: Hotspot;
    deleted: Hotspot;
    floorChange: number;
    hotspotChange: string;
}
export default class HotspotMgr extends EventDispatcher<HotspotMgrEvent> {
    public floorChange = 'floorChange' as const;
    public hotspotChange = 'hotspotChange' as const;
    public created = 'created' as const;
    public deleted = 'deleted' as const;
    public currentId: string;
    public currentFloor: number = 0;
    public data: SceneHotspot[] = [];
    public objects: Hotspot[] = [];
    public dataById: { [id: string]: SceneHotspot } = {};
    public objectById: { [id: string]: Hotspot } = {};
    public floors: number[] = [];
    public ids: string[] = [];


    /**
     * Create the hotspots by the given data.
     */
    public createHotspots(data: SceneHotspot[]) {
        data.map((item) => {
            const hotspot = new CustomObject.Hotspot();
            hotspot.createHotspot(item);
            this.data.push(item);
            this.objects.push(hotspot);
            this.dataById[item.id] = item;
            this.objectById[item.id] = hotspot;
            this.ids = Object.keys(this.dataById).filter((id) => Boolean(this.objectById[id]));
            this.dispatchEvent({ type: this.created, data: hotspot });
            return hotspot;
        });
        this.parseFloors();
        return this.objects;
    }

    /**
     * Delete all of the hotspots.
     */
    public resetHotspots() {
        this.objects.forEach((hotspot) => {
            hotspot.destroy();
            delete this.dataById[hotspot.id];
            delete this.objectById[hotspot.id];
            this.dispatchEvent({ type: this.deleted, data: hotspot });
        });
        this.data.length = 0;
        this.objects.length = 0;
        this.ids.length = 0;
    }

    /**
     * Hides the hotspots by the given ids.
     */
    public hideHotspots(ids = this.ids) {
        ids.forEach((id) => {
            const hotspotObject = this.objectById[id];
            if (hotspotObject) hotspotObject.visible = false;
        });
    }

    /**
     * Shows the hotspots by the given ids.
     */
    public showHotspots(ids = this.ids) {
        ids.forEach((id) => {
            const hotspotObject = this.objectById[id];
            if (hotspotObject) hotspotObject.visible = true;
        });
    }

    /**
     * Gets the neighbor hotspot ids by the given id.
     * If the given hotspot has no neighbors. All hotspots on the same floor are considered as neighbors.
     */
    public getNeighbors(id = this.currentId) {
        const hotspot = this.dataById[id];
        let neighbors = hotspot?.neighbors || [];
        neighbors = neighbors.length ? neighbors : this.getByFloor(hotspot?.floor);
        neighbors = neighbors.filter(
            (neighbor) => Boolean(this.dataById[neighbor]) && Boolean(this.objectById[neighbor]) && neighbor !== id,
        );
        return neighbors;
    }

    /**
     * Display only the neighbor hotspot by the given id.
     * If the given hotspot has no neighbors. All hotspots are considered as neighbors.
     */
    public displayNeighbors(id = this.currentId) {
        this.hideHotspots();
        const neighbors = this.getNeighbors(id);
        neighbors.forEach((neighbor) => {
            const neighborMesh = this.objectById[neighbor];
            if (neighborMesh) neighborMesh.visible = true;
        });
    }

    /**
     * Gets the nearest hotspot id by the given position and candidates.
     * Candidates will be neighbors of the id if not set.
     */
    public getNearestByPosition(pos: { x: number; y: number; z: number }, candidates = this.getNeighbors()) {
        const position = new THREE.Vector3(pos.x, pos.y, pos.z);
        let minDistance = Infinity;
        let nearestId = undefined;

        candidates.forEach((id) => {
            const hotspotObject = this.objectById[id];
            if (!hotspotObject) return;
            const hotspotPosition = hotspotObject.object.position;
            const distance = position.distanceTo(hotspotPosition);
            if (distance < minDistance) {
                minDistance = distance;
                nearestId = id;
            }
        });

        return nearestId;
    }

    /**
     * Gets the nearest neighbor hotspot id by the given id.
     * Candidates will be neighbors of the id if not set.
     */
    public getNearest(id = this.currentId, candidates = this.getNeighbors(id)) {
        const hotspot = this.objectById[id];
        if (!hotspot) return;
        return this.getNearestByPosition(hotspot.object.position, candidates);
    }

    /**
     * Gets the nearest hotspot id by the given direction, id, and candidates.
     * Candidates will be neighbors of the id if not set.
     */
    public getNearestInDirection(direction: THREE.Vector3, id = this.currentId, candidates = this.getNeighbors(id)) {
        const hotspotObject = this.objectById[id];
        if (!hotspotObject) return;

        const hotspotPosition = hotspotObject.object.position;

        let minDistance = Infinity;
        let nearestId = '';

        candidates.forEach((id) => {
            const target = this.dataById[id];
            const targetPos = new THREE.Vector3(target.position.x, target.position.y, target.position.z);
            const targetDirection = targetPos.clone().sub(hotspotPosition);
            const angle = targetDirection.angleTo(direction);
            if (angle > Math.PI / 4) return;
            const distance = targetPos.distanceTo(hotspotPosition);
            if (distance < minDistance) {
                minDistance = distance;
                nearestId = id;
            }
        });

        return nearestId;
    }

    /**
     * Gets the hotspot whose floor is close to or equal to the given floor.
     */
    public getByFloor(floor = 0, includeStair = true) {
        return this.ids.filter((id) => {
            const hotspotData = this.dataById[id];
            const hotspotFloor = hotspotData.floor || 0;
            if (includeStair) return Math.abs(floor - hotspotFloor) < 1;
            else return floor === hotspotFloor;
        });
    }

    private parseFloors() {
        const floors: number[] = [];
        this.ids.forEach((id) => {
            const hotspotData = this.dataById[id];
            const floor = Number((hotspotData.floor || 0).toFixed(0));
            if (!floors.includes(floor)) {
                floors.push(floor);
            }
        });
        floors.sort((a, b) => a - b);
        this.floors = floors;
    }
}
