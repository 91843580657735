import * as THREE from 'three';
import drawRoundedRect from 'core/utils/drawRoundedRect';

interface IBackgroundConfig {
    fillStyle?: string;
    nameTemplateStyle?: boolean;
    stroke?: boolean;
    iconStyle?: boolean;
}

interface ITextConfig {
    label: string;
    fontWeight: string;
    fontStyle: string;
    fontColor: string;
    padding: number;
    fontSize: number;
    alignment: string;
    lineSpace: number;
}

export default class TextTexture extends THREE.Texture {
    private canvas: HTMLCanvasElement;
    private context: CanvasRenderingContext2D;

    private textConfig: ITextConfig;
    private backgroundConfig: IBackgroundConfig;

    private padding: number;
    private fontSize: number;
    private alignment: string;
    private font: string;
    private defaultWidth: number;
    private lines: number;
    private lineSpace: number;

    constructor(texconfig: ITextConfig, backgroundconfig: IBackgroundConfig = {}) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        super(canvas);

        this.canvas = canvas;
        this.context = context;

        this.textConfig = texconfig;
        this.backgroundConfig = backgroundconfig;
        this.defaultWidth = 1100;
        this.create();
    }

    public get ratio() {
        return this.canvas.width / this.canvas.height;
    }

    public get lineNumber() {
        return this.lines;
    }

    public get width() {
        return this.canvas.width / this.defaultWidth;
    }

    public get height() {
        return this.canvas.height / this.defaultWidth;
    }

    private create() {
        const { label, fontWeight, fontStyle, fontColor, padding, alignment, fontSize, lineSpace } = this.textConfig;
        this.fontSize = fontSize;
        this.alignment = alignment;
        this.padding = padding;
        this.lineSpace = lineSpace;

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.font = fontStyle + ' ' + fontWeight + ' ' + fontSize + 'px Pretendard Variable';
        this.context.font = this.font;

        const { textArray, canvasWidth, canvasHeight } = this.wrapText(label);
        this.lines = textArray.length;
        this.canvas.width = canvasWidth + 58 + (this.backgroundConfig.iconStyle ? 100 : 0);
        this.canvas.height = canvasHeight + 8;

        if (this.backgroundConfig) {
            if (this.backgroundConfig.nameTemplateStyle) {
                this.context.strokeStyle = 'rgb(255, 255, 255)';
                this.context.lineWidth = 4;
                this.context.fillStyle = this.backgroundConfig.fillStyle || 'rgba(20, 20, 20, 0.7)';
                drawRoundedRect(
                    this.context,
                    2,
                    2,
                    canvasWidth + 50 + (this.backgroundConfig.iconStyle ? 100 : 0),
                    canvasHeight,
                    Math.floor(canvasHeight / 2),
                    true,
                    this.backgroundConfig.stroke || true,
                );

                if (this.backgroundConfig.iconStyle) {
                    const img = new Image();
                    img.onload = () => {
                        this.context.drawImage(img, 0, 0, 120, 120, 40, 40, 100, 100);
                        this.needsUpdate = true;
                    };
                    img.src = '/images/headshot/crown.png';
                }
            } else if (this.backgroundConfig.fillStyle) {
                const { fillStyle } = this.backgroundConfig;
                this.context.fillStyle = fillStyle;
                this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
            }
        }

        let x = 2;
        let y = this.padding / 2;
        if (this.alignment === 'center') x = this.canvas.width / 2;
        else if (this.alignment === 'left') x = this.padding / 2;
        else if (this.alignment === 'right') x = this.canvas.width - this.padding / 2;

        if (this.backgroundConfig.iconStyle) {
            x += 50;
        }

        textArray.forEach((line) => {
            this.context.textBaseline = 'top';
            this.context.font = this.font;
            this.context.fillStyle = fontColor;
            this.context.textAlign = this.alignment as CanvasTextAlign;
            this.context.fillText(line, x, y);
            y += fontSize + lineSpace;
        });
    }

    private wrapText(label) {
        let canvasWidth,
            canvasHeight = 0;
        if (typeof label != 'string') {
            return;
        }

        let arrText = label.split('');
        let textArray = [];
        let line = '';
        let lineHeight = this.fontSize;

        this.context.font = this.font;
        let metrics = this.context.measureText(label);
        if (metrics.width < this.defaultWidth) {
            canvasWidth = metrics.width + this.padding;
            canvasHeight = this.fontSize + this.padding;
            textArray = [label];
        } else {
            canvasWidth = this.defaultWidth + this.padding;
            canvasHeight = this.fontSize + this.padding;

            for (var n = 0; n < arrText.length; n++) {
                var testLine = line + arrText[n];
                this.context.font = this.font;
                metrics = this.context.measureText(testLine);
                var testWidth = metrics.width;
                if (testWidth > this.defaultWidth && n > 0) {
                    textArray.push(line);
                    line = arrText[n];
                    canvasHeight += lineHeight + this.lineSpace;
                } else {
                    line = testLine;
                }
            }
            textArray.push(line);
        }
        return { textArray: textArray, canvasWidth: canvasWidth, canvasHeight: canvasHeight };
    }
}
