import api, { getErrorMsg } from 'api';
import { MarkerType } from 'core/three/object/type';
import * as Media from 'core/types/media';
import {
    textMedia,
    portalMedia,
    npcMedia,
    tagMedia,
    websiteMedia,
    webwallMedia,
    musicMedia,
    videoMedia,
    arTagMedia,
} from './defaultMedia';
import { generateMultiLang } from 'core/utils/language';

const formatMedia = (media: Media.IMedia) => {
    media.id = undefined;
    media.type = media.type.toUpperCase() as MarkerType;
    media.multipleLanguage = '';
    media.multiLang = generateMultiLang();

    return media;
};

export const getMediaList = async (type: MarkerType): Promise<Media.IMedia[]> => {
    if (type === MarkerType.TEXT) return Promise.resolve(textMedia);
    if (type === MarkerType.PORTAL) return Promise.resolve(portalMedia);
    if (type === MarkerType.NPC) return Promise.resolve(npcMedia);
    if (type === MarkerType.ARTAG) return Promise.resolve(arTagMedia);
    const defaultMedia: Media.IMedia[] = [];

    switch (type) {
        case MarkerType.TAG:
            defaultMedia.push(...tagMedia);
            break;
        case MarkerType.WEBSITE:
            defaultMedia.push(...websiteMedia);
            break;
        case MarkerType.MUSIC:
            defaultMedia.push(...musicMedia);
            break;
        case MarkerType.VIDEO:
            defaultMedia.push(...videoMedia);
            break;
        case MarkerType.WEBWALL:
            defaultMedia.push(...webwallMedia);
            break;
        default:
            break;
    }

    try {
        const urlEnd = `api/v1/media?type=${type.toLocaleLowerCase()}`;
        const response = await api.get<Media.IMedia[]>(urlEnd);
        return response.data.concat(defaultMedia).map(formatMedia);
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};
