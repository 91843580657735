import { MarkerType } from 'core/three/object/type';
import * as Media from 'core/types/media';
import { generateMultiLang } from 'core/utils/language';

export const textMedia: Media.IText[] = [
    {
        type: MarkerType.TEXT,
        name: '文字模板',
        description: '',
        fileUrl: '',
        defaultWidth: 1.5,
        thumbnailUrl: 'https://cdn-images-prod.istaging.com/images/media/text.svg',
        linkUrl: '',
        linkName: '',
        openType: 'window',
        isVertical: false,
        followCamera: false,
        unclickable: false,
        searchable: true,
        visible: true,
        label: 'Text',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textColor: '#000000',
        lineSpace: 0.5,
        alignment: 'left',
        fontSize: 60,
        angle: 0,
        multipleLanguage: '',
        multiLang: generateMultiLang(),
        curve: 0,
    },
];

export const portalMedia: Media.IPortal[] = [
    'https://cdn-images-prod.istaging.com/images/media/portal1.png',
    'https://cdn-images-prod.istaging.com/images/media/portal2.png',
].map((url) => ({
    type: MarkerType.PORTAL,
    name: '',
    description: '',
    fileUrl: '',
    defaultWidth: 1.5,
    thumbnailUrl: url,
    linkUrl: '',
    linkName: '',
    openType: 'redirect',
    isVertical: true,
    followCamera: false,
    unclickable: false,
    searchable: true,
    visible: true,
    curve: 0,
    multipleLanguage: '',
    multiLang: generateMultiLang(),
}));

export const npcMedia: Media.IModel[] = [{ tall: 1.63, width: 394, height: 1584 }].map((size, index) => ({
    type: MarkerType.MODEL,
    name: `NPC ${index + 1}`,
    description: '',
    fileUrl: `https://cdn-images-prod.istaging.com/avatar/model/npc${index + 1}-full.glb`,
    defaultWidth: size.tall * (size.width / size.height),
    thumbnailUrl: `/images/npc/3d/npc${index + 1}-face.png`,
    linkUrl: '',
    linkName: '',
    openType: 'window',
    isVertical: true,
    followCamera: false,
    unclickable: false,
    searchable: false,
    visible: true,
    autoRotate: false,
    rotateControl: false,
    autoPlayAnimation: false,
    imageUrls: [],
    price: '0',
    videoUrl: '',
    multipleLanguage: '',
    multiLang: generateMultiLang(),
}));

export const tagMedia: Media.ITag[] = [
    'https://cdn-images-prod.istaging.com/images/media/bi_card-image_black.png',
    'https://cdn-images-prod.istaging.com/images/media/bi_card-image_white.png',
    'https://cdn-images-prod.istaging.com/images/media/bi_card-image_transparent.png',
].map((url, index) => ({
    defaultWidth: 1,
    fileUrl: url,
    followCamera: false,
    isVertical: false,
    linkName: '',
    linkUrl: '',
    name: `Default Image Tag ${index + 1}`,
    openType: 'window',
    description: '',
    thumbnailUrl: url,
    unclickable: false,
    visible: true,
    type: MarkerType.TAG,
    searchable: false,
    showHotspot: false,
    showTitle: false,
    showConnectedLine: false,
    hotspotIconHeight: 1,
    connectedLineLength: 1,
    multipleLanguage: '',
    multiLang: generateMultiLang(),
    ctaName: '',
    imageUrls: [],
    price: null,
    videoUrl: '',
    curve: 0,
}));

export const websiteMedia: Media.IWebsite[] = [
    'https://cdn-images-prod.istaging.com/images/media/lucide_external-link_black.png',
    'https://cdn-images-prod.istaging.com/images/media/lucide_external-link_white.png',
    'https://cdn-images-prod.istaging.com/images/media/lucide_external-link_transparent.png',
    'https://cdn-images-prod.istaging.com/images/media/akar-icons_link-chain_black.png',
    'https://cdn-images-prod.istaging.com/images/media/akar-icons_link-chain_white.png',
    'https://cdn-images-prod.istaging.com/images/media/akar-icons_link-chain_transparent.png',
].map((url, index) => ({
    name: `Default Website Tag ${index + 1}`,
    fileUrl: '',
    description: '',
    thumbnailUrl: url,
    isVertical: false,
    followCamera: false,
    linkName: '',
    linkUrl: 'https://www.istaging.com/',
    openType: 'window',
    unclickable: false,
    searchable: false,
    visible: true,
    defaultWidth: 1,
    type: MarkerType.WEBSITE,
    showHotspot: false,
    showTitle: false,
    showConnectedLine: false,
    hotspotIconHeight: 1,
    connectedLineLength: 1,
    multipleLanguage: '',
    multiLang: generateMultiLang(),
    curve: 0,
}));

export const webwallMedia: Media.IWebwall[] = [
    {
        type: MarkerType.WEBWALL,
        name: 'Default Webwall Tag 1',
        description: '',
        fileUrl: '',
        defaultWidth: 1.5,
        thumbnailUrl: 'https://cdn-images-prod.istaging.com/images/media/webwall.svg',
        linkUrl: 'https://www.istaging.com/',
        linkName: '',
        openType: 'window',
        isVertical: false,
        followCamera: false,
        unclickable: false,
        searchable: true,
        visible: true,
        multipleLanguage: '',
        multiLang: generateMultiLang(),
    },
];

export const musicMedia: Media.IMusic[] = [
    'https://cdn-images-prod.istaging.com/images/media/bi_music-note_black.png',
    'https://cdn-images-prod.istaging.com/images/media/bi_music-note_white.png',
    'https://cdn-images-prod.istaging.com/images/media/bi_music-note_transparent.png',
    'https://cdn-images-prod.istaging.com/images/media/fluent_music-note-2-play-20-regular_black.png',
    'https://cdn-images-prod.istaging.com/images/media/fluent_music-note-2-play-20-regular_white.png',
    'https://cdn-images-prod.istaging.com/images/media/fluent_music-note-2-play-20-regular_transparent.png',
].map((url, index) => ({
    name: `Default Music Tag ${index + 1}`,
    fileUrl: 'https://cdn-images-prod.istaging.com/images/media/file_example_MP3_700KB.mp3', // todo
    description: '',
    thumbnailUrl: url,
    isVertical: false,
    followCamera: false,
    linkName: '',
    linkUrl: '',
    openType: 'iframe',
    unclickable: false,
    searchable: false,
    visible: true,
    defaultWidth: 1,
    type: MarkerType.MUSIC,
    autoplay: false,
    volume: 50,
    curve: 0,
    multipleLanguage: '',
    multiLang: generateMultiLang(),
}));

export const videoMedia: Media.IVideo[] = [
    'https://cdn-images-prod.istaging.com/images/media/fluent_video-clip-20-regular_black.png',
    'https://cdn-images-prod.istaging.com/images/media/fluent_video-clip-20-regular_white.png',
    'https://cdn-images-prod.istaging.com/images/media/fluent_video-clip-20-regular_transparent.png',
    'https://cdn-images-prod.istaging.com/images/media/ic_round-slow-motion-video_black.png',
    'https://cdn-images-prod.istaging.com/images/media/ic_round-slow-motion-video_white.png',
    'https://cdn-images-prod.istaging.com/images/media/ic_round-slow-motion-video_transparent.png',
].map((url, index) => ({
    name: `Default Video Tag ${index + 1}`,
    fileUrl: 'https://cdn-images-prod.istaging.com/images/media/sample-mp4-file.mp4', // todo
    description: '',
    thumbnailUrl: url,
    isVertical: false,
    followCamera: false,
    linkName: '',
    linkUrl: '',
    openType: 'iframe',
    unclickable: false,
    searchable: false,
    visible: true,
    defaultWidth: 1,
    type: MarkerType.VIDEO,
    autoplay: false,
    greenVideo: false,
    volume: 50,
    openPlayer: false,
    multipleLanguage: '',
    multiLang: generateMultiLang(),
}));

export const arTagMedia: Media.IARTag[] = [
    {
        name: `AR Tag`,
        fileUrl: '',
        description: '',
        thumbnailUrl: 'https://cdn-images-prod.istaging.com/images/media/lucide_external-link_black.png',
        isVertical: false,
        followCamera: false,
        linkName: '',
        linkUrl: 'https://arviewer.istaging.com/?uid=16f5e26b-a680-43f1-9308-dd90600266b9',
        openType: 'iframe',
        unclickable: false,
        searchable: false,
        visible: true,
        defaultWidth: 1,
        type: MarkerType.ARTAG,
        showTitle: false,
        hotspotIconHeight: 1,
        disableBackground: false,
        curve: 0,
        multipleLanguage: '',
        multiLang: generateMultiLang(),
    },
];
