import * as THREE from 'three';
import EventDispatcher from './EventDispatcher';
import Scene from '../scene/Scene';
import SceneModel from '../object/sceneModel/SceneModel';
import _ from 'lodash';
import { loadModel } from 'core/utils/loader';
import { RenderFrame } from 'core/types';
interface SceneMgrEvent {
    progress: any;
    created: SceneModel;

}
export default class SceneMgr extends EventDispatcher<SceneMgrEvent>{
    public progress = 'progress' as const;
    public created = 'created' as const;
    public sceneModel = new SceneModel();

    public async load(url: string, isMatterport: boolean = false, panoramaEnabled: boolean = false) {
        const glb = await loadModel(url, (e) => {
            this.dispatchEvent({ type: this.progress, data: e });
        });

        const scene = glb.scene;

        // rotate 90 degree if is matterport model
        if (isMatterport) {
            scene.quaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2);
        }

        this.centerPosition(scene);

        // todo: check if necessary
        scene.updateMatrixWorld(true);

        this.sceneModel.setGLTF(glb);
        this.sceneModel.animations = glb.animations || [];
        this.sceneModel.setTntegratedMesh(
            panoramaEnabled ? this.sceneModel.glb : new THREE.Object3D(),
        );


        this.dispatchEvent({ type: this.created, data: this.sceneModel });
        return glb;
    }

    public update(renderFrame: RenderFrame) {
        this.sceneModel.updateAnimation(renderFrame.delteSec);
    }

    private centerPosition(group: THREE.Group) {
        const bbox = new THREE.Box3().setFromObject(group);
        const center = new THREE.Vector3();
        const size = new THREE.Vector3();

        bbox.getCenter(center);
        bbox.getSize(size);
        group.position.set(-center.x, 0, -center.z);

    }
}