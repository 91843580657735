import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide/Slide';
import clx from 'classnames';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import { ReactComponent as WebsiteIcon } from 'assets/images/icon/website.svg';
import { ReactComponent as WebsiteActiveIcon } from 'assets/images/icon/website-active.svg';
import { ReactComponent as WebwallIcon } from 'assets/images/icon/webwall.svg';
import { ReactComponent as WebwallActiveIcon } from 'assets/images/icon/webwall-active.svg';
import { ReactComponent as PortalIcon } from 'assets/images/icon/portal.svg';
import { ReactComponent as PortalActiveIcon } from 'assets/images/icon/portal-active.svg';
import { ReactComponent as ModelIcon } from 'assets/images/icon/model.svg';
import { ReactComponent as ModelActiveIcon } from 'assets/images/icon/model-active.svg';
import { ReactComponent as NPCIcon } from 'assets/images/icon/npc.svg';
import { ReactComponent as NPCActiveIcon } from 'assets/images/icon/npc-active.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/icon/arrow-down.svg';
import { ReactComponent as ARTagIcon } from 'assets/images/icon/arTag.svg';
import { MarkerType } from 'core/three/object/type';
import { useTranslation } from 'react-i18next';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { MediaStatus } from 'core/three/object/type';
import { MediaMarker } from 'core/types/media';

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        position: 'relative',
        zIndex: 2,
        background: '#444444',
    },
    buttonPanel: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '7px 21px',
        fontWeight: 'bold',
        boxShadow: '1px 0px 2px black',
        color: '#fff',
        '& > svg': {
            position: 'absolute',
            right: '14px',
        },
    },
    currentMarkerName: {
        textAlign: 'left',
        width: 'calc(100% - 60px)',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    markerList: {
        position: 'relative',
        overflowY: 'scroll',
        width: '100%',
        height: 260,
        maxHeight: 260,
        backgroundColor: '#666666',
    },
    emptyList: {
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: '#666666',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    emptyListHint: {
        textAlign: 'center',
        width: '90%',
        color: 'white',
        margin: '0',
        display: 'inline-block',
    },
    markerItems: {
        padding: '5px 10px',
        borderBottom: '1px solid #888888',
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        cursor: 'pointer',
        color: 'white',
        '&:hover': {
            color: '#C4C4C4',
        },
        '&:active': {
            color: theme.palette.primary.light,
        },
    },
    markerIcon: {
        width: 24,
        height: 24,
    },
    markerType: {
        marginRight: 20,
        fontSize: 12,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    markerName: {
        textAlign: 'left',
        width: 'calc(100% - 60px)',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    activeMarker: {
        color: theme.palette.primary.light,
        '&:hover': {
            color: theme.palette.primary.light,
        },
        '& > g path': {
            fill: theme.palette.primary.light,
        },
    },
    activeARTagMarker: {
        color: theme.palette.primary.light,
        '&:hover': {
            color: theme.palette.primary.light,
        },
        '& > path': {
            fill: theme.palette.primary.light,
        },
    },
}));

let firstLoad = true;

const MarkerList = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { currentMarker, markersMap, selectMarker, lookto } = React.useContext(TagEditorContext);
    const [open, setOpen] = React.useState(true);
    const markers = Object.keys(markersMap.state).map((key) => markersMap.state[key]);
    const displayMarkers = markers.filter(
        (marker) =>
            [MediaStatus.READ, MediaStatus.CREATED, MediaStatus.UPDATED].includes(marker.status) && marker.editable,
    );

    const showTagIcon = (marker: MediaMarker) => {
        switch (marker.type) {
            case MarkerType.TAG:
                return <ImageIcon />;
            case MarkerType.TEXT:
                return <TextFieldsIcon />;
            case MarkerType.VIDEO:
                return <YouTubeIcon />;
            case MarkerType.WEBSITE:
                if (marker.uuid === currentMarker?.uuid) {
                    return <WebsiteActiveIcon className={clx(classes.activeMarker, classes.markerIcon)} />;
                }
                return <WebsiteIcon className={classes.markerIcon} />;
            case MarkerType.WEBWALL:
                if (marker.uuid === currentMarker?.uuid) {
                    return <WebwallActiveIcon className={clx(classes.activeMarker, classes.markerIcon)} />;
                }
                return <WebwallIcon className={classes.markerIcon} />;
            case MarkerType.NPC:
                if (marker.uuid === currentMarker?.uuid) {
                    return <NPCActiveIcon className={classes.markerIcon} />;
                }
                return <NPCIcon className={classes.markerIcon} />;
            case MarkerType.PORTAL:
                if (marker.uuid === currentMarker?.uuid) {
                    return <PortalActiveIcon className={classes.markerIcon} />;
                }
                return <PortalIcon className={classes.markerIcon} />;
            case MarkerType.MODEL:
                if (marker.uuid === currentMarker?.uuid) {
                    return <ModelActiveIcon className={classes.markerIcon} />;
                }
                return <ModelIcon className={classes.markerIcon} />;
            case MarkerType.MUSIC:
                return <MusicNoteIcon />;
            case MarkerType.ARTAG:
                return <ARTagIcon className={marker.uuid === currentMarker?.uuid ? classes.activeARTagMarker : ''} />;
            default:
                return <ImageIcon />;
        }
    };
    const focusMarker = useCallback(
        (marker: MediaMarker) => {
            selectMarker.call(marker.uuid);
            lookto.call(marker.position);
        },
        [lookto, selectMarker],
    );

    useEffect(() => {
        if (!displayMarkers.length) {
            firstLoad = false;
            return;
        }
        if (!firstLoad) return;
        focusMarker(displayMarkers[0]);
        firstLoad = false;
    }, [displayMarkers, focusMarker]);

    return (
        <>
            {displayMarkers.length > 0 ? (
                <>
                    <div className={classes.buttonWrapper}>
                        <div className={classes.buttonPanel} onClick={() => setOpen((prev) => !prev)}>
                            {t('markerList.Title')}
                            <ArrowIcon className={open ? 'fa-icon-open' : 'fa-icon-close'} />
                        </div>
                    </div>
                    <Slide direction="down" in={open} mountOnEnter unmountOnExit>
                        <div className={classes.markerList}>
                            {displayMarkers.map((marker) => (
                                <div
                                    className={clx(
                                        classes.markerItems,
                                        marker.uuid === currentMarker?.uuid ? classes.activeMarker : '',
                                    )}
                                    onClick={() => focusMarker(marker)}
                                    key={marker.uuid}
                                >
                                    <div className={classes.markerType}>{showTagIcon(marker)}</div>
                                    <span className={classes.markerName}>{marker.name}</span>
                                </div>
                            ))}
                        </div>
                    </Slide>
                </>
            ) : (
                <>
                    <div className={classes.buttonWrapper}>
                        <div className={classes.buttonPanel}>{t('markerList.MarkerEdit')}</div>
                    </div>
                    <div className={classes.emptyList}>
                        <div>
                            <p className={classes.emptyListHint}>{t('markerList.Hint1')}</p>
                            <p className={classes.emptyListHint}>{t('markerList.Hint2')}</p>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default MarkerList;
