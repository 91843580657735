import * as THREE from 'three';
import { IBasicVideo } from './BasicVideo';
import { LogLevel, MediaPlayer, PlayerEventType, PlayerState } from 'amazon-ivs-player';
import { EquippedPromise, loadScript } from 'core/utils';

const ivsReady = new EquippedPromise<any>();
let loadScriptOnce = () => {
    loadScriptOnce = () => {};
    loadScript('https://player.live-video.net/1.26.0/amazon-ivs-player.min.js', () => {
        ivsReady.resolve((window as any).IVSPlayer);
    });
};

export default class StreamVideo implements IBasicVideo {
    public mesh: THREE.Mesh;
    public isPlaying: boolean;
    public thumbnailSize = { width: 1, height: 1 };
    public playerReady = new EquippedPromise();
    private texture: THREE.VideoTexture;
    private player: MediaPlayer;
    private url: string;
    private timer: any;

    constructor(thumbnailSize?) {
        loadScriptOnce();
        this.isPlaying = false;
        if (thumbnailSize) this.thumbnailSize = thumbnailSize;
    }

    private createMesh(videoTexture) {
        const ratio = 9 / 16;

        const geometry = new THREE.PlaneGeometry(this.thumbnailSize.width, this.thumbnailSize.width * ratio);
        const texture = videoTexture;
        const material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
        });

        const mesh = new THREE.Mesh(geometry, material);
        return mesh;
    }

    public async load(url: string) {
        this.url = url;
        this.player = (await ivsReady).create();
        this.player.setLogLevel(LogLevel.ERROR);
        const video = document.createElement('video');
        this.texture = new THREE.VideoTexture(video);
        this.texture.colorSpace = THREE.SRGBColorSpace;
        const videoEle = this.texture.image;
        this.player.attachHTMLVideoElement(videoEle);
        this.playerReady.resolve();
        this.mesh = this.createMesh(this.texture);
        this.checkVideo();
        return this.mesh;
    }

    public checkVideo() {
        // clearTimeout( this.timer );
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            const state = this.player.getState();
            if (state === PlayerState.READY) {
                this.playVideo();
            } else if (state === PlayerState.PLAYING) {
                // do nothing
            } else {
                this.player.load(this.url);
            }
            this.checkVideo();
        }, 1000);
    }

    public toggle() {
        if (this.isPlaying) this.pauseVideo();
        else this.playVideo();
    }

    public playVideo() {
        this.player.play();
        this.isPlaying = true;
    }

    public pauseVideo() {
        this.player.pause();
        this.isPlaying = false;
    }

    public getVolume() {
        return Promise.resolve(+this.player.getVolume().toFixed(2) * 100);
    }

    public getMuted() {
        return Promise.resolve(this.player.isMuted());
    }

    public setVolume(volume: number) {
        this.player.setVolume(volume / 100);
    }

    public update() {
        if (this.isPlaying) {
            this.texture.needsUpdate = true;
        }
    }

    public setCurrentTime(t: number) {}
    public getCurrentTime() {}

    public destroy() {
        clearTimeout(this.timer);
        //dispose geometry
        if (this.mesh.geometry) {
            this.mesh.geometry.dispose();
            this.mesh.geometry = null;
        }
        if (this.mesh.material) {
            //dispose material
            (this.mesh.material as THREE.Material).dispose();
            this.mesh.material = null;
        }
        this.player.delete();
    }

    public requestPip () {
        this.texture.image.requestPictureInPicture()
    }
}
