type loadScriptOption = {
    onLoad?: () => void
    onError?: (e: Event) => void
}

export class ScriptLoader {
    private baseUrl: string;

    constructor() {
        this.baseUrl = this.determineBaseUrl()
    }

    private determineBaseUrl(): string {
        if (typeof window == undefined) return ''

        const path = window.location.pathname
        const paths = path.replace(/^\//, '')
            .split('/')

        if (paths.length === 2) { return '../' }

        return './'
    }

    public loadScript(url: string, option?: loadScriptOption): Promise<void> {
        let resolvePromise: () => void
        let rejectPromise: (error: Error) => void
        const { onLoad, onError } = option

        const promise = new Promise<void>((resolve, reject) => {
            resolvePromise = resolve
            rejectPromise = reject
        })

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = () => {
            resolvePromise();
            if (onLoad) onLoad();
        }
        script.onerror = (e: any) => {
            rejectPromise(new Error(e));
            if (onError) onError(e)
        }
        script.src = this.baseUrl + url
        document.head.appendChild(script);

        return promise
    }
}