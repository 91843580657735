import * as THREE from 'three';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { MarkerType } from 'core/three/object/type';
import Media from '../Media';
import { IWebwall } from 'core/types/media';

export default class WebWall extends Media {
    private iframeWidth: number
    private iframeHeight: number 
    private webMesh: THREE.Mesh;
    private iframeElement: HTMLIFrameElement;
    private css3dObject: CSS3DObject;
    public css3d: boolean = true;

    constructor() {
        super(MarkerType.WEBWALL);
        this.iframeElement = document.createElement('iframe');
        this.iframeElement.style.border = '0px';
        this.iframeElement.style.opacity = '0.999';
        this.css3dObject = new CSS3DObject(this.iframeElement);

   
        const imgGeometry = new THREE.PlaneGeometry(1, 1);
        const imgMaterial = new THREE.MeshBasicMaterial({
            opacity: 0,
            color: new THREE.Color(0x000000),
            blending: THREE.NoBlending,
            side: THREE.DoubleSide,
            transparent: true,
        });

        this.webMesh = new THREE.Mesh(imgGeometry, imgMaterial);
        this.webMesh.add(this.css3dObject);

        this.object.add(this.webMesh);
    }

    public get json() {
        return {
            iframeHeight: this.iframeHeight,
            iframeWidth: this.iframeWidth,
            ...super['json'],
        };
    }

    public async init(data: IWebwall) {
        super.init(data);
        this.iframeElement.src = this.linkUrl;
        this.iframeHeight = data.iframeHeight || 2000;
        this.iframeWidth = data.iframeWidth || 1000;
        this.updateRaycastMesh([this.webMesh.geometry]);
        this.updateBoundaryBox(1, this.iframeHeight / this.iframeHeight, 0.001);
        this.updateIframeRatio()
    }

    public setJson(data: IWebwall) {
        super.setJson(data);
        this.linkUrl = data.linkUrl;
        this.iframeHeight = data.iframeHeight
        this.iframeWidth = data.iframeWidth
        this.updateRaycastMesh([this.webMesh.geometry]);
        super.markStatusUpdate();
    }

    public updateIframeRatio() {
        this.iframeElement.style.width = this.iframeWidth + 'px';
        this.iframeElement.style.height = this.iframeHeight + 'px';
        const ratio = this.iframeHeight / this.iframeWidth;
        this.css3dObject.scale.set(1 / this.iframeWidth, (1 / this.iframeHeight) * ratio, 1);
        this.webMesh.geometry.dispose()
        this.webMesh.geometry = new THREE.PlaneGeometry(1, ratio);
    }

    public showLevel() {
        if (!this.lodEnabled) {
            return;
        }
        const level = this.curLevel;
        if (this.prevLevel !== level) {
            this.prevLevel = level;
            switch (level) {
                case 0:
                    this.webMesh.visible = true;
                    this.showBoundaryBox(false);
                    break;
                case 1:
                    this.webMesh.visible = false;
                    this.showBoundaryBox(true);
                    break;
                case 2:
                    this.webMesh.visible = false;
                    this.showBoundaryBox(false);
                    break;
            }
        }
    }

    destroy() {
        super.destroy();
        if (this.iframeElement) {
            this.iframeElement.remove();
        }

        //dispose geometry
        if (this.webMesh.geometry) {
            this.webMesh.geometry.dispose();
            this.webMesh.geometry = null;
        }
        if (this.webMesh.material) {
            //dispose texture
            if ((this.webMesh.material as THREE.MeshBasicMaterial).map) {
                (this.webMesh.material as THREE.MeshBasicMaterial).map.dispose();
                (this.webMesh.material as THREE.MeshBasicMaterial).map = null;
            }

            //dispose material
            (this.webMesh.material as THREE.Material).dispose();
            this.webMesh.material = null;
        }
    }
}
