import * as THREE from 'three';
import { loadStandardCubeMap } from 'core/utils/loader';
import { CubemapMapping } from 'core/types/cubemap';

const envMapUrls: CubemapMapping = {
    nx: '/images/envmap/nx.jpg',
    ny: '/images/envmap/ny.jpg',
    nz: '/images/envmap/nz.jpg',
    px: '/images/envmap/px.jpg',
    py: '/images/envmap/py.jpg',
    pz: '/images/envmap/pz.jpg',
};

export default class PMREMGenerator extends THREE.PMREMGenerator {
    public renderTarget: THREE.WebGLRenderTarget;
    public async init() {
        this.compileCubemapShader();
        const cubeMap = await loadStandardCubeMap(envMapUrls);
        this.renderTarget = this.fromCubemap(cubeMap);
    }
}
