import * as THREE from 'three';
import AvatarModel from './AvatarModel';

export default class NpcModel extends AvatarModel {
    private idleTime: number;
    public movingSpeed = 3;
    public senseDistance = 2;
    async init(arg) {
        super.init(arg);
        this.createNamecard(arg.name, false);
    }

    protected updateDirection(dir: THREE.Vector3) {
        super.updateDirection(dir);
        const now = Date.now();
        if (this.currentAnimaion === this.animation.idle) {
            if (!this.idleTime) {
                this.idleTime = now;
            }
        } else {
            this.idleTime = null;
        }
        if (this.idleTime && now - this.idleTime > 1000) {
            this.object.lookAt(this.object.position.clone().sub(new THREE.Vector3(0.5, 0, 0.5))); // 當閒置一定時間看相使用者avatar
        }
    }
}
