import jsCookie from 'js-cookie';
import api, { setHeader, setPlatformHeader } from 'api';
import { platformUserIdKey, platformTokenKey } from 'constants/api';
import PLATFORM from 'constants/platform';
import { getDomain } from 'utils';

export const getPermission = async (username: string) => {
    const { productId } = PLATFORM;
    const urlEnd = `/sso/v1/permission/${productId}`;
    const platformToken = jsCookie.get(platformTokenKey);
    setPlatformHeader('Authorization', `Bearer ${platformToken}`);
    setHeader('Authorization', `Bearer ${platformToken}`);

    const config = {
        headers: {
            ProductKey: PLATFORM.productKey,
            Username: username,
            Authorization: `Bearer ${platformToken}`,
        },
    };
    const body = {};
    const response = await api.post(urlEnd, body, config);
    return response.data;
};

export const getDealerPermission = async (code: string) => {
    const urlEnd = `api/v1/dealerUserAcl/permission/${code}`;
    const response = await api.get(urlEnd);
    const result = response.data;
    return result;
};

export const autoLogin = async () => {
    const token = jsCookie.get(platformTokenKey);
    const userId = jsCookie.get(platformUserIdKey);
    if (token && userId) {
        setHeader('Authorization', `Bearer ${token}`);
        const urlEnd = `/api/v2/account/platform/${userId}`;
        const response = await api.get(urlEnd);
        const result = response.data;
        return result;
    }
    return Promise.reject();
};

export const login = async ({ email = '', password = '' }) => {
    const urlEnd = '/auth/v2/login';
    const body = {
        username: email,
        password: btoa(password),
    };
    const response = await api.post(urlEnd, body);
    const result = response.data;
    const { token, id } = response.data;
    jsCookie.set(platformTokenKey, token, { domain: getDomain() });
    jsCookie.set(platformUserIdKey, id, { domain: getDomain() });
    setPlatformHeader('Authorization', `Bearer ${token}`);
    setHeader('Authorization', `Bearer ${token}`);
    return result;
};
