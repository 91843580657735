import * as THREE from 'three';
import fragmentShader from './fragmentShader';
import vertexShader from './vertexShader';

export default class NormalDepthMapMaterial extends THREE.ShaderMaterial {
    constructor() {
        super({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
        });
        this.extensions.derivatives = true;
    }
}
