import * as THREE from 'three';
import { parseGIF, decompressFrames, ParsedFrame } from 'gifuct-js';

import { MarkerType } from 'core/three/object/type';
import Media from 'core/three/object/media/Media';
import { IGif } from 'core/types/media';
import { MeshBasicMaterial } from 'three';

class GifCanvasFactory {
    private instancesMap: Record<string, GifCanvas> = {};
    private instancesCount: Record<string, number> = {};
    public create(fileUrl) {
        if (this.instancesMap[fileUrl]) {
            this.instancesCount[fileUrl]++;
        } else {
            this.instancesMap[fileUrl] = new GifCanvas(fileUrl);
            this.instancesCount[fileUrl] = 1;
        }
        return this.instancesMap[fileUrl];
    }
    public dispose(gifCanvas: GifCanvas) {
        const fileUrl = gifCanvas.fileUrl;
        this.instancesCount[fileUrl]--;
        if (this.instancesCount[fileUrl] <= 0 && this.instancesMap[fileUrl]) {
            this.instancesMap[fileUrl].destroy();
            this.instancesMap[fileUrl] = null;
            this.instancesCount[fileUrl] = null;
        }
    }
}
class GifCanvas {
    public canvasElement: HTMLCanvasElement;
    private canvasCtx: CanvasRenderingContext2D;
    private tempCtx: CanvasRenderingContext2D;
    private tempCanvasElement: HTMLCanvasElement;
    private frameIndex = 0;
    private frames: ParsedFrame[] = [];
    private timer;
    constructor(public fileUrl: string) {
        const canvasElement = document.createElement('canvas');
        const tempCanvasElement = document.createElement('canvas');
        this.canvasElement = canvasElement;
        this.tempCanvasElement = tempCanvasElement;
        this.canvasCtx = canvasElement.getContext('2d');
        this.tempCtx = tempCanvasElement.getContext('2d');
        this.loadFrames()
            .then(() => {
                this.updateCanvas();
                this.renderFrame();
            })
            .catch(console.log);
    }
    async loadFrames(): Promise<void> {
        const res = await fetch(this.fileUrl, {
            cache: 'no-cache', // browser cache gif request of sidebar material, whose reponse doesn't come with cors related headers
        });
        const arraybuffer = await res.arrayBuffer();
        const parsedGif = parseGIF(arraybuffer);
        const frames = decompressFrames(parsedGif, true);
        this.frameIndex = 0;
        this.frames = frames;
    }
    async updateCanvas() {
        if (this.frames.length === 0) return;
        const width = this.frames[0].dims.width;
        const height = this.frames[0].dims.height;
        this.canvasElement.width = width;
        this.canvasElement.height = height;
    }

    get ratio() {
        if (this.frames.length === 0) return 1;
        const width = this.frames[0].dims.width;
        const height = this.frames[0].dims.height;
        const ratio = height / width;
        return ratio;
    }
    renderFrame() {
        const frame = this.frames[this.frameIndex];
        this.frameIndex += 1;
        if (this.frameIndex >= this.frames.length) {
            this.frameIndex = 0;
        }
        const width = frame.dims.width;
        const height = frame.dims.height;
        const imageData = new ImageData(frame.patch, width, height);
        if (frame.disposalType === 2) this.canvasCtx.clearRect(0, 0, width, height);
        this.tempCanvasElement.width = width;
        this.tempCanvasElement.height = height;
        this.tempCtx.putImageData(imageData, 0, 0);
        this.canvasCtx.drawImage(this.tempCanvasElement, frame.dims.left, frame.dims.top);
        this.timer = setTimeout(() => {
            requestAnimationFrame(this.renderFrame.bind(this));
        }, frame.delay);
    }

    public destroy() {
        window.clearTimeout(this.timer);
    }
}

export const gifCanvasFactory = new GifCanvasFactory();
export default class GifOnCanvas extends Media {
    private gifCanvas: GifCanvas;
    private canvasMesh: THREE.Mesh;
    private canvasTexture: THREE.CanvasTexture;
    private requestId: number;
    constructor() {
        super(MarkerType.GIF);
        const canvasGeometry = new THREE.PlaneGeometry(1.0, 1.0, 1, 1);
        const canvasMaterial = new MeshBasicMaterial({
            transparent: true,
            alphaTest: 0.1,
        });
        this.canvasMesh = new THREE.Mesh(canvasGeometry, canvasMaterial);
        this.object.add(this.canvasMesh);
    }

    public get json() {
        return {
            ...super['json'],
        };
    }

    public async init(data: IGif): Promise<void> {
        super.init(data);

        this.gifCanvas = gifCanvasFactory.create(data.fileUrl);
        this.canvasTexture = new THREE.CanvasTexture(this.gifCanvas.canvasElement);
        this.canvasTexture.colorSpace = THREE.SRGBColorSpace;
        (this.canvasMesh.material as MeshBasicMaterial).map = this.canvasTexture;
        this.updateTexture();
    }

    public setJson(data: IGif) {
        const oldFileUrl = this.fileUrl;
        super.setJson(data);
        super.markStatusUpdate();
        if (data.fileUrl !== oldFileUrl) {
            gifCanvasFactory.dispose(this.gifCanvas);
            this.gifCanvas = gifCanvasFactory.create(this.fileUrl);
        }
    }
    public updateTexture() {
        this.canvasTexture.needsUpdate = true;
        this.requestId = requestAnimationFrame(this.updateTexture.bind(this));
    }

    async updateMesh() {
        const ratio = this.gifCanvas.ratio;
        const oldGeometry = this.canvasMesh.geometry;
        const newGeometry = new THREE.PlaneGeometry(1, ratio);
        this.canvasMesh.geometry = newGeometry;
        if (oldGeometry) oldGeometry.dispose();

        this.updateRaycastMesh([newGeometry]);
        this.updateBoundaryBox(1, ratio, 0.001);
    }

    public showLevel() {
        if (!this.lodEnabled) {
            return;
        }
        const level = this.curLevel;
        if (this.prevLevel !== level) {
            this.prevLevel = level;
            switch (level) {
                case 0:
                    this.canvasMesh.visible = true;
                    this.showBoundaryBox(false);
                    break;
                case 1:
                    this.canvasMesh.visible = true;
                    this.showBoundaryBox(false);
                    break;
                case 2:
                    this.canvasMesh.visible = true;
                    this.showBoundaryBox(false);
                    break;
            }
        }
    }

    public destroy() {
        super.destroy();
        gifCanvasFactory.dispose(this.gifCanvas);
        cancelAnimationFrame(this.requestId);
        //dispose geometry
        if (this.canvasMesh.geometry) {
            this.canvasMesh.geometry.dispose();
            this.canvasMesh.geometry = null;
        }

        if (this.canvasMesh.material) {
            //dispose texture
            if ((this.canvasMesh.material as THREE.MeshBasicMaterial).map) {
                (this.canvasMesh.material as THREE.MeshBasicMaterial).map.dispose();
                (this.canvasMesh.material as THREE.MeshBasicMaterial).map = null;
            }

            //dispose material
            (this.canvasMesh.material as THREE.Material).dispose();
            this.canvasMesh.material = null;
        }
    }
}
