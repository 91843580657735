import * as THREE from 'three';
import OrbitControls from 'core/three/OrbitControls';
import EventDispatcher from './EventDispatcher';

const DAMPING = 0.1;
const PANSPEED = 0.05;
const ROTATESPEED = 0.05;
const TOP_MAX_POLAR_ANGLE = Math.PI / 2;
const TOP_MIN_POLAR_ANGLE = 0;
const GROUND_MAX_POLAR_ANGLE = Math.PI;
const GROUND_MIN_POLAR_ANGLE = 0;
const GROUND_MIN_DISTANCE = 0.00001; // 0.00001 not 0 to prevent lock.
const GROUND_MAX_DISTANCE = 4;
const TOP_MIN_DISTANCE = 12;
const TOP_MAX_DISTANCE = 20;
interface ViewportControlsEvent {
    change: void;
    distanceChange: number;
}
export default class ViewportControls extends EventDispatcher<ViewportControlsEvent> {
    /** event when the viewport has any change */
    public change = 'change' as const;
    /** event when the camera zoom has any change */
    public distanceChange = 'distanceChange' as const;
    /** the html element to listen the mouse event for the controls */
    public domElement: HTMLElement;
    /** the preferable distance. Systems (ThreeView) will try to adjust the object distance to this value while viewport isn't blocked by the scene */
    public preferDistance: number = 0;
    /** THREE.OrbitControls */
    public controls: OrbitControls;
    /** set this value to enable or disable manual zooming the object */
    public enableZoom: boolean = false;
    /** min distance of the object to the target */
    public minDistance = TOP_MIN_DISTANCE;
    /** max distance of the object to the target */
    public maxDistance = TOP_MAX_DISTANCE;
    /** the object to be controlled */
    private object: THREE.Object3D;
    private _distance: number = 0;
    private unSubscribeZoom = () => { };
    // public pmremGenerator: THREE.PMREMGenerator;

    get polarAngleLimit() {
        return [this.controls.minPolarAngle, this.controls.maxPolarAngle];
    }

    get azimuthAngleLimit() {
        return [this.controls.minAzimuthAngle, this.controls.maxAzimuthAngle];
    }

    get distanceLimit() {
        return [this.minDistance, this.maxDistance];
    }

    get enabled() {
        return this.controls.enabled;
    }

    set enabled(option: boolean) {
        this.controls.enabled = option;
    }

    public get distance() {
        return this._distance;
    }

    /**
     * set the object distance. The given value would be clamped within minDistance and maxDistance
     */
    public set distance(d: number) {
        d = Math.max(Math.min(d, this.maxDistance), this.minDistance);
        const targetPosition = this.getTargetPosition();
        const direction = new THREE.Vector3();
        this.object.getWorldDirection(direction);
        const objectPosition = targetPosition.clone().add(direction.multiplyScalar(-d));
        this.object.position.copy(objectPosition);
        this._distance = d;
        this.dispatchEvent({ type: this.distanceChange, data: d });
    }

    public get polarAngle() {
        return this.controls.getPolarAngle();
    }

    public set polarAngle(newValue: number) {
        const oldmin = this.controls.minPolarAngle;
        const oldmax = this.controls.maxPolarAngle;
        this.controls.minPolarAngle = newValue;
        this.controls.maxPolarAngle = newValue;
        this.controls.update();
        this.controls.minPolarAngle = oldmin;
        this.controls.maxPolarAngle = oldmax;
        this.controls.update();
    }

    public get azimuthAngle() {
        return this.controls.getAzimuthalAngle();
    }

    public set azimuthAngle(newValue: number) {
        const oldmin = this.controls.minAzimuthAngle;
        const oldmax = this.controls.maxAzimuthAngle;
        // fix coordinate , origion coordinate range is [-Math.PI,-0] [0,Math.PI] and ±Math.PI are neighbors
        // new value could be [ 0 , 2*Math.PI ]  or [ -0 , -2*Math.PI ]
        let fixCoordinate = newValue;
        if (fixCoordinate > Math.PI) {
            fixCoordinate = newValue - Math.PI + -Math.PI;
        } else if (fixCoordinate < -Math.PI) {
            fixCoordinate = newValue + Math.PI + Math.PI;
        }
        this.controls.minAzimuthAngle = fixCoordinate;
        this.controls.maxAzimuthAngle = fixCoordinate;
        this.controls.update();
        this.controls.minAzimuthAngle = oldmin;
        this.controls.maxAzimuthAngle = oldmax;
        this.controls.update();
    }

    constructor(domElement: HTMLElement, object: THREE.Object3D) {
        super()
        this.object = object;
        const controls = new OrbitControls(object, domElement);
        this.domElement = domElement;
        this.controls = controls;

        this.controls.target.set(0, 0, 0);
        this.object.position.set(0, 0, 0);
        this.controls.domElement = domElement;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = DAMPING;
        this.controls.panSpeed = PANSPEED;
        this.controls.rotateSpeed = ROTATESPEED;
        this.controls.enableZoom = false

        this.polarAngle = Math.PI / 4;
        this.azimuthAngle = 0;

        this.controls.update();
        (this.controls as unknown as THREE.EventDispatcher).addEventListener('change', () =>
            this.dispatchEvent({ type: this.change, data: void 0 }),
        );

        this.distance = 15;
    }

    public init() {
        this.subscribeZoom();
        // this.pmremGenerator = new THREE.PMREMGenerator(renderer);
        // this.pmremGenerator.compileCubemapShader();
    }

    public dispose() {
        this.unSubscribeZoom()
    }

    public update() {
        this.controls.update();
    }

    public setOrthographicLimit() {
        this.controls.minPolarAngle = 0;
        this.controls.maxPolarAngle = 0;
        this.controls.rotateSpeed = ROTATESPEED;
        this.minDistance = TOP_MIN_DISTANCE;
        this.maxDistance = TOP_MAX_DISTANCE;
    }

    public setTopViewLimit() {
        this.controls.minPolarAngle = TOP_MIN_POLAR_ANGLE;
        this.controls.maxPolarAngle = TOP_MAX_POLAR_ANGLE;
        this.controls.rotateSpeed = ROTATESPEED;
        this.minDistance = TOP_MIN_DISTANCE;
        this.maxDistance = TOP_MAX_DISTANCE;
    }

    public setFirstpersonViewLimit() {
        this.controls.minPolarAngle = GROUND_MIN_POLAR_ANGLE;
        this.controls.maxPolarAngle = GROUND_MAX_POLAR_ANGLE;
        this.controls.rotateSpeed = -ROTATESPEED;
        this.minDistance = GROUND_MIN_DISTANCE;
        this.maxDistance = GROUND_MAX_DISTANCE;
    }

    public unsetViewLimit() {
        this.controls.minPolarAngle = -Infinity;
        this.controls.maxPolarAngle = Infinity;
        this.minDistance = GROUND_MIN_DISTANCE;
        this.maxDistance = Infinity;
    }

    /**
     * get the position of controls object
     */
    public getObjectPosition() {
        return this.object.position.clone();
    }

    /**
     * get the position of controls target
     */
    public getTargetPosition() {
        return this.controls.target.clone();
    }

    /**
     * set the position of controls target
     */
    public setTargetPosition(pos: THREE.Vector3) {
        this.controls.target.set(pos.x, pos.y, pos.z);
    }

    /**
     * adjust the object to look to the give position
     */
    public setLookPisition(pos: THREE.Vector3) {
        const to = new THREE.Vector3(pos.x, pos.y, pos.z);
        const from = this.object.position;
        const direction = new THREE.Vector3().subVectors(to, from);
        const azimuthAngle = Math.atan2(direction.x, direction.z) - Math.PI;
        const polarAngle = Math.asin(direction.y / direction.length()) + Math.PI / 2;
        this.polarAngle = polarAngle;
        this.azimuthAngle = azimuthAngle;
    }

    /**
     * move the object along with target by the given movement
     */
    public move(movement: THREE.Vector3) {
        this.object.position.add(movement);
        this.controls.target.add(movement);
    }

    private subscribeZoom() {
        const onWheel = (e) => {
            e.preventDefault()
            if (!this.enableZoom) return;
            const delta = Math.max(Math.min(e.deltaY, 5), -5) * 0.01;
            this.distance += delta;
            this.preferDistance = this.distance;
        };
        this.domElement.addEventListener('wheel', onWheel);
        this.removeEventListener = () => {
            this.domElement.removeEventListener('wheel', onWheel);
        };
    }
}
