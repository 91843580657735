import { CSS3DRenderer } from "three/examples/jsm/renderers/CSS3DRenderer";
import EventDispatcher from "./EventDispatcher";
import * as THREE from 'three';
import Scene from 'core/three/scene/Scene';


interface RendererMgrEvent {
    preRender: number
    postRender: number
    resized: { width: number, height: number; };
}
export default class RendererMgr extends EventDispatcher<RendererMgrEvent> {
    /** event when the canvas is resized */
    public resized = 'resized' as const;
    /** event before rendering takes place */
    public preRender = 'preRender' as const;
    /** event after rendering takes place */
    public postRender = 'postRender' as const;
    public renderer: THREE.WebGLRenderer;
    public cssrenderer: CSS3DRenderer;
    public canvas: HTMLCanvasElement
    private observer: ResizeObserver;
    private timestamp: number = performance.now()

    constructor(canvas: HTMLCanvasElement, cssRoot: HTMLDivElement) {
        super()
        this.canvas = canvas
        this.initWebGlRenderer(canvas);
        this.initCssRenderer(cssRoot);
        this.setSize(canvas.clientWidth, canvas.clientHeight);
    }

    public init() {
        this.observer = new window.ResizeObserver(() => {
            this.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
        });
        this.observer.observe(this.canvas);
    }

    /**
     * starts the animation rendering loop on every frame.
     */
    public start(scene: Scene, camera: THREE.Camera) {
        this.renderer.setAnimationLoop(() => this.render(scene, camera))
    }

    /**
     * stops the animation rendering loop on every frame.
     */
    public stop() {
        this.renderer.setAnimationLoop(null)
    }

    public destroy() {
        this.observer.disconnect();
        this.renderer.setAnimationLoop(null)
    }

    private setSize(width: number, height: number) {
        this.renderer.setSize(width, height, false);
        this.renderer.setScissor(0, 0, width, height);
        this.renderer.setViewport(0, 0, width, height);
        this.cssrenderer.setSize( width % 2 === 0 ? width : width - 1, height % 2 === 0 ? height : height - 1);
        this.dispatchEvent({ type: this.resized, data: { width, height } });
    }

    private render(scene: Scene, camera: THREE.Camera) {
        const now = performance.now()
        const diff = now - this.timestamp
        this.timestamp = now
        this.dispatchEvent({type: this.preRender, data: diff})
        this.cssrenderer.render(scene.css3dScene, camera);
        this.renderer.render(scene, camera);
        this.dispatchEvent({type: this.postRender, data: diff})
    }

    private initWebGlRenderer(canvas: HTMLCanvasElement) {
        this.renderer = new THREE.WebGLRenderer({
            canvas,
            alpha: true,
            antialias: true,
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.autoClear = false;
        this.renderer.setClearColor(0x000000, 0.0);
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 1;
    }

    private initCssRenderer(cssRoot: HTMLDivElement) {
        this.cssrenderer = new CSS3DRenderer();
        this.cssrenderer.domElement.style.position = 'absolute';
        this.cssrenderer.domElement.style.top = '0';
        cssRoot.appendChild(this.cssrenderer.domElement);
    }
}
