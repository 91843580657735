import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { useContextState, ContextState } from 'hooks';

export const ThemeContext = React.createContext<{
    primary: ContextState<string>;
    secondary: ContextState<string>;
    mode: ContextState<'light' | 'dark'>;
}>(null);
export default function Provider(props) {
    const primary = useContextState('#F2539D');
    const secondary = useContextState('#662D91');
    const mode = useContextState<'light' | 'dark'>('light');
    const theme = createTheme({
        palette: {
            type: mode.state,
            primary: {
                main: primary.state,
            },
            secondary: {
                main: secondary.state,
            },
            error: {
                main: '#D96666',
            },
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*': {
                        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                            width: '7px',
                        },
                        '&::-webkit-scrollbar-button:horizontal:single-button:start': {
                            width: '24px',
                            background: '#000000 0 -52px no-repeat',
                            cursor: 'pointer',
                        },
                        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                            borderRadius: 10,
                            background: '#000',
                            border: '0.5px solid #FFFFFF',
                        },
                    },
                    '.MuiOutlinedInput-root': {
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: `2px solid ${primary.state} !important;`,
                        },
                    },
                },
            },
        },
    });
    return (
        <ThemeContext.Provider value={{ primary, secondary, mode }}>
            <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </ThemeContext.Provider>
    );
}
