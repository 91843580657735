import * as THREE from 'three';

/**
 * Safe version of THREE.EventDispatcher.
 * Catches the errors might happen during the handler registered by addEventListener to make sure all handlers are notified.
 * And Supports type checking.
 */
export default class EventDispatcher<
    T extends { [event: string]: any } = Record<string, any>,
> extends THREE.EventDispatcher<T> {
    dispatchEvent<K extends Extract<keyof T, string>>(event: { data: T[K]; type: K }): void {
        try {
            super.dispatchEvent(event as any);
        } catch (err) {
            console.error(err);
        }
    }
    addEventListener<K extends Extract<keyof T, string>, U extends THREE.Event & { data: T[K] }>(
        type: K,
        listener: (event: U) => void,
    ): () => void {

        super.addEventListener(type, listener);

        // @ts-ignored
        return () => this.removeEventListener(type, listener);
    }
}
