import * as THREE from 'three';
import CustomObject3D from '../object/CustomObject3D';
import { pointerRaycasterLayer } from 'core/constants/layer';
import RaycasterMgr from './RaycasterMgr';
import EventDispatcher from './EventDispatcher';
import CameraMgr from './CameraMgr';
import { InteractionObject } from '../object/InteractionObect';

export interface IRaycastResponse {
    target: CustomObject3D;
    rotation: THREE.Matrix4;
    position: THREE.Vector3;
    raycaster: Raycaster
}

const up = new THREE.Vector3(0, 1, 0);

export const RAYCASTTYPE = {
    GETBOTH: 0b11,
    GETPOSITION: 0b1,
    GETOBJECT: 0b10,
    hasProperty: (value: number, type: number) => {
        return (value & type) === type;
    },
};

export default class Raycaster<Event = {}> extends EventDispatcher<Event> {
    public latestObject: InteractionObject;
    public latestPosition = new THREE.Vector3();
    public raycasterMgr: RaycasterMgr;
    protected raycaster: THREE.Raycaster = new THREE.Raycaster();
    private isRaycastDown: boolean = false;
    private raycasterDownTime: number = 0;
    private raycasterUpTime: number = 0;
    private raycasterClickThreshold: number = 500; //milliseconds

    public constructor(raycasterMgr: RaycasterMgr) {
        super();
        this.raycasterMgr = raycasterMgr;
        this.raycaster.layers.enable(pointerRaycasterLayer);
        this.raycaster.layers.enable(pointerRaycasterLayer);
    }

    protected onRaycasterDown(): IRaycastResponse | null {
        this.raycasterDownTime = new Date().getTime();
        this.isRaycastDown = true;

        const { object } = this.raycast(this.raycasterMgr.castObjectTargets, false);
        const { position, worldNormal } = this.raycast(this.raycasterMgr.castPositionNormalTargets);
        const rotationMat4 = new THREE.Matrix4().lookAt(worldNormal, new THREE.Vector3(0, 0, 0), up);

        this.latestObject = object;
        const event = {
            target: object,
            rotation: rotationMat4,
            position: position,
            raycaster: this
        };
        this.raycasterMgr.dispatchEvent('mousedown', event);
        object?.onMouseDown(event);
        return event
    }

    protected onRaycasterUp(): IRaycastResponse | null {
        if (!this.isRaycastDown) return null;
        this.raycasterUpTime = new Date().getTime();

        const { object } = this.raycast(this.raycasterMgr.castObjectTargets, false);
        const { position, worldNormal } = this.raycast(this.raycasterMgr.castPositionNormalTargets);
        const rotationMat4 = new THREE.Matrix4().lookAt(worldNormal, new THREE.Vector3(0, 0, 0), up);
        const event = {
            target: object,
            rotation: rotationMat4,
            position: position,
            raycaster: this
        };
        this.raycasterMgr.dispatchEvent('mouseup', event);
        object?.onMouseUp(event);
        this.isRaycastDown = false;
        return event
    }

    protected onRaycasterMove(): IRaycastResponse | null {
        const { object } = this.raycast(this.raycasterMgr.castObjectTargets, false);
        const { position, worldNormal } = this.raycast(this.raycasterMgr.castPositionNormalTargets);
        const rotationMat4 = new THREE.Matrix4().lookAt(worldNormal, new THREE.Vector3(0, 0, 0), up);
        this.latestPosition = position.clone();
        const event = {
            target: object,
            rotation: rotationMat4,
            position: position,
            raycaster: this
        };

        object?.onMouseMove(event);
        if (object !== this.latestObject) {
            object?.onMouseEnter(event);
            object && this.raycasterMgr.dispatchEvent('mouseenter', event);
            this.latestObject?.onMouseLeave();
            this.latestObject &&
                this.raycasterMgr.dispatchEvent('mouseleave', {
                    target: this.latestObject,
                    position: position,
                    rotation: rotationMat4,
                    raycaster: this
                });
            this.latestObject = object;
        }

        this.raycasterMgr.dispatchEvent('mousemove', event);
        return event
    }

    protected onRaycasterClick(): IRaycastResponse | null {
        if (this.raycasterUpTime - this.raycasterDownTime > this.raycasterClickThreshold) return null;
        const { object } = this.raycast(this.raycasterMgr.castObjectTargets, false);
        const { position, worldNormal } = this.raycast(this.raycasterMgr.castPositionNormalTargets);
        const rotationMat4 = new THREE.Matrix4().lookAt(worldNormal, new THREE.Vector3(0, 0, 0), up);
        const event = {
            target: object,
            rotation: rotationMat4,
            position: position,
            raycaster: this
        };
        this.raycasterMgr.dispatchEvent('click', event);
        object?.onClick(event);
        return event
    }

    protected raycast(object3ds: CustomObject3D | CustomObject3D[], recursive = true) {
        object3ds = [].concat(object3ds);
        const targets = object3ds.map((object3d) => object3d.raycastTarget);
        const intersects = this.raycaster.intersectObjects(targets, recursive);
        if (!intersects.length) {
            return {
                object: undefined,
                position: new THREE.Vector3(),
                nornal: new THREE.Vector3(),
                worldNormal: new THREE.Vector3(),
            };
        }
        const intersect = intersects[0];
        const intersectObject3d = object3ds.find((object3d) => object3d.raycastTarget === intersect.object);
        return {
            object: intersectObject3d,
            position: intersect.point,
            normal: intersect.face.normal.clone(),
            worldNormal: intersect.face.normal.clone().transformDirection(intersect.object.matrixWorld),
        };
    }
}
