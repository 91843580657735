import * as React from 'react';
import './TopMenu.css';
import ThreeContext from 'app/pages/three-context';

import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import Instruction from 'app/components/TopMenu/Instruction/Instruction';
import Tooltip from '@material-ui/core/Tooltip';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as LocationIcon } from 'assets/images/icon/location.svg';
import { ReactComponent as SaveIcon } from 'assets/images/icon/save.svg';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ReactComponent as TurnLeftIcon } from 'assets/images/icon/turn-left.svg';
import { ReactComponent as TurnRightIcon } from 'assets/images/icon/turn-right.svg';

// constants
import SERVER from 'constants/server';

const useStyles = makeStyles(() =>
    createStyles({
        customTooltip: {
            background: '#333333',
            color: '#ffffff',
        },
        menuIcon: {
            margin: '11px 13px',
            width: 44,
            height: 44,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& img': {
                width: 26,
                height: 26,
            },
            '&:hover': {
                cursor: 'pointer',
            },
        },
        homeIcon: {
            fontSize: '35px',
            color: '#888888',
            '&:hover': {
                color: '#ffffff',
            },
        },
        actionBtn: {
            display: 'flex',
            '& > button': {
                color: '#ffffff',
            },
            '& > button:first-child': {
                borderColor: '#ffffff',
            },
        },
        customBtn: {
            marginRight: '10px',
            '&:hover': {
                background: 'rgba( 255, 255, 255, 0.3)',
            },
        },
        customIcon: {
            marginRight: '7px',
        },
        saveBtn: {
            paddingRight: '20px',
            paddingLeft: '20px',
            marginRight: '10px',
        },
    }),
);

export default function TopMenu() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { actions } = React.useContext(ThreeContext);
    const { initCamera, uploading } = React.useContext(TagEditorContext);

    const handleSave = () => {
        return actions.save();
    };

    return (
        <div className="top-menu">
            <Tooltip
                classes={{
                    tooltip: classes.customTooltip,
                }}
                title={t('tooltip.backToSceneList')}
                placement="right-end"
            >
                <a href={`${SERVER.SPACE_EDITOR_URL}`} className={classes.menuIcon} rel="noreferrer noopener">
                    <HomeOutlinedIcon className={classes.homeIcon} color="primary" />
                </a>
            </Tooltip>
            <div className={classes.actionBtn}>
                <IconButton aria-label="undo" size="medium" onClick={actions.undo}>
                    <TurnLeftIcon />
                </IconButton>
                <IconButton aria-label="redo" size="medium" onClick={actions.redo}>
                    <TurnRightIcon />
                </IconButton>
                <Tooltip
                    classes={{
                        tooltip: classes.customTooltip,
                    }}
                    title={t('tooltip.setInitialView')}
                    placement="right-end"
                >
                    <Button variant="outlined" className={classes.customBtn} onClick={initCamera.call}>
                        <LocationIcon className={classes.customIcon} />
                        {t('common.setInitialView')}
                    </Button>
                </Tooltip>
                <Button
                    disabled={uploading.state}
                    variant="contained"
                    color="primary"
                    className={classes.saveBtn}
                    onClick={handleSave}
                >
                    {uploading.state ? null : <SaveIcon className={classes.customIcon} />}
                    {t('common.Save')}
                </Button>
            </div>
        </div>
    );
}
