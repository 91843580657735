import * as THREE from 'three';
import EventDispatcher from './EventDispatcher';
import CollisionPerson from './CollisionPerson';
import { RenderFrame } from 'core/types';

interface PersonControlsEvent {
    positionChange: {
        position: { x: number; y: number; z: number },
        movement: { x: number; y: number; z: number }
    };
}
export default class PersonControls extends EventDispatcher<PersonControlsEvent> {
    public positionChange = 'positionChange' as const;
    public moveForward: boolean = false;
    public moveBackward: boolean = false;
    public moveLeft: boolean = false;
    public moveRight: boolean = false;
    public moveUp: boolean = false;
    public moveDown: boolean = false;
    public isWalking: boolean = false;
    public isFollowing: boolean = false;
    public destination = new THREE.Vector3();
    public enableUpAndDown: boolean = false;
    public scene: THREE.Object3D = null;
    public person = new CollisionPerson();

    private velocity = new THREE.Vector3();
    private _enabled: boolean = false;
    private _enablePhysic: boolean = false;
    private speed: number = 50;
    private prevTime: number = performance.now();
    protected domElement: HTMLCanvasElement | Document;

    constructor(canvas?: HTMLCanvasElement) {
        super();
        this.domElement = canvas || document;
    }

    set enabled(option: boolean) {
        this._enabled = option;
        this.detachKeyboardEvent();
        if (option) this.attachKeyboardEvent();
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enablePhysic(option: boolean) {
        this._enablePhysic = option;
        this.person.collisionEnabled = option;
    }

    get enablePhysic(): boolean {
        return this._enablePhysic;
    }

    get walking(): boolean {
        return this.isWalking;
    }

    /**
     * Returns a vector3 representing moving left and right, up and down, forth and back in local space.
     */
    get movingDirection(): THREE.Vector3 {
        return new THREE.Vector3(
            Number(this.moveRight) - Number(this.moveLeft),
            Number(this.moveUp) - Number(this.moveDown),
            Number(this.moveForward) - Number(this.moveBackward),
        );
    }

    set direction(dir: THREE.Vector3) {
        this.person.lookAt(this.person.position.clone().add(dir))
    }

    private onKeyDown(event: KeyboardEvent) {
        if (!this._enabled) {
            this.onBlur();
        }
        switch (event.code) {
            // up
            case 'KeyW':
            case 'ArrowUp':
                this.moveForward = true;
                this.isWalking = true;
                break;

            // left
            case 'KeyA':
            case 'ArrowLeft':
                this.moveLeft = true;
                this.isWalking = true;
                break;

            // down
            case 'KeyS':
            case 'ArrowDown':
                this.moveBackward = true;
                this.isWalking = true;
                break;

            // right
            case 'KeyD':
            case 'ArrowRight':
                this.moveRight = true;
                this.isWalking = true;
                break;

            // rise
            case 'KeyE':
                if (this.enableUpAndDown) {
                    this.moveUp = true;
                    this.isWalking = true;
                }
                break;

            // fall
            case 'KeyQ':
                if (this.enableUpAndDown) {
                    this.moveDown = true;
                    this.isWalking = true;
                }
                break;

            default:
                break;
        }
    }

    private onKeyUp(event: KeyboardEvent) {
        switch (event.code) {
            // up
            case 'KeyW':
            case 'ArrowUp':
                this.moveForward = false;
                this.isWalking = false;
                break;

            // left
            case 'KeyA':
            case 'ArrowLeft':
                this.moveLeft = false;
                this.isWalking = false;
                break;

            // down
            case 'KeyS':
            case 'ArrowDown':
                this.moveBackward = false;
                this.isWalking = false;
                break;

            // right
            case 'KeyD':
            case 'ArrowRight':
                this.moveRight = false;
                this.isWalking = false;
                break;

            // rise
            case 'KeyE':
                this.moveUp = false;
                this.isWalking = false;
                break;

            // fall
            case 'KeyQ':
                this.moveDown = false;
                this.isWalking = false;
                break;

            default:
                this.onBlur();
                break;
        }
    }

    public setScene(scene: THREE.Object3D) {
        // TODO: clean reference when scene destory
        this.scene = scene;
        this.person.setScene(scene);
    }

    /**
     * Apply the movement based on keybord status and physic condition.
     */
    public update(renderFrame: RenderFrame) {
        let movement = new THREE.Vector3();
        const delta = Math.min(renderFrame.delteSec, (1 / 60) * 3); // make sure delta not overbig under poor performance
        this.velocity.addScaledVector(this.velocity, -15.0 * delta);
        if (this.moveForward) this.velocity.add(this.person.forth.clone().multiplyScalar(this.speed * delta));
        if (this.moveBackward) this.velocity.add(this.person.back.clone().multiplyScalar(this.speed * delta));
        if (this.moveLeft) this.velocity.add(this.person.left.clone().multiplyScalar(this.speed * delta));
        if (this.moveRight) this.velocity.add(this.person.right.clone().multiplyScalar(this.speed * delta));
        if (this.moveUp) this.velocity.add(this.person.up.clone().multiplyScalar(this.speed * delta));
        if (this.moveDown) this.velocity.add(this.person.down.clone().multiplyScalar(this.speed * delta));
        if (!this.person.isOnFloor && this.enablePhysic) this.velocity.y -= 50 * delta;
        else if (this.person.isOnFloor && this.enablePhysic) this.velocity.y = 0;
        if (this.isFollowing)
            this.velocity.add(
                this.destination
                    .clone()
                    .sub(this.person.position)
                    .multiplyScalar(this.speed * delta),
            );
        if (this.velocity.length() > 0.01) {
            // Save performance when movemoent is tiny.
            movement = this.velocity.clone().multiplyScalar(delta);
            this.person.move(movement);
            this.dispatchEvent({
                type: this.positionChange,
                data: {
                    position: this.person.position,
                    movement: movement
                },
            });
        }
    }

    public setPosition(vec: THREE.Vector3) {
        const prevPosition = this.person.position.clone()
        this.person.setPosition(vec);
        this.dispatchEvent({
            type: this.positionChange,
            data: {
                position: this.person.position,
                movement: this.person.position.clone().sub(prevPosition)
            },
        });
    }

    private onBlur() {
        this.isWalking = false;
        this.moveBackward = false;
        this.moveDown = false;
        this.moveForward = false;
        this.moveUp = false;
        this.moveLeft = false;
        this.moveRight = false;
    }

    private attachKeyboardEvent() {
        this.domElement.addEventListener('keydown', this.onKeyDown.bind(this));
        window.addEventListener('keyup', this.onKeyUp.bind(this));
        this.domElement.addEventListener('blur', this.onBlur.bind(this));
    }

    private detachKeyboardEvent() {
        this.domElement.removeEventListener('keydown', this.onKeyDown.bind(this));
        window.removeEventListener('keyup', this.onKeyUp.bind(this));
        this.domElement.removeEventListener('blur', this.onBlur.bind(this));
    }
}
