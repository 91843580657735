import * as THREE from 'three'
import { pointerRaycasterLayer } from 'core/constants/layer';
import CustomObject3D from '../object/CustomObject3D';
import Raycaster, { IRaycastResponse, RAYCASTTYPE } from './Raycaster';

export type PointerEvents = 'click' | 'mousemove' | 'mouseup' | 'mousedown' | 'mouseenter' | 'mouseleave';
type RaycasterEventInfo<
    T extends CustomObject3D = CustomObject3D,
    C extends { new (...t: any[]): T } = { new (...t: any[]): T },
> = { targets?: C[]; exclude?: C[]; handler: (t?: T, position?: THREE.Vector3, rotation?: THREE.Matrix4, raycaster?: Raycaster) => void };

class RaycasterMgr {
    public castPositionNormalTargets: CustomObject3D[] = [];
    public castObjectTargets: CustomObject3D[] = [];
    private clickListeners: RaycasterEventInfo[] = [];
    private mousemoveListeners: RaycasterEventInfo[] = [];
    private mouseupListeners: RaycasterEventInfo[] = [];
    private mousedownListeners: RaycasterEventInfo[] = [];
    private mouseenterListeners: RaycasterEventInfo[] = [];
    private mouseleaveListeners: RaycasterEventInfo[] = [];
    private castPositionNormalLayer: number = pointerRaycasterLayer;
    private castObjectLayer: number = pointerRaycasterLayer;

    /**
     * add an eventListener to any raycaster with particular raycast target
     * @param event pointer event to listen
     * @param info.target filter out any event whose raycast target isn't the instance in the target classes. if not provided, any raycast target will be triggered.
     * @param info.exclude filter out any event whose raycast target is the instance in the exclude classes.
     * @param info.handler the event handler
     */
    public on(event: PointerEvents, info: RaycasterEventInfo) {
        this[`${event}Listeners`].push(info);
        return () => {
            const index = this[`${event}Listeners`].indexOf(info);
            if (index > -1) this[`${event}Listeners`].splice(index, 1);
        };
    }

    /**
     * @param {CustomObject3D | CustomObject3D[]} target add CustomObject3D as the raycaster targets
     * @param type the purpose of the target. With GETOBJECT type you get the obect. With GETPOSITION you get the normal the position.  
     */
    public addTarget(targets: CustomObject3D | CustomObject3D[], type = RAYCASTTYPE.GETOBJECT) {
        targets = [].concat(targets);
        targets.forEach((target) => target.addCustumDestory(this.removeTarget.bind(this, target, type)));
        if (RAYCASTTYPE.hasProperty(type, RAYCASTTYPE.GETPOSITION)) {
            targets.forEach((targets) => targets.enableRaycasterLayer(this.castPositionNormalLayer));
            this.castPositionNormalTargets.push(...targets);
        }
        if (RAYCASTTYPE.hasProperty(type, RAYCASTTYPE.GETOBJECT)) {
            targets.forEach((targets) => targets.enableRaycasterLayer(this.castObjectLayer));
            this.castObjectTargets.push(...targets);
        }
    }

    /**
     * @param {CustomObject3D | CustomObject3D[]} target remove CustomObject3D from the raycaster targets
     * @param type the purpose of the target. With GETOBJECT type you get the obect. With GETPOSITION you get the normal the position.  
     */
    public removeTarget(targets: CustomObject3D | CustomObject3D[], type = RAYCASTTYPE.GETOBJECT) {
        targets = [].concat(targets);
        const removeItemFrom = (items: any[], arr: any[]) => {
            items.forEach((item) => {
                const index = arr.indexOf(item);
                if (index !== -1) arr.splice(index, 1);
            });
        };
        if (RAYCASTTYPE.hasProperty(type, RAYCASTTYPE.GETPOSITION))
            removeItemFrom(targets, this.castPositionNormalTargets);

        if (RAYCASTTYPE.hasProperty(type, RAYCASTTYPE.GETOBJECT))
            removeItemFrom(targets, this.castObjectTargets);
    }

    /**
     * dispatch the raycaster events. called by Raycaster
     */
    public dispatchEvent(event: PointerEvents, info: IRaycastResponse) {
        if (!info?.target) {
            this[`${event}Listeners`].forEach(({ targets, exclude, handler }) => {
                if (targets && targets.length === 0) {
                    try {
                        handler(info.target as unknown as CustomObject3D, info.position, info.rotation);
                    } catch (err) {
                        console.error(err);
                    }
                }
            });
            return;
        }
        this[`${event}Listeners`].forEach(({ targets, exclude, handler }) => {
            const match = targets ? targets.some((targetClass) => info.target instanceof targetClass): true;
            const isExclude = (exclude || []).some((targetClass) => info.target instanceof targetClass);
            if (match && !isExclude) {
                try {
                    handler(info.target as unknown as CustomObject3D, info.position, info.rotation, info.raycaster);
                } catch (err) {
                    console.error(err);
                }
            }
        });
    }
}

export default RaycasterMgr;
