import SceneSlot from '../object/sceneSlot/SceneSlot';
import ISceneSlot from '../../types/sceneSlot'
import BaseMgr from './BaseMgr';

interface SceneSlotMgrEvent {
    created: SceneSlot;
}
export default class SceneSlotMgr extends BaseMgr<ISceneSlot, SceneSlotMgrEvent> {
    public created = 'created' as const;
    public sceneSlotsById: { [slotId: string]: SceneSlot } = {};
    public get state() {
        return this._state;
    }
    public set state(state: ISceneSlot[]) {
        const { toCreate, toDelete, toUpdate } = this.diff(this.state, state);
        this.create(toCreate);
        this.delete(toDelete);
        this.update(toUpdate);
        this._state = state;
    }
    public create(data: ISceneSlot[]) {
        data.forEach((item) => {
            const sceneSlot = new SceneSlot();
            sceneSlot.init(item);
            this.sceneSlotsById[item.id] = sceneSlot;
            this.dispatchEvent({ type: this.created, data: sceneSlot });
        });
    }
    public delete(data: ISceneSlot[]) {
        data.forEach((item) => {
            const slot = this.sceneSlotsById[item.id];
            slot.destroy();
            delete this.sceneSlotsById[item.id];
        });
    }
    public update(data: ISceneSlot[]) {
        data.forEach((item) => {
            const slot = this.sceneSlotsById[item.id];
            slot.json = item;
        });
    }
}
