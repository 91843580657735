import * as React from 'react';
import './PropertyMenu.css';
import './PropertyMenus/PropertyMenuGlobalStyle.css';
import ThreeContext from 'app/pages/three-context';
import TransformSetting from './PropertyMenus/TransformSetting';
import ContentSetting from './PropertyMenus/ContentSetting';
import LinkSetting from './PropertyMenus/LinkSetting';
import ImageSetting from './PropertyMenus/ImageSetting';
import { useTranslation } from 'react-i18next';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { getMarkerFields } from 'utils';

const PropertyMenu = ({
    openAdvancePropertyMenu,
}: {
    openAdvancePropertyMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { currentMarker } = React.useContext(TagEditorContext);
    const { actions } = React.useContext(ThreeContext);
    const { t } = useTranslation();

    if (currentMarker == null || !currentMarker.editable) return null;

    const deleteObject = () => {
        actions.deleteObject();

        // If not focus, after deleting, use undo keyboard shortcut won't active.
        const threeCanvas = document.querySelector<HTMLDivElement>('#three-root');
        threeCanvas.focus();
    };

    return (
        <div className="propertyMenu">
            <div className="titleBar">
                <button className="deleteButton" onClick={deleteObject}>
                    <img src="/images/icon/delete.svg" alt="delete" />
                </button>
                {t(`media.${currentMarker.type}`)}
            </div>
            <div className="MenuContainer">
                <TransformSetting type={currentMarker.type} fields={getMarkerFields(currentMarker.type)} />
                <ContentSetting type={currentMarker.type} fields={getMarkerFields(currentMarker.type)} />
                <LinkSetting type={currentMarker.type} fields={getMarkerFields(currentMarker.type)} />
                <ImageSetting type={currentMarker.type} fields={getMarkerFields(currentMarker.type)} />
                <div className="advanceBox">
                    <div className="divider"></div>
                    <button
                        className="advacnceSettingButton"
                        onClick={() => openAdvancePropertyMenu((value) => !value)}
                    >
                        {t('propertyMenu.advanceSetting')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PropertyMenu;
