import * as THREE from 'three';
import anime from 'animejs';
import { PanoInfo } from 'core/three/object/PanoramaMesh/shader/CubemapMaterial';
import { EASING_ANIME } from 'core/three/base/AnimationMgr';
import EventDispatcher from 'core/three/base/EventDispatcher';
import SceneMgr from 'core/three/base/SceneMgr';


export default class PanoramaAnimator extends EventDispatcher {
    public sceneMgr: SceneMgr;
    private currentPanoId: string;

    public set panorama(pano: PanoInfo) {
        if (this.currentPanoId !== pano.id) {
            this.currentPanoId = pano.id;
            this.sceneMgr.sceneModel.cubemapMaterial.currentIndexIsZero = !this.sceneMgr.sceneModel.cubemapMaterial.currentIndexIsZero;
        }
        this.sceneMgr.sceneModel.cubemapMaterial.setTexture(pano);
    }

    constructor(sceneMgr: SceneMgr) {
        super()
        this.sceneMgr = sceneMgr;
    }

    public fadeInAction() {
        return this.fadeAction(true)
    }

    public fadeOutAction() {
        return this.fadeAction(false)
    }

    public blendAction(): anime.AnimeAnimParams {
        const currentIndexIsZero = this.sceneMgr.sceneModel.cubemapMaterial.currentIndexIsZero;
        return {
            duration: 300,
            targets: [this.sceneMgr.sceneModel.cubemapMaterial],
            transition: currentIndexIsZero ? [1, 0] : [0, 1],
            easing: EASING_ANIME.EASE_IN_OUT_SINE,
            complete: () => {
                this.sceneMgr.sceneModel.cubemapMaterial.transition = this.sceneMgr.sceneModel.cubemapMaterial.currentIndexIsZero ? 0 : 1;
            },
        };
    }

    private fadeAction(fadeIn = true): anime.AnimeAnimParams {
        if (!fadeIn) this.sceneMgr.sceneModel.glbVisible = true
        return {
            duration: 300,
            targets: [this.sceneMgr.sceneModel],
            panoramaAlpha: fadeIn ? [1, 0] : [0, 1],
            easing: EASING_ANIME.LINEAR,
            complete: () => {
                this.sceneMgr.sceneModel.panoramaAlpha = fadeIn ? 1 : 0;
                if (fadeIn) this.sceneMgr.sceneModel.glbVisible = false
            },
        };
    }

}