import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { MarkerType } from 'core/three/object/type';
import { MarkerField } from 'constants/markerFields';

import { ReactComponent as ArrowIcon } from 'assets/images/icon/arrow-down.svg';

import Collapse from '@material-ui/core/Collapse';
import UploadThumbnailPropertyBox from './PropertyBox/UploadThumbnailPropertyBox';
import MultipleImagePropertyBox from './PropertyBox/MultipleImagePropertyBox';

const ImageSetting = ({ type, fields }: { type: MarkerType; fields: MarkerField }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const onCloseEvent = () => {
        setOpen((prev) => !prev);
    };

    return (
        <>
            <div className="property-slide-bar" onClick={onCloseEvent}>
                {t('propertyMenu.imageSetting')}
                <button>
                    <ArrowIcon className={open ? 'fa-icon-open' : 'fa-icon-close'} />
                </button>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div>
                    {fields.thumbnail && <UploadThumbnailPropertyBox />}
                    {fields.multiImage && <MultipleImagePropertyBox />}
                </div>
            </Collapse>
        </>
    );
};

export default ImageSetting;
