import axios from 'axios';
import SERVER from 'constants/server';

const defaultApi = axios.create({
    baseURL: SERVER.SERVER_URL,
});

export const platformApi = axios.create({
    baseURL: SERVER.PLATFORM_URL,
});

export const getErrorMsg = (err: any): string => err?.response?.data.msg;
export const setHeader = (key: string, value: string) => {
    defaultApi.defaults.headers.common[key] = value;
};

export const setPlatformHeader = (key: string, value: string) => {
    platformApi.defaults.headers.common[key] = value;
};

export default defaultApi;
