import { MultipleLanguageWithDefault, LanguageProperty, LOCALES } from 'core/types/media';

export const generateLanguageProperty: () => LanguageProperty = () => {
    return {
        name: '',
        description: '',
    };
};

export const generateMultiLang: () => MultipleLanguageWithDefault = () => {
    const multiLang = {
        default: '',
    };
    for (const lang of LOCALES) {
        multiLang[lang] = generateLanguageProperty();
    }
    return multiLang;
};
