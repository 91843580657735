import * as THREE from 'three';
import playBtnImage from './play-btn.svg?url&useResourceQuery=true';
import { VideoType } from './type';
import Thumbnail from '../utils/Thumbnail';
import { loadTexture } from 'core/utils/loader';

export default class VideoThumbnail extends THREE.Group {
    private thumbnailMesh: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial>;
    private playButton: THREE.Mesh;
    private thumbnailURL: string;
    public defaultWidth: number = 1.5;
    private playBtnGeo: THREE.PlaneGeometry;
    private playBtnMat: THREE.MeshBasicMaterial;
    private videoType: VideoType;

    private thumbnail: Thumbnail;
    private oldThumbnailUrl: string;

    constructor(thumbnailURL: string, videoType: VideoType) {
        super();
        this.thumbnailURL = thumbnailURL;
        this.videoType = videoType;

        // Create thumbnail mesh
        this.thumbnailMesh = new THREE.Mesh(
            new THREE.PlaneGeometry(1.0 * this.defaultWidth, 1.0 * this.defaultWidth, 1, 1),
            new THREE.MeshBasicMaterial({ map: null, side: THREE.DoubleSide, transparent: true, alphaTest: 0.1, combine: 0 }),
        );
        this.add(this.thumbnailMesh);
    }

    async init() {
        this.thumbnail = new Thumbnail(this.thumbnailURL);
        await this.thumbnail.init();
        await this.updateThumbnail();
        await this.updatePlayBtn();
    }

    async updateThumbnail() {
        if (this.oldThumbnailUrl === this.thumbnailURL) return;

        await this.thumbnail.update(this.thumbnailURL);
        const texture = this.thumbnail.texture;
        const textureRatio = this.thumbnail.ratio;

        const oldGeometry = this.thumbnailMesh.geometry;

        this.thumbnailMesh.geometry = new THREE.PlaneGeometry(
            1.0 * this.defaultWidth,
            textureRatio * this.defaultWidth,
            1,
            1,
        );
        this.thumbnailMesh.material.map = texture;

        if (this.videoType === VideoType.Youtube || this.videoType === VideoType.Vimeo) {
            const scale = this.thumbnailMesh.scale.clone();
            const defaultRatio = 1.25;
            scale.x = (1.6 * defaultRatio) / this.defaultWidth;
            scale.y = (0.9 * defaultRatio) / (textureRatio * this.defaultWidth);
            this.thumbnailMesh.scale.copy(scale);
        }

        if (oldGeometry) oldGeometry.dispose();

        this.oldThumbnailUrl = this.thumbnailURL;
    }

    async updatePlayBtn() {
        const texture = await loadTexture(playBtnImage);
        this.playBtnMat = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.DoubleSide,
            transparent: true,
            alphaTest: 0.1,
            combine: 0,
        });
        this.playBtnGeo = new THREE.PlaneGeometry(
            1.0 * this.defaultWidth,
            (texture.image.height / texture.image.width) * this.defaultWidth,
            1,
            1,
        );
        this.playButton = new THREE.Mesh(this.playBtnGeo, this.playBtnMat);
        this.playButton.scale.copy(new THREE.Vector3(0.5, 0.5, 1));
        this.playButton.translateZ(0.001);
        this.add(this.playButton);
    }

    get texture() {
        return this.thumbnail.texture;
    }

    get ratio() {
        return this.thumbnail.ratio;
    }

    public updateFrame() {
        this.thumbnail.updateFrame()
    }

    dispose() {
        this.playBtnGeo?.dispose();
        this.playBtnMat?.dispose();
        this.thumbnailMesh.geometry.dispose();
        this.thumbnail?.dispose();
        this.thumbnailMesh.material?.dispose();
    }

    destroy() {
        if (this.parent) {
            this.parent.remove(this);
        }

        this.thumbnailMesh.geometry?.dispose();
        this.thumbnail?.dispose();
        this.thumbnailMesh.material?.dispose();
        this.playBtnGeo?.dispose();
        this.playBtnMat?.dispose();
        this.playBtnGeo = null;
        this.playBtnMat = null;
    }
}
