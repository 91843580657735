import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './TitlePropertyBox.css';
import ThreeContext from 'app/pages/three-context';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { withStyles } from '@material-ui/core/styles';

enum UIComponetKey {
    value = 'value',
}

enum PropertyKey {
    name = 'name',
}

interface IPropertyData {
    name: string;
}

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
})(TextField);

const TitlePropertyBox: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);
    const maxNameLength: number = 100;

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event, newValue?) {
            markerUpdater.call({ ...propertyData, [propertykey]: newValue || event.target[key] });
        };
    };

    return (
        <div className="TitlePropertyBox">
            <div className="BoxTitle">{t(`propertyText.Title`) + '* :'}</div>
            <CssTextField
                id="title"
                multiline
                minRows={4}
                placeholder={t(`propertyText.Title`)}
                onChange={handleChange(PropertyKey.name, UIComponetKey.value)}
                inputProps={{ maxLength: maxNameLength }}
                variant="outlined"
                size="small"
                value={propertyData.name ?? ''}
                InputProps={{
                    endAdornment: (
                        <InputAdornment className="endAdornment" position="end">
                            {(propertyData.name?.length ?? '0') + '/' + maxNameLength}
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

export default TitlePropertyBox;
