import React from 'react';
import cls from 'classnames';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from 'assets/images/icon/arrow-down.svg';

import zhInstruction from 'assets/images/instruction.png';
import enInstruction from 'assets/images/en-instruction.png';
import styles from './Instruction.module.css';

interface InstructionProps {
    open: boolean;
    handleOpen: () => void;
}

const Instruction: React.FC<InstructionProps> = ({ open, handleOpen }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className={cls(styles.tutWrap, open ? styles.slideOpen : styles.slideClose)}>
            <Box className={styles.tutBtn} onClick={handleOpen}>
                {t('commom.instruction')}
                <button>
                    <ArrowIcon />
                </button>
            </Box>
            {i18n.language.includes('zh') ? (
                <img src={zhInstruction} alt="instruction" />
            ) : (
                <img src={enInstruction} alt="instruction" />
            )}
        </div>
    );
};

export default Instruction;
