import api, { getErrorMsg } from 'api';
import * as Media from 'core/types/media';
import SERVER from 'constants/server';

export const formatResponse = (marker: any): Media.IMarker => {
    const { type, attributes: customAttributes, ...markerAttributes } = marker;
    return {
        ...customAttributes,
        ...markerAttributes,
        type: type.toUpperCase(),
    };
};
const formatPayload = (marker: any) => {
    const result = { ...marker };
    const customAttributes: Media.CustomMarkerAttribute = {
        fontWeight: marker.fontWeight,
        fontStyle: marker.fontStyle,
        textColor: marker.textColor,
        alignment: marker.alignment,
        label: marker.label,
        lineSpace: marker.lineSpace,
        fontSize: marker.fontSize,
        angle: marker.angle,
        showHotspot: marker.showHotspot,
        showTitle: marker.showTitle,
        showConnectedLine: marker.showConnectedLine,
        hotspotIconHeight: marker.hotspotIconHeight,
        connectedLineLength: marker.connectedLineLength,
        autoplay: marker.autoplay,
        greenVideo: marker.greenVideo,
        openPlayer: marker.openPlayer,
        volume: marker.volume,
        autoRotate: marker.autoRotate,
        rotateControl: marker.rotateControl,
        disableBackground: marker.disableBackground || false,
        imageUrls: marker.imageUrls || [],
        price: marker.price || '',
        videoUrl: marker.videoUrl || '',
        autoPlayAnimation: marker.autoPlayAnimation || false,
        curve: marker.curve || 0,
    };
    result.attributes = customAttributes;
    result.type = marker.type.toLowerCase();
    for (const key in customAttributes) {
        delete result[key];
    }
    return result;
};
export const getMarkers = async (sceneId: string) => {
    try {
        const urlEnd = `/api/v1/markers?sceneId=${sceneId}`;
        const response = await api.get<Media.IMarker[]>(urlEnd);
        return response.data.map(formatResponse);
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};

export const createMarkers = async (info: {
    markers: Media.IMarker[];
    sceneId?: string;
    groupId?: string;
    ownerId?: string;
}) => {
    try {
        const urlEnd = '/api/v1/markers';
        const body = {
            sceneId: info.sceneId,
            ownerId: info.ownerId,
            groupId: info.groupId,
            data: info.markers.map(formatPayload),
        };
        const response = await api.post<Media.IMarker[]>(urlEnd, body);
        return response.data.map(formatResponse);
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};

export const updateMarkers = async (markers: Media.IMarker[]) => {
    try {
        const urlEnd = '/api/v1/markers';
        const body = markers.map(formatPayload);
        const response = await api.put<Media.IMarker[]>(urlEnd, body);
        return response.data.map(formatResponse);
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};

export const deleteMarkers = async (markerIds: string[]) => {
    try {
        const urlEnd = '/api/v1/markers';
        const body = markerIds;
        await api.delete<Media.IMarker[]>(urlEnd, { data: body });
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};

export const convertImgUrl2File = async (url: string) => {
    try {
        const urlEnd = '/public/v1/image/jpg';
        const response = await fetch(`${SERVER.SERVER_URL}${urlEnd}`, {
            method: 'POST',
            body: JSON.stringify({ url }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        }).then((res) => res.blob());

        const img = new File([response], 'file.jpg', { type: 'image/jpg' });
        return img;
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};

export const reportForm = async (form: object) => {
    try {
        const urlEnd = '/api/v1/mail/markerReport';
        const body = form;
        const response = await api.post(urlEnd, body);
        return response.data;
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};
