export enum MarkerType {
    TAG = 'TAG',
    TEXT = 'TEXT',
    VIDEO = 'VIDEO',
    GIF = 'GIF',
    MODEL = 'MODEL',
    WEBWALL = 'WEBWALL',
    WEBSITE = 'WEBSITE',
    IMAGE = 'IMAGE',
    PORTAL = 'PORTAL',
    NPC = 'NPC',
    MUSIC = 'MUSIC',
    ARTAG = 'ARTAG',
}

export enum EObjectType {
    SCENESLOT = 'SCENESLOT',
    MOUSEICON = 'MOUSEICON',
    SCENEMODEL = 'SCENEMODEL',
    HOTSPOT = 'HOTSPOT',
    BOOTH = 'BOOTH',
    VISITOR = 'VISITOR',
    PREVIEW_BOX = 'PREVIEW_BOX',
}

export type ObjectType = EObjectType | MarkerType;

export enum MediaStatus {
    CREATED = 'CREATED',
    DELETED = 'DELETED',
    UPDATED = 'UPDATED',
    INVALID = 'INVALID',
    READ = 'READ',
}
