import { IRaycastResponse } from './Raycaster';
import TransformControls, { PreviousStatus } from 'core/three/TransfromControls/TransformControls';
import * as THREE from 'three';
import CustomObject3D from '../object/CustomObject3D';
import Media from '../object/media/Media';
import { RaycastInfo } from '../object/RaycastObject';
import OrbitControls from '../OrbitControls';
import ViewportControls from './ViewportControls';
import RendererMgr from './RendererMgr';

let self: ObjectController;

export default class ObjectController {
    static hoverBoxColor: number = 0x82fcff;
    public selectedInfo: CustomObject3D;
    hoverBbox: THREE.BoxHelper;
    hoverObject: THREE.Object3D;
    transformControls: TransformControls;
    private camera: THREE.Camera;
    private canvas: HTMLCanvasElement;
    private _enabled = true;
    private viewportControls: ViewportControls;

    private _enableTranslationMode: boolean = true;
    private _enableRotationMode: boolean = true;
    private _enableScaleMode: boolean = true;
    private _disableDetchEvent: boolean = false; // prevent detach object while transforming object
    onObjectChange: any = () => {};
    onClick: (obj: RaycastInfo) => void;
    onObjectTransformUpdate: (pre: PreviousStatus, current: PreviousStatus, undoRedoId: string) => void = () => {};

    get controls() {
        return this.transformControls;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(option: boolean) {
        this._enabled = option;
        if (!option) {
            this.detachEvent();
        }
    }

    set enableTranslationMode(option: boolean) {
        this._enableTranslationMode = option;
    }

    set enableRotationMode(option: boolean) {
        this._enableRotationMode = option;
    }

    set enableScaleMode(option: boolean) {
        this._enableScaleMode = option;
    }

    constructor(viewportControls: ViewportControls, rendererMgr: RendererMgr ,canvas: HTMLCanvasElement, camera: THREE.PerspectiveCamera) {
        self = this;
        this.canvas = canvas;
        this.viewportControls= viewportControls
        const { renderer} = rendererMgr
        this.camera = camera;
        this.transformControls = new TransformControls(camera, renderer.domElement);

        this.transformControls.addEventListener('mouseDown', this.disableOribit);
        this.transformControls.addEventListener('mouseUp', this.onTransformControlMouseUpEvent.bind(this));
        this.transformControls.addEventListener('objectChange', () => {
            this.onObjectChange();
        });

        this.detachEvent = this.detachEvent.bind(this);
    }

    setRaycastScene(fetchworldPosition) {
        this.transformControls.setRaycastScene(fetchworldPosition);
    }

    attachTransformControl(media: Media | THREE.Object3D) {
        this.transformControls.enabled = true;
        this.transformControls.attachObject(media);
    }

    // TODO: refactor the object control and transform control mouse up/down event
    onTransformControlMouseUpEvent({ undoRedoId, target }: { undoRedoId: string; target: TransformControls }) {
        // check the create object is in Limit box
        if (!this.transformControls.checkIfAttachObjInBbox() && this.transformControls.enableLimitInBboxMode) {
            // delete the create object
            if (this.selectedInfo) {
                this.selectedInfo && this.selectedInfo.destroy();
                this.detachEvent();
                this.onClick(null);
            }
        }

        const currentStatus = {
            position: target.object.position.clone(),
            rotation: target.object.rotation.clone(),
            scale: target.object.scale.clone(),
        };
        this.onObjectTransformUpdate(target.previousStatus || currentStatus, currentStatus, undoRedoId);

        this.enableOribit();

        this._disableDetchEvent = true;
    }

    enableOribit() {
        self.viewportControls.enabled = true;
    }
    disableOribit() {
        self.viewportControls.enabled = false;
    }

    public setHover(media?: Media) {
        if (media !== undefined) {
            const { object } = media;
            if (object === this.hoverObject) return;
            this.hoverObject = object;
            this.hoverBbox?.parent?.remove(this.hoverBbox);
            const quaternion = object.quaternion.clone();
            object.quaternion.set(0, 0, 0, 1);
            this.hoverBbox = new THREE.BoxHelper(object, ObjectController.hoverBoxColor);
            object.quaternion.copy(quaternion);
            this.hoverBbox.applyMatrix4(object.matrixWorld.invert());
            object.add(this.hoverBbox);
        } else {
            this.hoverBbox?.parent?.remove(this.hoverBbox);
            this.hoverBbox = undefined;
            this.hoverObject = undefined;
        }
    }

    public raycastMouseMove(info: IRaycastResponse) {
        if (this._enabled && info.target && info.target.editable) {
            const { object } = info.target;

            if (object === this.hoverObject) return;
            this.hoverObject = object;

            this.hoverBbox?.parent?.remove(this.hoverBbox);
            const quaternion = object.quaternion.clone();
            object.quaternion.set(0, 0, 0, 1);

            // if (mediaClass instanceof Image) {
            //     let hotspotTag = mediaClass.gethotspotTag
            //     object.remove(hotspotTag);
            //     this.hoverBbox = new THREE.BoxHelper(object, 0xffff00);
            //     object.add(hotspotTag);
            // }
            // else
            this.hoverBbox = new THREE.BoxHelper(object, 0xffff00);
            object.quaternion.copy(quaternion);
            this.hoverBbox.applyMatrix4(object.matrixWorld.invert());
            object.add(this.hoverBbox);
        } else {
            this.hoverBbox?.parent?.remove(this.hoverBbox);
            this.hoverBbox = undefined;
            this.hoverObject = undefined;
        }
    }

    // TODO: refactor mouse up/down event
    public raycastMouseUp(info: IRaycastResponse) {
        if (info.target && this._enabled) {
            const { target } = info;
            if (this.onClick) {
                this.onClick(target);
            }
            if (info.target.editable) {
                this.selectedInfo = info.target;
                const media = target as Media;
                this.attachTransformControl(media);
                if (media.translateEnabled) {
                    this.transformControls.setMode('translate');
                } else if (media.scaleEnabled) {
                    this.transformControls.setMode('scale');
                } else if (media.rotateEnabled) {
                    this.transformControls.setMode('rotate');
                }
                this.canvas.addEventListener('contextmenu', this.detachEvent);
                this.canvas.addEventListener('keydown', this.keyboardEvent);
            }
        } else if (!this._disableDetchEvent) {
            this.detachEvent();
        }
        this._disableDetchEvent = false;
    }

    public detachEvent() {
        self.canvas.removeEventListener('contextmenu', this.detachEvent);
        self.transformControls.detach();
        self.transformControls.setMode('translate');
        self.transformControls.enabled = false;
        this.canvas.removeEventListener('keydown', this.keyboardEvent);
        this.selectedInfo = undefined;
    }

    private keyboardEvent(event: KeyboardEvent) {
        const { key } = event;
        switch (key) {
            case '1':
                if (self._enableTranslationMode && self.transformControls.media?.translateEnabled) {
                    self.transformControls.setMode('translate');
                }
                break;
            case '2':
                if (self._enableScaleMode && self.transformControls.media?.scaleEnabled) {
                    self.transformControls.setMode('scale');
                }
                break;
            case '3':
                if (self._enableRotationMode && self.transformControls.media?.rotateEnabled) {
                    self.transformControls.setMode('rotate');
                }
                break;
        }
    }

    public attachNewObject(obj: Media | THREE.Object3D, moveWithPointer: boolean = true): void {
        if (this.controls.media === obj) {
            return;
        }
        this.disableOribit();
        if (!this._enabled) return;
        this.transformControls.attachObject(obj instanceof Media ? obj.object : obj);
        this.canvas.addEventListener('contextmenu', this.detachEvent);
        this.canvas.addEventListener('keydown', this.keyboardEvent);
        this.transformControls.setAxis('XYZ');

        let media = obj as Media;
        if (media.translateEnabled) {
            if (moveWithPointer) {
                this.transformControls.addPointerMoveEvent();
                this.transformControls.setDragging(true);
                this.transformControls.enabled = true;
            }
            this.transformControls.setMode('translate');
        } else if (media.scaleEnabled) {
            this.transformControls.setMode('scale');
        } else if (media.rotateEnabled) {
            this.transformControls.setMode('rotate');
        }
        // this.transformControls.showTransformControllerHelper( false );
    }

    public setTransformCtrlLimitBboxMode(option: boolean) {
        this.transformControls.setEnableLimitInBboxMode(option);
    }
}
