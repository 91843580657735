import * as THREE from 'three';
import anime, { AnimeTimelineInstance } from 'animejs';
import EventDispatcher from './EventDispatcher';

import { EquippedPromise } from 'core/utils';
import { Vector3, Vector4 } from 'core/types/general';
import ViewportAnimator from './ViewportAnimator';
import PanoramaAnimator from './PanoramaAnimator';
import HotspotMgr from './HotspotMgr';
import { loadCubeMap } from 'core/utils/loader';

interface AnimationResponse {
    hotspotId?: string;
    animation: Promise<void>;
}

// Easing option type from animejs (use for animeJs timeline)
export enum EASING_ANIME {
    LINEAR = 'linear',
    EASE_IN_OUT_SINE = 'easeInOutSine'
}

export enum MoveDirection {
    FORWARD = 'FORWARD',
    BACK = 'BACK',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}
interface AnimationConfig {
    azimuthAngle?: number;
    polarAngle?: number;
    duration?: number;
    zoom?: number;
}

type ViewMode = 'dollhouse' | 'floorplan' | 'ground';
export type ViewModeFromTo = { from: ViewMode; to: ViewMode; };
interface AnimationEvent {
    viewModeChangeStart: ViewModeFromTo;
    viewModeChangeEnd: ViewModeFromTo;
}

export default class AnimationMgr extends EventDispatcher<AnimationEvent> {
    public enableCubeMap: boolean = true;
    public enableCalcDurationByDistance: boolean = true;
    public latestHotspotId: string = undefined;
    public latestGroundPos: Vector3;
    public currentViewMode: ViewMode = 'dollhouse';
    public timelineByObjectId: { [id: string]: AnimeTimelineInstance; } = {};
    public hotspotMgr: HotspotMgr;
    public viewportAnimator: ViewportAnimator;
    public panoramaAnimator: PanoramaAnimator;
    // todo: queue timelines
    private currentTimeline: anime.AnimeTimelineInstance = null;
    private isHotspotChanging: boolean = false;
    private nextHotspotAnimation: { id: string; promise: EquippedPromise<void>; } = null;

    constructor(
        hotspotMgr: HotspotMgr,
        panoramaAnimator: PanoramaAnimator,
        viewportAnimator: ViewportAnimator
    ) {
        super();
        this.hotspotMgr = hotspotMgr;
        this.panoramaAnimator = panoramaAnimator;
        this.viewportAnimator = viewportAnimator;
    }

    get isPlaying() {
        return this.isHotspotChanging || Boolean(this.currentTimeline);
    }

    get isTopView() {
        return this.currentViewMode === 'dollhouse' || this.currentViewMode === 'floorplan';
    }

    public stop() {
        this.currentTimeline.pause(); // todo: reject the promise
        this.isHotspotChanging = false;
        this.nextHotspotAnimation = null;
    }

    public rotateTo({ azimuthAngle, polarAngle, duration }: AnimationConfig) {
        const action = this.viewportAnimator.rotateToAction(azimuthAngle, polarAngle);
        if (duration) action.duration = duration;
        const animation = this.playAnimation([action]);
        return { animation };
    }

    public rotate({ azimuthAngle = 0, polarAngle = 0, duration }: AnimationConfig) {
        const action = this.viewportAnimator.rotateAction(azimuthAngle, polarAngle);
        if (duration) action.duration = duration;
        const animation = this.playAnimation([action]);
        return { animation };
    }

    public zoom({ zoom, duration }: AnimationConfig) {
        const action = this.viewportAnimator.zoomAction(zoom);
        if (duration) action.duration = duration;
        const animation = this.playAnimation([action]);
        return { animation };
    }

    public godown(position?: Vector3, config?: AnimationConfig): AnimationResponse {
        if (!this.isTopView) return this.goNowhere();
        const defaultHotspotId = this.hotspotMgr.currentId; // 無指定 hotspotId 默認上次 hotspotId
        let response: AnimationResponse;
        const { hotspotId: destHotspotId, animation } = response = defaultHotspotId
            ? this.goHotspot(defaultHotspotId, config)
            : this.goNearHotspot(position || new THREE.Vector3(0, 1.5, 0), config);
        if (!destHotspotId) {
            response = this.goGround(position, config);
        }
        return response;
    }

    public goTop(config?: AnimationConfig): AnimationResponse {
        if (this.isTopView) {
            return this.goNowhere();
        }
        if (this.isPlaying) {
            console.warn('Still Playing.');
            return this.goNowhere();
        }
        // this.latestHotspotId = this.transitionMesh.hotspotid;
        this.latestGroundPos = this.viewportAnimator.viewporControls.getTargetPosition();
        const actions = [
            this.viewportAnimator.goAction(new THREE.Vector3()),
            this.viewportAnimator.zommOutAction(),
            this.viewportAnimator.rotateToDollhouseAction(),
            this.panoramaAnimator.fadeOutAction()
        ];
        const maxDuration = config?.duration ?? Math.max(...actions.map(t => typeof t.duration === 'number' ? t.duration : 0));
        actions.forEach(action => action.duration = maxDuration);
        this.hotspotMgr.hideHotspots();
        const animation = this.playAnimation(actions, 'dollhouse');
        return { animation };
    }

    public goGround(position?: Vector3, config?: AnimationConfig) {
        if (this.isPlaying) {
            console.warn('Still Playing.');
            return this.goNowhere();
        }
        position = position ? new THREE.Vector3(position.x, position.y, position.z) : this.latestGroundPos || new THREE.Vector3();
        const actions = [
            this.viewportAnimator.goAction(position as THREE.Vector3),
            this.viewportAnimator.zoomInAction(),
            this.viewportAnimator.rotateToGroundAction(config?.azimuthAngle, config?.polarAngle)
        ];
        const maxDuration = config?.duration ?? Math.max(...actions.map(t => typeof t.duration === 'number' ? t.duration : 0));
        actions.forEach(action => action.duration = maxDuration);
        const animation = this.playAnimation(actions, 'ground');
        return { animation };
    }

    public goHotspot(hotspotId: string, config?: AnimationConfig): AnimationResponse {
        const hotspotInfo = this.hotspotMgr.dataById[hotspotId];
        if (!hotspotInfo) return this.goNowhere();
        if (hotspotInfo.id === this.hotspotMgr.currentId) return this.goNowhere();

        if (this.isPlaying) {
            const promise = new EquippedPromise<void>();
            this.nextHotspotAnimation = { id: hotspotId, promise };
            return { hotspotId, animation: promise };
        }
        this.isHotspotChanging = true;
        const { position, cameraHeight, rotation, lowerSkybox, higherSkybox, id } = hotspotInfo;
        const dstPos = new THREE.Vector3(position.x, position.y + cameraHeight ?? 1.7, position.z);
        const anime = (skybox: any) => {
            if (skybox) {
                this.panoramaAnimator.panorama = {
                    id: id,
                    position: dstPos,
                    rotation: rotation,
                    texture: skybox,
                };
            }
            const actions = [
                this.viewportAnimator.goAction(dstPos),
                this.viewportAnimator.zoomInAction(),
                this.viewportAnimator.rotateToAction(config?.azimuthAngle, config?.polarAngle),
                this.panoramaAnimator.blendAction(),
            ];
            if (this.isTopView) actions.push(this.panoramaAnimator.fadeInAction());
            const maxDuration = config?.duration ?? Math.max(...actions.map(t => typeof t.duration === 'number' ? t.duration : 0));
            actions.forEach(action => action.duration = maxDuration);
            const animation = this.playAnimation(actions, 'ground');
            return animation;
        };

        const goHotspotAnimation = () => {
            this.latestHotspotId = hotspotId;
            const loadLowTexturePromise = () =>
                lowerSkybox && this.enableCubeMap
                    ? loadCubeMap(lowerSkybox)
                    : Promise.resolve(null);
            const loadHighTexturePromise = () =>
                higherSkybox && this.enableCubeMap
                    ? loadCubeMap(higherSkybox)
                    : Promise.resolve(null);
            const lowTexturePromise = loadLowTexturePromise();
            const highTexturePromise = lowTexturePromise.then(loadHighTexturePromise);
            return lowTexturePromise
                .then(anime)
                .then(() => {
                    this.hotspotMgr.displayNeighbors(id);
                    this.hotspotMgr.currentId = id;
                    this.isHotspotChanging = false;
                    if (this.nextHotspotAnimation) {
                        const { id, promise } = this.nextHotspotAnimation;
                        this.nextHotspotAnimation = null;
                        this.goHotspot(id, undefined).animation.then(promise.resolve);
                        return Promise.resolve();
                    }
                    return highTexturePromise;
                })
                .then((highTexture) => {
                    if (highTexture) {
                        this.panoramaAnimator.panorama = {
                            id: id,
                            position: dstPos,
                            rotation: rotation,
                            texture: highTexture,
                        };
                    }
                });
        };
        return { hotspotId, animation: goHotspotAnimation() };
    }

    public goHotspotInDirection(direction: THREE.Vector3): AnimationResponse {
        const nearestId = this.hotspotMgr.getNearestInDirection(direction);
        if (nearestId) return this.goHotspot(nearestId);
        else {
            console.warn('No near hotspot in direction found.');
            return this.goNowhere();
        }
    }

    public goNearHotspot(position: Vector3, config?: AnimationConfig): AnimationResponse {
        if (!position) {
            console.warn('Position info needed.');
            return this.goNowhere();
        }
        const candidates = this.isTopView
            ? this.hotspotMgr.ids
            : this.hotspotMgr.getNeighbors();
        const nearestHotspot = this.hotspotMgr.getNearestByPosition(position, candidates);
        if (nearestHotspot) return this.goHotspot(nearestHotspot, config);
        else {
            console.warn('No near hotspot found.');
            return this.goNowhere();
        }
    }

    private goNowhere(): AnimationResponse {
        return { animation: Promise.resolve() };
    }

    private playAnimation(actions: anime.AnimeAnimParams[], toViewMode: ViewMode = this.currentViewMode) {
        this.currentTimeline = anime.timeline();
        actions.forEach(action => this.currentTimeline.add(action, 0));
        const fromViewMode = this.currentViewMode;
        if (fromViewMode !== toViewMode) this.dispatchEvent({ type: 'viewModeChangeStart', data: { from: fromViewMode, to: toViewMode } });
        return this.currentTimeline.finished.then(() => {
            this.currentTimeline = null;
            if (fromViewMode !== toViewMode) this.dispatchEvent({ type: 'viewModeChangeEnd', data: { from: fromViewMode, to: toViewMode } });
            this.currentViewMode = toViewMode;
        });
    }
}
