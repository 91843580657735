import * as React from 'react';
import { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import './MultipleLanguages.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';

enum UIComponetKey {
    value = 'value',
}

enum PropertyKey {
    multipleLanguage = 'multipleLanguage',
    name = 'name',
    description = 'description',
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
})(TextField);

const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
        color: '#aaa',
    },
}));

const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};

const MultipleLanguages: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const maxNameLength: number = 100;
    const maxDetailLength: number = 1000;
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);

    const SelectHandleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event, newValue?) {
            markerUpdater.call({ ...propertyData, [propertykey]: newValue.props.value || event.target[key] });
        };
    };

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event, newValue?) {
            propertyData.multiLang[propertyData.multipleLanguage][propertykey] = event.target[key];
            markerUpdater.call({ ...propertyData });
        };
    };

    return (
        <div className="MultipleLanguages">
            <div className="BoxTitle">{t(`propertyText.MultipleLanguages`) + ':'}</div>
            <div className="SelectorBox">
                <Select
                    renderValue={
                        propertyData.multipleLanguage !== 'default'
                            ? undefined
                            : () => <Placeholder>{t(`propertyText.MultipleLanguagesPlaceholder`)}</Placeholder>
                    }
                    MenuProps={MenuProps}
                    value={propertyData.multipleLanguage}
                    onChange={SelectHandleChange(PropertyKey.multipleLanguage, UIComponetKey.value)}
                >
                    <MenuItem value={'default'}>
                        <em>-</em>
                    </MenuItem>
                    <MenuItem value={'en'}>English</MenuItem>
                    <MenuItem value={'zh-tw'}>繁體中文</MenuItem>
                    <MenuItem value={'zh-cn'}>简体中文</MenuItem>
                    <MenuItem value={'fr'}>Français</MenuItem>
                    <MenuItem value={'ja'}>日本語</MenuItem>
                    <MenuItem value={'ko'}>한국어</MenuItem>
                    <MenuItem value={'es'}>Español</MenuItem>
                    <MenuItem value={'ru'}>Русский</MenuItem>
                    <MenuItem value={'ms'}>Bahasa Malaysia</MenuItem>
                    <MenuItem value={'cs'}>Český</MenuItem>
                    <MenuItem value={'da'}>Dansk</MenuItem>
                    <MenuItem value={'de'}>Deutsch</MenuItem>
                    <MenuItem value={'el'}>Ελληνικά</MenuItem>
                    <MenuItem value={'it'}>Italiano</MenuItem>
                    <MenuItem value={'lt'}>Lietuva</MenuItem>
                    <MenuItem value={'hu'}>Magyar</MenuItem>
                    <MenuItem value={'mn'}>МонголУлс</MenuItem>
                    <MenuItem value={'nl'}>Nederlands</MenuItem>
                    <MenuItem value={'no'}>Norsk</MenuItem>
                    <MenuItem value={'pl'}>Polski</MenuItem>
                    <MenuItem value={'pt'}>Português</MenuItem>
                    <MenuItem value={'ro'}>România</MenuItem>
                    <MenuItem value={'fi'}>Suomi</MenuItem>
                    <MenuItem value={'sv'}>Svenska</MenuItem>
                    <MenuItem value={'vi'}>Tiếng Việt</MenuItem>
                    <MenuItem value={'tr'}>Türkçe</MenuItem>
                    <MenuItem value={'uk'}>Україна</MenuItem>
                    <MenuItem value={'ar'}>العربية</MenuItem>
                </Select>
            </div>
            {propertyData.multipleLanguage !== 'default' &&
                propertyData.multipleLanguage !== undefined &&
                propertyData.multipleLanguage !== '' && (
                    <>
                        <div className="multipleLanguageTitle">
                            <div className="BoxTitle">{t(`propertyText.Title`) + '* :'}</div>
                            <CssTextField
                                id="multipleLanguageTitle"
                                multiline
                                minRows={4}
                                placeholder={t(`propertyText.Title`)}
                                onChange={handleChange(PropertyKey.name, UIComponetKey.value)}
                                inputProps={{ maxLength: maxNameLength }}
                                variant="outlined"
                                size="small"
                                value={propertyData.multiLang[propertyData.multipleLanguage]['name'] ?? ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment className="endAdornment" position="end">
                                            {(propertyData.multiLang[propertyData.multipleLanguage].name?.length ??
                                                '0') +
                                                '/' +
                                                maxNameLength}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="multipleLanguageDescription">
                            <div className="BoxTitle">{t(`propertyText.Detail`) + ':'}</div>
                            <CssTextField
                                id="multipleLanguageDescription"
                                multiline
                                minRows={5}
                                onChange={handleChange(PropertyKey.description, UIComponetKey.value)}
                                value={propertyData.multiLang[propertyData.multipleLanguage]['description'] ?? ''}
                                placeholder={t(`propertyText.PleaseInputTheDetail`)}
                                inputProps={{ maxLength: maxDetailLength }}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment className="endAdornment" position="end">
                                            {(propertyData.multiLang[propertyData.multipleLanguage].description
                                                ?.length ?? '0') +
                                                '/' +
                                                maxDetailLength}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </>
                )}
        </div>
    );
};

export default MultipleLanguages;
