import * as THREE from 'three';
import Media from './media/Media';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { IRaycastResponse } from '../base/Raycaster';
import EventDispatcher from '../base/EventDispatcher';

export enum EObjectMouseState {
    ON,
    NOTON,
}

export interface RaycastInfo {
    id?: string;
    url?: string;
    object?: THREE.Object3D;
    setJson?(obj: object): void;
    json?: any;
    isVertical?: boolean;
    mediaClass?: Media;
    editable?: boolean;
}

export abstract class RaycastObject extends EventDispatcher {
    public state: EObjectMouseState;
    constructor() {
        super();
        this.state = EObjectMouseState.NOTON;
    }
    public abstract get raycastTarget(): THREE.Object3D;
    abstract onMouseEnter(e: IRaycastResponse): void;
    abstract onMouseLeave(e: IRaycastResponse): void;

    /**
     * make object follow mouse
     * @param intersect raycaster callback return first intersect
     */
    abstract onMouseMove(e: IRaycastResponse): void;
    abstract onMouseUp(e: IRaycastResponse): void;
    abstract onMouseDown(e: IRaycastResponse): void;
    abstract onClick(e: IRaycastResponse): void;

    // for glb model
    public static mergeGroup(group): THREE.Mesh {
        const parent = group.parent;
        if (parent) parent.remove(group);

        const bufferGeometries: THREE.BufferGeometry[] = [];
        let mergedGeometry: THREE.BufferGeometry;
        group.updateMatrixWorld(true);
        group.traverse((node: THREE.Mesh) => {
            if (!node.isMesh) return;
            const cloneGeometry = node.geometry.clone().applyMatrix4(node.matrixWorld);
            /**
             * Discard uv information. When merge bufferGeometry, it's required that all geometry possessed with same attributes.
             * Since raycastMesh is invisible. There's no need to keep uv information.
             */
            delete cloneGeometry.attributes.uv;
            delete cloneGeometry.attributes.uv1;
            delete cloneGeometry.attributes.uv2;
            delete cloneGeometry.attributes.uv3;
            delete cloneGeometry.attributes.skinIndex
            delete cloneGeometry.attributes.skinWeight
            delete cloneGeometry.attributes.color
            delete cloneGeometry.attributes.tangent
            cloneGeometry.morphTargetsRelative = false
            cloneGeometry.morphAttributes = {}
            bufferGeometries.push(cloneGeometry);
        });
        try {
            mergedGeometry = mergeGeometries(bufferGeometries);
        } catch (err) {
            mergedGeometry = new THREE.BufferGeometry();
        }
        if (parent) parent.add(group);

        const material = new THREE.MeshBasicMaterial();
        const mesh = new THREE.Mesh(mergedGeometry, material);
        mesh.layers.disableAll();
        return mesh;
    }

    // for item which has local transfrom
    public static mergeMeshes(meshes: THREE.Mesh | THREE.Mesh[]): THREE.Mesh {
        const meshList = [].concat(meshes || []);
        const geometries: THREE.BufferGeometry[] = [];
        meshList.forEach((mesh) => {
            geometries.push(mesh.geometry);
        });
        const geometry = mergeGeometries(geometries);
        const mesh = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial());
        mesh.layers.disableAll();
        return mesh;
    }

    // for simple item
    public static mergeGeometries(geos: THREE.BufferGeometry | THREE.BufferGeometry[]): THREE.Mesh {
        const geometries = [].concat(geos || []);
        const mesh = new THREE.Mesh(
            mergeGeometries(geometries),
            new THREE.MeshBasicMaterial(),
        );
        mesh.layers.disableAll();
        return mesh;
    }

    // init raycast mesh avoid rejecting from gpuraycaster
    public static emptyRaycastMesh() {
        const mesh = new THREE.Mesh(new THREE.PlaneGeometry(1, 1, 4), new THREE.MeshBasicMaterial());
        mesh.layers.disableAll();
        return mesh;
    }
}
