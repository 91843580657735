import { parseGIF, decompressFrames, ParsedFrame } from 'gifuct-js';
import { EquippedPromise } from 'core/utils';
class GifCanvasFactory {
    private instancesMap: Record<string, GifCanvas> = {};
    private instancesCount: Record<string, number> = {};
    public create(fileUrl) {
        if (this.instancesMap[fileUrl]) {
            this.instancesCount[fileUrl]++;
        } else {
            this.instancesMap[fileUrl] = new GifCanvas(fileUrl);
            this.instancesCount[fileUrl] = 1;
        }
        return this.instancesMap[fileUrl];
    }
    public dispose(gifCanvas: GifCanvas) {
        const fileUrl = gifCanvas.fileUrl;
        this.instancesCount[fileUrl]--;
        if (this.instancesCount[fileUrl] <= 0) {
            this.instancesMap[fileUrl].destroy();
            this.instancesMap[fileUrl] = null;
            this.instancesCount[fileUrl] = null;
        }
    }
}
export class GifCanvas {
    public canvasElement: HTMLCanvasElement;
    private canvasCtx: CanvasRenderingContext2D;
    private tempCtx: CanvasRenderingContext2D;
    private tempCanvasElement: HTMLCanvasElement;
    private frameIndex = 0;
    private inited: EquippedPromise<void>;
    private frames: ParsedFrame[] = [];
    private waitTime: number = 0;
    private lastRenderTime: number = 0
    constructor(public fileUrl: string) {
        const canvasElement = document.createElement('canvas');
        const tempCanvasElement = document.createElement('canvas');
        this.canvasElement = canvasElement;
        this.tempCanvasElement = tempCanvasElement;
        this.canvasCtx = canvasElement.getContext('2d');
        this.tempCtx = tempCanvasElement.getContext('2d');
    }
    async init() {
        if (this.inited) return this.inited;
        this.inited = new EquippedPromise();
        await this.loadFrames();
        await this.updateCanvas();
        await this.renderFrame();
        this.inited.resolve();
    }
    async loadFrames(): Promise<void> {
        const res = await fetch(this.fileUrl, {
            cache: 'no-cache', // browser cache gif request of sidebar material, whose reponse doesn't come with cors related headers
        });
        const arraybuffer = await res.arrayBuffer();
        const parsedGif = parseGIF(arraybuffer);
        const frames = decompressFrames(parsedGif, true);
        this.frameIndex = 0;
        this.frames = frames;
    }
    async updateCanvas() {
        if (this.frames.length === 0) return;
        this.canvasElement.width = this.frames[this.frameIndex].dims.width;
        this.canvasElement.height = this.frames[this.frameIndex].dims.height;
    }

    get ratio() {
        if (this.frames.length === 0) return 1;
        const frame = this.frames[this.frameIndex];
        const width = frame.dims.width;
        const height = frame.dims.height;
        const ratio = height / width;
        return ratio;
    }
    renderFrame() {
        const start = performance.now();
        if (start - this.lastRenderTime < this.waitTime) return

        const frame = this.frames[this.frameIndex];
        this.frameIndex += 1;
        if (this.frameIndex >= this.frames.length) {
            this.frameIndex = 0;
        }
        const width = frame.dims.width;
        const height = frame.dims.height;
        const imageData = this.tempCtx.createImageData(width, height);
        imageData.data.set(frame.patch);
        if (frame.disposalType === 2 || frame.disposalType === 3) {
            this.tempCtx.clearRect(0, 0, this.tempCanvasElement.width, this.tempCanvasElement.height);
            this.canvasCtx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        }
        if (frame.dims.width > 1 || frame.dims.height > 1) {
            this.tempCanvasElement.width = width;
            this.tempCanvasElement.height = height;
            this.tempCtx.putImageData(imageData, 0, 0);
            this.canvasCtx.drawImage(this.tempCanvasElement, frame.dims.left, frame.dims.top);
        } else {
            this.tempCtx.clearRect(0, 0, this.tempCanvasElement.width, this.tempCanvasElement.height);
            this.canvasCtx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        }

        const end = performance.now();
        this.lastRenderTime = end
        this.waitTime = Math.floor(frame.delay - (end - start))
    }

    public destroy() {}
}

export const gifCanvasFactory = new GifCanvasFactory();
