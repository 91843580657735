import EventDispatcher from "./EventDispatcher";
import * as THREE from 'three';
interface CameraMgrEvent { }

const FOV_DEFAULT = 75;
const NEAR_PLANE_DEFAULT = 0.5;
const FAR_PLANE_DEFAULT = 30000;

export default class CameraMgr extends EventDispatcher<CameraMgrEvent> {
    
    private parentCamera: THREE.PerspectiveCamera;
    private childCamera: THREE.PerspectiveCamera;
    private cameraParenting = false

    constructor(cameraParenting = false) {
        super();
        /**
         * Camera parenting providing a way to control the camera's position in the world space without interfering with the VR headset's control over the camera's local space
         */
        this.cameraParenting = cameraParenting
        this.parentCamera = new THREE.PerspectiveCamera();
        this.childCamera = new THREE.PerspectiveCamera(
            FOV_DEFAULT,
            window.innerWidth / window.innerHeight,
            NEAR_PLANE_DEFAULT,
            FAR_PLANE_DEFAULT,
        );
        if (cameraParenting) this.parentCamera.add(this.childCamera);
    }

    /**
     * use the camera when changing position and rotation.
     */
    public get movingCamera() {
        if (this.cameraParenting) return this.parentCamera
        return this.childCamera;
    }

    /**
     * use the camera for rendering and viewport adjustment.
     */
    public get renderingCamera() {
        return this.childCamera;
    }

    public get fov() {
        return (this.childCamera.fov / 180) * Math.PI;
    }

    public set fov(value: number) {
        this.childCamera.fov = (value / Math.PI) * 180;
        this.childCamera.updateProjectionMatrix();
    }

    public get near() {
        return this.childCamera.near;
    }

    public set near(n: number) {
        this.childCamera.near = n;
        this.childCamera.updateProjectionMatrix();
    }

    public get far() {
        return this.childCamera.far;
    }

    public set far(n: number) {
        this.childCamera.far = n;
        this.childCamera.updateProjectionMatrix();
    }

    public get aspect() {
        return this.childCamera.aspect;
    }

    public set aspect(n: number) {
        this.childCamera.aspect = n;
        this.childCamera.updateProjectionMatrix();
    }

    public get zoom() {
        return this.childCamera.zoom;
    }

    public set zoom(n: number) {
        n = Math.max(0.5, n);
        n = Math.min(2, n);
        this.childCamera.zoom = n;
        this.childCamera.updateProjectionMatrix();
    }

    public getDirection() {
        const cameraWorldDir = new THREE.Vector3();
        this.childCamera.getWorldDirection(cameraWorldDir);
        return cameraWorldDir;
    }

    public getQuaternion() {
        const cameraWorldQuat = new THREE.Quaternion();
        this.childCamera.getWorldQuaternion(cameraWorldQuat);
        return cameraWorldQuat.clone();
    }

    public getPosition() {
        const cameraWorldPos = new THREE.Vector3();
        this.childCamera.getWorldPosition(cameraWorldPos);
        return cameraWorldPos.clone();
    }

    /**
     * get the x y coordinate by the position
     * @param position postion in 3d scene
     * @returns coordinate of x, y beteween 0 to 1  
     */
    public getSrceenCoordinate(position: THREE.Vector3) {
        const screenCoord = position.clone().project(this.childCamera);
        screenCoord.x = (screenCoord.x + 1) / 2;
        screenCoord.y = -(screenCoord.y - 1) / 2;
        const max = new THREE.Vector3(1, 1, 1);
        const min = new THREE.Vector3(0, 0, -1);
        return screenCoord.clamp(min, max);
    }

    public update() {
        this.parentCamera.updateMatrixWorld();
    }

    /**
     * check whether the given position is in the view field
     * @param position postion in 3d scene
     */
    public checkInFrustum(position: THREE.Vector3) {
        const frustum = new THREE.Frustum();
        const projScreenMatrix = new THREE.Matrix4();
        projScreenMatrix.multiplyMatrices(this.childCamera.projectionMatrix, this.childCamera.matrixWorldInverse);
        frustum.setFromProjectionMatrix(projScreenMatrix);
        if (frustum.containsPoint(position)) {
            return true;
        } else {
            return false;
        }
    }

}