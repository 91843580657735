import * as THREE from 'three';
import { EObjectType } from "../type";
import Media from '../media/Media';
export class PreviewBox extends Media {
    constructor(width: number, height: number, depth: number) {
        super(EObjectType.PREVIEW_BOX);
        const boxGeometry = new THREE.BoxGeometry(width, height, depth,);
        const material = new THREE.MeshBasicMaterial({ color: 0x1B7AF8 });
        const mesh = new THREE.Mesh(boxGeometry, material);
        this.object.add(mesh);
    }
    public onMouseEnter() { };
    public onMouseLeave() { };
    public onMouseMove() { };
    public onMouseUp() { };
    public onMouseDown() { };
    public onClick() { };
}
export default PreviewBox;
