import * as THREE from 'three';

export default class MouseCursor extends THREE.Mesh {
    constructor() {
        const geometry = new THREE.RingGeometry(0.1, 0.15, 36, 1, 32);
        const material = new THREE.MeshBasicMaterial({
            color: 0x00ffea,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 0,
            depthTest: false,
        });
        super(geometry, material);
        this.renderOrder = 2;
    }

    public set opacity(value: number) {
        const mat = this.material as THREE.Material;
        mat.opacity = value;
    }

    public set(rot4: THREE.Matrix4, pos: THREE.Vector3) {
        this.position.set(pos.x, pos.y, pos.z);
        this.setRotationFromMatrix(rot4);
        this.opacity = 0.3;
    }
}
