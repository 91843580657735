import * as THREE from 'three';
import { IVisitor } from 'core/types/media';
import { EObjectType } from 'core/three/object/type';
import TextTexture from 'core/three/TextTexture/TextTexture';
import { recursiveDispose } from 'core/utils';
import { VisitorAvatar } from 'core/types/visitorAvatar';
import InteractionObect from '../../InteractionObect';
import { loadTexture } from 'core/utils/loader';
import { RenderFrame } from 'core/types';

type TImageWrapperMesh = THREE.Mesh<THREE.RingGeometry, THREE.MeshBasicMaterial>;
type TImageMesh = THREE.Mesh<THREE.CircleGeometry, THREE.MeshBasicMaterial>;
type TTextMesh = THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial>;
type TStickMesh = THREE.Mesh<THREE.CylinderGeometry, THREE.MeshBasicMaterial>;
type TOtherVisitorIconMesh = THREE.Mesh<THREE.CircleGeometry, THREE.MeshBasicMaterial>;
type TRingMesh = THREE.Mesh<THREE.RingGeometry, THREE.MeshBasicMaterial>;

export default class VisitorHeadshot extends InteractionObect {
    static headshotMeshSet: Map<string, [TImageWrapperMesh, TImageMesh]> = new Map();
    static imageTextureSet: Map<string, THREE.Texture> = new Map();

    static adminColor: 'yellow';
    static imgWrapperGeo = new THREE.RingGeometry(0.34, 0.4, 30);
    static imgWrapperAdmintMat = new THREE.MeshBasicMaterial({ color: 'yellow', side: THREE.DoubleSide });
    static imgWrapperDefaultMat = new THREE.MeshBasicMaterial({ color: 0x3d86ff, side: THREE.DoubleSide });
    static imgGeo = new THREE.CircleGeometry(0.35, 30);
    static stickGeo = new THREE.CylinderGeometry(0.04, 0.04, 0.8, 15);
    static stickDefaultMat = new THREE.MeshBasicMaterial({ color: 0xffffff });
    static stickAdminMat = new THREE.MeshBasicMaterial({ color: 'yellow' });

    private imgWrapperMesh: TImageWrapperMesh;
    private imageMesh: TImageMesh;
    private textMesh: TTextMesh;
    private stickMesh: TStickMesh;

    private adminRingMeshs: TRingMesh[] = [];

    private otherVisitorMeshGroup = new THREE.Group();
    private otherVisitorMesh: TImageWrapperMesh;
    private otherVisitorIconMesh: TOtherVisitorIconMesh;

    public height = 1.6;
    public isOwner: boolean = false;
    public name: string = '';
    public fileUrl: string;
    public otherVisitors: IVisitor[] = [];

    constructor(data: IVisitor) {
        super(EObjectType.VISITOR);

        this.fileUrl = data.fileUrl || '';
        this.otherVisitors = data.otherVisitors || [];
        this.id = data.userId || '';
        this.name = data.name || '';
        this.isOwner = data.isOwner || false;

        if (VisitorHeadshot.headshotMeshSet.has(this.id)) {
            const [imgWrapperMesh, imageMesh] = VisitorHeadshot.headshotMeshSet.get(this.id);
            this.imgWrapperMesh = imgWrapperMesh;
            this.imageMesh = imageMesh;
        } else {
            const [imgWrapperMesh, imageMesh] = this.createHeadshotMesh(this.fileUrl);
            this.imgWrapperMesh = imgWrapperMesh;
            this.imageMesh = imageMesh;
        }

        VisitorHeadshot.headshotMeshSet.set(this.id, [this.imgWrapperMesh, this.imageMesh]);

        this.stickMesh = new THREE.Mesh(
            VisitorHeadshot.stickGeo,
            this.isOwner ? VisitorHeadshot.stickAdminMat : VisitorHeadshot.stickDefaultMat,
        );
        this.stickMesh.position.y = 0.4;

        this.textMesh = this.createLabel();

        this.otherVisitorIconMesh = this.createOtherVisitorIcon();

        this.otherVisitorMeshGroup.position.x = -0.2;
        this.otherVisitorMeshGroup.position.z = -0.05;

        this.object.add(this.imgWrapperMesh);
        this.object.add(this.imageMesh);
        this.object.add(this.textMesh);
        this.object.add(this.stickMesh);
        this.object.add(this.otherVisitorMeshGroup);

        const raycastMeshs = [this.imgWrapperMesh, this.imageMesh, this.textMesh];

        if (this.isOwner) {
            this.adminRingMeshs = this.createBlurRing();
            this.object.add(...this.adminRingMeshs);
        }

        this.updateRaycastMesh(raycastMeshs);
    }

    public updateOtherVisitor(newOtherVisitors: IVisitor[]) {
        const newOtherVisitor1 = newOtherVisitors[0];
        const hasOtherRemainVisitors = newOtherVisitors.slice(1);

        try {
            if (this.otherVisitorMesh) {
                this.otherVisitorMeshGroup.remove(this.otherVisitorMesh);
                this.otherVisitorMeshGroup.remove(this.otherVisitorIconMesh);
            }

            if (newOtherVisitor1) {
                if (VisitorHeadshot.headshotMeshSet.has(newOtherVisitor1.userId)) {
                    const [imgWrapperMesh] = VisitorHeadshot.headshotMeshSet.get(newOtherVisitor1.userId);
                    this.otherVisitorMesh = imgWrapperMesh;
                    this.otherVisitorMeshGroup.add(imgWrapperMesh);
                } else {
                    const [imgWrapperMesh, imageMesh] = this.createHeadshotMesh(newOtherVisitor1.fileUrl);
                    VisitorHeadshot.headshotMeshSet.set(newOtherVisitor1.userId, [imgWrapperMesh, imageMesh]);
                    this.otherVisitorMesh = imgWrapperMesh;
                    this.otherVisitorMeshGroup.add(imgWrapperMesh);
                }
                if (hasOtherRemainVisitors.length > 0) {
                    this.otherVisitorMeshGroup.add(this.otherVisitorIconMesh);
                }
            } else {
                this.otherVisitorMesh = null;
            }
            this.otherVisitors = newOtherVisitors;
        } catch (error) {
            console.error(error);
        }
    }

    public createHeadshotMesh(url: string): [TImageWrapperMesh, TImageMesh] {
        const imgWrapperMesh = new THREE.Mesh(
            VisitorHeadshot.imgWrapperGeo,
            this.isOwner ? VisitorHeadshot.imgWrapperAdmintMat : VisitorHeadshot.imgWrapperDefaultMat,
        );
        imgWrapperMesh.position.y = 0.8 + 0.4;

        const imageMesh = new THREE.Mesh(
            VisitorHeadshot.imgGeo,
            new THREE.MeshBasicMaterial({
                map: null,
                side: THREE.DoubleSide,
                transparent: true,
                combine: 0,
                color: 0xffffff,
            }),
        );
        imageMesh.position.y = 0.8 + 0.4;
        imageMesh.position.z = 0.01;

        if (this.isOwner) {
            this.loadTexture('/images/glow.png', (texture) => {
                const glowEffectMaterial = new THREE.MeshBasicMaterial({
                    map: texture,
                    color: 'yellow',
                    transparent: true,
                    blending: THREE.AdditiveBlending,
                });
                const glowEffectMesh = new THREE.Mesh(new THREE.CircleGeometry(0.6, 30), glowEffectMaterial);
                glowEffectMesh.position.z = -0.02;
                imgWrapperMesh.add(glowEffectMesh);
            });
        }

        if (VisitorHeadshot.imageTextureSet.has(url)) {
            const oldTexture = imageMesh.material.map;
            const newTexture = VisitorHeadshot.imageTextureSet.get(url);
            if (oldTexture && newTexture && oldTexture.id === newTexture.id) return;

            // No need to dispose oldTexture, perhaps it will use in later realtime update.
            imageMesh.material.map = newTexture;
            imageMesh.material.needsUpdate = true;
        } else {
            this.loadTexture(url, (texture) => {
                VisitorHeadshot.imageTextureSet.set(url, texture);

                imageMesh.material.map = texture;
                imageMesh.material.needsUpdate = true;
            });
        }

        return [imgWrapperMesh, imageMesh];
    }

    private createOtherVisitorIcon() {
        const geometry = new THREE.CircleGeometry(0.4, 30);
        const material = new THREE.MeshBasicMaterial({ map: null });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.x = -0.2;
        mesh.position.y = 0.8 + 0.4;
        mesh.position.z = -0.05;

        this.loadTexture('/images/headshot/others.png', (texture) => {
            mesh.material.map = texture;
            mesh.material.needsUpdate = true;
        });

        return mesh;
    }

    private createLabel() {
        // Create text mesh
        const textTexture = new TextTexture(
            {
                label: this.name,
                padding: 80,
                fontColor: '#ffffff',
                fontSize: 72,
                fontStyle: 'normal',
                fontWeight: 'bold',
                alignment: 'center',
                lineSpace: 0.5,
            },
            {
                nameTemplateStyle: true,
                fillStyle: this.isOwner ? '#ED0973B2' : 'rgba(20, 20, 20, 1)',
                iconStyle: this.isOwner,
            },
        );
        textTexture.needsUpdate = true;

        const textMaterial = new THREE.MeshBasicMaterial({ map: textTexture, transparent: true });

        const sizeX = textTexture.width * 2;
        const sizeY = textTexture.height * 2;
        const textGeometry = new THREE.PlaneGeometry(sizeX, sizeY);

        const mesh = new THREE.Mesh(textGeometry, textMaterial);

        mesh.position.y = 1.8;

        return mesh;
    }

    private createBlurRing() {
        const SMALL_RING_SIZE = 0.2;
        const BIG_RING_SIZE = 0.4;
        const RING_BUFFER_DISTANCE = 0.025;
        const SEGMENTS = 30;
        const BLUR_EFFECT_RING_BUFFER_DISTANCE = 0.3;

        const smallRingGeo = new THREE.RingGeometry(
            SMALL_RING_SIZE,
            SMALL_RING_SIZE + RING_BUFFER_DISTANCE,
            SEGMENTS,
        );
        const bigRingGeo = new THREE.RingGeometry(BIG_RING_SIZE, BIG_RING_SIZE + RING_BUFFER_DISTANCE, SEGMENTS);
        const ringMat = new THREE.MeshBasicMaterial({ color: 'yellow' });
        const smallRingMesh = new THREE.Mesh(smallRingGeo, ringMat);
        const bigRingMesh = new THREE.Mesh(bigRingGeo, ringMat);
        smallRingMesh.rotation.x = -Math.PI / 2;
        smallRingMesh.position.y = 0.05;
        bigRingMesh.rotation.x = -Math.PI / 2;
        bigRingMesh.position.y = 0.04;

        this.loadTexture('/images/glow.png', (texture: THREE.Texture) => {
            const glowEffectMaterial = new THREE.MeshBasicMaterial({
                map: texture,
                color: 'yellow',
                transparent: true,
                blending: THREE.AdditiveBlending,
            });
            const glowEffectSmallRingMesh = new THREE.Mesh(
                new THREE.RingGeometry(
                    SMALL_RING_SIZE + RING_BUFFER_DISTANCE,
                    SMALL_RING_SIZE + RING_BUFFER_DISTANCE + BLUR_EFFECT_RING_BUFFER_DISTANCE,
                    SEGMENTS,
                ),
                glowEffectMaterial,
            );
            smallRingMesh.add(glowEffectSmallRingMesh);

            const glowEffectBigRingMesh = new THREE.Mesh(
                new THREE.RingGeometry(
                    BIG_RING_SIZE + RING_BUFFER_DISTANCE,
                    BIG_RING_SIZE + RING_BUFFER_DISTANCE + BLUR_EFFECT_RING_BUFFER_DISTANCE,
                    SEGMENTS,
                ),
                glowEffectMaterial,
            );
            bigRingMesh.add(glowEffectBigRingMesh);
        });

        return [smallRingMesh, bigRingMesh];
    }

    onMouseEnter() {}
    onMouseUp() {}
    onMouseLeave() {}
    onMouseMove() {}
    onMouseDown() {}
    onClick() {}

    public get json(): object {
        return {
            type: this.type,
            position: {
                x: this.object.position.x,
                y: this.object.position.y,
                z: this.object.position.z,
            },
            rotation: {
                x: this.object.quaternion.x,
                y: this.object.quaternion.y,
                z: this.object.quaternion.z,
                w: this.object.quaternion.w,
            },
            scale: {
                x: this.object.scale.x,
                y: this.object.scale.y,
                z: this.object.scale.z,
            },
        };
    }

    public setJson(json: object) {}

    public update(renderFrame: RenderFrame) {
        this.lookAt(renderFrame.cameraPosition);
        this.updatePosition(renderFrame.delteSec)
    }

    public lookAt(pos: THREE.Vector3) {
        const n = new THREE.Vector3().subVectors(pos, this.object.position).normalize();
        n.y = 0;
        const mat4 = new THREE.Matrix4().lookAt(n, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0));
        this.object.setRotationFromMatrix(mat4);
    }

    public destroy() {
        super.destroy();

        if (this.otherVisitorIconMesh) recursiveDispose(this.otherVisitorIconMesh);
        if (this.adminRingMeshs.length > 0) this.adminRingMeshs.forEach((mesh) => recursiveDispose(mesh));

        recursiveDispose(this.textMesh);
    }

    public loadTexture(url: string, fn: (texture: THREE.Texture) => void) {
            loadTexture(url)
            .then((texture: THREE.Texture) => {
                if (texture) {
                    fn(texture);
                }
            })
            .catch((error) => {
                console.error(`Load Texture: "${url}" Error`, error);
            });
    }
}
