import { BufferAttribute, PlaneGeometry } from 'three';

class CurvePlaneGeometry extends PlaneGeometry {
    static optimized: boolean = false;
    public curve: number;
    constructor(width = 1, height = 1, curve = 0, widthSegments = 6, heightSegments = 1) {
        if (CurvePlaneGeometry.optimized && curve === 0) widthSegments = 1;
        super(width, height, widthSegments, heightSegments);
        this.curvePlane(curve);
    }
    public curvePlane(curve = 0) {
        curve = Math.min(Math.max(curve, -1), 1);
        this.curve = curve;
        const position = this.getAttribute('position') as BufferAttribute;
        position.needsUpdate = true;
        const { widthSegments, heightSegments } = this.parameters;
        const gridX = Math.floor(widthSegments);
        const gridY = Math.floor(heightSegments);
        const gridX1 = gridX + 1;
        const gridY1 = gridY + 1;

        for (let iy = 0; iy < gridY1; iy++) {
            for (let ix = 0; ix < gridX1; ix++) {
                const index = iy * gridX1 + ix;
                let z = Math.sin((ix / gridX) * Math.PI) * curve;
                position.setZ(index, z);
            }
        }
    }
}

export { CurvePlaneGeometry };
