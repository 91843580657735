import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './DetailPropertyBox.css';
import ThreeContext from 'app/pages/three-context';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';

enum UIComponetKey {
    value = 'value',
}

enum PropertyKey {
    description = 'description',
}

interface IPropertyData {
    description: string;
}

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
})(TextField);

const DetailPropertyBox: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const maxDetailLength: number = 1000;
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event, newValue?) {
            markerUpdater.call({ ...propertyData, [propertykey]: newValue || event.target[key] });
        };
    };

    return (
        <div className="DetailPropertyBox">
            <div className="BoxTitle">{t(`propertyText.Detail`) + ':'}</div>
            <CssTextField
                id="description"
                multiline
                minRows={6}
                onChange={handleChange(PropertyKey.description, UIComponetKey.value)}
                value={propertyData.description ?? ''}
                placeholder={t(`propertyText.PleaseInputTheDetail`)}
                inputProps={{ maxLength: maxDetailLength }}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment className="endAdornment" position="end">
                            {(propertyData.description?.length ?? '0') + '/' + maxDetailLength}
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

export default DetailPropertyBox;
