import * as THREE from 'three';
import fragmentShader from './fragmentShader';
import vertexShader from './vertexShader';

export default class GreenVideoShaderMaterial extends THREE.ShaderMaterial {
    constructor() {
        super({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            uniforms: {
                tex: {
                    value: null,
                },
                color: {
                    value: null,
                },
            },
        });
    }
}
