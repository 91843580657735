import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './IconTagPropertyBox.css';
import ThreeContext from 'app/pages/three-context';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';

import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';

enum UIComponetKey {
    value = 'value',
    checked = 'checked',
}

enum PropertyKey {
    showTitle = 'showTitle',
    hotspotIconHeight = 'hotspotIconHeight',
}

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#fff',
        },
        checkedIcon: {
            borderRadius: 4,
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 20,
                height: 20,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
        },
        textFieldRoot: {
            width: '100%',
        },
    }),
);

const IconTagPropertyBox: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);

    const handleValueChange = (propertykey: PropertyKey.hotspotIconHeight, key: UIComponetKey) => {
        return function (event, newValue?) {
            propertyData[propertykey] = event.target[key] / 100;
            markerUpdater.call({ ...propertyData, [propertykey]: event.target[key] / 100 });
        };
    };

    const handleCheckBoxChange = (propertykey: PropertyKey.showTitle, key: UIComponetKey) => {
        return function (event, newValue?) {
            markerUpdater.call({ ...propertyData, [propertykey]: newValue || event.target[key] });
        };
    };

    return (
        <div className="IconTagPropertyBox">
            <div className="BoxTitle">
                <div className="TitleText">{t(`propertyText.IconTag`) + ':'}</div>
            </div>
            <Grid container>
                <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid id="hotspotIconHeightGrid" item xs={12}>
                        <CssTextField
                            id="hotspotIconHeight"
                            value={(propertyData.hotspotIconHeight ?? 0.1) * 100}
                            onChange={handleValueChange(PropertyKey.hotspotIconHeight, UIComponetKey.value)}
                            type="number"
                            inputProps={{
                                style: { textAlign: 'center' },
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">H</InputAdornment>,
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            }}
                            variant="outlined"
                            size="small"
                            classes={{ root: classes.textFieldRoot }}
                        />
                    </Grid>
                </Grid>
                <Grid id="showTitleGrid" item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="showTitle"
                                checked={propertyData.showTitle ?? true}
                                onChange={handleCheckBoxChange(PropertyKey.showTitle, UIComponetKey.checked)}
                                checkedIcon={<span className={classes.checkedIcon} />}
                                color="primary"
                                size="small"
                                classes={{
                                    root: classes.root,
                                }}
                            />
                        }
                        label={t(`propertyText.ShowTitle`)}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default IconTagPropertyBox;
