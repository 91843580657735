import * as React from 'react';
import { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './LinkPropertyBox.css';
import TextField from '@material-ui/core/TextField';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { withStyles } from '@material-ui/core/styles';
import { YOUTUBE_URL_PATTERN, VIMEO_URL_PATTERN } from 'constants/regex';

enum UIComponetKey {
    value = 'value',
}

enum PropertyKey {
    videoUrl = 'videoUrl',
}

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
})(TextField);

const regexs = [YOUTUBE_URL_PATTERN, VIMEO_URL_PATTERN];

const MoreVideoPropertyBox = () => {
    const { t } = useTranslation();
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);
    const [inputLinkUrl, setInputLinkUrl] = useState('');

    const linkFormatError = useMemo(() => {
        return !regexs.some((regex) => regex.test(inputLinkUrl));
    }, [inputLinkUrl]);

    useEffect(() => {
        setInputLinkUrl(propertyData[PropertyKey.videoUrl] || '');
    }, [propertyData]);

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event) {
            markerUpdater.call({ ...propertyData, [propertykey]: event.target[key] });
        };
    };

    return (
        <div className="LinkPropertyBox">
            <div className="BoxTitle">{t('propertyText.videoSetting') + ':'}</div>
            <CssTextField
                id="linkUrl"
                placeholder="http://"
                variant="outlined"
                size="small"
                onChange={(e) => {
                    setInputLinkUrl(e.target.value);
                    handleChange(PropertyKey.videoUrl, UIComponetKey.value)(e);
                }}
                value={inputLinkUrl}
                margin="dense"
            />
            {linkFormatError && <span className="FormatError">{t('propertyText.FormatError')}</span>}
            <span className="HelperHint">{t('propertyText.videoSettingHint')}</span>
        </div>
    );
};

export default MoreVideoPropertyBox;
