import * as THREE from 'three';
import fragmentShader from './fragmentShader';
import vertexShader from './vertexShader';

export interface PanoInfo {
    id: string;
    position: {
        x: number;
        y: number;
        z: number;
    };
    rotation: {
        x: number;
        y: number;
        z: number;
        w: number;
    };
    texture: THREE.CubeTexture | THREE.Texture;
}

export default class CubemapMaterial extends THREE.ShaderMaterial {
    public currentIndexIsZero: boolean;
    constructor() {
        super({
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            uniforms: {
                tEquirect0: { value: null },
                tEquirect1: { value: null },
                tAltas0: { value: null },
                tAltas1: { value: null },
                texture0isAltas: { value: null },
                texture1isAltas: { value: null },
                shrink: { value: 0.999 },
                position0: { value: new THREE.Vector3() },
                position1: { value: new THREE.Vector3() },
                rotation0: { value: new THREE.Vector4() },
                rotation1: { value: new THREE.Vector4() },
                blackFactor: { value: 1 },
                time: { value: 0 },
                alpha: { value: 1.0 },
                offestRotate: { value: new THREE.Vector3() },
            },
            transparent: true,
            //prevent z-fighting
            polygonOffset: true,
            polygonOffsetFactor: 1.0,
            polygonOffsetUnits: 4.0,
        });
        this.currentIndexIsZero = true;
    }

    public set axisIsZ(value: boolean) {
        if (value) {
            this.uniforms.offestRotate.value = new THREE.Vector3(0.0, 0.0, 0.0);
        } else {
            this.uniforms.offestRotate.value = new THREE.Vector3(0.0, 0.0, 0.0);
        }
    }

    public set alpha(value: number) {
        this.uniforms.alpha.value = value;
    }

    public set transition(value: number) {
        this.uniforms.time.value = value;
    }

    public get transition() {
        return this.uniforms.time.value
    }

    private cleanAndSetTexture(conatiner, texture) {
        const oldTexture = conatiner.value;
        conatiner.value = texture;
        if (oldTexture != null) oldTexture.dispose();
    }

    public async setTexture(info: PanoInfo) {
        const { position, rotation, texture } = info;
        texture.generateMipmaps = false;
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.wrapS = THREE.ClampToEdgeWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;

        if (this.currentIndexIsZero) {
            if (texture instanceof THREE.CubeTexture) {
                this.cleanAndSetTexture(this.uniforms.tEquirect0, texture);
                this.uniforms.texture0isAltas.value = 0;
            } else if (texture instanceof THREE.Texture) {
                this.cleanAndSetTexture(this.uniforms.tAltas0, texture);
                this.uniforms.texture0isAltas.value = 1;
            }

            this.uniforms.position0.value = new THREE.Vector3(position.x, position.y, position.z);
            this.uniforms.rotation0.value = new THREE.Vector4(rotation.x, rotation.y, rotation.z, rotation.w);
        } else {
            if (texture instanceof THREE.CubeTexture) {
                this.cleanAndSetTexture(this.uniforms.tEquirect1, texture);
                this.uniforms.texture1isAltas.value = 0;
            } else if (texture instanceof THREE.Texture) {
                this.cleanAndSetTexture(this.uniforms.tAltas1, texture);
                this.uniforms.texture1isAltas.value = 1;
            }

            this.uniforms.position1.value = new THREE.Vector3(position.x, position.y, position.z);
            this.uniforms.rotation1.value = new THREE.Vector4(rotation.x, rotation.y, rotation.z, rotation.w);
        }
    }

    dispose() {
        this.uniforms.tEquirect0.value?.dispose();
        this.uniforms.tEquirect1.value?.dispose();
        this.uniforms.tAltas0.value?.dispose();
        this.uniforms.tAltas1.value?.dispose();
        super.dispose();
    }
}
