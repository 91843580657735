import {
    DoubleSide,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    PlaneGeometry,
    Quaternion,
    Vector3,
} from 'three';
import TransformControls from './TransformControls';

export default class TransformControlsPlane extends Object3D {
    type: string;
    planeMesh: Mesh;
    transformControls: TransformControls;

    constructor(transformControls: TransformControls) {
        super();
        this.add(
            new Mesh(
                new PlaneGeometry(100000, 100000, 2, 2),
                new MeshBasicMaterial({
                    visible: false,
                    wireframe: true,
                    side: DoubleSide,
                    transparent: true,
                    opacity: 0.1,
                    toneMapped: false,
                }),
            ),
        );
        this.type = 'TransformControlsPlane';
        this.transformControls = transformControls;
    }

    updateMatrixWorld() {
        let unitX = new Vector3(1, 0, 0);
        let unitY = new Vector3(0, 1, 0);
        let unitZ = new Vector3(0, 0, 1);

        let tempVector = new Vector3();
        let dirVector = new Vector3();
        let alignVector = new Vector3();
        let tempMatrix = new Matrix4();
        let identityQuaternion = new Quaternion();

        let space = this.transformControls.space;

        this.position.copy(this.transformControls.worldPosition);

        if (this.transformControls.mode === 'scale') space = 'local'; // scale always oriented to local rotation

        unitX
            .set(1, 0, 0)
            .applyQuaternion(space === 'local' ? this.transformControls.worldQuaternion : identityQuaternion);
        unitY
            .set(0, 1, 0)
            .applyQuaternion(space === 'local' ? this.transformControls.worldQuaternion : identityQuaternion);
        unitZ
            .set(0, 0, 1)
            .applyQuaternion(space === 'local' ? this.transformControls.worldQuaternion : identityQuaternion);

        // Align the plane for current transform mode, axis and space.

        alignVector.copy(unitY);

        switch (this.transformControls.mode) {
            case 'translate':
            case 'scale':
                switch (this.transformControls.axis) {
                    case 'X':
                        alignVector.copy(this.transformControls.eye).cross(unitX);
                        dirVector.copy(unitX).cross(alignVector);
                        break;
                    case 'Y':
                        alignVector.copy(this.transformControls.eye).cross(unitY);
                        dirVector.copy(unitY).cross(alignVector);
                        break;
                    case 'Z':
                        alignVector.copy(this.transformControls.eye).cross(unitZ);
                        dirVector.copy(unitZ).cross(alignVector);
                        break;
                    case 'XY':
                        dirVector.copy(unitZ);
                        break;
                    case 'YZ':
                        dirVector.copy(unitX);
                        break;
                    case 'XZ':
                        alignVector.copy(unitZ);
                        dirVector.copy(unitY);
                        break;
                    case 'XYZ':
                    case 'E':
                        dirVector.set(0, 0, 0);
                        break;
                }

                break;
            case 'rotate':
            default:
                // special case for rotate
                dirVector.set(0, 0, 0);
        }

        if (dirVector.length() === 0) {
            // If in rotate mode, make the plane parallel to camera
            this.quaternion.copy(this.transformControls.cameraQuaternion);
        } else {
            tempMatrix.lookAt(tempVector.set(0, 0, 0), dirVector, alignVector);

            this.quaternion.setFromRotationMatrix(tempMatrix);
        }
        Object3D.prototype.updateMatrixWorld.call(this);
    }
}
