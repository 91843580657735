import React, { PropsWithChildren } from 'react';
import ThemeProvider, { ThemeContext } from 'ThemeProvider';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import TagEditor from 'app/pages/TagEditor/TagEditor';
import SERVER from 'constants/server';
import { autoLogin, login, getPermission, getDealerPermission } from 'api/auth';
import { getScene } from 'api/scene';
import { getAcl, ACL } from 'api/acl';
import 'i18n';
import SnackbarPopup from 'app/components/SnackbarPopup/SnackbarPopup';

interface ISceneContext {
    scene: any;
    acl: ACL;
}
export const SceneContext = React.createContext<ISceneContext>(null);
const Scene = (props) => {
    const { sceneId } = useParams();
    const [sceneData, setSceneData] = React.useState(null);
    const [sceneRoleAcl, setSceneRoleAcl] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const { primary, secondary } = React.useContext(ThemeContext);

    React.useEffect(() => {
        Promise.all([getScene(sceneId).then(setSceneData), getAcl(sceneId).then((acl) => setSceneRoleAcl(acl[0]))])
            .catch(() => {
                if (window.location.hostname === 'localhost') {
                    alert('Please check the logged in user has permission to the scene or the scene id is correct.');
                }
            })
            .finally(() => setLoading(false));
    }, [sceneId]);

    React.useEffect(() => {
        if (!sceneData) return;
        if (sceneData.mainColor) primary.setState(sceneData.mainColor);
        if (sceneData.mainColor) document.documentElement.style.setProperty('--primary', sceneData.mainColor);
        if (sceneData.subColor) secondary.setState(sceneData.subColor);
        if (sceneData.title) document.title = sceneData.title;
        if (sceneData.favicon)
            document.querySelector("link[rel*='icon']").setAttribute('href', decodeURIComponent(sceneData.favicon));

        // if (sceneData.colorMode) primary.setState(sceneData.colorMode)
    }, [sceneData]);

    if (loading) return <span>loading...</span>;
    if (sceneData == null) return <span>Permission denied.</span>;
    return (
        <SceneContext.Provider value={{ scene: sceneData, acl: sceneRoleAcl }}>
            <TagEditor />
        </SceneContext.Provider>
    );
};

export interface Permission {
    accountId: string;
    expiredAt: string;
    features: { id: string; name: string; expiredAt: string }[];
    id: string;
    maxLiveTours: number;
    maxTags: number;
    name: string;
    permits: any;
    token: string;
    tokenExpiredAt: string;
}
interface UserProfile {
    id: string;
    username: string;
    dealerCode: string;
}

interface IAppContext {
    toast: (type: 'success' | 'error' | 'info' | 'warning', message: string) => void;
    auth: UserProfile;
    permission: Permission;
}
export const AppContext = React.createContext<IAppContext>(null);
const AppContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [alertType, setAlertType] = React.useState<'success' | 'error' | 'info' | 'warning'>('success');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [profile, setProfile] = React.useState<UserProfile>(null);
    const [permission, setPermission] = React.useState<Permission>(null);

    const toast = (type, message) => {
        setAlertOpen(true);
        setAlertType(type);
        setAlertMessage(message);
    };

    React.useEffect(() => {
        autoLogin()
            .catch(() => {
                if (window.location.hostname === 'localhost') {
                    return login({ email: prompt('email'), password: prompt('password') }).catch(alert);
                } else {
                    window.location.href = SERVER.SPACE_EDITOR_URL;
                }
            })
            .then(setProfile);
    }, []);

    React.useEffect(() => {
        if (profile == null) return;
        profile.dealerCode
            ? getDealerPermission(profile.dealerCode).then(setPermission)
            : getPermission(profile.username).then(setPermission);
    }, [profile]);

    if (!profile || !permission) return <span>loading...</span>;
    return (
        <AppContext.Provider value={{ toast, permission, auth: profile }}>
            <SnackbarPopup
                message={alertMessage}
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                severity={alertType}
            ></SnackbarPopup>
            {children}
        </AppContext.Provider>
    );
};

function App() {
    return (
        <ThemeProvider>
            <AppContextProvider>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route path="/:editorPage/:sceneId" Component={Scene} />
                        <Route path="/:sceneId" Component={Scene} />
                    </Routes>
                </BrowserRouter>
            </AppContextProvider>
        </ThemeProvider>
    );
}

export default App;
