import * as THREE from 'three';
import { gifCanvasFactory, GifCanvas } from './GifTexture';
import { loadTexture } from 'core/utils/loader';

export default class Thumbnail {
    private _type: 'gif' | 'jpg/png';
    private _fileUrl: string;
    private _texture: any;
    private _gifCanvas: GifCanvas | null = null;
    public scale: number = 1;

    public static gifRegex = /\.gif/i;

    public static getType(fileUrl) {
        return this.gifRegex.test(fileUrl) ? 'gif' : 'jpg/png';
    }

    constructor(fileUrl: string) {
        this._fileUrl = fileUrl;
        this._type = Thumbnail.getType(fileUrl);
    }

    public async init() {
        switch (this._type) {
            case 'gif':
                await this.loadGif();
                break;

            case 'jpg/png':
                await this.loadJpgOrPng();
                break;

            default:
                break;
        }
    }

    public async update(fileUrl) {
        if (fileUrl === this._fileUrl) return;

        this._fileUrl = fileUrl;
        this._type = Thumbnail.getType(fileUrl);

        switch (this._type) {
            case 'gif':
                await this.loadGif();
                break;

            case 'jpg/png':
                await this.loadJpgOrPng();
                break;

            default:
                break;
        }
    }

    private async loadGif() {
        this.dispose();

        try {
            this._gifCanvas = gifCanvasFactory.create(this.qualityUrl);
            await this._gifCanvas.init();
        } catch {
            this._gifCanvas = gifCanvasFactory.create(this._fileUrl);
            await this._gifCanvas.init();
        }

        this._texture = new THREE.CanvasTexture(this._gifCanvas.canvasElement);
        this._texture.colorSpace = THREE.SRGBColorSpace;
        this._texture.anisotropy = 0;
        this._texture.magFilter = THREE.NearestFilter;
        this._texture.minFilter = THREE.NearestFilter;
    }

    public updateFrame() {
        if (this._gifCanvas) {
            this._gifCanvas.renderFrame();
            this._texture.needsUpdate = true;
        }
    }

    private async loadJpgOrPng() {
        this.dispose();

        this._texture = await loadTexture(this.qualityUrl).catch(() => loadTexture(this._fileUrl));
        this._texture.needsUpdate = true;
    }

    private get quality() {
        if (this.scale <= 0.1) return 'small';
        else if (this.scale <= 0.5) return 'medium';
        else if (this.scale <= 1) return 'large';
        else if (this.scale <= 2) return 'xlarge';
        else return 'standard';
    }

    private get qualityUrl() {
        const url = this._fileUrl;
        const quality = this.quality;
        if (url.includes('dev')) return url; // dev 多數素材並無使用cdn 因此無法觸法 lambda edge
        if (!url.startsWith('http')) return url;
        try {
            const filepath = url.split('.').slice(0, -1).join('.');
            const fileType = url.split('.').pop();
            const isQualityUrl = /_(small|medium|large|xlarge )/.test(url);
            if (this.quality === 'standard' && isQualityUrl) {
                const unQualityUrl = filepath.split('_').slice(0, -1).join('_');
                return `${unQualityUrl}.${fileType}`;
            } else if (this.quality !== 'standard' && /jpg|png|jpeg|gif/i.test(fileType) && !isQualityUrl) {
                return `${filepath}_${quality}.${fileType}`;
            } else {
                return url;
            }
        } catch (err) {
            return url;
        }
    }

    public get texture() {
        return this._texture;
    }

    public get type() {
        return this._type;
    }

    public get ratio() {
        switch (this._type) {
            case 'gif':
                if (this._gifCanvas) {
                    return this._gifCanvas.ratio;
                }
                return 1;

            case 'jpg/png':
                if (this._texture) {
                    return this._texture.image.height / this._texture.image.width;
                }
                return 1;

            default:
                return 1;
        }
    }

    public dispose() {
        if (this._gifCanvas) {
            gifCanvasFactory.dispose(this._gifCanvas);
            this._gifCanvas = null;
        }
        if (this._texture) {
            this._texture.dispose();
        }
    }
}
