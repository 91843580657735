import * as THREE from 'three';
import { MarkerType } from 'core/three/object/type';
import Media from 'core/three/object/media/Media';
import TextTexture from 'core/three/TextTexture/TextTexture';
import { IWebsite } from 'core/types/media';
import Thumbnail from '../utils/Thumbnail';
import { CurvePlaneGeometry } from 'core/utils/CurvePlaneGeometry';

export default class Website extends Media {
    private lineMesh: THREE.Mesh;
    private iconMesh: THREE.Mesh<CurvePlaneGeometry, THREE.MeshBasicMaterial>;
    private textMesh: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial>;
    private textHeight: number;
    private textWdith: number;
    private tempGroup: THREE.Group;
    private curve: number;

    private showTitle: boolean;
    private hotspotIconHeight: number;
    private hotspotIconWidth: number;

    private thumbnail: Thumbnail;
    private oldThumbnailUrl: string;

    constructor() {
        super(MarkerType.WEBSITE);

        this.tempGroup = new THREE.Group();
        // create Image Mesh
        const imgGeometry = new CurvePlaneGeometry(1.0, 1.0);
        const imgMaterial = new THREE.MeshBasicMaterial({ map: null, side: THREE.DoubleSide, transparent: true, alphaTest: 0.1 });
        this.iconMesh = new THREE.Mesh(imgGeometry, imgMaterial);
        this.object.add(this.iconMesh);

        //create Text mesh
        this.textHeight = 0.5;
        const textMaterial = new THREE.MeshBasicMaterial({ map: null, side: THREE.DoubleSide, transparent: true });
        this.textMesh = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), textMaterial);
    }

    public get json() {
        return {
            ...super['json'],
            showTitle: this.showTitle,
            hotspotIconHeight: this.hotspotIconHeight,
            curve: this.curve,
        };
    }

    public async setJson(data: IWebsite) {
        super.setJson(data);

        //TODO: update showTitle,showConnectedLine iconWidth property
        this.showTitle = data.showTitle;
        this.hotspotIconHeight = data.hotspotIconHeight;
        this.curve = data.curve;
        super.markStatusUpdate();

        this.updateLabel();
        await this.updateIcon();
        this.updateIconScale();
        this.updateBoundary();
    }

    public async init(data: IWebsite) {
        super.init(data);
        this.showTitle = data.showTitle ?? false;
        this.curve = data.curve ?? 0;
        this.hotspotIconHeight = data.hotspotIconHeight ?? 1;

        //TODO: update showTitle,showConnectedLine iconWidth property
        this.updateLabel();
        this.thumbnail = new Thumbnail(this.thumbnailUrl);
        await this.thumbnail.init();
        await this.updateIcon();
        this.updateIconScale();
        this.updateBoundary();
    }

    async updateIcon() {
        this.iconMesh.geometry.curvePlane(this.curve);
        if (this.oldThumbnailUrl === this.thumbnailUrl) return;

        await this.thumbnail.update(this.thumbnailUrl);
        const texture = this.thumbnail.texture;
        const ratio = this.thumbnail.ratio;
        this.hotspotIconWidth = ratio / (ratio * ratio); // Example: 2/3 transform to 3/2

        const oldGeometry = this.iconMesh.geometry;

        this.iconMesh.material.map = texture;
        this.iconMesh.geometry = new CurvePlaneGeometry(this.hotspotIconWidth, 1, this.curve);

        if (oldGeometry) oldGeometry.dispose();

        this.oldThumbnailUrl = this.thumbnailUrl;
    }
    private updateIconScale(): void {
        const size = this.hotspotIconHeight;
        this.iconMesh.scale.set(size, size, size);
    }

    private updateLabel(): void {
        // create a canvas element
        const texture = new TextTexture(
            {
                label: this.name,
                fontWeight: 'Normal',
                fontStyle: 'Normal',
                fontColor: '#ffffff',
                padding: 15,
                fontSize: 35,
                alignment: 'center',
                lineSpace: 0,
            },
            {
                fillStyle: 'rgba(0,0,0,0.5)',
            },
        );

        texture.needsUpdate = true;

        // store old texture and geometry
        const oldTexture = this.textMesh.material.map;
        const oldGeometry = this.textMesh.geometry;

        let sizeX = texture.width * 0.35;
        let sizeY = texture.height * 0.35;

        //set new geometry and texture
        this.textMesh.material.map = texture;
        this.textMesh.geometry = new THREE.PlaneGeometry(sizeX, sizeY);

        //dispose old texture and geometry
        if (oldTexture) oldTexture.dispose();
        if (oldGeometry) oldGeometry.dispose();

        this.textMesh.scale.set(6, 6, 6);
        this.textWdith = sizeX * 6;

        if (this.showTitle) {
            this.object.add(this.textMesh);
            this.updateRaycastMesh([this.iconMesh, this.textMesh]);
        } else {
            this.object.remove(this.textMesh);
            this.updateRaycastMesh([this.iconMesh]);
        }
    }

    updateBoundary(): void {
        const displayTextHeight = this.showTitle ? this.textHeight : 0;
        const displayIconHeight = this.hotspotIconHeight;
        const displayIconWidth = this.showTitle ? this.textWdith : 0;
        this.iconMesh.position.setY(0);
        this.textMesh.position.setY(displayIconHeight / 2 + displayTextHeight / 2);
        const boundaryWidth = Math.max(displayIconWidth, this.hotspotIconWidth);
        this.updateBoundaryBox(boundaryWidth, displayTextHeight + displayIconHeight, 0.002);
    }

    public showLevel() {
        if (!this.lodEnabled) {
            return;
        }
        const level = this.curLevel;
        if (this.prevLevel !== level) {
            this.prevLevel = level;
            switch (level) {
                case 0:
                    this.iconMesh.visible = true;
                    this.textMesh.visible = true;
                    this.showBoundaryBox(false);
                    break;
                case 1:
                    this.iconMesh.visible = false;
                    this.textMesh.visible = false;
                    this.showBoundaryBox(true);
                    break;
                case 2:
                    this.iconMesh.visible = false;
                    this.textMesh.visible = false;
                    this.showBoundaryBox(false);
                    break;
            }
        }
    }

    destroy() {
        super.destroy();

        //dispose geometry
        if (this.iconMesh.geometry) {
            this.iconMesh.geometry.dispose();
            this.iconMesh.geometry = null;
        }
        if (this.iconMesh.material) {
            //dispose texture
            if (this.iconMesh.material.map) {
                this.iconMesh.material.map.dispose();
                this.iconMesh.material.map = null;
            }

            //dispose material
            this.iconMesh.material.dispose();
            this.iconMesh.material = null;
        }

        //dispose geometry
        if (this.textMesh.geometry) {
            this.textMesh.geometry.dispose();
            this.textMesh.geometry = null;
        }
        if (this.textMesh.material) {
            //dispose texture
            if (this.textMesh.material.map) {
                this.textMesh.material.map.dispose();
                this.textMesh.material.map = null;
            }

            //dispose material
            this.textMesh.material.dispose();
            this.textMesh.material = null;
        }
        this.thumbnail?.dispose();
    }
}
