import * as THREE from 'three';
import { InteractionObject } from '../InteractionObect';

export default function Animated<T extends new (...args: any[]) => InteractionObject>(Base: T) {
    return class AnimeObject extends Base {
        public autoRotate = false;
        public movingSpeed = 2;
        public rotateSpeed = 36; // degree per sec
        public rotateControl = false;
        public destination: THREE.Vector3 = null;

        constructor(...args) {
            super(...args);
        }
        static dragStartMouse = { x: 0, y: 0 };
        static currentMouse = { x: 0, y: 0 };

        private rotateY(deltaSec: number) {
            const rad = (this.rotateSpeed / 180) * Math.PI;
            const rotation = rad * deltaSec;
            this.object.rotation.y += rotation;
        }

        public updateAnimation(deltaSec: number) {
            if (this.autoRotate) this.rotateY(deltaSec);
        }

        public updatePosition(deltaSec: number) {
            if (!this.destination) return;
            const direction = new THREE.Vector3().subVectors(this.destination, this.object.position).normalize();
            this.object.position.add(direction.multiplyScalar(deltaSec * this.movingSpeed));
            if (this.object.position.distanceTo(this.destination) <= 0.1) {
                this.destination = null;
            }
        }
    };
}
