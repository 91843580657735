// react, redux
import React, { useContext } from 'react';
import ThreeContext from 'app/pages/three-context';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'App';

// material ui , css
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';

// api
import { convertImgUrl2File } from 'api/marker';

// utils, resources
import { compressImgAndUploadFile } from 'utils';
import { YOUTUBE_URL_PATTERN, VIMEO_URL_PATTERN } from 'constants/regex';
import { debounce } from 'core/utils/index';
import { useLatest } from 'hooks';

enum UIComponetKey {
    value = 'value',
}

enum PropertyKey {
    fileUrl = 'fileUrl',
    thumbnailUrl = 'thumbnailUrl',
}

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
})(TextField);

const cache = {};
const VideoUrlPropertyBox: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);
    const { auth } = React.useContext(AppContext);
    const latestPropertyDataRef = useLatest(propertyData);
    const path = `user/${auth.id}/media`;

    const updateThumbnail = debounce(async (videoUrl: string) => {
        if (!videoUrl) return;
        let thumbnailUrl = '';
        let tempMarkerData = latestPropertyDataRef.current;

        if (YOUTUBE_URL_PATTERN.test(videoUrl)) {
            const [_, videoId] = videoUrl.match(YOUTUBE_URL_PATTERN);
            if (cache[videoId]) return cache[videoId];
            const imageUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg `;
            const thumbnailFile = await convertImgUrl2File(imageUrl);
            thumbnailUrl = await compressImgAndUploadFile(thumbnailFile, path);
            cache[videoId] = thumbnailUrl;
        }

        if (VIMEO_URL_PATTERN.test(videoUrl)) {
            const videoId = videoUrl.match(VIMEO_URL_PATTERN)[4];
            if (cache[videoId]) return cache[videoId];
            const imageUrl = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`)
                .then((res) => res.json())
                .then((json) => json[0].thumbnail_medium + '.jpg');
            const thumbnailFile = await convertImgUrl2File(imageUrl);
            thumbnailUrl = await compressImgAndUploadFile(thumbnailFile, path);
            cache[videoId] = thumbnailUrl;
        }

        if (tempMarkerData.id === latestPropertyDataRef.current.id) tempMarkerData = latestPropertyDataRef.current; // prevent markId in latestPropertyDataRef isn't same as before uploading

        // if no new thumbnail, use the old one
        if (!thumbnailUrl) {
            thumbnailUrl = latestPropertyDataRef.current.thumbnailUrl;
        }

        markerUpdater.call({
            ...latestPropertyDataRef.current,
            [PropertyKey.thumbnailUrl]: thumbnailUrl,
        });
    }, 1000);

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return async function (event, newValue?) {
            markerUpdater.call({
                ...propertyData,
                [PropertyKey.fileUrl]: event.target[key],
            });
            updateThumbnail(event.target[key]);
        };
    };

    return (
        <div className="TitlePropertyBox">
            <div className="BoxTitle">{t(`propertyText.VideoUrl`) + '* :'}</div>
            <CssTextField
                id="fileUrl"
                placeholder={t(`propertyText.VideoUrl`)}
                onChange={handleChange(PropertyKey.fileUrl, UIComponetKey.value)}
                variant="outlined"
                size="small"
                value={propertyData.fileUrl ?? ''}
            />
        </div>
    );
};

export default VideoUrlPropertyBox;
