import api from 'api';
import axios from 'axios';
import SERVER from 'constants/server';
import { getRandom } from 'utils';

const bucketName = SERVER.BUCKET_NAME;

const uploadSignedUrl = async (bucket: string, key: string, acl?: string) => {
    try {
        const urlEnd = `/api/v1/s3/signedUrl`;
        const response = await api.post<string>(urlEnd, {
            bucket,
            key,
            acl,
        });
        return response.data;
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};

export const uploadFile = async (file: File, path: string, name?: string) => {
    const fileType = file.name.split('.').pop();
    const filename = name || `${Date.now()}_${getRandom(7)}.${fileType}`;
    const pathname = `${path}/${filename}`;
    const signedUrl = await uploadSignedUrl(bucketName, pathname, 'public-read');
    await axios.put(signedUrl, file, {
        headers: {
            'x-amz-acl': 'public-read',
        },
    });
    return `${SERVER.CDN_URL}/${pathname}`;
};
