// react
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// material ui
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button/Button';
import { makeStyles } from '@material-ui/core/styles';

// components
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { AppContext } from 'App';

// api
import { uploadFile } from 'api/s3';
import { reportForm } from 'api/marker';

const useStyles = makeStyles({
    reportFormContainer: {
        flex: 1,
        background: '#fff',
        padding: 5,
        overflow: 'scroll',
        width: 250,
    },
    ReportButtonPanel: {
        width: '100%',
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    reportFormPanel: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    reportReason: {
        width: '90%',
        textAlign: 'left',
    },
    reasonTextField: {
        width: '90%',
    },
    markerImage: {
        width: '90%',
        textAlign: 'left',
    },
    img: {
        margin: '8px 0',
        width: '90%',
    },
    hr: {
        margin: '10px 0',
        height: 1,
        width: '90%',
        border: '1px solid #ccc',
    },
});

interface ReportData {
    userId: string;
    description: string;
    groupId: string;
    markerId: string;
    snapshot: string;
    thumbnailUrl: string;
}
const ReportForm = () => {
    const { toast } = React.useContext(AppContext);
    const styles = useStyles();
    const { t } = useTranslation();
    const userId = React.useContext(AppContext).auth.id;
    const { currentMarker } = React.useContext(TagEditorContext);
    const [isReporting, setIsReporting] = React.useState(false);
    const [form, setFormState] = React.useState<ReportData>({
        userId: '',
        description: '',
        markerId: '',
        groupId: '',
        snapshot: '',
        thumbnailUrl: '',
    });
    const setForm = React.useCallback(
        (form: Partial<ReportData>) => setFormState((prev) => ({ ...prev, ...form })),
        [],
    );
    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        if (file == null) return;
        const fileUrl = await uploadFile(file, `/user/${userId}/media`);
        setForm({ snapshot: fileUrl });
        e.target.value = '';
    };

    useEffect(() => {
        if (currentMarker == null || currentMarker.editable) return;
        setIsReporting(false);
        setForm({
            userId: userId,
            groupId: currentMarker.groupId,
            description: '',
            markerId: currentMarker.id,
            thumbnailUrl: currentMarker.thumbnailUrl,
            snapshot: '',
        });
    }, [currentMarker, setForm, userId]);

    const handleSendReport = async () => {
        setIsReporting(false);
        const response = await reportForm(form);
        if (response.msg === 'success') {
            toast('success', t('snackbar.reportSuccess'));
        }

        if (response.msg !== 'success') {
            toast('error', t('snackbar.reportFailed'));
        }
    };

    const handleOpenReport = () => {
        setIsReporting(true);
    };

    if (currentMarker == null || currentMarker.editable) return null;
    return (
        <div className={styles.reportFormContainer}>
            <div className={styles.ReportButtonPanel}>
                {isReporting ? (
                    <Button variant="contained" onClick={handleSendReport}>
                        {t('common.Send')}
                    </Button>
                ) : (
                    <Button variant="contained" onClick={handleOpenReport}>
                        {t('TagEditor.Report')}
                    </Button>
                )}
            </div>
            <div className={styles.reportFormPanel} style={{ display: isReporting ? 'flex' : 'none' }}>
                <span className={styles.reportReason}>{t('TagEditor.ReportReason')}</span>
                <TextField
                    className={styles.reasonTextField}
                    multiline
                    variant="outlined"
                    minRows={4}
                    margin="dense"
                    size="small"
                    value={form.description}
                    onChange={(e) => setForm({ description: e.target.value })}
                />
                <hr className={styles.hr} />
                <span className={styles.markerImage}>{t('media.IMAGE')}</span>
                <img className={styles.img} src={form.thumbnailUrl} alt="" />
                <hr className={styles.hr} />
                <Button variant="contained" component="label">
                    {t('TagEditor.UploadPicture')}
                    <input type="file" hidden onChange={onFileChange} accept="image/*" />
                </Button>
                {form.snapshot ? <img className={styles.img} src={form.snapshot} alt="" /> : null}
            </div>
        </div>
    );
};

export default ReportForm;
