import React from 'react';
import styles from './index.module.css';
import jsCookie from 'js-cookie';
import { getDomain } from 'utils';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
    const { t } = useTranslation();
    const [isCookieBannerShow, setIsCookieBannerShow] = React.useState(false);
    React.useEffect(() => {
        if (jsCookie.get('istaging_cookie') === undefined) {
            setIsCookieBannerShow(true);
        }
    }, []);

    const acceptCookieHandler = () => {
        jsCookie.set('istaging_cookie', 'all_agreed', { domain: getDomain(), expires: 365 });
        setIsCookieBannerShow(false);
    };
    return (
        <>
            {isCookieBannerShow && (
                <div className={styles['cookie-banner']}>
                    <span className={styles['cookie-banner-content']}>
                        {t('cookieBanner.content')}
                        <a href="https://www.istaging.com/zh-tw/privacy/" target="_blank" rel="noreferrer">
                            {t('cookieBanner.policyLinkText')}
                        </a>
                        {t('cookieBanner.contentLinkAfter')}
                    </span>
                    <button className={styles['cookie-banner-btn']} onClick={acceptCookieHandler}>
                        {t('cookieBanner.buttonText')}
                    </button>
                </div>
            )}
        </>
    );
};
export default CookieBanner;
