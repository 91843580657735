import * as React from 'react';
import { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './LinkPropertyBox.css';
import { MarkerType } from 'core/three/object/type';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';

enum UIComponetKey {
    value = 'value',
    checked = 'checked',
}

enum PropertyKey {
    linkUrl = 'linkUrl',
    linkName = 'linkName',
    openNewWindow = 'openNewWindow',
}

type ARLinkProps = {
    type: MarkerType.ARTAG;
    showOpenNewWindow: false;
};

type DefaultProps = {
    type?: null;
    showOpenNewWindow?: boolean;
};

const linkRegex = {
    [MarkerType.ARTAG]: /^https:\/\/arviewer\.istaging\.com/,
};

type LinkPropertyBoxProps = ARLinkProps | DefaultProps;

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#fff',
        },
        checkedIcon: {
            borderRadius: 4,
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 20,
                height: 20,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
        },
    }),
);

const LinkPropertyBox: React.FunctionComponent<LinkPropertyBoxProps> = ({
    type,
    showOpenNewWindow = true,
}: LinkPropertyBoxProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);
    const [inputLinkUrl, setInputLinkUrl] = useState('');

    const regex = useMemo(() => {
        return type ? linkRegex[type] || /./ : /./;
    }, [type]);

    const linkFormatError = useMemo(() => {
        return !regex.test(inputLinkUrl);
    }, [inputLinkUrl, regex]);

    useEffect(() => {
        setInputLinkUrl(propertyData[PropertyKey.linkUrl] || '');
    }, [propertyData]);

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event) {
            if (propertykey === PropertyKey.openNewWindow) {
                const openTypeNew = event.target[key];
                markerUpdater.call({ ...propertyData, openType: openTypeNew });
            } else {
                markerUpdater.call({ ...propertyData, [propertykey]: event.target[key] });
            }
        };
    };

    return (
        <div className="LinkPropertyBox">
            <div className="BoxTitle">{t(`propertyText.Link`) + ':'}</div>
            <CssTextField
                id="linkUrl"
                placeholder="http://"
                variant="outlined"
                size="small"
                onChange={(e) => {
                    setInputLinkUrl(e.target.value);
                    if (regex.test(e.target.value)) {
                        handleChange(PropertyKey.linkUrl, UIComponetKey.value)(e);
                    }
                }}
                value={inputLinkUrl}
                margin="dense"
            />
            {linkFormatError && <span className="FormatError">{t('propertyText.FormatError')}</span>}
            {showOpenNewWindow && (
                <RadioGroup
                    name="openNewWindow"
                    value={propertyData.openType}
                    onChange={handleChange(PropertyKey.openNewWindow, UIComponetKey.value)}
                >
                    <FormControlLabel
                        value="window"
                        control={
                            <Radio
                                color="primary"
                                size="small"
                                classes={{
                                    root: classes.root,
                                }}
                            />
                        }
                        label={t(`propertyText.OpenNewWindow`)}
                    />
                    <FormControlLabel
                        value="redirect"
                        control={
                            <Radio
                                color="primary"
                                size="small"
                                classes={{
                                    root: classes.root,
                                }}
                            />
                        }
                        label={t(`propertyText.Redirect`)}
                    />
                    <FormControlLabel
                        value="iframe"
                        control={
                            <Radio
                                color="primary"
                                size="small"
                                classes={{
                                    root: classes.root,
                                }}
                            />
                        }
                        label={t(`propertyText.Iframe`)}
                    />
                </RadioGroup>
            )}
        </div>
    );
};

export default LinkPropertyBox;
