import * as React from 'react';
import './StylePropertyBox.css';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ThreeContext from 'app/pages/three-context';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import Grid from '@material-ui/core/Grid';
import { ChromePicker } from 'react-color';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { withStyles } from '@material-ui/core/styles';

enum UIComponetKey {
    value = 'value',
    checked = 'checked',
}
enum PropertyKey {
    fontStyle = 'fontStyle',
    fontWeight = 'fontWeight',
    textColor = 'textColor',
    alignment = 'alignment',
    fontSize = 'fontSize',
    lineSpace = 'lineSpace',
}
enum FontStyle {
    Normal = 'normal',
    Italic = 'italic',
}
enum FontWeight {
    Normal = 'normal',
    Bold = 'bold',
}
interface IPropertyData {
    fontStyle: string;
    fontWeight: string;
    textColor: string;
    alignment: string;
    fontSize: number;
    lineSpace: number;
}

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
    },
})(TextField);

const StylePropertyBox: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const textSizeOptions: number[] = [14, , 16, 18, 20, 22, 24, 26, 28, 30, 40, 50, 60];
    const lineSpaceOptions: number[] = [0.5, 1.0, 1.5, 2.0, 2.5, 3, 3.5, 4.0];
    const elRef = React.useRef(null); // use to set color picker anchor

    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);

    const [openColorPicker, setOpenColorPicker] = React.useState(false);

    const handleStyleSelectChange = (key: PropertyKey, UIkey: UIComponetKey) => {
        return function (event, newValue?) {
            markerUpdater.call({ ...propertyData, [key]: newValue || event.target[key] });
        };
    };

    const handleStyleInputChange = (key: PropertyKey.lineSpace | PropertyKey.fontSize) => {
        return function (event, newValue: string, mode: 'reset' | 'input') {
            if (mode === 'reset') return;
            markerUpdater.call({ ...propertyData, [key]: Number(newValue) });
        };
    };

    const handleFormatChange = (key: PropertyKey) => {
        let value: FontWeight | FontStyle;
        if (key === PropertyKey.fontWeight) {
            if (propertyData[key] == FontWeight.Bold) value = FontWeight.Normal;
            else value = FontWeight.Bold;
        } else if (key === PropertyKey.fontStyle) {
            if (propertyData[key] == FontStyle.Italic) value = FontWeight.Normal;
            else value = FontStyle.Italic;
        }
        markerUpdater.call({ ...propertyData, [key]: value });
    };

    const handleColorChangeComplete = (color) => {
        markerUpdater.call({ ...propertyData, [PropertyKey.textColor]: color.hex });
    };

    return (
        <div className="StylePropertyBox" ref={elRef}>
            <div className="TitleText">{t(`propertyText.Style`) + ':'}</div>
            <Grid container spacing={1}>
                <Grid id="formatGrid" item xs={12}>
                    <ButtonGroup aria-label="outlined primary button group">
                        <Popper open={openColorPicker} anchorEl={elRef.current} placement="left">
                            <ChromePicker
                                className="colorPicker"
                                disableAlpha={true}
                                color={propertyData.textColor ?? '#000000'}
                                // onChange={ handleColorChange }
                                onChangeComplete={handleColorChangeComplete}
                                width="250px"
                            />
                        </Popper>
                        <Button
                            className={openColorPicker ? 'selectedBtn' : ''}
                            onClick={() => {
                                setOpenColorPicker((prev) => !prev);
                            }}
                        >
                            <ExpandMoreIcon />
                        </Button>
                        <Button
                            className={propertyData.fontWeight === FontWeight.Bold ? 'selectedBtn' : ''}
                            onClick={() => {
                                handleFormatChange(PropertyKey.fontWeight);
                            }}
                        >
                            <FormatBoldIcon />
                        </Button>
                        <Button
                            className={propertyData.fontStyle === FontStyle.Italic ? 'selectedBtn' : ''}
                            onClick={() => {
                                handleFormatChange(PropertyKey.fontStyle);
                            }}
                        >
                            <FormatItalicIcon />
                        </Button>
                    </ButtonGroup>
                </Grid>

                <Grid id="alignmentGrid" item xs={12}>
                    <ToggleButtonGroup
                        value={propertyData.alignment ?? 'left'}
                        exclusive
                        onChange={handleStyleSelectChange(PropertyKey.alignment, UIComponetKey.value)}
                        aria-label="text-alignment"
                        size="large"
                        color="primary"
                    >
                        <ToggleButton value="left" aria-label="left-aligned">
                            <FormatAlignLeftIcon />
                        </ToggleButton>
                        <ToggleButton value="center" aria-label="centered">
                            <FormatAlignCenterIcon />
                        </ToggleButton>
                        <ToggleButton value="right" aria-label="right-aligned">
                            <FormatAlignRightIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                <Grid id="textSizeGrid" item xs={6}>
                    <div className="TitleText">{t(`propertyText.Size`) + ':'}</div>
                    <Autocomplete
                        id="font-size-selector"
                        freeSolo
                        options={textSizeOptions}
                        getOptionLabel={(option) => option.toString()}
                        value={propertyData.fontSize ?? 60}
                        onChange={handleStyleSelectChange(PropertyKey.fontSize, UIComponetKey.value)}
                        onInputChange={handleStyleInputChange(PropertyKey.fontSize)}
                        renderInput={(params) => (
                            <CssTextField {...params} id="font-size-inputText" type="number" variant="outlined" />
                        )}
                    />
                </Grid>

                <Grid id="lineSpaceGrid" item xs={6}>
                    <div className="TitleText">{t(`propertyText.LineSpace`) + ':'}</div>
                    <Autocomplete
                        id="line-space-selector"
                        freeSolo
                        options={lineSpaceOptions}
                        value={propertyData.lineSpace ?? 0.5}
                        getOptionLabel={(option) => option.toString()}
                        onChange={handleStyleSelectChange(PropertyKey.lineSpace, UIComponetKey.value)}
                        onInputChange={handleStyleInputChange(PropertyKey.lineSpace)}
                        renderInput={(params) => (
                            <CssTextField {...params} id="line-space-inputText" type="number" variant="outlined" />
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default StylePropertyBox;
