/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
export default function roundRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius = 5,
    fill = false,
    stroke = false,
) {
    const radiusConfig = { tl: radius, tr: radius, br: radius, bl: radius };
    ctx.beginPath();
    ctx.moveTo(x + radiusConfig.tl, y);
    ctx.lineTo(x + width - radiusConfig.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radiusConfig.tr);
    ctx.lineTo(x + width, y + height - radiusConfig.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radiusConfig.br, y + height);
    ctx.lineTo(x + radiusConfig.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radiusConfig.bl);
    ctx.lineTo(x, y + radiusConfig.tl);
    ctx.quadraticCurveTo(x, y, x + radiusConfig.tl, y);
    ctx.closePath();
    if (fill) {
        ctx.fill();
    }
    if (stroke) {
        ctx.stroke();
    }
}
