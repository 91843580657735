import { MarkerType, MediaStatus } from 'core/three/object/type';
import * as THREE from 'three';
import { MediaMarker, MultipleLanguageWithDefault, ViewSpot } from 'core/types/media';
import merge from 'lodash/merge';
import InteractionObect from '../InteractionObect';
import { generateMultiLang } from 'core/utils/language';
import MarkerMgr from 'core/three/base/MarkerMgr';
import { RenderFrame } from 'core/types';

export default class Media extends InteractionObect {
    public _id?: string;
    public undoRedoId: string;
    public uuid: string;
    public groupId: string;
    public slotId: string;
    public status: MediaStatus = MediaStatus.CREATED;
    public name: string = '';
    public fileUrl: string = '';
    public usdzUrl: string = '';
    public linkUrl: string = '';
    public thumbnailUrl: string = '';
    public linkName: string = '';
    public openType: 'redirect' | 'window' | 'iframe' = 'window';
    public openNewWindow: boolean = false;
    public description: string = '';
    public unclickable: boolean = false;
    public openLinkDirectly: boolean = false;
    public visible: boolean = false;
    public customData: string = '';
    public isVertical: boolean = false;
    public heightoffset: number = 0;
    public widthoffset: number = 0;
    public defaultWidth: number = 1.5; // !! to discuss
    public followCamera: boolean = false;
    public lodDistance: number = 2;
    public boundaryBox: THREE.Mesh;
    public boxHelper: THREE.BoxHelper;
    public lod: THREE.LOD = new THREE.LOD();
    public lodEnabled: boolean = false;
    public translateEnabled = true;
    public scaleEnabled = true;
    public rotateEnabled = true;
    public searchable: boolean = false;
    public manager: MarkerMgr;
    public quality: 'small' | 'medium' | 'standard';
    public curLevel: number = 0;
    public prevLevel: number = undefined;
    public multipleLanguage: string = 'default';
    public multiLang: MultipleLanguageWithDefault = generateMultiLang();
    public viewSpot: ViewSpot = {};
    constructor(type) {
        super(type);
        this.createBoundaryBox();
    }

    private originRotation: THREE.Quaternion;

    public get json(): Partial<MediaMarker> {
        return {
            ...super['json'],
            id: this._id,
            undoRedoId: this.undoRedoId,
            uuid: this.uuid,
            groupId: this.groupId,
            slotId: this.slotId,
            status: this.status,
            name: this.name,
            fileUrl: this.fileUrl,
            usdzUrl: this.usdzUrl,
            linkUrl: this.linkUrl,
            thumbnailUrl: this.thumbnailUrl,
            linkName: this.linkName,
            description: this.description,
            defaultWidth: this.defaultWidth,
            unclickable: this.unclickable,
            openType: this.openType,
            visible: this.visible,
            isVertical: this.isVertical,
            followCamera: this.followCamera,
            lodDistance: this.lodDistance,
            searchable: this.searchable,
            editable: this.editable,
            multipleLanguage: this.multipleLanguage,
            multiLang: this.multiLang,
            viewSpot: this.viewSpot,
            translateEnabled: this.translateEnabled,
            rotateEnabled: this.rotateEnabled,
            scaleEnabled: this.scaleEnabled,
        };
    }

    public setJson(data: any) {
        super.setJson(data);
        this.name = data.name;
        this.fileUrl = data.fileUrl;
        this.usdzUrl = data.usdzUrl;
        this.linkUrl = data.linkUrl;
        this.thumbnailUrl = data.thumbnailUrl;
        this.linkName = data.linkName;
        this.openType = data.openType;
        this.openNewWindow = data.openType === 'window' ? true : false;
        this.description = data.description;
        this.unclickable = data.unclickable;
        this.openLinkDirectly = data.openLinkDirectly;
        this.visible = data.visible;
        this.customData = data.customData;
        this.defaultWidth = data.defaultWidth;
        this.lodDistance = data.lodDistance;
        this.searchable = data.searchable;
        this.multipleLanguage = data.multipleLanguage;
        this.multiLang = data.multiLang;
        this.viewSpot = data.viewSpot;

        if (data.hasOwnProperty('isVertical')) {
            if (this.isVertical !== data.isVertical) {
                this.isVertical = data.isVertical;
                this.setObjVertical(data.isVertical);
            }
        }
        if (this.followCamera !== data.followCamera) {
            this.followCamera = data.followCamera;
        }
    }

    public init(data: any) {
        this._id = data.id;
        this.undoRedoId = data.undoRedoId;
        this.groupId = data.groupId;
        this.slotId = data.slotId;
        this.name = data.name;
        this.fileUrl = data.fileUrl;
        this.usdzUrl = data.usdzUrl;
        this.linkUrl = data.linkUrl;
        this.defaultWidth = data.defaultWidth;
        this.thumbnailUrl = data.thumbnailUrl;
        this.linkName = data.linkName;
        this.openType = data.openType;
        this.openNewWindow = data.openType === 'window';
        this.description = data.description;
        this.unclickable = data.unclickable;
        this.openLinkDirectly = data.openLinkDirectly;
        this.visible = data.visible;
        this.customData = data.customData;
        this.isVertical = data.isVertical ? data.isVertical : false;
        this.followCamera = data.followCamera ? data.followCamera : false;
        this.lodDistance = data.lodDistance ? data.lodDistance : 2;
        this.searchable = data.searchable ? data.searchable : false;
        this.lod = new THREE.LOD();
        this.editable = data.editable;
        this.uuid = this.object.uuid;
        this.quality = data.quality ? data.quality : 'standard';
        this.translateEnabled = data.translateEnabled ?? true;
        this.rotateEnabled = data.rotateEnabled ?? true;
        this.scaleEnabled = data.scaleEnabled ?? true;

        if (data.multiLang) {
            this.multiLang = merge(this.multiLang, data.multiLang);
        }

        if (data.viewSpot) {
            this.viewSpot = merge(this.viewSpot, data.viewSpot);
        }

        if (data.id != null) this.status = MediaStatus.READ;
    }

    public update(renderFrame: RenderFrame) {
        this.updatePosition(renderFrame.delteSec)
        this.updateAnimation(renderFrame.delteSec);
        if (this.followCamera) this.lookAt(renderFrame.cameraPosition);
    }

    public lookAt(pos: THREE.Vector3) {
        const n = new THREE.Vector3().subVectors(pos, this.object.position).normalize();
        n.y = 0;
        const mat4 = new THREE.Matrix4().lookAt(n, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0));
        this.object.setRotationFromMatrix(mat4);
    }

    public destroy() {
        super.destroy();
        this.removeBoundaryBox();
        this.removeLodObject();

        if (this.status === MediaStatus.CREATED) {
            this.status = MediaStatus.INVALID;
        } else {
            this.status = MediaStatus.DELETED;
        }
        this.manager?.onMediaUpdate(this);
    }
    public markStatusUpdate() {
        if (this.status === MediaStatus.READ) this.status = MediaStatus.UPDATED;
        this.manager?.onMediaUpdate(this);
    }

    public set LODLevel(level: number) {
        this.curLevel = level;
        this.lodEnabled = true;
    }

    public showLevel() {}

    updateQuality(quality: 'small' | 'medium' | 'standard') {}

    setObjVertical(enable: boolean) {
        let offsetY = this.heightoffset * this.object.scale.y;
        let offsetZ = this.widthoffset * this.object.scale.z;

        if (enable) {
            if (this.type === MarkerType.MODEL) {
                this.object.rotateX(Math.PI / 2);
                if (this.widthoffset > this.heightoffset) this.object.translateZ(-offsetZ);
                else this.object.translateZ(offsetZ);
            } else {
                this.object.rotateX(Math.PI / 2);
                this.object.translateY(offsetY);
            }
        } else {
            if (this.type === MarkerType.MODEL) {
                this.object.rotateX(-Math.PI / 2);
                if (this.widthoffset > this.heightoffset) this.object.translateY(-offsetZ);
                else this.object.translateY(offsetZ);
            } else {
                this.object.rotateX(-Math.PI / 2);
                this.object.translateZ(-offsetY);
            }
        }
    }
    showBoundaryBox(option: boolean) {
        if (option) {
            this.boundaryBox.visible = true;
            (this.boundaryBox.material as THREE.MeshBasicMaterial).opacity = 0.5;
            this.boxHelper.material.opacity = 1;
        } else {
            this.boundaryBox.visible = false;
            (this.boundaryBox.material as THREE.MeshBasicMaterial).opacity = 0;
            this.boxHelper.material.opacity = 0;
        }
    }

    protected removeLodObject() {
        this.lod.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                if (child.geometry) child.geometry.dispose();
                if (child.material) child.material.dispose();
            }
        });
    }

    /**
     * create initial boundary box, default height:1,width:1,depth:1
     */
    protected createBoundaryBox() {
        const material = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0, color: 0x398ded });
        this.boundaryBox = new THREE.Mesh(undefined, material);
        this.boundaryBox.visible = false;
        this.boxHelper = new THREE.BoxHelper(this.boundaryBox, 0x398ded);
        this.boxHelper.material.opacity = 0;
        this.boxHelper.material.transparent = true;
        this.object.add(this.boundaryBox);
        this.boundaryBox.add(this.boxHelper);
    }

    protected removeBoundaryBox() {
        if ((this.boundaryBox as THREE.Mesh)?.geometry) (this.boundaryBox as THREE.Mesh)?.geometry.dispose();
        if (this.boundaryBox?.material as THREE.MeshBasicMaterial)
            (this.boundaryBox?.material as THREE.MeshBasicMaterial).dispose();

        if ((this.boxHelper as THREE.BoxHelper)?.geometry) (this.boxHelper as THREE.BoxHelper)?.geometry.dispose();
        this.boxHelper?.material?.dispose()
    }

    /**
     * update boundary box size
     * @param width    Boundary Box width, default value 1
     * @param height   Boundary Box height, default value 1
     * @param depth    Boundary Box depth, default value 1
     */
    protected updateBoundaryBox(width: number = 1, height: number = 1, depth: number = 1) {
        const oldGeometry = (this.boundaryBox as THREE.Mesh).geometry;
        const newGeometry = new THREE.BoxGeometry(width, height, depth);
        (this.boundaryBox as THREE.Mesh).geometry = newGeometry;
        if (oldGeometry) oldGeometry.dispose();
        this.heightoffset = Math.abs(height / 2);
    }
    protected updateRaycastBoundary(width: number = 1, height: number = 1, depth: number = 1) {
        if (this.raycastMesh) {
            const oldGeometry = (this.raycastMesh as THREE.Mesh).geometry;
            const newGeometry = new THREE.BoxGeometry(width, height, depth);

            this.raycastMesh.geometry = newGeometry;
            if (oldGeometry) oldGeometry.dispose();
        }
    }
}
