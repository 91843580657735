import {
    BoxGeometry,
    BufferGeometry,
    Color,
    CylinderGeometry,
    DoubleSide,
    Euler,
    Float32BufferAttribute,
    Line,
    LineBasicMaterial,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    OctahedronGeometry,
    Quaternion,
    SphereGeometry,
    TorusGeometry,
    Vector3,
    PerspectiveCamera,
    OrthographicCamera,
} from 'three';
import TransformControls from './TransformControls';

export default class TransformControlsGizmo extends Object3D {
    type: string;
    gizmo: any;
    picker: any;
    helper: any;
    transformControl: TransformControls;

    constructor(transformControl: TransformControls) {
        super();
        this.type = 'TransformControlsGizmo';
        this.transformControl = transformControl;
        // shared materials

        let gizmoMaterial = new MeshBasicMaterial({
            depthTest: false,
            depthWrite: false,
            transparent: true,
            side: DoubleSide,
            fog: false,
            toneMapped: false,
        });

        let gizmoLineMaterial = new LineBasicMaterial({
            depthTest: false,
            depthWrite: false,
            transparent: true,
            linewidth: 1,
            fog: false,
            toneMapped: false,
        });

        // Make unique material for each axis/color

        let matInvisible = gizmoMaterial.clone();
        matInvisible.opacity = 0.15;

        let matHelper = gizmoMaterial.clone();
        matHelper.opacity = 0.33;

        let matRed = gizmoMaterial.clone();
        matRed.color.set(0xff0000);

        let matGreen = gizmoMaterial.clone();
        matGreen.color.set(0x00ff00);

        let matBlue = gizmoMaterial.clone();
        matBlue.color.set(0x0000ff);

        let matWhiteTransparent = gizmoMaterial.clone();
        matWhiteTransparent.opacity = 0.25;

        let matYellowTransparent = matWhiteTransparent.clone();
        matYellowTransparent.color.set(0xffff00);

        let matCyanTransparent = matWhiteTransparent.clone();
        matCyanTransparent.color.set(0x00ffff);

        let matMagentaTransparent = matWhiteTransparent.clone();
        matMagentaTransparent.color.set(0xff00ff);

        let matYellow = gizmoMaterial.clone();
        matYellow.color.set(0xffff00);

        let matLineRed = gizmoLineMaterial.clone();
        matLineRed.color.set(0xff0000);

        let matLineGreen = gizmoLineMaterial.clone();
        matLineGreen.color.set(0x00ff00);

        let matLineBlue = gizmoLineMaterial.clone();
        matLineBlue.color.set(0x0000ff);

        let matLineCyan = gizmoLineMaterial.clone();
        matLineCyan.color.set(0x00ffff);

        let matLineMagenta = gizmoLineMaterial.clone();
        matLineMagenta.color.set(0xff00ff);

        let matLineYellow = gizmoLineMaterial.clone();
        matLineYellow.color.set(0xffff00);

        let matLineGray = gizmoLineMaterial.clone();
        matLineGray.color.set(0x787878);

        let matLineYellowTransparent = matLineYellow.clone();
        matLineYellowTransparent.opacity = 0.25;

        // reusable geometry

        let arrowGeometry = new CylinderGeometry(0, 0.05, 0.2, 12, 1, false);

        let scaleHandleGeometry = new BoxGeometry(0.125, 0.125, 0.125);

        let lineGeometry = new BufferGeometry();
        lineGeometry.setAttribute('position', new Float32BufferAttribute([0, 0, 0, 1, 0, 0], 3));

        let CircleGeometry = function (radius, arc) {
            let geometry = new BufferGeometry();
            let vertices = [];

            for (let i = 0; i <= 64 * arc; ++i) {
                vertices.push(0, Math.cos((i / 32) * Math.PI) * radius, Math.sin((i / 32) * Math.PI) * radius);
            }

            geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));

            return geometry;
        };

        // Special geometry for transform helper. If scaled with position vector it spans from [0,0,0] to position

        let TranslateHelperGeometry = function () {
            let geometry = new BufferGeometry();

            geometry.setAttribute('position', new Float32BufferAttribute([0, 0, 0, 1, 1, 1], 3));

            return geometry;
        };

        // Gizmo definitions - custom hierarchy definitions for setupGizmo() function

        let gizmoTranslate = {
            X: [
                [new Mesh(arrowGeometry, matRed), [1, 0, 0], [0, 0, -Math.PI / 2], null, 'fwd'],
                [new Mesh(arrowGeometry, matRed), [1, 0, 0], [0, 0, Math.PI / 2], null, 'bwd'],
                [new Line(lineGeometry, matLineRed)],
            ],
            Y: [
                [new Mesh(arrowGeometry, matGreen), [0, 1, 0], null, null, 'fwd'],
                [new Mesh(arrowGeometry, matGreen), [0, 1, 0], [Math.PI, 0, 0], null, 'bwd'],
                [new Line(lineGeometry, matLineGreen), null, [0, 0, Math.PI / 2]],
            ],
            Z: [
                [new Mesh(arrowGeometry, matBlue), [0, 0, 1], [Math.PI / 2, 0, 0], null, 'fwd'],
                [new Mesh(arrowGeometry, matBlue), [0, 0, 1], [-Math.PI / 2, 0, 0], null, 'bwd'],
                [new Line(lineGeometry, matLineBlue), null, [0, -Math.PI / 2, 0]],
            ],
            XYZ: [[new Mesh(new OctahedronGeometry(0.1, 0), matWhiteTransparent.clone()), [0, 0, 0], [0, 0, 0]]],
            // XY: [
            //     [ new Mesh( new PlaneGeometry( 0.295, 0.295 ), matYellowTransparent.clone() ), [ 0.15, 0.15, 0 ] ],
            //     [ new Line( lineGeometry, matLineYellow ), [ 0.18, 0.3, 0 ], null, [ 0.125, 1, 1 ] ],
            //     [ new Line( lineGeometry, matLineYellow ), [ 0.3, 0.18, 0 ], [ 0, 0, Math.PI / 2 ], [ 0.125, 1, 1 ] ]
            // ],
            // YZ: [
            //     [ new Mesh( new PlaneGeometry( 0.295, 0.295 ), matCyanTransparent.clone() ), [ 0, 0.15, 0.15 ], [ 0, Math.PI / 2, 0 ] ],
            //     [ new Line( lineGeometry, matLineCyan ), [ 0, 0.18, 0.3 ], [ 0, 0, Math.PI / 2 ], [ 0.125, 1, 1 ] ],
            //     [ new Line( lineGeometry, matLineCyan ), [ 0, 0.3, 0.18 ], [ 0, - Math.PI / 2, 0 ], [ 0.125, 1, 1 ] ]
            // ],
            // XZ: [
            //     [ new Mesh( new PlaneGeometry( 0.295, 0.295 ), matMagentaTransparent.clone() ), [ 0.15, 0, 0.15 ], [ - Math.PI / 2, 0, 0 ] ],
            //     [ new Line( lineGeometry, matLineMagenta ), [ 0.18, 0, 0.3 ], null, [ 0.125, 1, 1 ] ],
            //     [ new Line( lineGeometry, matLineMagenta ), [ 0.3, 0, 0.18 ], [ 0, - Math.PI / 2, 0 ], [ 0.125, 1, 1 ] ]
            // ]
        };

        let pickerTranslate = {
            X: [
                [
                    new Mesh(new CylinderGeometry(0.2, 0, 1, 4, 1, false), matInvisible),
                    [0.6, 0, 0],
                    [0, 0, -Math.PI / 2],
                ],
            ],
            Y: [[new Mesh(new CylinderGeometry(0.2, 0, 1, 4, 1, false), matInvisible), [0, 0.6, 0]]],
            Z: [
                [
                    new Mesh(new CylinderGeometry(0.2, 0, 1, 4, 1, false), matInvisible),
                    [0, 0, 0.6],
                    [Math.PI / 2, 0, 0],
                ],
            ],
            XYZ: [[new Mesh(new OctahedronGeometry(0.2, 0), matInvisible)]],
            // XY: [
            //     [ new Mesh( new PlaneGeometry( 0.4, 0.4 ), matInvisible ), [ 0.2, 0.2, 0 ] ]
            // ],
            // YZ: [
            //     [ new Mesh( new PlaneGeometry( 0.4, 0.4 ), matInvisible ), [ 0, 0.2, 0.2 ], [ 0, Math.PI / 2, 0 ] ]
            // ],
            // XZ: [
            //     [ new Mesh( new PlaneGeometry( 0.4, 0.4 ), matInvisible ), [ 0.2, 0, 0.2 ], [ - Math.PI / 2, 0, 0 ] ]
            // ]
        };

        let helperTranslate = {
            START: [[new Mesh(new OctahedronGeometry(0.01, 2), matHelper), null, null, null, 'helper']],
            END: [[new Mesh(new OctahedronGeometry(0.01, 2), matHelper), null, null, null, 'helper']],
            DELTA: [[new Line(TranslateHelperGeometry(), matHelper), null, null, null, 'helper']],
            X: [[new Line(lineGeometry, matHelper.clone()), [-1e3, 0, 0], null, [1e6, 1, 1], 'helper']],
            Y: [[new Line(lineGeometry, matHelper.clone()), [0, -1e3, 0], [0, 0, Math.PI / 2], [1e6, 1, 1], 'helper']],
            Z: [[new Line(lineGeometry, matHelper.clone()), [0, 0, -1e3], [0, -Math.PI / 2, 0], [1e6, 1, 1], 'helper']],
        };

        let gizmoRotate = {
            X: [
                [new Line(CircleGeometry(1, 0.5), matLineRed)],
                [new Mesh(new OctahedronGeometry(0.04, 0), matRed), [0, 0, 0.99], null, [1, 3, 1]],
            ],
            Y: [
                [new Line(CircleGeometry(1, 0.5), matLineGreen), null, [0, 0, -Math.PI / 2]],
                [new Mesh(new OctahedronGeometry(0.04, 0), matGreen), [0, 0, 0.99], null, [3, 1, 1]],
            ],
            Z: [
                [new Line(CircleGeometry(1, 0.5), matLineBlue), null, [0, Math.PI / 2, 0]],
                [new Mesh(new OctahedronGeometry(0.04, 0), matBlue), [0.99, 0, 0], null, [1, 3, 1]],
            ],
            E: [
                [new Line(CircleGeometry(1.25, 1), matLineYellowTransparent), null, [0, Math.PI / 2, 0]],
                [
                    new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false), matLineYellowTransparent),
                    [1.17, 0, 0],
                    [0, 0, -Math.PI / 2],
                    [1, 1, 0.001],
                ],
                [
                    new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false), matLineYellowTransparent),
                    [-1.17, 0, 0],
                    [0, 0, Math.PI / 2],
                    [1, 1, 0.001],
                ],
                [
                    new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false), matLineYellowTransparent),
                    [0, -1.17, 0],
                    [Math.PI, 0, 0],
                    [1, 1, 0.001],
                ],
                [
                    new Mesh(new CylinderGeometry(0.03, 0, 0.15, 4, 1, false), matLineYellowTransparent),
                    [0, 1.17, 0],
                    [0, 0, 0],
                    [1, 1, 0.001],
                ],
            ],
            XYZE: [[new Line(CircleGeometry(1, 1), matLineGray), null, [0, Math.PI / 2, 0]]],
        };

        let helperRotate = {
            AXIS: [[new Line(lineGeometry, matHelper.clone()), [-1e3, 0, 0], null, [1e6, 1, 1], 'helper']],
        };

        let pickerRotate = {
            X: [
                [
                    new Mesh(new TorusGeometry(1, 0.1, 4, 24), matInvisible),
                    [0, 0, 0],
                    [0, -Math.PI / 2, -Math.PI / 2],
                ],
            ],
            Y: [[new Mesh(new TorusGeometry(1, 0.1, 4, 24), matInvisible), [0, 0, 0], [Math.PI / 2, 0, 0]]],
            Z: [[new Mesh(new TorusGeometry(1, 0.1, 4, 24), matInvisible), [0, 0, 0], [0, 0, -Math.PI / 2]]],
            E: [[new Mesh(new TorusGeometry(1.25, 0.1, 2, 24), matInvisible)]],
            XYZE: [[new Mesh(new SphereGeometry(0.7, 10, 8), matInvisible)]],
        };

        let gizmoScale = {
            X: [
                [new Mesh(scaleHandleGeometry, matRed), [0.8, 0, 0], [0, 0, -Math.PI / 2]],
                [new Line(lineGeometry, matLineRed), null, null, [0.8, 1, 1]],
            ],
            Y: [
                [new Mesh(scaleHandleGeometry, matGreen), [0, 0.8, 0]],
                [new Line(lineGeometry, matLineGreen), null, [0, 0, Math.PI / 2], [0.8, 1, 1]],
            ],
            Z: [
                [new Mesh(scaleHandleGeometry, matBlue), [0, 0, 0.8], [Math.PI / 2, 0, 0]],
                [new Line(lineGeometry, matLineBlue), null, [0, -Math.PI / 2, 0], [0.8, 1, 1]],
            ],
            XY: [
                [new Mesh(scaleHandleGeometry, matYellowTransparent), [0.85, 0.85, 0], null, [2, 2, 0.2]],
                [new Line(lineGeometry, matLineYellow), [0.855, 0.98, 0], null, [0.125, 1, 1]],
                [new Line(lineGeometry, matLineYellow), [0.98, 0.855, 0], [0, 0, Math.PI / 2], [0.125, 1, 1]],
            ],
            YZ: [
                [new Mesh(scaleHandleGeometry, matCyanTransparent), [0, 0.85, 0.85], null, [0.2, 2, 2]],
                [new Line(lineGeometry, matLineCyan), [0, 0.855, 0.98], [0, 0, Math.PI / 2], [0.125, 1, 1]],
                [new Line(lineGeometry, matLineCyan), [0, 0.98, 0.855], [0, -Math.PI / 2, 0], [0.125, 1, 1]],
            ],
            XZ: [
                [new Mesh(scaleHandleGeometry, matMagentaTransparent), [0.85, 0, 0.85], null, [2, 0.2, 2]],
                [new Line(lineGeometry, matLineMagenta), [0.855, 0, 0.98], null, [0.125, 1, 1]],
                [new Line(lineGeometry, matLineMagenta), [0.98, 0, 0.855], [0, -Math.PI / 2, 0], [0.125, 1, 1]],
            ],
            XYZX: [[new Mesh(new BoxGeometry(0.125, 0.125, 0.125), matWhiteTransparent.clone()), [1.1, 0, 0]]],
            XYZY: [[new Mesh(new BoxGeometry(0.125, 0.125, 0.125), matWhiteTransparent.clone()), [0, 1.1, 0]]],
            XYZZ: [[new Mesh(new BoxGeometry(0.125, 0.125, 0.125), matWhiteTransparent.clone()), [0, 0, 1.1]]],
        };

        let pickerScale = {
            X: [
                [
                    new Mesh(new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), matInvisible),
                    [0.5, 0, 0],
                    [0, 0, -Math.PI / 2],
                ],
            ],
            Y: [[new Mesh(new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), matInvisible), [0, 0.5, 0]]],
            Z: [
                [
                    new Mesh(new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), matInvisible),
                    [0, 0, 0.5],
                    [Math.PI / 2, 0, 0],
                ],
            ],
            XY: [[new Mesh(scaleHandleGeometry, matInvisible), [0.85, 0.85, 0], null, [3, 3, 0.2]]],
            YZ: [[new Mesh(scaleHandleGeometry, matInvisible), [0, 0.85, 0.85], null, [0.2, 3, 3]]],
            XZ: [[new Mesh(scaleHandleGeometry, matInvisible), [0.85, 0, 0.85], null, [3, 0.2, 3]]],
            XYZX: [[new Mesh(new BoxGeometry(0.2, 0.2, 0.2), matInvisible), [1.1, 0, 0]]],
            XYZY: [[new Mesh(new BoxGeometry(0.2, 0.2, 0.2), matInvisible), [0, 1.1, 0]]],
            XYZZ: [[new Mesh(new BoxGeometry(0.2, 0.2, 0.2), matInvisible), [0, 0, 1.1]]],
        };

        let helperScale = {
            X: [[new Line(lineGeometry, matHelper.clone()), [-1e3, 0, 0], null, [1e6, 1, 1], 'helper']],
            Y: [[new Line(lineGeometry, matHelper.clone()), [0, -1e3, 0], [0, 0, Math.PI / 2], [1e6, 1, 1], 'helper']],
            Z: [[new Line(lineGeometry, matHelper.clone()), [0, 0, -1e3], [0, -Math.PI / 2, 0], [1e6, 1, 1], 'helper']],
        };

        // Creates an Object3D with gizmos described in custom hierarchy definition.

        let setupGizmo = function (gizmoMap) {
            let gizmo = new Object3D();

            for (let name in gizmoMap) {
                for (let i = gizmoMap[name].length; i--; ) {
                    let object = gizmoMap[name][i][0].clone();
                    let position = gizmoMap[name][i][1];
                    let rotation = gizmoMap[name][i][2];
                    let scale = gizmoMap[name][i][3];
                    let tag = gizmoMap[name][i][4];

                    // name and tag properties are essential for picking and updating logic.
                    object.name = name;
                    object.tag = tag;

                    if (position) {
                        object.position.set(position[0], position[1], position[2]);
                    }

                    if (rotation) {
                        object.rotation.set(rotation[0], rotation[1], rotation[2]);
                    }

                    if (scale) {
                        object.scale.set(scale[0], scale[1], scale[2]);
                    }

                    object.updateMatrix();

                    let tempGeometry = object.geometry.clone();
                    tempGeometry.applyMatrix4(object.matrix);
                    object.geometry = tempGeometry;
                    object.renderOrder = Infinity;

                    object.position.set(0, 0, 0);
                    object.rotation.set(0, 0, 0);
                    object.scale.set(1, 1, 1);

                    gizmo.add(object);
                }
            }

            return gizmo;
        };

        // Gizmo creation
        this.gizmo = {};
        this.picker = {};
        this.helper = {};

        this.add((this.gizmo['translate'] = setupGizmo(gizmoTranslate)));
        this.add((this.gizmo['rotate'] = setupGizmo(gizmoRotate)));
        this.add((this.gizmo['scale'] = setupGizmo(gizmoScale)));
        this.add((this.picker['translate'] = setupGizmo(pickerTranslate)));
        this.add((this.picker['rotate'] = setupGizmo(pickerRotate)));
        this.add((this.picker['scale'] = setupGizmo(pickerScale)));
        this.add((this.helper['translate'] = setupGizmo(helperTranslate)));
        this.add((this.helper['rotate'] = setupGizmo(helperRotate)));
        this.add((this.helper['scale'] = setupGizmo(helperScale)));

        // Pickers should be hidden always
        this.picker['translate'].visible = false;
        this.picker['rotate'].visible = false;
        this.picker['scale'].visible = false;
    }

    showGizmo(enable: boolean) {
        this.gizmo['translate'].visible = enable;
        this.gizmo['rotate'].visible = enable;
        this.gizmo['scale'].visible = enable;

        this.helper['translate'].visible = enable;
        this.helper['rotate'].visible = enable;
        this.helper['scale'].visible = enable;
    }

    showAverageScaleOnly() {
        const axis = ['X', 'Y', 'Z', 'XY', 'YZ', 'XZ'];
        this.gizmo['scale'].children.forEach((element) => {
            const { name, type } = element;
            if (axis.includes(name)) {
                element.visible = false;
            }
        });
    }

    updateMatrixWorld(): void {
        // Reusable utility letiables

        let tempVector = new Vector3(0, 0, 0);
        let tempEuler = new Euler();
        let alignVector = new Vector3(0, 1, 0);
        let zeroVector = new Vector3(0, 0, 0);
        let lookAtMatrix = new Matrix4();
        let tempQuaternion = new Quaternion();
        let tempQuaternion2 = new Quaternion();
        let identityQuaternion = new Quaternion();

        let unitX = new Vector3(1, 0, 0);
        let unitY = new Vector3(0, 1, 0);
        let unitZ = new Vector3(0, 0, 1);

        var space = this.transformControl.space;

        if (this.transformControl.mode === 'scale') space = 'local'; // scale always oriented to local rotation

        var quaternion = space === 'local' ? this.transformControl.worldQuaternion : identityQuaternion;

        // Show only gizmos for current transform mode

        this.gizmo['translate'].visible =
            this.transformControl.mode === 'translate' && this.transformControl.showHelper;
        this.gizmo['rotate'].visible = this.transformControl.mode === 'rotate' && this.transformControl.showHelper;
        this.gizmo['scale'].visible = this.transformControl.mode === 'scale' && this.transformControl.showHelper;

        this.helper['translate'].visible =
            this.transformControl.mode === 'translate' && this.transformControl.showHelper;
        this.helper['rotate'].visible = this.transformControl.mode === 'rotate' && this.transformControl.showHelper;
        this.helper['scale'].visible = this.transformControl.mode === 'scale' && this.transformControl.showHelper;

        let handles = [];
        handles = handles.concat(this.picker[this.transformControl.mode].children);
        handles = handles.concat(this.gizmo[this.transformControl.mode].children);
        handles = handles.concat(this.helper[this.transformControl.mode].children);

        for (let i = 0; i < handles.length; i++) {
            let handle = handles[i];

            // hide aligned to camera

            handle.visible = true;
            handle.rotation.set(0, 0, 0);
            let factor = 1;
            handle.position.copy(this.transformControl.worldPosition);
            if (this.transformControl.camera instanceof PerspectiveCamera)
                factor =
                    this.transformControl.worldPosition.distanceTo(this.transformControl.cameraPosition) *
                    Math.min(
                        (1.9 * Math.tan((Math.PI * this.transformControl.camera.fov) / 360)) /
                            this.transformControl.camera.zoom,
                        7,
                    );
            if (this.transformControl.camera instanceof OrthographicCamera) {
                factor = this.transformControl.worldPosition.distanceTo(this.transformControl.cameraPosition);
            }

            handle.scale.set(1, 1, 1).multiplyScalar((factor * this.transformControl.size) / 7);

            // TODO: simplify helpers and consider decoupling from gizmo

            if (handle.tag === 'helper') {
                handle.visible = false;

                if (handle.name === 'AXIS') {
                    handle.position.copy(this.transformControl.worldPositionStart);
                    handle.visible = !!this.transformControl.axis;

                    if (this.transformControl.axis === 'X') {
                        tempQuaternion.setFromEuler(tempEuler.set(0, 0, 0));
                        handle.quaternion.copy(quaternion).multiply(tempQuaternion);

                        if (
                            Math.abs(
                                alignVector.copy(unitX).applyQuaternion(quaternion).dot(this.transformControl.eye),
                            ) > 0.9
                        ) {
                            handle.visible = false;
                        }
                    }

                    if (this.transformControl.axis === 'Y') {
                        tempQuaternion.setFromEuler(tempEuler.set(0, 0, Math.PI / 2));
                        handle.quaternion.copy(quaternion).multiply(tempQuaternion);

                        if (
                            Math.abs(
                                alignVector.copy(unitY).applyQuaternion(quaternion).dot(this.transformControl.eye),
                            ) > 0.9
                        ) {
                            handle.visible = false;
                        }
                    }

                    if (this.transformControl.axis === 'Z') {
                        tempQuaternion.setFromEuler(tempEuler.set(0, Math.PI / 2, 0));
                        handle.quaternion.copy(quaternion).multiply(tempQuaternion);

                        if (
                            Math.abs(
                                alignVector.copy(unitZ).applyQuaternion(quaternion).dot(this.transformControl.eye),
                            ) > 0.9
                        ) {
                            handle.visible = false;
                        }
                    }

                    if (this.transformControl.axis === 'XYZE') {
                        tempQuaternion.setFromEuler(tempEuler.set(0, Math.PI / 2, 0));
                        alignVector.copy(this.transformControl.rotationAxis);
                        handle.quaternion.setFromRotationMatrix(lookAtMatrix.lookAt(zeroVector, alignVector, unitY));
                        handle.quaternion.multiply(tempQuaternion);
                        handle.visible = this.transformControl.dragging;
                    }

                    if (this.transformControl.axis === 'E') {
                        handle.visible = false;
                    }
                } else if (handle.name === 'START') {
                    handle.position.copy(this.transformControl.worldPositionStart);
                    handle.visible = this.transformControl.dragging;
                } else if (handle.name === 'END') {
                    handle.position.copy(this.transformControl.worldPosition);
                    handle.visible = this.transformControl.dragging;
                } else if (handle.name === 'DELTA') {
                    handle.position.copy(this.transformControl.worldPositionStart);
                    handle.quaternion.copy(this.transformControl.worldQuaternionStart);
                    tempVector
                        .set(1e-10, 1e-10, 1e-10)
                        .add(this.transformControl.worldPositionStart)
                        .sub(this.transformControl.worldPosition)
                        .multiplyScalar(-1);
                    tempVector.applyQuaternion(this.transformControl.worldQuaternionStart.clone().invert());
                    handle.scale.copy(tempVector);
                    handle.visible = this.transformControl.dragging;
                } else {
                    handle.quaternion.copy(quaternion);

                    if (this.transformControl.dragging) {
                        handle.position.copy(this.transformControl.worldPositionStart);
                    } else {
                        handle.position.copy(this.transformControl.worldPosition);
                    }

                    if (this.transformControl.axis) {
                        handle.visible = this.transformControl.axis.search(handle.name) !== -1;
                    }
                }

                // If updating helper, skip rest of the loop
                continue;
            }

            // Align handles to current local or world rotation

            handle.quaternion.copy(quaternion);

            if (this.transformControl.mode === 'translate' || this.transformControl.mode === 'scale') {
                // Hide translate and scale axis facing the camera

                var AXIS_HIDE_TRESHOLD = 0.99;
                var PLANE_HIDE_TRESHOLD = 0.2;
                var AXIS_FLIP_TRESHOLD = 0.0;

                if (handle.name === 'X' || handle.name === 'XYZX') {
                    if (
                        Math.abs(alignVector.copy(unitX).applyQuaternion(quaternion).dot(this.transformControl.eye)) >
                        AXIS_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }

                if (handle.name === 'Y' || handle.name === 'XYZY') {
                    if (
                        Math.abs(alignVector.copy(unitY).applyQuaternion(quaternion).dot(this.transformControl.eye)) >
                        AXIS_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }

                if (handle.name === 'Z' || handle.name === 'XYZZ') {
                    if (
                        Math.abs(alignVector.copy(unitZ).applyQuaternion(quaternion).dot(this.transformControl.eye)) >
                        AXIS_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }

                if (handle.name === 'XY') {
                    if (
                        Math.abs(alignVector.copy(unitZ).applyQuaternion(quaternion).dot(this.transformControl.eye)) <
                        PLANE_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }

                if (handle.name === 'YZ') {
                    if (
                        Math.abs(alignVector.copy(unitX).applyQuaternion(quaternion).dot(this.transformControl.eye)) <
                        PLANE_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }

                if (handle.name === 'XZ') {
                    if (
                        Math.abs(alignVector.copy(unitY).applyQuaternion(quaternion).dot(this.transformControl.eye)) <
                        PLANE_HIDE_TRESHOLD
                    ) {
                        handle.scale.set(1e-10, 1e-10, 1e-10);
                        handle.visible = false;
                    }
                }

                // Flip translate and scale axis ocluded behind another axis

                if (handle.name.search('X') !== -1) {
                    if (
                        alignVector.copy(unitX).applyQuaternion(quaternion).dot(this.transformControl.eye) <
                        AXIS_FLIP_TRESHOLD
                    ) {
                        if (handle.tag === 'fwd') {
                            handle.visible = false;
                        } else {
                            handle.scale.x *= -1;
                        }
                    } else if (handle.tag === 'bwd') {
                        handle.visible = false;
                    }
                }

                if (handle.name.search('Y') !== -1) {
                    if (
                        alignVector.copy(unitY).applyQuaternion(quaternion).dot(this.transformControl.eye) <
                        AXIS_FLIP_TRESHOLD
                    ) {
                        if (handle.tag === 'fwd') {
                            handle.visible = false;
                        } else {
                            handle.scale.y *= -1;
                        }
                    } else if (handle.tag === 'bwd') {
                        handle.visible = false;
                    }
                }

                if (handle.name.search('Z') !== -1) {
                    if (
                        alignVector.copy(unitZ).applyQuaternion(quaternion).dot(this.transformControl.eye) <
                        AXIS_FLIP_TRESHOLD
                    ) {
                        if (handle.tag === 'fwd') {
                            handle.visible = false;
                        } else {
                            handle.scale.z *= -1;
                        }
                    } else if (handle.tag === 'bwd') {
                        handle.visible = false;
                    }
                }
            } else if (this.transformControl.mode === 'rotate') {
                // Align handles to current local or world rotation

                tempQuaternion2.copy(quaternion);
                alignVector.copy(this.transformControl.eye).applyQuaternion(tempQuaternion.copy(quaternion).invert());

                if (handle.name.search('E') !== -1) {
                    handle.quaternion.setFromRotationMatrix(
                        lookAtMatrix.lookAt(this.transformControl.eye, zeroVector, unitY),
                    );
                }

                if (handle.name === 'X') {
                    tempQuaternion.setFromAxisAngle(unitX, Math.atan2(-alignVector.y, alignVector.z));
                    tempQuaternion.multiplyQuaternions(tempQuaternion2, tempQuaternion);
                    handle.quaternion.copy(tempQuaternion);
                }

                if (handle.name === 'Y') {
                    tempQuaternion.setFromAxisAngle(unitY, Math.atan2(alignVector.x, alignVector.z));
                    tempQuaternion.multiplyQuaternions(tempQuaternion2, tempQuaternion);
                    handle.quaternion.copy(tempQuaternion);
                }

                if (handle.name === 'Z') {
                    tempQuaternion.setFromAxisAngle(unitZ, Math.atan2(alignVector.y, alignVector.x));
                    tempQuaternion.multiplyQuaternions(tempQuaternion2, tempQuaternion);
                    handle.quaternion.copy(tempQuaternion);
                }
            }

            // Hide disabled axes
            handle.visible = handle.visible && (handle.name.indexOf('X') === -1 || this.transformControl.showX);
            handle.visible = handle.visible && (handle.name.indexOf('Y') === -1 || this.transformControl.showY);
            handle.visible = handle.visible && (handle.name.indexOf('Z') === -1 || this.transformControl.showZ);
            handle.visible = handle.visible && (handle.name.indexOf('XYZ') === -1 || this.transformControl.showXYZ);
            handle.visible =
                handle.visible &&
                (handle.name.indexOf('E') === -1 ||
                    (this.transformControl.showX && this.transformControl.showY && this.transformControl.showZ));
            if (this.transformControl.proportionalScale === true) {
                this.showAverageScaleOnly();
            }
            // highlight selected axis

            handle.material._opacity = handle.material._opacity || handle.material.opacity;
            handle.material._color = handle.material._color || handle.material.color.clone();

            handle.material.color.copy(handle.material._color);
            handle.material.opacity = handle.material._opacity;

            if (!this.transformControl.enabled) {
                handle.material.opacity *= 0.5;
                handle.material.color.lerp(new Color(1, 1, 1), 0.5);
            } else if (this.transformControl.axis) {
                if (handle.name === this.transformControl.axis) {
                    handle.material.opacity = 1.0;
                    handle.material.color.lerp(new Color(1, 1, 1), 0.5);
                } else if (
                    this.transformControl.axis.split('').some(function (a) {
                        return handle.name === a;
                    })
                ) {
                    handle.material.opacity = 1.0;
                    handle.material.color.lerp(new Color(1, 1, 1), 0.5);
                } else {
                    handle.material.opacity *= 0.25;
                    handle.material.color.lerp(new Color(1, 1, 1), 0.5);
                }
            }
        }

        Object3D.prototype.updateMatrixWorld.call(this);
    }
}
