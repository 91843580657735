import * as THREE from 'three';
import Model from './Model';
import { RenderFrame } from 'core/types';

const scene = new THREE.Scene();
const camera = new THREE.OrthographicCamera(-1, 1, 1, -1);
scene.add(new THREE.AmbientLight(0xffffff, 0.3));
scene.add(new THREE.HemisphereLight());
scene.add(new THREE.DirectionalLight(0xffffff, 0.2));


export default class ThumbnailModel extends Model {
    static renderer: THREE.WebGLRenderer;
    public height = 1;
    public thumbnail: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshStandardMaterial>;
    public thumbnails: THREE.WebGLRenderTarget[];
    public currentThumbnail: THREE.WebGLRenderTarget;
    public setMesh(obj) {
        super.setMesh(obj);
        this.height = this.size.y
        this.captureThumbnail();
    }
    private captureThumbnail(height = this.height) {
        const parent = this.glb.parent;
        const size = new THREE.Vector3();
        const box = new THREE.Box3().setFromObject(this.glb);
        box.getSize(size);
        const toScale = height / size.y;
        const width = Math.max(size.x, size.z) * toScale;
        camera.left = -width / 2;
        camera.right = width / 2;
        camera.top = height / 2;
        camera.bottom = -height / 2;
        camera.far = width * 5;
        camera.near = camera.far * 0.0001;
        camera.updateProjectionMatrix();

        const cameraPos = [
            { x: -width, y: height / 2, z: width }, // leftFront
            { x: -width, y: height / 2, z: -width }, // leftBack
            { x: width, y: height / 2, z: -width }, // rightBack
            { x: width, y: height / 2, z: width }, // rightFront
        ];
        scene.add(this.glb);
        this.thumbnails = [];
        cameraPos.forEach((pos) => {
            camera.position.set(pos.x, pos.y, pos.z);
            camera.lookAt(0, height / 2, 0);
            const renderTarget = new THREE.WebGLRenderTarget(512, 512);
            ThumbnailModel.renderer.setRenderTarget(renderTarget);
            ThumbnailModel.renderer.render(scene, camera);
            ThumbnailModel.renderer.setRenderTarget(null);
            this.thumbnails.push(renderTarget);
        });

        this.thumbnail = new THREE.Mesh(
            new THREE.PlaneGeometry(width, height),
            new THREE.MeshStandardMaterial({ transparent: true, side: THREE.DoubleSide, alphaTest: 0.1 }),
        );
        this.thumbnail.position.copy(this.glb.position);
        this.thumbnail.position.y += height / 2;
        this.object.add(this.thumbnail);

        scene.remove(this.glb);
        if (parent) parent.add(this.glb);
    }

    public update(renderFrame: RenderFrame) {
        super.update(renderFrame);
        this.thumbnailLookAt(renderFrame.cameraPosition)
    }

    public destroy() {
        this.thumbnails?.forEach((renderTarget) => {
            renderTarget.dispose();
        });
        super.destroy();
    }

    public thumbnailLookAt(pos: THREE.Vector3) {
        // here do not super.lookAt because we only want the namecard to face to camera. Maybe there's better apporach
        if (this.thumbnails?.length !== 4) return
        const n = new THREE.Vector3().subVectors(pos, this.object.position).normalize();
        const dir = new THREE.Vector3();
        this.object.getWorldDirection(dir);
        n.y = dir.y = 0;
        const isLeft = new THREE.Vector3().crossVectors(n, dir).y > 0;
        const isFront = n.dot(dir) > 0;
        const thumbnail = isLeft
            ? isFront
                ? this.thumbnails[0]
                : this.thumbnails[1]
            : isFront
            ? this.thumbnails[3]
            : this.thumbnails[2];

        if (this.currentThumbnail !== thumbnail) {
            this.thumbnail.material.map = thumbnail.texture;
            this.thumbnail.material.needsUpdate = true;
            this.currentThumbnail = thumbnail;
        }
        this.thumbnail.lookAt(pos);
    }
}
