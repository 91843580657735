export default `
varying vec3 pos;

void main() {
    vec3 normal = normalize(cross(dFdx(pos), dFdy(pos)));

    float z = gl_FragCoord.z;  // depth value [0,1]
    
    gl_FragColor = vec4(normal*0.5+0.5, z);
}
`;
