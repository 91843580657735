export default `
varying vec3 modelPositionEquirect;
varying mediump vec3 modelPositionAltas;

void main()	
{
    vec4 worldpos = modelMatrix * vec4( position, 1.0 ) ;

    modelPositionEquirect = vec3(worldpos.x,worldpos.y,-worldpos.z);
    modelPositionAltas = vec3(position.x, -position.y, -position.z);

    gl_Position =  projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;
