// Video ID vimeo.com/[Video ID]
// Channels vimeo.com/channels/[Channel]/[Video ID]
// Groups vimeo.com/groups/[Group]/[Video ID]
// Player player.vimeo.com/video/[Video ID]
export const VIMEO_URL_PATTERN =
    /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

// Support parsing below urls
// https://www.youtube.com/watch?v=072tU1tamd0
// https://youtu.be/072tU1tamd0
// https://www.youtube.com/embed/072tU1tamd0
// https://www.youtube.com/v/072tU1tamd0
// https://www.youtube-nocookie.com/embed/072tU1tamd0
export const YOUTUBE_URL_PATTERN =
    /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/i;
