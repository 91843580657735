import React from 'react';
import { PreviousStatus } from 'core/three/TransfromControls/TransformControls';

export interface ThreeContextType {
    actions: {
        save: (x?: string) => void;
        goTop: () => void;
        goDown: () => void;
        createObject: (data) => void;
        deleteObject?: () => void;
        undo: () => void;
        redo: () => void;
        getObject: (id: string) => any;
        getObjectByUndoRedoId: (undoRedoId: string) => any;
        onObjectTransformUpdate: (pre: PreviousStatus, current: PreviousStatus, undoRedoId: string) => void;
    };
    setActions: (value: object) => void;
    property?: {
        value: object;
        update: (value: object) => void;
    };
    setProperty?: (value: object) => void;
}

const ThreeContext = React.createContext<ThreeContextType>({
    actions: {
        save: () => {},
        goTop: () => {},
        goDown: () => {},
        createObject: (data) => {},
        deleteObject: () => {},
        undo: () => {},
        redo: () => {},
        getObject: (id) => {},
        getObjectByUndoRedoId: (undoRedoId) => {},
        onObjectTransformUpdate: (pre, current, undoRedoId) => {},
    },
    setActions: () => {},
    property: {
        value: {},
        update: (value: object) => {},
    },
    setProperty: () => {},
});

export default ThreeContext;
