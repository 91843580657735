import * as THREE from 'three';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { CubemapMapping } from 'core/types/cubemap';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { EquippedPromise } from "core/utils";
import { ScriptLoader } from './scriptLoader'

const waitKtxTranscoder = new EquippedPromise();
const scriptLoader = new ScriptLoader();
scriptLoader.loadScript('ktx2/msc_basis_transcoder.js', {
    onLoad: () => {
        waitKtxTranscoder.resolve();
    },
    onError: (error) => {
        console.log('There is something wrong when loading ktx2 transcoder files', error)
    }
})

const textureloader = new THREE.TextureLoader();
const objLoader = new OBJLoader();
const mtlLoader = new MTLLoader();
const dracoLoader = new DRACOLoader();
const gltfLoader = new GLTFLoader();
const cubeTextuerLoader = new THREE.CubeTextureLoader();
const ktx2Loader = new KTX2Loader();

gltfLoader.setDRACOLoader(dracoLoader);
dracoLoader.setDecoderPath('/draco/');
ktx2Loader.detectSupport(new THREE.WebGLRenderer());
ktx2Loader.setTranscoderPath('/ktx2/');
gltfLoader.setKTX2Loader(ktx2Loader);

export const loadTexture = async (url: string) => {
    const load = (url: string): Promise<THREE.Texture> => {
        return new Promise((resolve, reject) => {
            textureloader.crossOrigin = 'anonymous';
            textureloader.load(
                url,
                (texture) => {
                    texture.colorSpace = THREE.SRGBColorSpace
                    resolve(texture);
                },
                () => { },
                reject,
            );
        });
    };

    return load(url).catch(() => {
        /**
         * Browser might cache response (like sidemenu image) without cors headers.
         * Since we set crossOrigin to anonymous (to prevent tainted canvas), cors header is a must.
         * Therefore, we should retry the requset with a searchParam to prevent cache when cache occurs.
         */
        const urlObj = new URL(url);
        urlObj.searchParams.append('t', Date.now().toString());
        return load(urlObj.href);
    });
};

export const loadModel = async (url: string, onProgress?: any): Promise<GLTF> => {
    await waitKtxTranscoder
    const isObjModel = url.endsWith('obj');
    if (isObjModel) {
        const path = url.split('/').slice(0, -1).join('/') + '/';
        const objName = url.split('/').pop();
        const fileName = objName.split('.')[0];
        const mtlName = fileName + '.mtl';
        mtlLoader.setPath(path);
        const materials = await mtlLoader.loadAsync(mtlName);
        materials.preload();
        objLoader.setMaterials(materials);
        const model = await objLoader.loadAsync(url, onProgress ? onProgress : null);
        const group = new THREE.Group();
        group.add(model);
        const gltf = { scene: group, scenes: [group] } as GLTF;
        return gltf;
    } else {
        const gltf: GLTF = await gltfLoader.loadAsync(url, onProgress ? onProgress : null);
        return gltf;
    }
};

export const loadCubeMap = async (urls: string[]): Promise<THREE.CubeTexture> => {
    // nx,px,py,ny,nz,pz
    // const orderUrls = [
    //     urls[ 4 ],
    //     urls[ 2 ],
    //     urls[ 0 ],
    //     urls[ 5 ],
    //     urls[ 3 ],
    //     urls[ 1 ]
    // ];
    const orderUrls = [urls[2], urls[4], urls[0], urls[5], urls[1], urls[3]];
    if (!('createImageBitmap' in window)) {
        return new Promise((resolve) => cubeTextuerLoader.load(orderUrls, resolve));
    }
    const cubeTexture = new THREE.CubeTexture();
    cubeTexture.images = await Promise.all(
        orderUrls.map((url, index) => {
            return fetch(url)
                .then((res) => res.blob())
                .then((blob) => createImageBitmap(blob));
        }),
    );
    cubeTexture.needsUpdate = true;
    return cubeTexture;
};

export const loadStandardCubeMap = async (cubeMapUrls: CubemapMapping): Promise<THREE.CubeTexture> => {
    const orderUrls = [cubeMapUrls.px, cubeMapUrls.nx, cubeMapUrls.py, cubeMapUrls.ny, cubeMapUrls.pz, cubeMapUrls.nz];
    if (!('createImageBitmap' in window)) {
        return new Promise((resolve) => cubeTextuerLoader.load(orderUrls, resolve));
    }
    const cubeTexture = new THREE.CubeTexture();
    cubeTexture.images = await Promise.all(
        orderUrls.map((url) => {
            return fetch(url)
                .then((res) => res.blob())
                .then((blob) => createImageBitmap(blob));
        }),
    );
    cubeTexture.needsUpdate = true;
    return cubeTexture;
};
