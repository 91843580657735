import React, { useRef, useState, ContextType, FC, PropsWithChildren } from 'react';
import GroupSelection from './GroupSelection';
import PropertyMenu from 'app/components/PropertyMenu';
import ReportForm from 'app/components/ReportForm/ReportForm';
import MarkerList from 'app/components/MarkerList/MarkerList';
import TopMenu from 'app/components/TopMenu/TagEditor/TopMenu';
import SideMenu from 'app/components/SideMenu';
import ThreeCanvas from 'app/pages/TagEditor/ThreeCanvas';
import ThreeContext from 'app/pages/three-context';
import { SceneContext } from 'App';
import 'app/components/PropertyMenu.css';
import TagEditor from 'app/three/ThreeViewPage/TagEditor';
import TagEditorEffects from './TagEditorEffects';
import Tutorial from './Tutorial';
import { MarkerType, MediaStatus } from 'core/three/object/type';
import { IMedia } from 'core/types/media';
import { useContextState, useContextAction, ContextAction, ContextState } from 'hooks';
import { MediaMarker } from 'core/types/media';
import AdvancePropertyMenu from 'app/components/AdvancePropertyMenu';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import { getMediaList } from 'api/media';
import { ReactComponent as ModelIcon } from 'assets/images/icon/model.svg';
import { ReactComponent as PortalIcon } from 'assets/images/icon/portal.svg';
import { ReactComponent as NPCIcon } from 'assets/images/icon/npc.svg';
import { ReactComponent as WebsiteIcon } from 'assets/images/icon/website.svg';
import { ReactComponent as WebwallIcon } from 'assets/images/icon/webwall.svg';
import { ReactComponent as ARTagIcon } from 'assets/images/icon/arTag.svg';
import { useTranslation } from 'react-i18next';
import ISceneSlot from 'core/types/sceneSlot';

type ThreeContextType = ContextType<typeof ThreeContext>;

const ThreeContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [actions, setActionsState] = React.useState({} as any);
    const [property, setProperty] = React.useState({
        value: {},
        update: (obj: object) => {},
    });
    const setActions = React.useCallback(
        (actions: Partial<ThreeContextType>) => setActionsState((prev) => ({ ...prev, ...actions })),
        [],
    );
    const defaultValue: ThreeContextType = { actions, setActions, property, setProperty };
    const Provider = ThreeContext.Provider;
    return <Provider value={defaultValue}>{children}</Provider>;
};

export interface TagEditorContextType {
    initCamera: ContextAction<() => void>;
    selectMarker: ContextAction<(uuid: string) => void>;
    lookto: ContextAction<(pos: { x: number; y: number; z: number }) => void>;
    markersMap: ContextState<{ [markerId: string]: MediaMarker }>;
    markerUpdater: ContextAction<(obj: object) => void>;
    groupId: ContextState<string>;
    currentMarkerId: ContextState<string>;
    currentMarker: MediaMarker;
    uploading: ContextState<boolean>;
    sideMenu: ContextState<boolean>;
    currentSlotId: ContextState<string>;
}

export const TagEditorContext = React.createContext({} as unknown as TagEditorContextType);

const TagContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const Provider = TagEditorContext.Provider;
    const markersMap = useContextState<{ [markerId: string]: MediaMarker }>({});
    const initCamera = useContextAction(() => {});
    const selectMarker = useContextAction((id: string) => {});
    const lookto = useContextAction((pos) => {});
    const markerUpdater = useContextAction((obj: object) => {});
    const currentMarkerId = useContextState('');
    const groupId = useContextState('');
    const uploading = useContextState(false);
    const sideMenu = useContextState(false);
    const currentMarker = markersMap.state[currentMarkerId.state];
    const currentSlotId = useContextState('');
    const defaultValue = {
        markersMap,
        initCamera,
        currentMarkerId,
        currentMarker,
        markerUpdater,
        selectMarker,
        lookto,
        groupId,
        uploading,
        sideMenu,
        currentSlotId,
    };
    return <Provider value={defaultValue}>{children}</Provider>;
};

function TagEditorPage() {
    return (
        <ThreeContextProvider>
            <TagContextProvider>
                <Editor />
            </TagContextProvider>
        </ThreeContextProvider>
    );
}

const Editor = () => {
    const { scene, acl } = React.useContext(SceneContext);

    const [groupIndex, setGroupIndex] = useState(0);
    const [initialPos, setInitialPos] = useState(null);
    const [initialSize, setInitialSize] = useState(null);
    const [openAdvancePropertyMenu, setOpenAdvancePropertyMenu] = useState(false);

    const wrapperResize = useRef<HTMLDivElement>();
    const [three, setThree] = useState<TagEditor>(null);
    const { groupId } = React.useContext(TagEditorContext);

    const allGroupOption = {
        id: null, // pass null as query of groupId to api/vi/markers/?groupId will load all the markers of the scene
        name: 'All',
    };
    const groupOtions = [allGroupOption, ...scene.editGroups];

    let selectGroupId = groupOtions.length ? groupOtions[groupIndex]?.id : scene.groupId;

    let resizable = wrapperResize.current;
    const initial = (e: React.DragEvent<HTMLDivElement>) => {
        setInitialPos(e.clientX);
        setInitialSize(resizable.offsetWidth);
    };

    const resize = (e: React.DragEvent<HTMLDivElement>) => {
        if (e.clientX === 0) return;
        const newWidth = (e.clientX - initialPos).toString();
        resizable.style.width = `${parseInt(initialSize) - parseInt(newWidth)}px`;
    };

    React.useEffect(() => {
        groupId.setState(selectGroupId);
    }, [selectGroupId, groupId]);

    return (
        <div className="TagEditor">
            <TopMenu />
            <Tutorial />
            <div className="propertyWrapper" ref={wrapperResize}>
                <div className="wrapperResize" draggable={true} onDragStart={initial} onDrag={resize}></div>
                {groupOtions.length > 1 ? (
                    <GroupSelection onChange={setGroupIndex} currentIndex={groupIndex} groups={groupOtions} />
                ) : null}
                <MarkerList />
                <PropertyMenu openAdvancePropertyMenu={setOpenAdvancePropertyMenu} />
                <AdvancePropertyMenu open={openAdvancePropertyMenu} setOpen={setOpenAdvancePropertyMenu} />
                <ReportForm />
            </div>
            <TagEditorSideMenu />
            <TagEditorEffects three={three} scene={scene} />
            <SceneSlotLogic three={three} />
            <ThreeCanvas sceneData={scene} onInit={setThree} />
        </div>
    );
};

const TagEditorSideMenu = () => {
    const { t } = useTranslation();
    const { acl, scene } = React.useContext(SceneContext);
    const {
        sideMenu: { state: open, setState: setOpen },
        currentSlotId,
    } = React.useContext(TagEditorContext);
    const [media, setMedia] = React.useState<MarkerType | null>(null);
    const [mediaList, setMediaList] = React.useState<IMedia[]>([]);
    const enforceCreateBySlot = new URLSearchParams(window.location.search).get('enforceSlot') === 'true';

    const action = function () {
        if (enforceCreateBySlot && !currentSlotId.state) return;
        setMedia(this.type);
        setOpen(true);
    };
    const sdieMenuProps = [
        {
            tooltip: t('tooltip.textMarker'),
            icon: <TextFieldsIcon />,
            hidden: !acl.textTag,
            type: MarkerType.TEXT,
            action,
        },
        {
            tooltip: t('tooltip.tagMarker'),
            icon: <ImageIcon />,
            hidden: !acl.imageTag,
            type: MarkerType.TAG,
            action,
        },
        {
            tooltip: t('tooltip.videoMarker'),
            icon: <YouTubeIcon />,
            hidden: !acl.videoTag,
            type: MarkerType.VIDEO,
            action,
        },
        {
            tooltip: t('tooltip.websiteMarker'),
            icon: <WebsiteIcon />,
            hidden: !acl.websiteTag,
            type: MarkerType.WEBSITE,
            action,
        },
        {
            tooltip: t('tooltip.webwallMarker'),
            icon: <WebwallIcon />,
            hidden: !acl.webwallTag,
            type: MarkerType.WEBWALL,
            action,
        },
        {
            tooltip: t('tooltip.npcMarker'),
            icon: <NPCIcon />,
            hidden: !acl.npcTag,
            type: MarkerType.NPC,
            action,
        },
        {
            tooltip: t('tooltip.portalMarker'),
            icon: <PortalIcon />,
            hidden: !(scene.permission === 'admin' && acl.portalTag),
            type: MarkerType.PORTAL,
            action,
        },
        {
            tooltip: t('tooltip.modelMarker'),
            icon: <ModelIcon />,
            hidden: !acl.modelTag,
            type: MarkerType.MODEL,
            action,
        },
        {
            tooltip: t('tooltip.musicMarker'),
            icon: <MusicNoteIcon />,
            hidden: !acl.audioTag,
            type: MarkerType.MUSIC,
            action,
        },
        {
            tooltip: t('media.ARTAG'),
            icon: <ARTagIcon />,
            hidden: !acl.arTag,
            type: MarkerType.ARTAG,
            action,
        },
    ];

    React.useEffect(() => {
        setMediaList([]);
        getMediaList(
            media || sdieMenuProps.find((item) => !item.hidden).type /** the first avalible type of media */,
        ).then(setMediaList);
    }, [media]);

    return (
        <SideMenu
            open={open}
            icons={sdieMenuProps}
            media={mediaList}
            onRefresh={() => getMediaList(media).then(setMediaList)}
            onClose={() => {
                setOpen(false);
                currentSlotId.setState('');
            }}
        />
    );
};

const SceneSlotLogic = ({ three }: { three: TagEditor }) => {
    const { scene } = React.useContext(SceneContext);
    const { markersMap } = React.useContext(TagEditorContext);

    React.useEffect(() => {
        if (three == null) return;
        const slotsData = (scene.sceneSlots || []) as ISceneSlot[];
        const usedSlots = Object.values(markersMap.state)
            .filter((m) => [MediaStatus.CREATED, MediaStatus.UPDATED, MediaStatus.READ].includes(m.status))
            .map((m) => m.slotId)
            .filter(Boolean);
        const displaySlots = slotsData.filter((slot) => !usedSlots.includes(slot.id));
        three.sceneSlotMgr.state = displaySlots;
    }, [three, scene, markersMap.state]);

    return null;
};

export default TagEditorPage;
