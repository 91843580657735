import imageCompression from 'browser-image-compression';
import {
    imageField,
    textField,
    videoField,
    webWallField,
    websiteField,
    tagField,
    modelField,
    musicField,
    portalField,
    arTagField,
    npcField,
} from 'constants/markerFields';
import { MarkerType } from 'core/three/object/type';

// utils, resources
import { uploadFile } from 'api/s3';

// reference https://stackoverflow.com/questions/11401897/get-the-current-domain-name-with-javascript-not-the-path-etc
export const getDomain = (url = window.location.href, subdomain = false) => {
    if (window.location.hostname === 'localhost') return 'localhost';
    const host = url.replace(/(https?:\/\/)?(www.)?/i, '');
    let result = host;
    if (!subdomain) {
        const paths = host.split('.');
        result = paths.slice(paths.length - 2).join('.');
    }

    if (result.indexOf('/') !== -1) {
        return result.split('/')[0];
    }

    return result;
};

export const getRandom = (digit: number) =>
    Math.random()
        .toString()
        .substring(2, digit + 2);

const defaultSetting = {
    maxSize: 5 * 1024 * 1024,
    regExp: /png$|PNG$|jpg$|JPG$|jpeg$|JPEG$/i,
    accept: 'image/*',
};

export const verifyFile = (file: File, config: Partial<typeof defaultSetting> = defaultSetting): string => {
    const setting = { ...defaultSetting, ...config };
    let result = '';

    if (!setting.regExp.test(file.name)) {
        result = '檔案格式不符';
    }

    if (file.size > setting.maxSize) {
        result = '超過檔案大小';
    }

    return result;
};

export const getBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const compressImage = async (file: File, option?: { maxWidthOrHeight: number }) => {
    const options = Object.assign(
        {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: file.type,
        },
        option,
    );
    const compressed = await imageCompression(file, options);

    return compressed;
};

export const compressImgAndUploadFile = async (thumbnailFile: File, path: string) => {
    const compressedFiles = await Promise.all([
        compressImage(thumbnailFile),
        compressImage(thumbnailFile, { maxWidthOrHeight: 100 }),
        compressImage(thumbnailFile, { maxWidthOrHeight: 400 }),
    ]);

    const filename = `${Date.now()}_${getRandom(7)}`;
    const standardName = `${filename}.jpg`;
    const smallName = `${filename}_small.jpg`;
    const mediumName = `${filename}_medium.jpg`;

    const urls = await Promise.all([
        uploadFile(compressedFiles[0], path, standardName),
        uploadFile(compressedFiles[1], path, smallName),
        uploadFile(compressedFiles[2], path, mediumName),
    ]);

    return urls[0];
};

export const assertIsNode = (e: EventTarget): e is Node => {
    if ('nodeType' in e) {
        return true;
    }
    return false;
};

export const getMarkerFields = (type: MarkerType) => {
    switch (type) {
        case MarkerType.IMAGE:
            return imageField;
        case MarkerType.TEXT:
            return textField;
        case MarkerType.VIDEO:
            return videoField;
        case MarkerType.WEBWALL:
            return webWallField;
        case MarkerType.TAG:
            return tagField;
        case MarkerType.MODEL:
            return modelField;
        case MarkerType.PORTAL:
            return portalField;
        case MarkerType.WEBSITE:
            return websiteField;
        case MarkerType.NPC:
            return npcField;
        case MarkerType.MUSIC:
            return musicField;
        case MarkerType.ARTAG:
            return arTagField;
        default:
            return {};
    }
};
