export default `
uniform mediump sampler2D tex;
uniform mediump vec3 color;
varying mediump vec2 vUv;
void main() {
  mediump vec3 tColor = texture2D( tex, vUv ).rgb;
  mediump float a = (length(tColor - color) - 0.4) * 7.0;

  if(a<0.3)
    discard;
  else
    gl_FragColor = vec4(tColor, 1.0);
}
`;
