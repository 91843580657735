import * as THREE from 'three';
import GreenVideoShaderMaterial from './shader/ChromaVideoShader';
import BasicVideo from '../videoPlayer/BasicVideo';

export default class GreenVideo extends BasicVideo {
    constructor(thumbnailSize?) {
        super(thumbnailSize);
    }

    private setShaderMaterial() {
        const keyColor = new THREE.Color(0x00d432);
        const shader = new GreenVideoShaderMaterial();
        shader.uniforms.tex.value = this.player;
        shader.uniforms.color.value = keyColor;
        const material = new THREE.ShaderMaterial({
            fragmentShader: shader.fragmentShader,
            vertexShader: shader.vertexShader,
            uniforms: shader.uniforms,
            transparent: true,
            side: THREE.DoubleSide,
        });
        if (this.mesh.material) (this.mesh.material as THREE.Material).dispose();
        this.mesh.material = material;
    }

    public async load(url: string) {
        await super.load(url);
        this.setShaderMaterial();
        return this.mesh;
    }

    public destroy() {
        super.destroy();
        const material = this.mesh.material as THREE.ShaderMaterial;
        if (material) {
            //dispose texture
            if (material.uniforms.tex.value) {
                material.uniforms.tex.value.dispose();
                material.uniforms.tex.value = null;
            }
        }
    }
}
