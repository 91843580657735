import { MarkerType, MediaStatus } from 'core/three/object/type';
import { VisitorAvatar } from './visitorAvatar';
import { RequiredKey } from 'core/types/utils';
export interface IMedia {
    id?: string;
    ownerId?: string;
    groupId?: string;
    slotId?: string;
    name: string;
    fileUrl: string;
    usdzUrl?: string;
    description: string;
    thumbnailUrl: string;
    type: MarkerType;
    isVertical: boolean;
    followCamera: boolean;
    linkName: string;
    linkUrl: string;
    openType: 'redirect' | 'window' | 'iframe';
    unclickable: boolean;
    searchable: boolean;
    visible: boolean;
    defaultWidth: number;
    lodDistance?: number;
    quality?: 'small' | 'medium' | 'standard';
    LODLevel?: number;
    multipleLanguage?: string;
    multiLang?: MultipleLanguage;
    ctaName?: string;
    translateEnabled?: boolean;
    scaleEnabled?: boolean;
    rotateEnabled?: boolean;
    viewSpot?: ViewSpot;
}

export interface IText extends IMedia {
    type: MarkerType.TEXT;
    fontWeight: string;
    fontStyle: string;
    textColor: string;
    alignment: string;
    label: string;
    lineSpace: number;
    fontSize: number;
    angle: number;
    curve: number;
}

export interface IVideo extends IMedia {
    type: MarkerType.VIDEO;
    autoplay: boolean;
    volume: number;
    openPlayer: boolean;
    greenVideo: boolean;
}

export interface IModel extends IMedia {
    type: MarkerType.MODEL;
    autoRotate: boolean;
    rotateControl: boolean;
    imageUrls: string[];
    price: string | null;
    videoUrl: string;
    autoPlayAnimation: boolean;
}

export interface IWebwall extends IMedia {
    type: MarkerType.WEBWALL;
    iframeWidth?: number;
    iframeHeight?: number;
}

export interface IWebsite extends IMedia {
    type: MarkerType.WEBSITE;
    showHotspot: boolean;
    showTitle: boolean;
    showConnectedLine: boolean;
    hotspotIconHeight: number;
    connectedLineLength: number;
    curve: number;
}

export interface IARTag extends IMedia {
    type: MarkerType.ARTAG;
    showTitle: boolean;
    hotspotIconHeight: number;
    disableBackground: boolean;
    curve: number;
}

export interface IGif extends IMedia {
    type: MarkerType.GIF;
}

export interface IPortal extends IMedia {
    type: MarkerType.PORTAL;
    curve: number;
}

export interface ITag extends IMedia {
    type: MarkerType.TAG;
    showHotspot: boolean;
    showTitle: boolean;
    showConnectedLine: boolean;
    hotspotIconHeight: number;
    connectedLineLength: number;
    imageUrls: string[];
    price: string | null;
    videoUrl: string;
    curve: number;
}

export interface IMusic extends IMedia {
    type: MarkerType.MUSIC;
    autoplay: boolean;
    volume: number;
    curve: number;
}

export interface MarkerAttribute {
    id: string;
    rotation: {
        x: number;
        y: number;
        z: number;
        w: number;
    };
    position: {
        x: number;
        y: number;
        z: number;
    };
    scale: {
        x: number;
        y: number;
        z: number;
    };
    editable: boolean;
    undoRedoId: string;
}

export interface BoothVisitor {
    role: 'visitor' | 'exhibitor';
    avatar: number;
    userId: string;
    name: string;
    company: string;
    hotspotId: string;
    position?: { x: number; y: number; z: number };
    fileUrl?: string;
    defaultWidth: number;
}

export interface IVisitor {
    fileUrl: string;
    name: string;
    type: VisitorAvatar;
    position?: {
        x: number;
        y: number;
        z: number;
    };
    userId: string;
    hotspotId?: string;
    otherVisitors?: IVisitor[];
    isOwner: boolean;
}

export const LOCALES = [
    'en',
    'zh-tw',
    'zh-cn',
    'fr',
    'ja',
    'ko',
    'es',
    'ru',
    'ms',
    'cs',
    'da',
    'de',
    'el',
    'it',
    'lt',
    'hu',
    'mn',
    'nl',
    'no',
    'pl',
    'pt',
    'ro',
    'fi',
    'sv',
    'vi',
    'tr',
    'uk',
    'ar',
] as const;
export interface LanguageProperty {
    name: string;
    description: string;
}

type MultipleLanguage = {
    [key in (typeof LOCALES)[number]]?: LanguageProperty;
};

export interface MultipleLanguageWithDefault extends MultipleLanguage {
    default: string;
}

export interface ViewSpot {
    hotspotId?: string;
    position?: { x: number; y: number; z: number };
    azimuthAngle?: number;
    polarAngle?: number;
    enable?: boolean;
}

interface CustomMarker extends IText, IVideo, IWebwall, IPortal, ITag, IText, IModel, IGif, IWebsite, IARTag {
    type: any;
}

export type CustomMarkerAttribute = Omit<CustomMarker, keyof IMedia>;

export type IMarker = (
    | IText
    | IVideo
    | IWebwall
    | IPortal
    | ITag
    | IText
    | IModel
    | IGif
    | IWebsite
    | IMusic
    | IARTag
) &
    MarkerAttribute;

export type MediaMarker = CustomMarker & MarkerAttribute & { status: MediaStatus; uuid: string };
