import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import LooksIcon from '@material-ui/icons/Looks';
import Tooltip from '@material-ui/core/Tooltip';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { ReactComponent as InfoCircleIcon } from 'assets/images/icon/info-circle.svg';
import './CurvePropertyBox.css';

enum UIComponetKey {
    value = 'value',
}

enum PropertyKey {
    curve = 'curve',
}

const CurveSlider = withStyles({})(Slider);

const CurvePropertyBox: React.FC = () => {
    const { t } = useTranslation();
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event, newValue?) {
            markerUpdater.call({ ...propertyData, [propertykey]: newValue ?? event.target[key] });
        };
    };

    return (
        <div className="CurvePropertyBox">
            <div className="BoxTitle">
                <span>{t(`propertyText.Curve`)}</span>
                <span>&nbsp;</span>
            </div>
            <Grid container spacing={2}>
                <Grid item>
                    <LooksIcon />
                </Grid>
                <Grid item xs>
                    <CurveSlider
                        value={propertyData.curve}
                        onChange={handleChange(PropertyKey.curve, UIComponetKey.value)}
                        aria-labelledby="continuous-slider"
                        min={-1}
                        max={1}
                        step={0.01}
                    />
                </Grid>
                <Grid item className="curveValue">
                    {propertyData.curve}
                </Grid>
            </Grid>
        </div>
    );
};

export default CurvePropertyBox;
