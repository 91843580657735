// react, redux
import React, { useState, ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// material ui , css
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

// constants
import * as MARKERS from 'constants/markers';

// utils, resources
import { verifyFile, getBase64, compressImage } from 'utils';
import { uploadFile } from 'api/s3';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import './UpLoadFilePropertyBox.css';
import { AppContext } from 'App';
import { useLatest } from 'hooks';

interface Props {
    type: string;
}

enum UIComponentKey {
    value = 'value',
}

enum PropertyKey {
    name = 'fileUrl',
}

const mediaTypeFileRegex = {
    image: /png$|jpg$|jpeg$/i,
    tag: /png$|jpg$|jpeg$|gif$/i,
    video: /mp4$/i,
    music: /mp3$/i,
    model: /glb$/i,
    booth: /glb$/i,
};

const mediaTypeMaxFileSize = {
    tag: MARKERS.TAG_FILE_MAX_SIZE,
    image: MARKERS.IMAGE_FILE_MAX_SIZE,
    video: MARKERS.VIDEO_FILE_MAX_SIZE,
    music: MARKERS.MUSIC_FILE_MAX_SIZE,
    booth: MARKERS.BOOTH_FILE_MAX_SIZE,
    model: MARKERS.MODEL_FILE_MAX_SIZE,
};

const acceptTypes = {
    tag: ['jpg', 'png', 'gif'].join(', '),
    image: ['jpg', 'png'].join(', '),
    video: 'mp4',
    music: 'mp3',
    booth: '.glb',
    model: '.glb',
};

const inputAcceptExt = {
    music: '.mp3',
};

const UpLoadFilePropertyBox: React.FunctionComponent<Props> = ({ type }: Props) => {
    const mediaType = type.toLowerCase();
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);
    const latestPropertyDataRef = useLatest(propertyData);

    const [image64Object, setImage64Object] = useState<{ [markerId: string]: string }>({}); // To seperate different marker status
    const [filenameObject, setFilenameObject] = useState<{ [markerId: string]: string }>({}); // To seperate different marker status
    const [isLoadingObject, setIsLoadingObject] = useState<{ [markerId: string]: boolean }>({}); // To seperate different marker status

    const maxSize = mediaTypeMaxFileSize[mediaType] / 1024 / 1024;
    const fileUrl = propertyData[PropertyKey.name];
    const handleChange = (propertykey: PropertyKey, key: UIComponentKey) => {
        return async function (e: ChangeEvent<HTMLInputElement>, newValue?) {
            const currentMarkerId = latestPropertyDataRef.current.id;
            setIsLoadingObject((loading) => ({ ...loading, [currentMarkerId]: true }));

            if (newValue) {
                propertyData[propertykey] = newValue;
            }

            const file = e.target.files[0];

            if (file == null) return;
            const path = `user/${auth.id}/media`;
            const config = {
                maxSize: mediaTypeMaxFileSize[mediaType],
                regExp: mediaTypeFileRegex[mediaType],
            };

            const errMsg = verifyFile(file, config);

            if (errMsg) {
                setIsLoadingObject((loadings) => ({ ...loadings, [currentMarkerId]: false }));
                alert(errMsg);
                return;
            }

            try {
                let tempMarkerData = latestPropertyDataRef.current;
                const isTagMarker = mediaType === 'tag';

                if (isTagMarker) {
                    const base64File = await getBase64(file);
                    setImage64Object((image64s) => ({ ...image64s, [currentMarkerId]: base64File }));
                }

                const fileToUpload = isTagMarker ? await compressImage(file) : file;
                const url = await uploadFile(fileToUpload, path);
                setFilenameObject((filenames) => ({ ...filenames, [currentMarkerId]: file.name }));

                setIsLoadingObject((loadings) => ({ ...loadings, [currentMarkerId]: false }));
                setImage64Object((image64s) => ({ ...image64s, [currentMarkerId]: '' }));
                if (tempMarkerData.id === latestPropertyDataRef.current.id)
                    tempMarkerData = latestPropertyDataRef.current; // prevent markId in latestPropertyDataRef isn't same as before uploading
                markerUpdater.call({ ...tempMarkerData, [propertykey]: url });
            } catch (err) {
                console.log(err);
            }
        };
    };

    const handleRemove = () => {
        setFilenameObject((filenames) => ({ ...filenames, [latestPropertyDataRef.current.id]: '' }));
        setImage64Object((image64s) => ({ ...image64s, [latestPropertyDataRef.current.id]: '' }));
        markerUpdater.call({ ...propertyData, [PropertyKey.name]: '' });
    };

    const StatusIcon = ({ onClick }: { onClick: () => void }) => (
        <div className="status">
            <img className="success" src="/images/icon/upload-success.svg" alt="" />
            <img className="remove" src="/images/icon/close-circle.svg" alt="" onClick={onClick} />
        </div>
    );
    const acceptType = inputAcceptExt[mediaType] || '*';

    return (
        <div className="UpLoadFilePropertyBox">
            <div className="imageActionWrapper">
                <Tooltip
                    title={t(`propertyText.FileUploadToolTip`, {
                        maxSize,
                        acceptTypes: acceptTypes[mediaType],
                    })}
                >
                    {
                        <Button className="imageUploadButton" variant="contained" component="label" size="small">
                            {isLoadingObject[propertyData.id] ? (
                                <CircularProgress size={30} />
                            ) : (
                                <>
                                    {t(`propertyText.FileUpload`)}
                                    <input
                                        type="file"
                                        accept={acceptType}
                                        hidden
                                        onChange={handleChange(PropertyKey.name, UIComponentKey.value)}
                                    />
                                </>
                            )}
                        </Button>
                    }
                </Tooltip>

                {filenameObject[propertyData.id] && (
                    <div className="row">
                        <StatusIcon onClick={handleRemove} />
                        <div className="filename">{filenameObject[propertyData.id]}</div>
                    </div>
                )}
            </div>
            {(image64Object[propertyData.id] || fileUrl) && mediaType !== 'video' && (
                <div
                    className="previewImage"
                    style={{
                        backgroundImage: `url(${image64Object[propertyData.id] || fileUrl})`,
                    }}
                />
            )}
        </div>
    );
};

export default UpLoadFilePropertyBox;
