import * as THREE from 'three';
import { RaycastObject } from './RaycastObject';
import NormalDepthMapMaterial from 'core/three/GpuRaycaster/NormalDepthMap/NormalDepthMapMaterial';
import { ObjectType, EObjectType } from './type';
export default abstract class CustomObject3D {
    public id: string;
    public type: ObjectType;
    public css3d: boolean;
    public senseDistance: number = 0
    private _object: THREE.LOD;
    public get object() {
        return this._object;
    }

    private renderDepthAndNormalMat = new NormalDepthMapMaterial();
    private oldMaterial = null;
    public editable = true;
    public renderNormalAndDepth(value) {
        const raycastTarget = this.raycastTarget;
        if (raycastTarget) {
            if (this.oldMaterial === null) this.oldMaterial = raycastTarget.material;
            if (value) {
                raycastTarget.material = this.renderDepthAndNormalMat;
            } else {
                raycastTarget.material = this.oldMaterial;
            }
        }
    }

    public renderIndexColor(renderIndex) {}

    public enableRaycasterLayer(visiblelayer) {
        const raycastTarget = this.raycastTarget;
        if (raycastTarget) raycastTarget.layers.enable(visiblelayer);
    }

    public setRaycastIndex(visibleLayer, index: number): boolean {
        function genNewColorMaterial(index): THREE.ShaderMaterial {
            return new THREE.ShaderMaterial({
                vertexShader: `
                void main()	
                {
                    gl_Position = projectionMatrix * modelViewMatrix  * vec4( position, 1.0 );
                }`,
                fragmentShader: `
                uniform vec3 color;            
                void main() {           
                    gl_FragColor = vec4(color,1.0);
                }
                `,
                uniforms: {
                    color: { value: new THREE.Color().setHex(index) },
                },
                side: THREE.DoubleSide,
            });
        }

        const raycastTarget = this.raycastTarget;
        if (raycastTarget) {
            raycastTarget.layers.enable(visibleLayer);

            const oldMaterial = raycastTarget.material;
            raycastTarget.material = genNewColorMaterial(index);
            oldMaterial?.dispose();
            return true;
        } else {
            return false;
        }
    }

    /**
     *
     * @param type object type
     */
    constructor(type: EObjectType) {
        this._object = new THREE.LOD();
        this.updateRaycastMesh();
        this.type = type;
        this.custumDestoryFuncs = [];
    }

    public get json(): object {
        return {
            type: this.type,
            position: {
                x: this.object.position.x,
                y: this.object.position.y,
                z: this.object.position.z,
            },
            rotation: {
                x: this.object.quaternion.x,
                y: this.object.quaternion.y,
                z: this.object.quaternion.z,
                w: this.object.quaternion.w,
            },
            scale: {
                x: this.object.scale.x,
                y: this.object.scale.y,
                z: this.object.scale.z,
            },
        };
    }

    public setJson(json: object) {}

    protected raycastMesh: THREE.Mesh;
    public get raycastTarget(): any {
        return this.raycastMesh;
    }
    protected updateRaycastMesh(mesh = undefined) {
        if (!mesh) {
            this.raycastMesh = RaycastObject.emptyRaycastMesh();
            this.object.add(this.raycastMesh);
            return;
        }
        let mergeFunction;

        if (mesh instanceof THREE.Group) mergeFunction = RaycastObject.mergeGroup;
        else if (mesh instanceof THREE.BufferGeometry || mesh[0] instanceof THREE.BufferGeometry)
            mergeFunction = RaycastObject.mergeGeometries;
        else if (mesh instanceof THREE.Mesh || mesh[0] instanceof THREE.Mesh) mergeFunction = RaycastObject.mergeMeshes;

        if (this.raycastMesh) {
            const oldGeomerty = this.raycastMesh.geometry;
            this.raycastMesh.geometry = mergeFunction(mesh).geometry;
            if (oldGeomerty) oldGeomerty.dispose();
        } else {
            this.raycastMesh = mergeFunction(mesh);
            this.object.add(this.raycastMesh);
        }
    }

    /**
     * clean this object, js wiil not call this automaticlly
     */
    private custumDestoryFuncs: (() => void)[];
    public addCustumDestory(func: () => void) {
        this.custumDestoryFuncs.push(func);
    }
    public destroy() {
        this.custumDestoryFuncs.forEach((destroy) => {
            destroy();
        });

        if (this.object.parent) this.object.parent.remove(this.object);

        // store old texture and geometry
        const oldTexture = ((this.raycastMesh as THREE.Mesh).material as THREE.MeshBasicMaterial).map;
        const oldGeometry = (this.raycastMesh as THREE.Mesh).geometry;
        //dispose old texture and geometry
        if (oldTexture) oldTexture.dispose();
        if (oldGeometry) oldGeometry.dispose();
    }
}
