import React, { useContext, useEffect, useState } from 'react';
import ThreeContext from 'app/pages/three-context';
import { useTranslation } from 'react-i18next';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { SceneContext } from 'App';

import './CompanyInfoPropertyBox.css';

const CompanyInfoPropertyBox: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { currentMarker } = useContext(TagEditorContext);
    const { scene } = useContext(SceneContext);
    const groupNameMap = React.useMemo(() => {
        return scene.editGroups.reduce((map, group) => ({ ...map, [group.id]: group.name }), {});
    }, [scene.editGroups]);
    const groupName = groupNameMap[currentMarker.groupId];

    return groupName ? (
        <div className="CompanyInfoPropertyBox">
            <div className="label">{t('propertyText.companyLabel')} :</div>
            {groupName}
        </div>
    ) : null;
};

export default CompanyInfoPropertyBox;
