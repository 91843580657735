import * as THREE from 'three';
import AvatarModel from '../object/media/model/AvatarModel';
import PersonControls from './PersonControls';
import VisitorHeadshot from '../object/media/image/VisitorHeadshot';
import { RenderFrame } from 'core/types';

export default class ThirdPersonControls extends PersonControls {
    public model: AvatarModel | VisitorHeadshot;

    /**
     * set the avatar model for personControls
     */
    public setModel(model: AvatarModel | VisitorHeadshot) {
        this.model = model;
        this.model.object.position.copy(this.person.position)
    }

    public update(renderFrame: RenderFrame) {
        super.update(renderFrame);
        this.updateModelPosition()
        // this.updateModelAnimation(renderFrame.delteSec)
        // this.updateHeadshotFollowCamera(renderFrame.cameraPosition)
    }

    public setPosition(position: THREE.Vector3) {
        super.setPosition(position);
        this.updateModelPosition()
    }

    private updateModelPosition() {
        this.model?.object.position.copy(this.person.position);
    }

    // private updateModelAnimation(deltaMs: number) {
    //     if (this.model instanceof AvatarModel) this.model.updateAnimation(deltaMs);
    // }

    // private updateHeadshotFollowCamera(cameraPosition: THREE.Vector3) {
    //     if (this.model instanceof VisitorHeadshot) this.model.lookAt(cameraPosition)
    // }
}