import React, { useEffect } from 'react';
import { getMarkers, createMarkers, updateMarkers, deleteMarkers } from 'api/marker';
import { AppContext } from 'App';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import ThreeContext from '../three-context';
import TagEditor from 'app/three/ThreeViewPage/TagEditor';
import { MediaStatus } from 'core/three/object/type';
import { useTranslation } from 'react-i18next';
import { MediaMarker } from 'core/types/media';

interface IProps {
    three: TagEditor;
    scene: any;
}
const TagEditorEffects = ({ three, scene }: IProps) => {
    const { setActions } = React.useContext(ThreeContext);
    const { auth, permission } = React.useContext(AppContext);
    const {
        groupId: { state: groupIdState },
    } = React.useContext(TagEditorContext);
    const {
        initCamera: { setAction: setCameraAction },
    } = React.useContext(TagEditorContext);
    const {
        selectMarker: { setAction: setSelectMarkerAction },
    } = React.useContext(TagEditorContext);
    const {
        lookto: { setAction: setLooktoAction },
    } = React.useContext(TagEditorContext);
    const {
        markersMap: { setState: setContextMarkersMap, state: contextMarkersMap },
    } = React.useContext(TagEditorContext);
    const {
        currentMarkerId: { setState: setCurrentMarker },
    } = React.useContext(TagEditorContext);
    const { toast } = React.useContext(AppContext);
    const { id: sceneId, sceneHotspots, editGroups } = scene;
    const { t } = useTranslation();

    useEffect(() => {
        if (three == null) return;
        const cancelOnInit = three.markerMgr.addEventListener(three.markerMgr.inited, ({ data: media }) => {
            const json = media.json as MediaMarker;
            setContextMarkersMap((prev) => ({ ...prev, [json.id || json.uuid]: json }));
        });
        const cancelOnUpdate = three.markerMgr.addEventListener(three.markerMgr.updated, ({ data: media }) => {
            const json = media.json as MediaMarker;
            setContextMarkersMap((prev) => ({ ...prev, [json.id || json.uuid]: json }));
        });
        return () => {
            cancelOnInit();
            cancelOnUpdate();
        };
    }, [three, setContextMarkersMap]);

    // three view action
    useEffect(() => {
        if (three == null) return;
        setActions({
            goDown: three.animationMgr.godown.bind(three.animationMgr),
            goTop: three.animationMgr.goTop.bind(three.animationMgr),
            deleteObject: three.deleteObject.bind(three),
            undo: three.undoRedoController.undo.bind(three.undoRedoController),
            redo: three.undoRedoController.redo.bind(three.undoRedoController),
            getObjectByUndoRedoId: three.getObjectByUndoRedoId.bind(three),
            onObjectTransformUpdate: three.onObjectTransformUpdate.bind(three),
        });
    }, [three, setActions]);

    // three view action
    useEffect(() => {
        if (three == null) return;
        setCameraAction(three.setInitialCamera.bind(three, sceneId, t, toast));
        setSelectMarkerAction(three.selectObjectById.bind(three));
        setLooktoAction(three.viewportControls.setLookPisition.bind(three.viewportControls));
    }, [three, setCameraAction, setSelectMarkerAction, setLooktoAction, sceneId, t, toast]);

    // save
    React.useEffect(() => {
        if (three == null) return;
        const save = () => {
            const initMarkers = three.initMarkers.bind(three);
            const markers_data = Object.values(contextMarkersMap);
            const createdMarkers = markers_data.filter((marker) => marker.status === MediaStatus.CREATED);
            const updatedMarkers = markers_data.filter((marker) => marker.status === MediaStatus.UPDATED);
            const deletedMarkers = markers_data.filter((marker) => marker.status === MediaStatus.DELETED);
            const deletedMarkerIds = deletedMarkers.map((marker) => marker.id);

            Promise.all([
                updatedMarkers.length && updateMarkers(updatedMarkers),
                deletedMarkerIds.length && deleteMarkers(deletedMarkerIds),
            ])
                .then(
                    () =>
                        createdMarkers.length &&
                        createMarkers({ markers: createdMarkers, sceneId, groupId: groupIdState, ownerId: auth.id }),
                )
                .then(() => toast('success', t('MessageBox.SaveSuccessfully')))
                .catch((err) => {
                    console.log(err);
                    toast('error', err);
                })
                .then(() => setContextMarkersMap({}))
                .then(() => getMarkers(sceneId))
                .then(initMarkers);
        };

        setActions({ save });
    }, [three, groupIdState, auth, sceneId, setActions, toast, contextMarkersMap, setContextMarkersMap]);

    // create marker
    React.useEffect(() => {
        if (three == null) return;
        const threeCreate = three.createObject.bind(three);
        const group = editGroups.find((group) => group.id === groupIdState);
        const maxTag = group ? group.maxTag : permission.maxTags || 0;
        const createObject = (media) => {
            media.groupId = groupIdState;
            media = { ...media, ...media.attributes };
            const { created, deleted, read } = three.markerMgr.mediaStatus;
            if (read + created - deleted >= maxTag) alert('已達標籤數量上限');
            else threeCreate(media);
        };
        setActions({ createObject });
    }, [three, groupIdState, editGroups, setActions, permission]);

    React.useEffect(() => {
        setCurrentMarker('');
    }, [setCurrentMarker, groupIdState]);

    return null;
};

export default TagEditorEffects;
