export interface MarkerField {
    name?: boolean;
    description?: boolean;
    linkUrl?: boolean;
    openType?: boolean;
    ctaName?: boolean;
    moreVideoUrl?: boolean;
    videoUrl?: boolean;
    price?: boolean;
    iconTag?: boolean;
    thumbnail?: boolean;
    companyInfo?: boolean;
    multiImage?: boolean;
    textStyle?: boolean;
    textContent?: boolean;
    spaceId?: boolean;
    fileUrl?: boolean;
    volume?: boolean;
    curve?: boolean;
}

export const imageField: MarkerField = {
    name: true,
    description: true,
    linkUrl: true,
    openType: true,
};

export const tagField: MarkerField = {
    name: true,
    description: true,
    linkUrl: true,
    openType: true,
    ctaName: true,
    moreVideoUrl: true,
    price: true,
    thumbnail: true,
    companyInfo: true,
    multiImage: true,
    curve: true,
};

export const textField: MarkerField = {
    name: true,
    textStyle: true,
    textContent: true,
    companyInfo: true,
    curve: true,
};

export const modelField: MarkerField = {
    name: true,
    description: true,
    linkUrl: true,
    ctaName: true,
    moreVideoUrl: true,
    price: true,
    multiImage: true,
};

export const portalField: MarkerField = {
    name: true,
    thumbnail: true,
    spaceId: true,
    companyInfo: true,
    curve: true,
};

export const npcField: MarkerField = {
    name: true,
    companyInfo: true,
};

export const musicField: MarkerField = {
    name: true,
    description: true,
    thumbnail: true,
    companyInfo: true,
    fileUrl: true,
    curve: true,
};

export const videoField: MarkerField = {
    name: true,
    thumbnail: true,
    videoUrl: true,
    volume: true,
    fileUrl: true,
    companyInfo: true,
};

export const websiteField: MarkerField = {
    name: true,
    description: true,
    thumbnail: true,
    linkUrl: true,
    companyInfo: true,
    curve: true,
};

export const webWallField: MarkerField = {
    linkUrl: true,
};

export const arTagField: MarkerField = {
    name: true,
    description: true,
    thumbnail: true,
    linkUrl: true,
    companyInfo: true,
    curve: true,
};
