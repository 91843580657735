import * as THREE from 'three';
import { MarkerType } from 'core/three/object/type';
import TextTexture from 'core/three/TextTexture/TextTexture';
import Media from '../Media';
import { IText } from 'core/types/media';
import { CurvePlaneGeometry } from 'core/utils/CurvePlaneGeometry';

export default class Text extends Media {
    private textMesh: THREE.Mesh;
    private fontWeight: string;
    private fontStyle: string;
    private textColor: string;
    private alignment: string;
    private label: string;
    private lineSpace: number;
    private fontSize: number;
    private angle: number;
    private curve: number;

    constructor() {
        super(MarkerType.TEXT);

        const material = new THREE.MeshBasicMaterial({ map: null, side: THREE.DoubleSide });
        material.transparent = true;

        this.textMesh = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), material);

        this.object.add(this.textMesh);
    }

    public get json() {
        return {
            ...super['json'],
            label: this.label,
            fontWeight: this.fontWeight,
            fontStyle: this.fontStyle,
            textColor: this.textColor,
            lineSpace: this.lineSpace,
            alignment: this.alignment,
            fontSize: this.fontSize,
            angle: this.angle,
            curve: this.curve,
        };
    }

    public setJson(data: IText) {
        super.setJson(data);
        this.fontWeight = data.fontWeight;
        this.fontStyle = data.fontStyle;
        this.textColor = data.textColor;
        this.lineSpace = data.lineSpace;
        this.alignment = data.alignment;
        this.fontSize = data.fontSize;
        this.angle = data.angle;
        this.label = data.label;
        this.description = data.label; // apply label to description for search
        this.curve = data.curve;
        // this.rotateText();
        this.updateLabel();
        super.markStatusUpdate();
    }

    public async init(data: IText) {
        super.init(data);
        this.fontWeight = data.fontWeight ?? 'normal';
        this.fontStyle = data.fontStyle ?? 'normal';
        this.textColor = data.textColor ?? '#000000';
        this.alignment = data.alignment ?? 'left';
        this.lineSpace = data.lineSpace ?? 0.5;
        this.fontSize = data.fontSize ?? 60;
        this.angle = data.angle ?? 0;
        this.label = data.label ?? 'Text';
        this.curve = data.curve ?? 0;
        this.description = this.label; // apply label to description for search
        this.updateLabel();
        // this.rotateText();
    }

    private updateLabel(): void {
        // create a canvas element
        const texture = new TextTexture(
            {
                label: this.label,
                fontWeight: this.fontWeight,
                fontStyle: this.fontStyle,
                fontColor: this.textColor,
                fontSize: this.fontSize,
                alignment: this.alignment,
                lineSpace: this.lineSpace,
                padding: 20,
            },
            {
                fillStyle: 'rgba(0,0,0,0.0)',
            },
        );

        texture.needsUpdate = true;

        // store old texture and geometry
        const oldTexture = ((this.textMesh as THREE.Mesh).material as THREE.MeshBasicMaterial).map;
        const oldGeometry = (this.textMesh as THREE.Mesh).geometry;

        //set new geometry and texture
        ((this.textMesh as THREE.Mesh).material as THREE.MeshBasicMaterial).map = texture;

        let sizeX = texture.width * 2;
        let sizeY = texture.height * 2;

        (this.textMesh as THREE.Mesh).geometry = new CurvePlaneGeometry(sizeX, sizeY, this.curve);
        //dispose old texture and geometry
        if (oldTexture) oldTexture.dispose();
        if (oldGeometry) oldGeometry.dispose();
        this.updateRaycastMesh(this.textMesh.geometry);
        this.updateBoundaryBox(sizeX, sizeY, 0.001);
    }

    public showLevel() {
        if (!this.lodEnabled) {
            return;
        }
        const level = this.curLevel;
        if (this.prevLevel !== level) {
            this.prevLevel = level;
            switch (level) {
                case 0:
                    this.textMesh.visible = true;
                    this.showBoundaryBox(false);
                    break;
                case 1:
                    this.textMesh.visible = false;
                    this.showBoundaryBox(true);
                    break;
                case 2:
                    this.textMesh.visible = false;
                    this.showBoundaryBox(false);
                    break;
            }
        }
    }

    destroy() {
        super.destroy();

        //dispose geometry
        if (this.textMesh.geometry) {
            this.textMesh.geometry.dispose();
            this.textMesh.geometry = null;
        }
        if (this.textMesh.material) {
            //dispose texture
            if ((this.textMesh.material as THREE.MeshBasicMaterial).map) {
                (this.textMesh.material as THREE.MeshBasicMaterial).map.dispose();
                (this.textMesh.material as THREE.MeshBasicMaterial).map = null;
            }

            //dispose material
            (this.textMesh.material as THREE.Material).dispose();
            this.textMesh.material = null;
        }
    }

    // private rotateText()
    // {
    //     console.log(this.object.rotation.z )
    //     let ori_angle = this.object.rotation.z;
    //     let target_angle = this.angle / 180 * Math.PI;

    //     this.object.rotateZ( target_angle - ori_angle );
    //     console.log(this.object.rotation.z )
    //     console.log('---' )

    // }
}
