import * as THREE from 'three';
import { EObjectType } from 'core/three/object/type';
import Model from 'core/three/object/media/model/Model';
import PanoramaMesh from 'core/three/object/PanoramaMesh/PanoramaMesh';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { TriangleCategorizer } from 'core/utils/triangleCategorizer';
export default class SceneModel extends Model {
    public panorama: PanoramaMesh;
    constructor() {
        super();
        this.type = EObjectType.SCENEMODEL;
        this.object.remove(this.boundaryBox);
    }

    private traverse(
        object: THREE.Group,
        seteMat?: (mtl: THREE.Material) => void,
        setMesh?: (node: THREE.Mesh) => void,
    ) {
        object.traverse((node: THREE.Mesh) => {
            if (!node.isMesh) return;
            const materials = Array.isArray(node.material) ? node.material : [node.material];
            if (seteMat) materials.forEach(seteMat);
            if (setMesh) setMesh(node);
        });
    }

    public overwriteGLTFmat(obj) {
        this.traverse(
            obj,
            (material) => {
             
            },
            (mesh) => {
                mesh.renderOrder = -1;
            },
        );
    }

    public setGLTF(obj: GLTF) {
        super.setGLTF(obj, this.overwriteGLTFmat.bind(this));
    }

    public setMesh(obj) {
        super.setMesh(obj);
        this.raycastMesh.triangleCategorizer = new TriangleCategorizer(this.raycastMesh);
    }

    public setTntegratedMesh(obj: THREE.Object3D) {
        this.panorama = new PanoramaMesh(obj);
        this.panorama.renderOrder = -2;
    }

    public async load(url: string): Promise<void> {
        return super.load(url, this.overwriteGLTFmat.bind(this));
    }

    public set panoramaAlpha (alpha: number) {
        if (alpha === 0) this.object.remove(this.panorama)
        else this.object.add(this.panorama)
        this.panorama.cubemapMaterial.alpha = alpha
    }

    public set glbVisible (bool: boolean) {
        if (bool) this.object.add(this.glb)
        else this.object.remove(this.glb)
    }

    get cubemapMaterial() {
        return this.panorama.cubemapMaterial;
    }
 
    public get sceneBoundary(): THREE.Box3 {
        const boundary = new THREE.Box3();
        boundary.setFromObject(this.glb);
        return boundary;
    }
}
