import React, { ChangeEvent } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputRoot: {
            backgroundColor: '#4D4D4D',
            color: '#fff',
            '& button': {
                color: '#fff',
            },
        },
        paper: {
            backgroundColor: '#4D4D4D',
            color: '#fff',
        },
    }),
);

interface GroupSelectionProps {
    groups: any[];
    currentIndex: number;
    onChange: (i: number) => void;
}

const GroupSelection: React.FunctionComponent<GroupSelectionProps> = ({ groups, currentIndex, onChange }) => {
    const styles = useStyles();
    const handleChange = (e: ChangeEvent<HTMLInputElement>, value) => {
        onChange(groups.indexOf(value));
    };

    return (
        <Autocomplete
            classes={styles}
            options={groups}
            value={groups[currentIndex]}
            getOptionLabel={(option) => option.name}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    className="group-selection-text"
                    placeholder={groups.length ? '' : '0'}
                />
            )}
        />
    );
};

export default GroupSelection;
