// react
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { assertIsNode } from 'utils';

import Tooltip from '@material-ui/core/Tooltip';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { IMedia } from 'core/types/media';
import Instruction from './Instruction';

import './SideMenu.css';
import TitlebarGridList from './SideMenu/TitlebarGridList';
import { MarkerType } from 'core/three/object/type';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sideMain: {
            width: '50px',
            height: '100%',
            overflow: 'auto',
            backgroundColor: '#4D4D4D',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            '& button': {
                width: '40px !important',
                height: '40px !important',
                margin: '5px 0 !important',
                padding: '0 !important',
                backgroundColor: 'transparent !important',
                border: 'none !important',
                '& img': {
                    width: '21px',
                    height: '16px',
                },
                '& svg path': {
                    width: '21px',
                    height: '16px',
                    color: '#EFEFEF !important',
                },
            },
            '& button:hover': {
                backgroundColor: '#1C1C1C !important',
                borderRadius: '4px',
            },
            '& button:active': {
                boxShadow: '2px 2px 3px inset rgba(0,0,0,0.3) !important',
            },
        },
        websiteIcon: {
            width: '21px',
            height: '16px',
        },
        customTooltip: {
            background: '#333333',
            color: '#ffffff',
        },
        tagButton: {
            '& > button': {
                cursor: 'pointer',
            },
        },
        menuIcon: {
            margin: '0 0 10px 0',
            width: 44,
            height: 44,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& img': {
                width: 26,
                height: 26,
            },
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }),
);

interface ISideMenuProps {
    icons: {
        tooltip: string;
        icon: JSX.Element;
        hidden?: boolean;
        type: MarkerType;
        action(): void;
    }[];
    media: IMedia[];
    open: boolean;
    onClose(): void;
    onRefresh(): void;
}

const SideMenu: React.FunctionComponent<ISideMenuProps> = (props) => {
    const classes = useStyles();
    const [instructionOpen, setInstructionOpenOpen] = React.useState(true);
    const sideMenuRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const clickOutsideHandler = (e: MouseEvent) => {
            if (!assertIsNode(e.target)) return;
            if (sideMenuRef.current && !sideMenuRef.current.contains(e.target)) {
                props.onClose();
            }
        };
        document.addEventListener('mousedown', clickOutsideHandler);
        return () => {
            document.removeEventListener('mousedown', clickOutsideHandler);
        };
    }, []);

    return (
        <div ref={sideMenuRef} className="sideMenu">
            <Instruction open={instructionOpen} handleOpen={() => setInstructionOpenOpen((prev) => !prev)} />
            <div className={classes.sideMain}>
                {props.icons
                    .filter((icon) => !icon.hidden)
                    .map((item, index) => (
                        <Tooltip
                            classes={{
                                tooltip: classes.customTooltip,
                            }}
                            key={index}
                            title={item.tooltip}
                            placement="right-end"
                        >
                            <button onClick={() => item.action()}>{item.icon}</button>
                        </Tooltip>
                    ))}
            </div>
            {props.open && (
                <TitlebarGridList
                    className="sideSub"
                    onClose={props.onClose}
                    onRefresh={props.onRefresh}
                    list={props.media}
                />
            )}
        </div>
    );
};

export default SideMenu;
