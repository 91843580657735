export default `
varying vec3 modelPositionEquirect;
varying mediump vec3 modelPositionAltas;


uniform vec3 offestRotate;
uniform samplerCube tEquirect0;
uniform samplerCube tEquirect1;

uniform sampler2D tAltas0;
uniform sampler2D tAltas1;
uniform float shrink;

uniform int texture0isAltas;
uniform int texture1isAltas;

uniform vec3 position0;
uniform vec4 rotation0;
uniform vec3 position1;
uniform vec4 rotation1;

uniform lowp float blackFactor;
uniform lowp float time;
uniform lowp float alpha;

vec3 qtransform(vec4 q, vec3 v){ 
	return v + 2.0*cross(cross(v, q.xyz ) + q.w*v, q.xyz);
}

void cubemap(vec3 r, out float texId, out vec2 st) {
    mediump vec3 uvw;
    lowp vec3 absr = abs(r);

    if (absr.x > absr.y && absr.x > absr.z) {
        // x major
        lowp float negx = step(r.x, 0.0);
        uvw = vec3(r.zy, absr.x) * vec3(mix(-1.0, 1.0, negx), -1, 1);
        texId = negx;
    } else if (absr.y > absr.z) {
        // y major
        lowp float negy = step(r.y, 0.0);
        uvw = vec3(r.xz, absr.y) * vec3(1.0, mix(1.0, -1.0, negy), 1.0);
        texId = 2.0 + negy;
    } else {
        // z major
        lowp float negz = step(r.z, 0.0);
        uvw = vec3(r.xy, absr.z) * vec3(mix(1.0, -1.0, negz), -1, 1);
        texId = 4.0 + negz;
    }
    st = vec2(uvw.xy / uvw.z + 1.) * .5;
}
 
mediump vec4 texCubemap(vec3 uvw,sampler2D tEquirect) {
    lowp float texId;
    mediump vec2 st;

    // calc textureId depends on direction and get the UV coordinate
    cubemap(uvw, texId, st);

    //return color
    lowp float order;
    if (texId > 4.0) order = 2.0;
    else if (texId > 3.0) order = 4.0;
    else if (texId > 2.0) order = 1.0;
    else if (texId > 1.0) order = 0.0;
    else if (texId > 0.0) order = 5.0;
    else order = 3.0;

    return texture2D(tEquirect, vec2(st.x*shrink, (st.y*shrink + order) / 6.0));
}
 
mediump vec4 getColorAltas(vec3 point,sampler2D texture, vec3 position,vec4 quaternion,mat3 rotateOffset){

    point.x = point.x - position.x;
    point.y = point.y + position.y;
    point.z = point.z + position.z;

    vec3 modelVertex = qtransform(quaternion,point)*rotateOffset;

    return texCubemap(normalize(modelVertex),texture);
}

mediump vec4 getColorEquirect(vec3 point,samplerCube tex, vec3 position,vec4 quaternion,mat3 rotateOffset){

    point.x = point.x - position.x;
    point.y = point.y - position.y;
    point.z = point.z + position.z;

    vec3 modelVertex = qtransform(quaternion,point)*rotateOffset;

    return vec4(textureCube(tex, modelVertex).xyz, 1.0);
}

void main()	{	    

    mat3 rotationMatX = mat3(1.0, 0.0, 0.0,
        0.0, cos(offestRotate.x), -sin(offestRotate.x),
        0.0, sin(offestRotate.x),cos(offestRotate.x));

    mat3 rotationMatY = mat3(cos(offestRotate.y), 0.0, sin(offestRotate.y), 
            0.0, 1.0, 0.0,
            -sin(offestRotate.y), 0.0, cos(offestRotate.y));

    mat3 rotationMatZ = mat3(cos(offestRotate.z), -sin(offestRotate.z),0.0,
            sin(offestRotate.z), cos(offestRotate.z), 0.0,
            0.0,0.0,1.0);
    mat3 rotateOffset = rotationMatX*rotationMatY*rotationMatZ;

    lowp vec4 color0 = vec4(0.0,0.0,0.0,0.0);
    lowp vec4 color1 = vec4(0.0,0.0,0.0,0.0);
    
    if(texture0isAltas==0){
        color0 = getColorEquirect(modelPositionEquirect,tEquirect0,position0,rotation0,rotateOffset);
    }else{
        color0 = getColorAltas(modelPositionAltas,tAltas0,position0,rotation0,rotateOffset);
    }

    if(texture1isAltas==0){
        color1 = getColorEquirect(modelPositionEquirect,tEquirect1,position1,rotation1,rotateOffset);
    }else{
        color1 = getColorAltas(modelPositionAltas,tAltas1,position1,rotation1,rotateOffset);
    }

    vec3 mixColor = mix(color0.xyz,color1.xyz,time);
   
    gl_FragColor = vec4(mixColor.x*blackFactor,mixColor.y*blackFactor,mixColor.z*blackFactor, alpha);
}
`;
