import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './AttributePropertyBox.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { MarkerType } from 'core/three/object/type';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

enum UIComponetKey {
    checked = 'checked',
}

enum PropertyKey {
    followCamera = 'followCamera',
    unclickable = 'unclickable',
    isVertical = 'isVertical',
    openLinkDirectly = 'openLinkDirectly',
    visible = 'visible',
    autoplay = 'autoplay',
    greenVideo = 'greenVideo',
    searchable = 'searchable',
    autoRotate = 'autoRotate',
    disableBackground = 'disableBackground',
    autoPlayAnimation = 'autoPlayAnimation',
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#fff',
            padding: '4px 12px',
        },
        checkedIcon: {
            borderRadius: 4,
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 20,
                height: 20,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
        },
        tooltipButtonRoot: {
            color: '#CCC',
            padding: '0 8px',
        },
    }),
);

const AttributePropertyBox = (props) => {
    const { t } = useTranslation();
    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);
    const classes = useStyles();

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event, newValue?) {
            markerUpdater.call({ ...propertyData, [propertykey]: newValue || event.target[key] });
        };
    };

    // all AttributeCompoenet
    const AttributeItemPair = {
        followCamera: {
            propertyKey: PropertyKey.followCamera,
            label: t('propertyText.FollowCamera'),
            tooltip: t('propertyText.FollowCameraHint'),
        },
        unclickable: {
            propertyKey: PropertyKey.unclickable,
            label: t('propertyText.Unclickable'),
            tooltip: t('propertyText.UnclickableHint'),
        },
        isVertical: {
            propertyKey: PropertyKey.isVertical,
            label: t('propertyText.Vertical'),
            tooltip: t('propertyText.VerticalHint'),
        },
        openLinkDirectly: {
            propertyKey: PropertyKey.openLinkDirectly,
            label: t('propertyText.OpenLinkDirectly'),
        },
        visible: {
            propertyKey: PropertyKey.visible,
            label: t('propertyText.visible'),
        },
        autoplay: {
            propertyKey: PropertyKey.autoplay,
            label: t('propertyText.Autoplay'),
        },
        greenVideo: {
            propertyKey: PropertyKey.greenVideo,
            label: t('propertyText.GreenVideo'),
        },
        searchable: {
            propertyKey: PropertyKey.searchable,
            label: t('propertyText.Searchable'),
            tooltip: t('propertyText.SearchableHint'),
        },
        autoRotate: {
            propertyKey: PropertyKey.autoRotate,
            label: t('propertyText.autoRotate'),
        },
        disableBackground: {
            propertyKey: PropertyKey.disableBackground,
            label: t('propertyText.disableBackground'),
        },
        autoPlayAnimation: {
            propertyKey: PropertyKey.autoPlayAnimation,
            label: t('propertyText.autoPlayAnimation'),
        },
    };

    // add / delete attribute in menu (in order)
    const AttributesMenu = {
        ImageMenu: [AttributeItemPair.followCamera, AttributeItemPair.isVertical, AttributeItemPair.searchable],
        TextMenu: [AttributeItemPair.followCamera, AttributeItemPair.isVertical, AttributeItemPair.searchable],
        VideoMenu: [
            AttributeItemPair.followCamera,
            AttributeItemPair.isVertical,
            AttributeItemPair.searchable,
            AttributeItemPair.autoplay,
            AttributeItemPair.greenVideo,
        ],
        WebWallMenu: [AttributeItemPair.followCamera, AttributeItemPair.isVertical, AttributeItemPair.searchable],
        TagMenu: [
            AttributeItemPair.followCamera,
            AttributeItemPair.isVertical,
            AttributeItemPair.unclickable,
            AttributeItemPair.searchable,
        ],
        ModelMenu: [
            AttributeItemPair.unclickable,
            AttributeItemPair.searchable,
            AttributeItemPair.autoRotate,
            AttributeItemPair.autoPlayAnimation,
        ],
        PortalMenu: [AttributeItemPair.visible],
        WebSiteMenu: [AttributeItemPair.followCamera, AttributeItemPair.isVertical, AttributeItemPair.searchable],
        NpcMenu: [AttributeItemPair.followCamera, AttributeItemPair.searchable],
        MusicMenu: [AttributeItemPair.isVertical, AttributeItemPair.autoplay, AttributeItemPair.searchable],
        ARTagMenu: [
            AttributeItemPair.disableBackground,
            AttributeItemPair.followCamera,
            AttributeItemPair.isVertical,
            AttributeItemPair.searchable,
        ],
    };

    function AttributeItem(props) {
        const { propertyKey, label, tooltip } = props;
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            name={propertyKey}
                            checked={propertyData[propertyKey] ?? false}
                            onChange={handleChange(propertyKey, UIComponetKey.checked)}
                            checkedIcon={<span className={classes.checkedIcon} />}
                            color="primary"
                            size="small"
                            classes={{
                                root: classes.root,
                            }}
                        />
                    }
                    label={
                        <>
                            {label}
                            {tooltip && (
                                <Tooltip title={tooltip}>
                                    <IconButton classes={{ root: classes.tooltipButtonRoot }} aria-label="help">
                                        <HelpOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    }
                />
            </>
        );
    }

    function AttributeMenu(props) {
        const menu = props.menu;
        return menu.map((item, index) => (
            <AttributeItem key={index} propertyKey={item.propertyKey} label={item.label} tooltip={item.tooltip} />
        ));
    }

    function renderMenuAttribute(mediaType: MarkerType) {
        switch (mediaType) {
            case MarkerType.IMAGE:
                return <AttributeMenu menu={AttributesMenu.ImageMenu} />;
            case MarkerType.TEXT:
                return <AttributeMenu menu={AttributesMenu.TextMenu} />;
            case MarkerType.WEBWALL:
                return <AttributeMenu menu={AttributesMenu.WebWallMenu} />;
            case MarkerType.VIDEO:
                return <AttributeMenu menu={AttributesMenu.VideoMenu} />;
            case MarkerType.TAG:
                return <AttributeMenu menu={AttributesMenu.TagMenu} />;
            case MarkerType.MODEL:
                return <AttributeMenu menu={AttributesMenu.ModelMenu} />;
            case MarkerType.PORTAL:
                return <AttributeMenu menu={AttributesMenu.PortalMenu} />;
            case MarkerType.WEBSITE:
                return <AttributeMenu menu={AttributesMenu.WebSiteMenu} />;
            case MarkerType.NPC:
                return <AttributeMenu menu={AttributesMenu.NpcMenu} />;
            case MarkerType.MUSIC:
                return <AttributeMenu menu={AttributesMenu.MusicMenu} />;
            case MarkerType.ARTAG:
                return <AttributeMenu menu={AttributesMenu.ARTagMenu} />;
            default:
                return null;
        }
    }

    const attributeMenu = renderMenuAttribute(props.type);

    return (
        <>
            {attributeMenu && (
                <div className="AttributePropertyBox">
                    <div className="BoxTitle">{t(`propertyText.Attributes`) + ':'}</div>
                    {attributeMenu}
                </div>
            )}
        </>
    );
};

export default AttributePropertyBox;
