import api, { getErrorMsg } from 'api';
import { formatResponse as formatMarker } from 'api/marker';

export const getScene = async (sceneId) => {
    try {
        const urlEnd = `/api/v1/scenes/${sceneId}`;
        const response = await api.get(urlEnd);
        response.data.markers = (response.data.markers || []).map(formatMarker) || [];
        response.data.sceneHotspots = response.data.sceneHotspots || [];
        response.data.sceneHotspots.forEach((hotspot) => {
            hotspot.neighbors = hotspot.neighbors || [];
        });
        response.data.editGroups = response.data.editGroups || [];
        response.data.groupAreas = response.data.groupAreas || [];

        // 因後端尚未開發 開放以下參數測試 完成串接後可移除
        const mainColor = new URLSearchParams(window.location.search).get('main-color');
        const subColor = new URLSearchParams(window.location.search).get('sub-color');
        const title = new URLSearchParams(window.location.search).get('title');
        const favicon = new URLSearchParams(window.location.search).get('favicon');
        if (mainColor) response.data.mainColor = '#' + mainColor;
        if (subColor) response.data.subColor = '#' + subColor;
        if (title) response.data.title = title;
        if (favicon) response.data.favicon = favicon;
        return response.data;
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};

export const updateScene = async (payload: { id: string; [prop: string]: any }) => {
    try {
        const urlEnd = `api/v1/scenes`;
        const response = await api.put(urlEnd, payload);
        return response.data;
    } catch (err) {
        return Promise.reject(getErrorMsg(err));
    }
};
