import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { MarkerType } from 'core/three/object/type';
import { MarkerField } from 'constants/markerFields';

import { ReactComponent as ArrowIcon } from 'assets/images/icon/arrow-down.svg';

import Collapse from '@material-ui/core/Collapse';
import TitlePropertyBox from './PropertyBox/TitlePropertyBox';
import DetailPropertyBox from './PropertyBox/DetailPropertyBox';
import IconTagPropertyBox from './PropertyBox/IconTagPropertyBox';
import CompanyInfoPropertyBox from './PropertyBox/CompanyInfoPropertyBox';
import TextPropertyBox from './PropertyBox/TextPropertyBox';
import StylePropertyBox from './PropertyBox/StylePropertyBox';
import VolumePropertyBox from './PropertyBox/VolumePropertyBox';
import PricePropertyBox from './PropertyBox/PricePropertyBox';

const ContentSetting = ({ type, fields }: { type: MarkerType; fields: MarkerField }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const onCloseEvent = () => {
        setOpen((prev) => !prev);
    };

    return (
        <>
            <div className="property-slide-bar" onClick={onCloseEvent}>
                {t('propertyMenu.contentSetting')}
                <button>
                    <ArrowIcon className={open ? 'fa-icon-open' : 'fa-icon-close'} />
                </button>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div>
                    {fields.name && <TitlePropertyBox />}
                    {fields.price && <PricePropertyBox />}
                    {fields.description && <DetailPropertyBox />}
                    {fields.iconTag && <IconTagPropertyBox />}
                    {fields.companyInfo && <CompanyInfoPropertyBox />}
                    {fields.textContent && <TextPropertyBox />}
                    {fields.textStyle && <StylePropertyBox />}
                    {fields.volume && <VolumePropertyBox />}
                </div>
            </Collapse>
        </>
    );
};

export default ContentSetting;
