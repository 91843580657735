import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import ThreeContext from 'app/pages/three-context';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';

import './SpacePropertyBox.css';

enum UIComponetKey {
    value = 'value',
}
enum PropertyKey {
    linkUrl = 'linkUrl',
}
interface IPropertyData {
    linkUrl: string;
}
const SpacePropertyBox: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const { markerUpdater, currentMarker: propertyData } = useContext(TagEditorContext);

    const handleChange = (propertykey: PropertyKey, key: UIComponetKey) => {
        return function (event, newValue?) {
            markerUpdater.call({ ...propertyData, [propertykey]: newValue ?? event.target[key] });
        };
    };

    return (
        <div className="SpacePropertyBox">
            <div className="BoxTitle">{t(`propertyText.SpaceID`) + ':'}</div>
            <TextField
                id="spaceId"
                placeholder={t(`propertyText.SpaceID`)}
                variant="outlined"
                value={propertyData.linkUrl ?? '穿越空間'}
                onChange={handleChange(PropertyKey.linkUrl, UIComponetKey.value)}
                size="small"
            />
        </div>
    );
};

export default SpacePropertyBox;
