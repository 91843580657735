import * as THREE from 'three';
import CubemapMaterial from './shader/CubemapMaterial';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';

export default class PanoramaMesh extends THREE.Mesh {
    public cubemapMaterial: CubemapMaterial;
    constructor(glb: THREE.Object3D) {
        function mergeGeosWithBbox(glb: THREE.Object3D, size = 3000): THREE.BufferGeometry {

            const geometries: THREE.BufferGeometry[] = [];

            glb.traverse((node: THREE.Mesh) => {
                if (!node.isMesh) return;
                const cloneGeometry = node.geometry.clone().applyMatrix4(node.matrixWorld);
                delete cloneGeometry.attributes.uv;
                delete cloneGeometry.attributes.uv1;
                delete cloneGeometry.attributes.uv2;
                delete cloneGeometry.attributes.uv3;
                delete cloneGeometry.attributes.normal;
                delete cloneGeometry.attributes.skinIndex;
                delete cloneGeometry.attributes.skinWeight;
                delete cloneGeometry.attributes.color;
                delete cloneGeometry.attributes.tangent;
                cloneGeometry.morphTargetsRelative = false;
                cloneGeometry.morphAttributes = {};
                geometries.push(cloneGeometry);
            });

            const boxGeometry = new THREE.BoxGeometry(size, size, size);
            delete boxGeometry.attributes.normal
            delete boxGeometry.attributes.uv
            const positions = boxGeometry.getAttribute('position');
            for (let i = 0; i < positions.count; i++) {
                positions.setX(i, -positions.getX(i));
            }
            if (!geometries.length) return boxGeometry
            const mergedGeometry = mergeGeometries(geometries)
            if (mergedGeometry.index == null) boxGeometry.setIndex(null)
            return mergeGeometries([mergedGeometry, boxGeometry])
        }

        const geometry = mergeGeosWithBbox(glb);
        const material = new CubemapMaterial();

        super(geometry, material);

        this.cubemapMaterial = material;
    }

    resetCubemapMaterial() {
        const oldCubemapMaterial = this.cubemapMaterial;
        this.cubemapMaterial = new CubemapMaterial();
        this.material = this.cubemapMaterial;
        this.material.needsUpdate = true;

        oldCubemapMaterial?.dispose();
    }
}
