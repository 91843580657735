import React, { useEffect } from 'react';

import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

interface ISnackbar extends SnackbarOrigin {
    open: boolean;
}

interface Props {
    message?: string;
    open?: boolean;
    vertical?: 'top' | 'bottom';
    horizontal?: 'center' | 'right' | 'left';
    severity?: 'error' | 'success' | 'info' | 'warning';
    onClose?: () => void;
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarPopup = ({
    message = '',
    open = false,
    vertical = 'top',
    horizontal = 'center',
    severity = 'success',
    onClose,
}: Props) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={onClose}
            message={message}
            key={vertical + horizontal}
        >
            <Alert onClose={onClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarPopup;
