import React, { useEffect, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tutorialContainer: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0,0.6)',
        zIndex: theme.zIndex.tooltip,
        flexDirection: 'column',
        '& img': {
            marginBottom: 50,
        },
    },
    hide: {
        display: 'none',
    },
    description: {
        lineHeight: 2,
        fontSize: 20,
        color: '#fff',
    },
}));

const Tutorial = () => {
    const [showTutorial, setShowTutorial] = useState(true);
    const styles = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        const hasFinishedTut = localStorage.getItem('finishedTagEditorTutorial');
        if (hasFinishedTut) {
            setShowTutorial(false);
        }
    }, []);

    const handleClickTut = () => {
        localStorage.setItem('finishedTagEditorTutorial', '1');
        setShowTutorial(false);
    };

    return (
        <div
            className={`${styles.tutorialContainer} ${styles.flexCenter} ${showTutorial ? '' : styles.hide}`}
            onClick={handleClickTut}
        >
            <img src="/images/icon/tag-editor-tutorial.svg" alt="" />
            <div className={styles.description}>
                {t('tutorial.descriptionMouse')} <br />
                {t('tutorial.descriptionKeyboard')}
                <br />
                {t('tutorial.descriptionSwitchMode')}
                <br />
            </div>
        </div>
    );
};

export default Tutorial;
