import * as THREE from 'three';
import CustomObject3D from 'core/three/object/CustomObject3D';
import { EObjectType, MarkerType, ObjectType } from 'core/three/object/type';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
interface CustomLight {
    type: string;
    name: string;
    position: { x: number; y: number; z: number };
    intensity: number;
    color: string;
}

export default class Scene extends THREE.Scene {
    lights: THREE.Group;
    css3dScene: THREE.Scene;
    constructor() {
        super();
        this.lights = new THREE.Group();
        this.lights.name = 'lights';
        super.add(this.lights);

        this.background = new THREE.Color(0x999999);
        const hemiLight = new THREE.HemisphereLight();
        hemiLight.name = 'hemi_light';
        this.lights.add(hemiLight);

        const light1 = new THREE.AmbientLight(0xffffff, 0.3);
        light1.name = 'ambient_light';
        this.lights.add(light1);

        const light2 = new THREE.DirectionalLight(0xffffff, 2.5);
        light2.position.set(0.5, 0, 0.866); // ~60º
        light2.name = 'main_light';
        this.lights.add(light2);

        this.css3dScene = new THREE.Scene();
        super.add(this.css3dScene);
    }

    public add(...obj: any[]): this {
        if (arguments.length > 1) {
            for (let i = 0; i < arguments.length; i++) {
                this.add(arguments[i]);
            }
            return this;
        }

        if (obj[0] instanceof CustomObject3D) {
            this.postprocess(obj[0].object, obj[0].type);
            if (obj[0].css3d) {
                this.css3dScene.add(obj[0].object);
            } else {
                super.add(obj[0].object);
            }
        } else if (obj) {
            // cancel default scene.add
            // console.warn( 'THREE object :', obj )
            super.add(...obj);
        }

        return this;
    }

    private postprocess(obj: THREE.Object3D, type: ObjectType) {
        /**
         *  remove all transparent pixel ( alpha below 0.1 )
         */
        const onBeforeCompile2DiscardTransparent = function (shader) {
            function insert(str, index, value) {
                return str.substr(0, index) + value + str.substr(index);
            }
            const mainEndIndex = shader.fragmentShader.lastIndexOf('}');
            const addOnshader = [`if(gl_FragColor.a < 0.1){`, `   discard;`, `}`, ``].join('\n');
            shader.fragmentShader = insert(shader.fragmentShader, mainEndIndex, addOnshader);
        };

        switch (type) {
            case MarkerType.TEXT:
            case MarkerType.IMAGE:
            case MarkerType.PORTAL:
            case MarkerType.TAG:
            case EObjectType.VISITOR: {
                obj.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.material.onBeforeCompile = onBeforeCompile2DiscardTransparent;
                    }
                });
                break;
            }
        }

        // arrange object render order
        switch (type) {
            case EObjectType.MOUSEICON: {
                obj.renderOrder = 100;
                break;
            }
            default: {
                obj.renderOrder = 0;
                break;
            }
        }
    }
}
