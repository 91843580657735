import * as THREE from 'three';
import { Capsule } from 'three/examples/jsm/math/Capsule';
import { collisionPersonLayer } from 'core/constants/layer';

/**
 * Stimulate a person with physic collision effect in 3d scene.
 * The physic engine is implemented by checking the horizontal and vertical raycasts from the person to the scene.
 */
export default class CollisionPerson extends THREE.Object3D {
    /** the height of collision person */
    public height: number = 1.5;
    /** the thickness of collision person */
    public radius: number = 0.3;
    /** the physic collision scene to be detected */
    public scene: THREE.Object3D = null; 
    public collisionEnabled: boolean = false;
    public isOnFloor: boolean = false;
    public capsule = new Capsule(); 
    public layer: number = collisionPersonLayer;
    private lastHorizonNormal: THREE.Vector3 = null;

    constructor() {
        super();
        this.capsule.set(new THREE.Vector3(0, this.height, 0), new THREE.Vector3(0, 0, 0), this.radius);
    }

    public setScene(scene: THREE.Object3D) {
        this.scene = scene;
        this.scene.layers.enable(this.layer);
    }

    public move(vec: THREE.Vector3): THREE.Vector3 {
        this.updateCapsule();
        this.applyCollide(vec);
        this.applyClimb(vec);
        this.capsule.translate(vec);
        this.position.add(vec);
        return vec;
    }

    public setPosition(vec: THREE.Vector3) {
        this.updateCapsule(vec);
        this.position.copy(vec);
        this.lastHorizonNormal = null;
        this.isOnFloor = false;
    }

    public get forth(): THREE.Vector3 {
        const vec = new THREE.Vector3();
        this.getWorldDirection(vec);
        vec.y = 0;
        vec.normalize();
        return vec;
    }

    public get back(): THREE.Vector3 {
        return this.forth.multiplyScalar(-1);
    }

    public get right(): THREE.Vector3 {
        return this.forth.clone().setY(0).normalize().cross(this.up);
    }

    public get left(): THREE.Vector3 {
        return this.right.multiplyScalar(-1);
    }

    public get down(): THREE.Vector3 {
        return this.up.clone().multiplyScalar(-1);
    }

    private updateCapsule(vec = this.position) {
        this.capsule.start.copy(vec);
        this.capsule.end.copy(vec);
        this.capsule.start.y += this.height;
    }

    private applyCollide(vec: THREE.Vector3) {
        const self = this;
        if (!this.collisionEnabled) return;
        if (!this.scene) return;
        applyHorizonCollision(vec);
        applyVerticalCollision(vec);

        function applyHorizonCollision(vec: THREE.Vector3) {
            if (vec.length() === 0) return;
            const rayKneeToKneeDest = new THREE.Raycaster(self.knee, vec, 0, vec.length() + self.radius);
            rayKneeToKneeDest.layers.set(self.layer);
            const collisions = rayKneeToKneeDest.intersectObject(self.scene, true);
            if (collisions.length) {
                const normal = collisions[0].face.normal;
                normal.applyEuler(new THREE.Euler().setFromRotationMatrix(self.scene.matrixWorld));
                normal.applyEuler(collisions[0].object.rotation);
                if (normal.dot(vec) > 0) normal.multiplyScalar(-1);

                // 當lastHorizonNormal與normal不同時 代表是兩面不同方向的牆 當兩牆角度差異大時 選擇停下 避免在兩牆中來回碰撞
                if (self.lastHorizonNormal?.angleTo(normal) > (30 / 180) * Math.PI) {
                    vec.setX(0);
                    vec.setZ(0);
                } else {
                    const depth = vec.length() + self.radius - collisions[0].distance;
                    vec.add(normal.multiplyScalar(depth));
                    self.lastHorizonNormal = normal;
                }
            } else {
                self.lastHorizonNormal = null;
            }
        }

        function applyVerticalCollision(vec: THREE.Vector3) {
            if (vec.length() === 0) return;
            const headDest = self.head.add(vec);
            const down = new THREE.Vector3(0, -1, 0);
            const kneeToHead = (self.height / 3) * 2;
            const rayHeadDestToKneeDest = new THREE.Raycaster(headDest, down, 0, kneeToHead);
            rayHeadDestToKneeDest.layers.set(self.layer);
            const collisions = rayHeadDestToKneeDest.intersectObject(self.scene, true);
            if (collisions.length) {
                vec.setScalar(0);
            }
        }
    }

    private applyClimb(vec: THREE.Vector3): THREE.Vector3 {
        if (!this.collisionEnabled) return;
        if (!this.scene) return;
        if (vec.length() === 0) return;

        const headDest = this.head.add(vec);
        const kneeHeight = this.height / 3;
        const bodyHeight = (this.height / 3) * 2;
        const down = new THREE.Vector3(0, -1, 0);
        const rayHeadDestToFloor = new THREE.Raycaster(headDest, down);
        rayHeadDestToFloor.layers.set(this.layer);
        const collisions = rayHeadDestToFloor.intersectObject(this.scene, true);
        if (collisions.length) {
            const distanceToHead = collisions[0].distance;
            const distanceToKnee = distanceToHead - bodyHeight;
            // climb up when the ground is getting higher but not high over kneeHeight
            const shouldClimb = this.height > distanceToHead && this.height - distanceToHead > kneeHeight;
            // if distanceToHead is short than height means able to stand on
            // if distanceToHead is a bit over than kneeHeight should be consider as on the floor to prevent gravity causing trumble
            this.isOnFloor = distanceToHead <= this.height + 0.01;
            if (shouldClimb) vec.setScalar(0);
            else if (this.isOnFloor) vec.y += kneeHeight - distanceToKnee;
        } else {
            this.isOnFloor = true;
        }
    }

    private get head() {
        return this.capsule.start.clone();
    }

    private get feet() {
        return this.capsule.end.clone();
    }

    private get knee() {
        const kneeHeight = this.height / 3;
        const kneePosition = this.feet.clone().add(new THREE.Vector3(0, kneeHeight, 0));
        return kneePosition;
    }
}
