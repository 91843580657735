import CustomObject3D from './CustomObject3D';
import Animated from './mixin/Animeted';
export class InteractionObject extends CustomObject3D {
    public onMouseEnter() {}
    public onMouseLeave() {}
    public onMouseMove() {}
    public onMouseUp() {}
    public onMouseDown() {}
    public onClick() {}
}

export default Animated(InteractionObject);
