import * as React from 'react';
import { useEffect } from 'react';
import './ThreeCanvas.css';
import TagEditor from 'app/three/ThreeViewPage/TagEditor';
import ProgressBar from 'app/components/ProgressBar';
import { TagEditorContext } from 'app/pages/TagEditor/TagEditor';
import { RaycastInfo } from 'core/three/object/RaycastObject';

interface IThreeCanvasProps {
    sceneData: any;
    onInit: (three: TagEditor) => void;
}

const ThreeCanvas: React.FunctionComponent<IThreeCanvasProps> = ({ sceneData, onInit }) => {
    const {
        markersMap: { setState: setContextMarkersMap },
        sideMenu,
        currentSlotId,
    } = React.useContext(TagEditorContext);
    const { currentMarkerId, markerUpdater } = React.useContext(TagEditorContext);
    const threeRef = React.useRef<TagEditor>(null);

    useEffect(() => {
        const userActions = {
            bindSlot: (slotId: string) => {
                sideMenu.setState(true);
                currentSlotId.setState(slotId);
            },
            clickMarker: (target?: RaycastInfo) => {
                if (target == null) {
                    markerUpdater.setAction(() => {});
                    currentMarkerId.setState('');
                } else {
                    currentMarkerId.setState(target.json.id || target.json.uuid);
                    target.editable && markerUpdater.setAction(target.setJson.bind(target));
                }
            },
            clearContextmap: () => {
                setContextMarkersMap({});
            },
        };
        //Init when component mount
        const three = new TagEditor(userActions);
        threeRef.current = three;
        three.init(sceneData);
        three.animate();
        onInit(three);

        return () => {
            three.clear();
        };
    }, []);

    return (
        <div className="viewerContainer">
            <div id="css3d"></div>
            <canvas
                id="three-root"
                onDragOver={(e) => {
                    e.preventDefault();
                }}
                tabIndex={1}
            />
        </div>
    );
};

export default ThreeCanvas;
