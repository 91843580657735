import * as THREE from 'three';
import Scene from 'core/three/scene/Scene';
import { RaycastInfo } from 'core/three/object/RaycastObject';
import AnimationMgr from 'core/three/base/AnimationMgr';
import MouseCursor from 'core/three/object/mousecursor/MouseCursor';
import ObjectController from 'core/three/base/ObjectController';
import PointerRaycaster from 'core/three/base/PointerRaycaster';
import Media from 'core/three/object/media/Media';
import { IMarker } from 'core/types/media';
import { updateScene } from 'api/scene';
import UndoRedoController from 'core/three/base/UndoRedoController';
import { PreviousStatus } from 'core/three/TransfromControls/TransformControls';
import { getRandom } from 'utils';
import HotspotMgr from 'core/three/base/HotspotMgr';
import SceneModel from 'core/three/object/sceneModel/SceneModel';
import Hotspot from 'core/three/object/hotspot/Hotspot';
import SceneSlot from 'core/three/object/sceneSlot/SceneSlot';
import SceneSlotMgr from 'core/three/base/SceneSlotMgr';
import CustomObject3D from 'core/three/object/CustomObject3D';
import PreviewBox from 'core/three/object/previewBox/PreviewBox';
import ThumbnailModel from 'core/three/object/media/model/ThumbnailModel';
import MarkerMgr from 'core/three/base/MarkerMgr';
import SceneMgr from 'core/three/base/SceneMgr';
import RaycasterMgr from 'core/three/base/RaycasterMgr';
import { EquippedPromise } from 'core/utils';
import { MarkerType, MediaStatus } from 'core/three/object/type';
import ViewportControls from 'core/three/base/ViewportControls';
import RendererMgr from 'core/three/base/RendererMgr';
import ThirdPersonControls from 'core/three/base/ThirdPersonControls';
import { RAYCASTTYPE } from 'core/three/base/Raycaster';
import CameraMgr from 'core/three/base/CameraMgr';
import PanoramaAnimator from 'core/three/base/PanoramaAnimator';
import ViewportAnimator from 'core/three/base/ViewportAnimator';
import { RenderFrame } from 'core/types';
import AvatarModel from 'core/three/object/media/model/AvatarModel';
import PMREMGenerator from 'core/three/base/PMREMGenerator';
import Model from 'core/three/object/media/model/Model';

// debugging code
declare global {
    interface Window {
        three: any;
    }
}

interface UserActions {
    bindSlot: (slotId: string) => void;
    clickMarker: (target?: RaycastInfo) => void;
    clearContextmap: () => void;
}

enum Limitation {
    floorHeight = 0.5,
}

enum MovingMode {
    Freeform = 0,
    Hotspot = 1,
}

export default class TagEditor {
    public animationMgr: AnimationMgr;
    public hotspotMgr: HotspotMgr;
    public viewportControls: ViewportControls;
    public personControls: ThirdPersonControls;
    public undoRedoController: UndoRedoController;
    public raycasterMgr: RaycasterMgr;
    public sceneMgr: SceneMgr;
    public sceneSlotMgr: SceneSlotMgr;
    public pointerRaycaster: PointerRaycaster;
    public rendererMgr: RendererMgr;
    public markerMgr: MarkerMgr;
    public cameraMgr: CameraMgr;
    public panoramaAnimator: PanoramaAnimator;
    public viewportAnimator: ViewportAnimator;
    public objectController: ObjectController;
    public pmremGenerator: PMREMGenerator;
    mousecursor: MouseCursor = null;
    scene: Scene = null;
    movingMode: MovingMode;
    userActions: UserActions;
    container: HTMLCanvasElement;
    cssRoot: HTMLDivElement;
    allowStick2Floor: boolean = true;
    data: any;
    firstLoad = true;
    inited = new EquippedPromise();

    constructor(userActions) {
        window.three = this; // debugging code
        this.userActions = userActions;
        this.scene = new Scene();
        this.container = document.getElementById('three-root') as HTMLCanvasElement;
        this.cssRoot = document.getElementById('css3d') as HTMLDivElement;

        this.sceneMgr = new SceneMgr();
        this.cameraMgr = new CameraMgr(false /** supportXr */);
        this.hotspotMgr = new HotspotMgr();
        this.raycasterMgr = new RaycasterMgr();
        this.markerMgr = new MarkerMgr();
        this.personControls = new ThirdPersonControls(this.container);
        this.sceneSlotMgr = new SceneSlotMgr();
        this.rendererMgr = new RendererMgr(this.container, this.cssRoot);
        this.pmremGenerator = new PMREMGenerator(this.rendererMgr.renderer);
        this.panoramaAnimator = new PanoramaAnimator(this.sceneMgr);
        this.viewportControls = new ViewportControls(this.container, this.cameraMgr.movingCamera);
        this.viewportAnimator = new ViewportAnimator(this.viewportControls);
        this.animationMgr = new AnimationMgr(this.hotspotMgr, this.panoramaAnimator, this.viewportAnimator);
        this.pointerRaycaster = new PointerRaycaster(this.raycasterMgr, this.cameraMgr, this.container);
        this.undoRedoController = new UndoRedoController();

        this.registerPointerMgrEvents();
        this.registerSceneMgrEvents();
        this.registerAnimationMgrEvents();
        this.registerMarkerMgrEvents();
        this.registerHotspotMgrEvents();
        this.registerSceneSlotMgrEvents();
        this.registerRenderMgrEvents();
        this.registerPersonControlsEvents();
        this.registerViewportControlsEvents();
        this.registerKeyBoardEvents();
    }

    public async init(scene) {
        this.data = scene;

        this.rendererMgr.init();
        this.undoRedoController.init(this.container);
        this.pointerRaycaster.init();
        this.viewportControls.init();
        this.pmremGenerator.init();

        await this.sceneMgr.load(scene.glbUrl, scene.isMp, true);

        ThumbnailModel.renderer = this.rendererMgr.renderer;
        Model.cubeRenderTarget = this.pmremGenerator.renderTarget;
        // this.animationMgr.enablePanoramaMesh = false;
        this.animationMgr.enableCubeMap = false;
        this.personControls.enableUpAndDown = true;
        this.movingMode = null;

        this.initAnimation();
        this.initMousecursor();
        this.initHotspots();
        this.initObjectController();
        this.inited.resolve();
    }

    public animate() {
        this.rendererMgr.start(this.scene, this.cameraMgr.renderingCamera);
    }

    public initMarkers(markers: IMarker[] = this.data.markers) {
        this.data.markers = markers;
        this.data.markers.forEach((marker) => (marker.editable = true));
        /*
         * Set undoRedoId for undo redo action
         * The reason for not using ID is that ID is used to determine whether this data has been created in the backend.
         * Same as UUID, UUID should not be used since objects may be created and deleted repeatedly in the Three.js environment.
         */
        this.data.markers.forEach((marker) => (marker.undoRedoId = marker.id));
        this.objectController.detachEvent();
        this.markerMgr.resetMedia();
        this.userActions.clearContextmap();
        this.userActions.clickMarker(null);
        this.undoRedoController.clear();
        this.markerMgr.create(markers);
    }

    private initAnimation() {
        const initialPosition = this.data.initialPosition || { x: 0, y: 1.5, z: 0 };
        const initialAzimuthAngle = this.data.initialAzimuthAngle ?? Math.PI;
        const initialPolarAngle = this.data.initialPolarAngle ?? Math.PI / 2;
        const result = this.animationMgr.godown(initialPosition, {
            azimuthAngle: initialAzimuthAngle,
            polarAngle: initialPolarAngle,
        });
        result.animation.then(() => {
            this.setToFreeFormMode();
        });
    }

    private initMousecursor() {
        this.mousecursor = new MouseCursor();
        this.scene.add(this.mousecursor);
    }

    private initHotspots() {
        this.hotspotMgr.resetHotspots();
        this.hotspotMgr.createHotspots(this.data.sceneHotspots);
        this.hotspotMgr.hideHotspots();
    }

    private initObjectController() {
        this.objectController = new ObjectController(
            this.viewportControls,
            this.rendererMgr,
            this.container,
            this.cameraMgr.movingCamera,
        );
        this.objectController.onClick = this.userActions.clickMarker;
        this.objectController.onObjectTransformUpdate = this.onObjectTransformUpdate.bind(this);
        this.objectController.setTransformCtrlLimitBboxMode(false);
        /**
         *  make transform object on floor
         */
        this.objectController.setRaycastScene((pointer) => {
            const intersect = this.pointerRaycaster.getPositionNormal();
            let result: any = false;
            if (intersect) {
                const boundary = new THREE.Box3();
                const normal = intersect.worldNormal;
                boundary.setFromObject(this.scene);
                result = {
                    point: intersect.position.add(normal.clone().multiplyScalar(1e-2 * 0.5)),
                    face: {
                        normal: intersect.worldNormal,
                    },
                    object: this.sceneMgr.sceneModel.object,
                };
                if (
                    !this.allowStick2Floor &&
                    Math.ceil(normal.y) === 1 &&
                    Math.floor(intersect.position.y) < Limitation.floorHeight
                ) {
                    return false;
                }
            }
            return result;
        });
        this.scene.add(this.objectController.controls);
    }

    private registerSceneMgrEvents() {
        this.sceneMgr.addEventListener('created', ({ data: sceneModel }) => {
            this.raycasterMgr.addTarget(sceneModel, RAYCASTTYPE.GETPOSITION);
            this.scene.add(sceneModel);
        });
    }

    private registerHotspotMgrEvents() {
        this.hotspotMgr.addEventListener(this.hotspotMgr.created, ({ data: hotspot }) => {
            this.raycasterMgr.addTarget(hotspot);
            this.scene.add(hotspot);
        });

        this.hotspotMgr.addEventListener(this.hotspotMgr.deleted, ({ data: hotspot }) => {
            this.raycasterMgr.removeTarget(hotspot);
            this.scene.remove(hotspot.object);
        });
    }

    private registerSceneSlotMgrEvents() {
        this.sceneSlotMgr.addEventListener(this.sceneSlotMgr.created, (event) => {
            this.raycasterMgr.addTarget(event.data);
            this.scene.add(event.data);
        });
        // todo: finish deleted
    }

    private registerMarkerMgrEvents() {
        this.markerMgr.addEventListener(this.markerMgr.inited, ({ data: media }) => {
            // normalize model dimension to 1 to better editing experience
            if (media.type === MarkerType.MODEL && media.status === MediaStatus.CREATED) {
                const size = new THREE.Vector3();
                const bbox = new THREE.Box3().setFromObject(media.object);
                bbox.getSize(size);
                const maxSize = Math.max(size.x, size.y, size.z);
                media.object.scale.multiply(new THREE.Vector3(1 / maxSize, 1 / maxSize, 1 / maxSize));
                media.markStatusUpdate();
            }
        });

        this.markerMgr.addEventListener(this.markerMgr.inited, ({ data: media }) => {
            this.raycasterMgr.addTarget(media);
            this.scene.add(media);
        });

        this.markerMgr.addEventListener(this.markerMgr.deleted, ({ data: media }) => {
            this.raycasterMgr.removeTarget(media);
            this.scene.remove(media.object); // todo: same interface as add
        });
    }

    private registerAnimationMgrEvents() {
        this.animationMgr.addEventListener('viewModeChangeStart', (event) => {
            this.viewportControls.enabled = false;
        });

        this.animationMgr.addEventListener('viewModeChangeEnd', (event) => {
            this.viewportControls.enabled = true;
        });

        this.animationMgr.addEventListener('viewModeChangeStart', (event) => {
            this.viewportControls.unsetViewLimit();
        });

        this.animationMgr.addEventListener('viewModeChangeEnd', (event) => {
            if (event.data.to === 'ground') this.viewportControls.setFirstpersonViewLimit();
            if (event.data.to === 'dollhouse') this.viewportControls.setTopViewLimit();
            if (event.data.to === 'floorplan') this.viewportControls.setOrthographicLimit();
        });

        this.animationMgr.addEventListener('viewModeChangeEnd', (event) => {
            if (event.data.to === 'ground') {
                if (this.firstLoad) {
                    this.initMarkers();
                    this.viewportControls.distance = 0;
                    this.firstLoad = false;
                }
            }
        });
    }

    private registerPersonControlsEvents() {
        this.personControls.addEventListener(this.personControls.positionChange, ({ data }) => {
            this.viewportControls.move(new THREE.Vector3(data.movement.x, data.movement.y, data.movement.z));
        });
    }

    private registerPointerMgrEvents() {
        this.raycasterMgr.on('mouseup', {
            targets: [Hotspot],
            handler: (target, position) => {
                if (this.movingMode !== MovingMode.Hotspot) return;
                this.animationMgr.goHotspot(target.id);
            },
        });

        this.raycasterMgr.on('mouseup', {
            targets: [],
            handler: () => {
                this.objectController.detachEvent();
                this.userActions.clickMarker(null);
            },
        });

        this.raycasterMgr.on('mouseup', {
            targets: [Media],
            exclude: [SceneModel],
            handler: (target, position, rotation) => {
                if (this.animationMgr.currentViewMode !== 'ground') return;
                this.objectController.raycastMouseUp({ target, position, rotation, raycaster: undefined });
            },
        });

        this.raycasterMgr.on('mousemove', {
            targets: [Media],
            exclude: [SceneModel],
            handler: (target, position, rotation) => {
                if (this.animationMgr.currentViewMode !== 'ground') return;
                this.objectController.raycastMouseMove({ target, position, rotation, raycaster: undefined });
            },
        });

        this.raycasterMgr.on('click', {
            targets: [SceneSlot],
            handler: (target: SceneSlot) => {
                this.userActions.bindSlot(target.id);
            },
        });
    }

    private registerViewportControlsEvents() {
        this.viewportControls.addEventListener(this.viewportControls.change, () => {
            this.personControls.direction = this.cameraMgr.getDirection();
        });
    }

    private registerRenderMgrEvents() {
        this.rendererMgr.addEventListener(this.rendererMgr.resized, ({ data }) => {
            this.cameraMgr.aspect = data.width / data.height;
        });

        this.rendererMgr.addEventListener(this.rendererMgr.preRender, ({ data: diff }) => {});

        this.rendererMgr.addEventListener(this.rendererMgr.postRender, ({ data: diff }) => {
            const renderFrame: RenderFrame = {
                environment: 'editor',
                isXrMode: false,
                cameraDirection: this.cameraMgr.getDirection(),
                cameraPosition: this.cameraMgr.getPosition(),
                cameraQuaternion: this.cameraMgr.getQuaternion(),
                xrPose: null,
                xrViewerPose: null,
                delteSec: diff / 1000,
                fpsStat: 'normal',
            };
            this.viewportControls.update();
            this.cameraMgr.update();
            this.personControls.update(renderFrame);
            this.sceneMgr.update(renderFrame);
            this.markerMgr.update(renderFrame);
        });
    }

    public setInitialCamera(sceneId: string, t, toast) {
        const initialPosition = this.cameraMgr.getPosition();
        const initialPolarAngle = this.viewportControls.polarAngle;
        const initialAzimuthAngle = this.viewportControls.azimuthAngle;
        updateScene({
            id: sceneId,
            initialPosition,
            initialPolarAngle,
            initialAzimuthAngle,
        }).then(() => {
            toast('success', t('MessageBox.SaveSuccessfully'));
        });
    }

    //TODO: refactor create object when mouse up event and check limit in box
    public async createObject(data, activeByUndoRedo: boolean) {
        const slotData = this.sceneSlotMgr.sceneSlotsById[data.slotId];
        // create marker in far away positionto prevent that object creating in limit box mode will trigger bug
        const defaultCreatePos = { x: 0, y: 0, z: 0 };
        let mediaData: IMarker = JSON.parse(JSON.stringify(data));
        mediaData.position = slotData?.object.position || mediaData.position || defaultCreatePos;
        mediaData.rotation = slotData?.object.quaternion || mediaData.rotation || { x: 0, y: 0, z: 0, w: 0 };
        mediaData.scale = slotData?.object.scale || mediaData.scale || { x: 1, y: 1, z: 1 };
        mediaData.editable = mediaData.editable || true;
        mediaData.slotId = slotData?.id || mediaData.slotId;
        mediaData.undoRedoId = mediaData.undoRedoId || getRandom(20);
        mediaData.translateEnabled = slotData?.translateEnabled ?? mediaData.translateEnabled;
        mediaData.rotateEnabled = slotData?.rotateEnabled ?? mediaData.rotateEnabled;
        mediaData.scaleEnabled = slotData?.scaleEnabled ?? mediaData.scaleEnabled;

        const previewBox = new PreviewBox(1, 1, mediaData.type === 'MODEL' ? 1 : 0.1);

        this.scene.add(previewBox);
        if (!activeByUndoRedo) {
            this.objectController.attachNewObject(previewBox);
        }

        const newMedia = await this.markerMgr.create([mediaData]);

        const custumObject3d = newMedia[0];
        if (custumObject3d == null) return;

        const { object } = custumObject3d;

        if (!activeByUndoRedo) {
            this.objectController.attachTransformControl(custumObject3d);
        }
        object.position.copy(previewBox.object.position);
        previewBox.destroy();

        if (activeByUndoRedo) {
            return {
                action: async (activeByUndoRedo: boolean) => {
                    const result = await this.deleteObject.bind(this)(custumObject3d.json.undoRedoId, activeByUndoRedo);
                    return result;
                },
            };
        } else {
            this.undoRedoController.pushNewAction({
                action: async (activeByUndoRedo: boolean) => {
                    const result = await this.deleteObject.bind(this)(custumObject3d.json.undoRedoId, activeByUndoRedo);
                    return result;
                },
            });
            this.selectObject(custumObject3d);
        }
    }

    deleteObject(undoRedoId: string, activeByUndoRedo: boolean) {
        const { detachEvent, selectedInfo } = this.objectController;
        let objectJson = null;
        if (typeof undoRedoId !== 'string') {
            if (selectedInfo) {
                objectJson = JSON.parse(JSON.stringify(selectedInfo.json));
                detachEvent();
                selectedInfo.destroy();
                this.userActions.clickMarker(null);
            }
        } else {
            if (selectedInfo && undoRedoId === (selectedInfo.json as any)?.undoRedoId) {
                objectJson = JSON.parse(JSON.stringify(selectedInfo.json));
                detachEvent();
                selectedInfo.destroy();
                this.userActions.clickMarker(null);
            } else {
                const media = this.markerMgr.mediaList.find((m) => m.undoRedoId === undoRedoId);
                if (!media) return;
                objectJson = JSON.parse(JSON.stringify(media.json));
                media.destroy();
            }
        }

        if (objectJson) {
            if (!activeByUndoRedo) {
                this.undoRedoController.pushNewAction({
                    action: async (activeByUndoRedo: boolean) => {
                        const result = await this.createObject.bind(this)(objectJson, activeByUndoRedo);
                        return result;
                    },
                });
            } else {
                return {
                    action: async (activeByUndoRedo: boolean) => {
                        const result = await this.createObject.bind(this)(objectJson, activeByUndoRedo);
                        return result;
                    },
                };
            }
        }
    }

    getObjectByUndoRedoId(undoRedoId: string) {
        return this.markerMgr.mediaList.find((media) => media.undoRedoId === undoRedoId);
    }

    selectObjectById(uuid: string) {
        this.selectObject(this.markerMgr.mediaList.find((media) => media.uuid === uuid));
    }

    selectObject(media: CustomObject3D) {
        this.container.focus();
        if (media == null) return;
        this.objectController.selectedInfo = media;
        this.objectController.raycastMouseUp({
            target: media,
            position: undefined,
            rotation: undefined,
            raycaster: undefined,
        });
    }

    onObjectTransformUpdate(pre: PreviousStatus, current: PreviousStatus, undoRedoId: string) {
        const transformAction = (pre: PreviousStatus, current: PreviousStatus, undoRedoId: string) => {
            return () => {
                const media = this.markerMgr.mediaList.find((media) => media.undoRedoId === undoRedoId);
                if (!media) return;
                media.object.position.copy(pre.position);
                media.object.rotation.copy(pre.rotation);
                media.object.scale.copy(pre.scale);
                this.markerMgr.onMediaUpdate(media);
                return {
                    action: transformAction(current, pre, undoRedoId),
                };
            };
        };
        this.undoRedoController.pushNewAction({
            action: transformAction(pre, current, undoRedoId),
        });
    }

    private registerKeyBoardEvents() {
        const detachSelectedObject = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                this.objectController.detachEvent();
                this.userActions.clickMarker(null);
            }
        };

        const toggleMoveMode = (event: KeyboardEvent) => {
            if (event.key === 'p' || event.key === 'P') {
                if (this.animationMgr.currentViewMode !== 'ground' && this.animationMgr.isPlaying) return;
                if (this.data.sceneHotspots.length < 1) return;

                if (this.movingMode === MovingMode.Freeform) {
                    this.animationMgr.enableCubeMap = true;
                    const result = this.animationMgr.goNearHotspot(this.cameraMgr.getPosition());
                    if (result.hotspotId) {
                        this.setToHotspotMode();
                    }
                } else {
                    this.animationMgr.enableCubeMap = false;
                    this.setToFreeFormMode();
                }
            }
        };

        this.container.addEventListener('keydown', detachSelectedObject);
        this.container.addEventListener('keydown', toggleMoveMode);

        return () => {
            this.container.removeEventListener('keydown', detachSelectedObject);
            this.container.removeEventListener('keydown', toggleMoveMode);
        };
    }

    private setToHotspotMode() {
        if (this.movingMode === MovingMode.Hotspot) return;
        this.movingMode = MovingMode.Hotspot;
        this.hotspotMgr.showHotspots();
        this.mousecursor.visible = true;
        this.objectController.enabled = true;
        this.personControls.enabled = false;
        this.sceneMgr.sceneModel.panoramaAlpha = 1;
        this.sceneMgr.sceneModel.glbVisible = false;
    }

    private setToFreeFormMode() {
        if (this.movingMode === MovingMode.Freeform) return;
        this.movingMode = MovingMode.Freeform;
        this.hotspotMgr.hideHotspots();
        this.mousecursor.visible = false;
        this.objectController.enabled = true;
        this.personControls.enabled = true;
        this.sceneMgr.sceneModel.panoramaAlpha = 0;
        this.sceneMgr.sceneModel.glbVisible = true;
    }

    public clear() {
        // todo: clear mgr events
        THREE.Cache.clear();
    }
}
