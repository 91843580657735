import * as THREE from 'three';
import { throttle } from 'core/utils';
import Raycaster from './Raycaster';
import RaycasterMgr from './RaycasterMgr';
import CameraMgr from './CameraMgr';

export default class PointerRaycaster extends Raycaster {
    public domElement: HTMLElement;
    public cameraMgr: CameraMgr
    private pointer = new THREE.Vector2();
    private downPointer = new THREE.Vector2();
    private pointerUpThreshold: number = 0.1;
    private addEventListeners: () => void;
    private removeEventListeners: () => void;

    constructor(raycasterMgr: RaycasterMgr, cameraMgr: CameraMgr, domElement: HTMLElement) {
        super(raycasterMgr)
        this.domElement = domElement
        this.cameraMgr = cameraMgr
    }

    public init() {
        const eventListeners = {
            pointerMove: throttle(this.onPointerMove.bind(this), 50),
            updatePointer: this.updatePointer.bind(this),
            pointerDown: this.onPointerDown.bind(this),
            pointerUp: this.onPointerUp.bind(this),
            touchEnd: this.onPointerUp.bind(this),
            touchStart: this.onPointerDown.bind(this),
        };

        this.addEventListeners = () => {
            this.domElement.addEventListener('mousemove', eventListeners.pointerMove, false);
            this.domElement.addEventListener('mousemove', eventListeners.updatePointer, false);
            this.domElement.addEventListener('mousedown', eventListeners.pointerDown, false);
            window.addEventListener('mouseup', eventListeners.pointerUp, false);
            window.addEventListener('touchend', eventListeners.touchEnd, false);
            this.domElement.addEventListener('touchstart', eventListeners.touchStart, false);
        };

        this.removeEventListeners = () => {
            this.domElement.removeEventListener('mousemove', eventListeners.pointerMove, false);
            this.domElement.removeEventListener('mousemove', eventListeners.updatePointer, false);
            this.domElement.removeEventListener('mousedown', eventListeners.pointerDown, false);
            window.removeEventListener('mouseup', eventListeners.pointerUp, false);
            window.removeEventListener('touchend', eventListeners.touchEnd, false);
            this.domElement.removeEventListener('touchstart', eventListeners.touchStart, false);
        };

        this.addEventListeners();
    }

    public dispose() {
        this.removeEventListeners();
    }

    public getPositionNormal() {
        // todo: remove. use RaycasterMgr event
        return this.raycast(this.raycasterMgr.castPositionNormalTargets, true);
    }

    protected onPointerDown(event: TouchEvent | PointerEvent): void {
        this.updatePointer(event);
        this.updatePointer(event, this.downPointer);
        this.raycaster.setFromCamera(this.pointer, this.cameraMgr.movingCamera);
        super.onRaycasterDown();
    }

    protected onPointerMove(event: TouchEvent | PointerEvent): void {
        this.updatePointer(event);
        this.raycaster.setFromCamera(this.pointer, this.cameraMgr.movingCamera);
        super.onRaycasterMove();
    }

    protected onPointerUp(event: TouchEvent | PointerEvent): void {
        this.updatePointer(event);
        this.raycaster.setFromCamera(this.pointer, this.cameraMgr.movingCamera);
        super.onRaycasterUp();
        this.onPointerClick();
    }

    protected onPointerClick(): void {
        if (this.downPointer.distanceTo(this.pointer) > this.pointerUpThreshold) return; // todo: check in MouseControls
        super.onRaycasterClick();
    }

    private updatePointer(event: TouchEvent | PointerEvent | MouseEvent, pointer = this.pointer) {
        let x = 0;
        let y = 0;
        if (typeof TouchEvent !== 'undefined' && event instanceof TouchEvent) {
            const { left, top } = this.domElement.getBoundingClientRect();
            x = event.changedTouches[0].clientX - left;
            y = event.changedTouches[0].clientY - top;
        } else if(event instanceof PointerEvent || event instanceof MouseEvent ){
            x = event.offsetX;
            y = event.offsetY;
        }
        pointer.x = (x / this.domElement.clientWidth) * 2 - 1;
        pointer.y = -(y / this.domElement.clientHeight) * 2 + 1;
    }
}
